import { Component, OnInit, Inject } from '@angular/core';
import { GenericPicklistModel, EmployeeGroupPicklistModel } from '../../model/picklist.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoaderService } from '../../services/loader.service';
import { PicklistService } from '../../services/picklist.service';
import { ToastService } from '../../services/toaster.service';
import { DocumentsService } from 'src/app/modules/admin/modules/documents/services/documents.service';
import { ErrorToasterComponent } from '../error-toaster/error-toaster.component';
import { ToasterComponent } from '../toaster/toaster.component';
@Component({
  selector: 'app-mat-share-document',
  templateUrl: './mat-share-document.component.html',
  styleUrls: ['./mat-share-document.component.scss']
})
export class MatShareDocumentComponent implements OnInit {
  form: FormGroup;
  LetterTitles: Object;
  populate: any;
  letterPreview: any;
  letterDownload: any;
  showPreviewButton: any;
  DocumentType = [{ id: '1', name: 'URL' }, { id: '2', name: 'File' }];
  FileInput: any = false;
  FolderInput = false;
  file: any;
  fileContent: { mimeType: any, size: any, title: any };
  Rename: boolean = false;
  organization: any = [];
  employeeGroup: any[];
  groups: any[];
  Access = [];
  ShowPublic = false;
  ShowShared = false;
  addFolder: boolean = false;
  accessId: any;
  constructor(
    public dialogRef: MatDialogRef<MatShareDocumentComponent>,
    private loaderService: LoaderService,
    private DocumentService: DocumentsService,
    private fb: FormBuilder,
    private pickListService: PicklistService,
    private toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.formGenerate();
  }


  formGenerate() {
    this.form = this.fb.group({
      // Type: new FormControl(''),
      title: '',
      Url: '',
      organizationId: '',
      employeeGroupId: '',
      accessTypeId: ''
    });
  }
  onSubmit() {
    if (localStorage.getItem('activeListing') == 'Private') {
      this.accessId = 2;
    }
    if (localStorage.getItem('activeListing') == 'Public') {
      this.accessId = 1;
    }
    if (localStorage.getItem('activeListing') == 'Shared') {
      this.accessId = 3;
    }
    const data = {
      parentId: this.data.value.parentId || null,
      folderId: this.data.value.RowId,
      accessTypeId: this.accessId,
      organizationId: +this.form.value.organizationId || null,
      employeeGroupId: this.form.value.employeeGroupId || null,
    };
    this.loaderService.show();
    this.DocumentService.ShareDocument(data).subscribe(data => {
      this.loaderService.hide();
      this.dialogRef.close(true);
    }, error => {
      this.errorSnackBar(error)
    });
  }

  AddFolder() {
    const data = {
      //for private scope(self)
      // accessTypeId: this.form.value.accessTypeId,
      accessTypeId: this.accessId,
      employeeGroupId: this.form.value.employeeGroupId || null,
      parentId: this.data.value.parentId || null,
      organizationId: +this.form.value.organizationId || null,
      title: this.form.value.title,
      isDirectory: true,
      type: 'Folder',
    };
    this.loaderService.show();
    this.DocumentService.PostDocument(data).subscribe(data => {
      this.loaderService.hide();
      this.dialogRef.close(true);
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }
  // getDocumentAccess() {
  //   this.DocumentService.getDocumentAccessList().subscribe((data: Array<GenericPicklistModel>) => {
  //     this.Access = data;
  //   });
  // }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  errorSnackBar(error) {
    this.toast.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(message) {
    this.toast.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }
}
