import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { CommonServiceService } from '../common-service.service';
import { HttpHandlerProvider } from '../http-handler.provider';
import {CMSFloorMapAccessList} from 'src/app/config/constant';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    public commonService: CommonServiceService,
    public httpHandlerProviderService: HttpHandlerProvider
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem('isLogged')) {
      this.httpHandlerProviderService.isLogged = true;
      if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('HEALTH_DASHBOARD')) {
        this.router.navigate(['/admin/healthcare-dashboard']);
      }
      else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('ENTITY_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/entity-overview']);
      }
      else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('VISITOR_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/visitor']);
      } else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('SEAT_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/seat']);
      }
      else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('MEET_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/meet']);
      } else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('HYGIENE_DASHBOARD')) {
        let getDeviceCategories = JSON.parse(localStorage.getItem("categories"));
        if (getDeviceCategories.includes('s300_aqi') || getDeviceCategories.includes('s300_atp')) {
          this.router.navigate(['/admin/enterprise-dashboard/hygiene-air']);
        }
        else if (getDeviceCategories.includes('s100_aqi') || getDeviceCategories.includes('s100_atp')) {
          this.router.navigate(['/admin/enterprise-dashboard/hygiene']);
        }

      } else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('CM_DASHBOARD')) {
      if(CMSFloorMapAccessList.customerIds.includes(localStorage.getItem('customerId'))){
        this.router.navigate(['/admin/enterprise-dashboard/continuous-monitoring']);
      }
      else{
        this.router.navigate(['/admin/enterprise-dashboard/continuous-monitoring/cms']);
      }


      } 
      // else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('ENTERPRISE_DASHBOARD')) {
      //   this.router.navigate(['/admin/enterprise-dashboard/visitor']);
      // } 
      else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('HYGIENE_MERGED_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/hygieneo']);
      }
      else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('SMART_WASHROOM_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/smart-washroom']);
      }
      
      return true;
    } else {
      if (route.routeConfig.path == '') {
        this.router.navigate(['/auth/login']);
        return false;
      } else {
        if (route.routeConfig.path == 'resetPasswordLinkSuccess') {
          if (localStorage.getItem('LinkSent') == 'true') {
            return true;
          }
        }
        if (route.routeConfig.path == 'urlExpired') {
          if (localStorage.getItem('UrlExpired') == 'true') {
            return true;
          }
        }
        this.router.navigate(['/auth/login']);
        return false;
      }
    }
    return true;
  }
}
