import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonServiceService } from 'src/app/core/common-service.service';

@Component({
  selector: 'app-patient-confirmation-form-field',
  templateUrl: './patient-configuration-form-field.html',
  styleUrls: ['./patient-configuration-form-field.scss']
})

export class PatientConfigurationFormFieldComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  isActive = true;
  searchText = '';
  max: null;
  screenWidth: number;
  showMobileTable: boolean;
  serviceName : string= '';
  type : string = '';
  constructor(
    private router: Router,
    private commonService: CommonServiceService
  ) {
    this.onResize();
  }

  ngOnInit() {
    this.serviceName = "patient-service";
    this.type = "Patient";
    // this.backLink = ;
    this.permissionsObject = this.commonService.setPermissions('PATIENT_FORMS_FIELD_CONFIGURATION');
    this.commonService.callToggleSkill.subscribe((data) => {
      this.change();
    });
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
  searchItem() {
    this.commonService.searchText = this.searchText.trim();
    this.commonService.sendSearchEvent();
  }

  change() {
    this.isActive = !this.isActive;
  }
  searchInputChanged() {
    this.commonService.searchText = this.searchText;
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }

  tabChange(event) {
    if(event === 0) {
      this.type = 'patient';
    } else {
      this.type = 'blacklist_visitor';
    }
  }

  navigateTo() {
    this.router.navigate(['admin/patient-list']);
  }
}
 