import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';


// ----------component--------------------------------------------------------------------------------------//
@Component({
  selector: 'app-blacklist-reason',
  templateUrl: './blacklist-reason.html',
  styleUrls: ['./blacklist-reason.scss']
})

export class BlacklistReasonComponent implements OnInit {
  searchText = '';
  message;
  actionsToggle = false;
  deleteButton: boolean;
  isActive: boolean;
  isList = true;
  screenWidth: number;
  showMobileTable: boolean;
  ipAddress: any;
  filterValue: any;
  filterView: boolean = false;
  isFilterImplemented: boolean = false;
  patientCodeArray = [];
  departmentArray = [];
  organisationArray = [];
  reportingManagerArray = [];
  public pageInfo: PaginationModel = new PaginationModel();
  patientStatusArray: any = [];
  permissionsObject = {read: false, create: false, update: false, delete: false};
  constructor(
    private commonService: CommonServiceService,
    private router: Router,
    private loaderService: LoaderService,
    public toastService: ToastService,
    private dialog: DialogService) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }
  toggleAction() {
    this.actionsToggle = !this.actionsToggle;
  }
  ngOnInit() {
    this.permissionsObject =  this.commonService.setPermissions('PATIENT_BLACKLIST_REASON');
    this.commonService.searchText = '';

    this.commonService.callToggle.subscribe((data) => {
      this.change();
    });

    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }

  }

  searchItem() {
    this.commonService.sendFilterCloseEvent();
    this.commonService.searchText = this.searchText.trim();
    this.commonService.sendSearchEvent();

  }
  searchInputChanged() {
    this.commonService.searchText = this.searchText;
  }

  // -----toggle--------------------------------------------------------------------------------------//
  change() {
    this.isActive = !this.isActive;
  }
  listGridView() {
    this.isList = !this.isList;
  }
  mapKeysOfFilter() {
    const body = {};
    this.departmentArray.length > 0 ? body['departments'] = this.departmentArray : null;
    this.organisationArray.length > 0 ? body['organizations'] = this.organisationArray : null;
    this.reportingManagerArray.length > 0 ? body['reportingManager'] = this.reportingManagerArray : null;
    this.patientCodeArray.length > 0 ? body['users'] = this.patientCodeArray : null;
    this.patientStatusArray.length > 0 ? body['patientStatus'] = this.patientStatusArray : null;
    return body;
  }
  
  errorSnackbar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(message: string, panelClass: string) {
    if (panelClass === 'delete-success') {
      this.toastService.opentoast({ toastName: 'deletePatientSuccess', data: { name: '' } });
    } else if (panelClass === 'Error-Code-400') {
      this.toastService.opentoast({ toastName: 'error400', data: { name: '' } });
    } else if (panelClass === 'Error-Code-401') {
      this.toastService.opentoast({ toastName: 'error401', data: { name: '' } });
    } else if (panelClass === 'Error-Code-404') {
      this.toastService.opentoast({ toastName: 'error404', data: { name: '' } });
    } else if (panelClass === 'Error-Code-409') {
      this.toastService.opentoast({ toastName: 'emplisterror409', data: { name: '' } });
    } else if (panelClass === 'Error-Code-500') {
      this.toastService.opentoast({ toastName: 'error500', data: { name: '' } });
    } else if (panelClass === 'BulkUpdateSuccess') {
      this.toastService.opentoast({ toastName: 'BulkUpdateSuccess', data: { name: '' } });
    } else if (panelClass === 'downloaded-csv-success') {
      this.toastService.opentoast({ toastName: 'Patient-List-Downloaded', data: { name: '' } });
    } else if (panelClass === 'downloaded-csv-No-data') {
      this.toastService.opentoast({ toastName: 'NoDataToExport', data: { name: '' } });
    }

  }

  addReason() {
    this.router.navigate(['admin/patient-list/add-blacklist-reason']);
  }
}
