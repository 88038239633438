export interface FilterSelectedModel {
  id: number;
}
export class selectedModel {
  constructor() {
    this.type = 0;
  }
  type: number;
}
export class ActionStatusFilter {
  constructor() {
    this.abbreviation = '';
    this.id = 0;
    this.type = '';
    this.status = '';
    this.actionStatus = '';
    this.selected = false;
  }
  actionStatus: string;
  abbreviation: string;
  id: number;
  status: string;
  type: string;
  selected: boolean;
}
export class Organisation {
  constructor() {
    this.code = '';
    this.id = 0;
    this.name = '';
    this.email = '';
    this.selected = false;
  }
  code: string;
  email: string;
  id: number;
  name: string;
  selected: boolean;
}
export class Position {
  constructor() {
    this.id = 0;
    this.title = '';
    this.selected = false;
  }
  id: number;
  title: string;
  selected: boolean;
}
export class Status {
  constructor() {
    this.id = 0;
    this.status = '';
    this.selected = false;
  }
  id: number;
  status: string;
  selected: boolean;
}
export class Candidate {
  constructor() {
    this.id = 0;
    this.name = '';
    this.email = '';
    this.selected = false;
  }
  email: string;
  id: number;
  name: string;
  selected: boolean;
}
export class AttendanceTypeFilter {
  constructor() {
    this.abbreviation = '';
    this.id = 0;
    this.type = '';
    this.status = '';
    this.actionStatus = '';
    this.selected = false;
  }
  actionStatus: string;
  abbreviation: string;
  id: number;
  status: string;
  type: string;
  selected: boolean;
}
export class PatientStatusFilter {
  constructor() {
    this.patientStatus = '';
    this.id = 0;
    this.status = '';
    this.selected = false;
  }
  patientStatus: string;
  id: number;
  status: string;
  selected: boolean;
}
export class LeaveTypeFilter {
  constructor() {
    this.abbreviation = '';
    this.id = 0;
    this.name = '';
    this.leaveType = '';
    this.statusId = '';
    this.selected = false;
  }
  abbreviation: string;
  id: number;
  name: string;
  statusId: string;
  leaveType: string;
  selected: boolean;
}
export class Department {
  constructor() {
    this.departmentCode = '';
    this.id = 0;
    this.name = '';
    this.organizationId = 0;
    this.organizationName = '';
    this.selected = false;

  }
  departmentCode: string;
  id: number;
  name: string;
  organizationId: number;
  organizationName: string;
  selected: boolean;

}

export class PatientFilter {
  constructor() {
    this.code = '';
    this.id = 0;
    this.fullName = '';
    this.firstName = '';
    this.lastName = '';
    this.middleName = '';
    this.email = '';
    this.selected = false;

  }
  code: string;
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  middleName: string;
  selected: boolean;

}

export class ReportingManagerFilter {
  constructor() {
    this.code = '';
    this.email = '';
    this.id = 0;
    this.name = '';
    this.selected = false;

  }
  code: string;
  email: string;
  id: number;
  name: string;
  selected: boolean;

}

export class OrganisationFilterResultModel {
  constructor() {
    this.content = new Array<Organisation>();
  }
  content: Organisation[];
  totalElements: number;
  totalPages: number;
}

export class ReportingManagerFilterResultModel {
  constructor() {
    this.content = new Array<ReportingManagerFilter>();
  }
  content: ReportingManagerFilter[];
  totalElements: number;
  totalPages: number;
}

export class DepartmentFilterResultModel {
  constructor() {
    this.content = new Array<Department>();
  }
  content: Department[];
  totalElements: number;
  totalPages: number;
}
export class AttendancetypeFilterResultModel {
  constructor() {
    this.content = new Array<AttendanceTypeFilter>();
  }
  content: AttendanceTypeFilter[];
}
export class PatientCodeFilterResultModel {
  constructor() {
    this.content = new Array<PatientFilter>();
  }
  content: PatientFilter[];
  totalElements: number;
  totalPages: number;
}

export class PositionFilterResultModel {
  constructor() {
    this.content = new Array<Position>();
  }
  content: Position[];
  totalElements: number;
  totalPages: number;
}

export class StatusFilterResultModel {
  constructor() {
    this.content = new Array<Status>();
  }
  content: Status[];
  totalElements: number;
  totalPages: number;
}
export class CandidateFilterResultModel {
  constructor() {
    this.content = new Array<Candidate>();
  }
  content: Candidate[];
  totalElements: number;
  totalPages: number;
}