import { Component, OnInit, ViewChild, OnDestroy, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { WorkOrderService } from '../../services/work-order.service';
import { WorkOrderModel } from '../../models/work-order.model';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from 'src/app/modules/shared/component/success-dialog/success-dialog';

@Component({
  selector: 'app-child-work-order-listview',
  templateUrl: './child-work-order-listview.html',
  styleUrls: ['./child-work-order-listview.scss'],
})
export class ChildWorkOrderListComponent implements OnInit, OnDestroy {
  @Input() workOrderId: number;
  public pageInfo: PaginationModel = new PaginationModel();
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  element: any = [];
  noResult: boolean;
  searchEventSubscription: Subscription;
  saveSearch: any = '';
  message;
  data;
  showPagination: boolean;
  show;
  private subscriptions: Subscription[] = [];
  pageSizeArray = [10, 50, 100];
  childWorkOrderList = [];
  expandIndex: number;
  checkedWorkorders: any = [];
  headCheckBox: boolean;
  industryId = localStorage.getItem('industryId');
  screenWidth: number;
  showMobileTable: boolean;
  // currentDate: any = new Date().valueOf();
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }
  constructor(
    public router: Router,
    public commonService: CommonServiceService,
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public dialog: DialogService,
    private workOrderService: WorkOrderService,
    private matDialog: MatDialog
  ) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
    this.onResize();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  pageSizeChange(event) {
    if (event.value) {
      this.pageInfo.page = 0;
      this.pageInfo.size = event.value;
      this.getListing();
    }
  }

  ngOnInit() {
    this.commonService.searchText = '';
    this.subscriptions.push(
      this.commonService.getSearchEvent().subscribe(() => {
        this.search();
      })
    );
    this.show = true;
    this.showPagination = true;
    this.getListing();
  }

  ngOnChanges() {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
    this.getListing();
  }

  mapData(item, headers) {
    return {
      heading: headers,
      content: item,
    };
  }

  SetPage(event: any) {
    this.pageInfo.page = event.page;
    this.pageInfo.size = event.size;
    this.getListing();
  }

  getListing() {
    this.headCheckBox = false;
    this.checkedWorkorders = [];
    this.workOrderService.selectedWorkOrdersList = [];
    this.loaderService.show();
    this.workOrderService.getWorkOrderList(this.pageInfo, {}, this.workOrderId).subscribe((response: WorkOrderModel) => {
      this.pageInfo.TotalRecord = response.data.totalElements;
      this.pageInfo.totalPages = response.data.totalPage;
      this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
      this.element = response.data.payload;
      // this.currentDate = new Date().valueOf();
      if (this.element.length) {
        this.noResult = false;
        this.element.forEach(item => {
          let hrs = 0;
          let minutes = 0;
          if (item.sla > 60) {
            hrs = Math.floor(item.sla / 60);
            minutes = item.sla - hrs * 60;
          } else {
            minutes = item.sla;
          }
          // item.escalateTime = moment(item.startTime).add(item.sla, 'minutes').valueOf();
          item['expectedClosureTime'] = (hrs && minutes) ? `${hrs} hours : ${minutes} min` : (hrs ? `${hrs} hours` : minutes ? `${minutes} minutes` : null);
          item['orderDate'] = item.orderDate ? moment(item.orderDate).format('DD-MM-YYYY') : null;
          item['startTime'] = item.startTime ? moment(item.startTime).format('hh:mm A') : null;
          item['calendarId'] = item.hygieneCalenderId;
          if (item.status == 'WORKORDER_OPEN') {
            item['workOrderStatus'] = 'Not Started';
            item['workOrderStatusClass'] = "not-started";
          } else if (item.status == 'WORKORDER_CREATED') {
            item['workOrderStatus'] = 'Not Started'
            item['workOrderStatusClass'] = 'not-started';
          } else if (item.status == 'WORKORDER_INPROGRESS') {
            item['workOrderStatus'] = 'Started';
            item['workOrderStatusClass'] = 'started';
          } else if (item.status == 'WORKORDER_HOLD') {
            item['workOrderStatus'] = 'On Hold';
            item['workOrderStatusClass'] = 'onHold';
          } else if (item.status == 'WORKORDER_COMPLETED') {
            item['workOrderStatus'] = 'Completed';
            item['workOrderStatusClass'] = 'completed';
          } else if (item.status == 'WORKORDER_CLOSED') {
            item['workOrderStatus'] = 'Closed';
            item['workOrderStatusClass'] = 'closed';
          } else if (item.status == 'WORKORDER_ESCALATED') {
            item['workOrderStatus'] = 'Escalated';
            item['workOrderStatusClass'] = 'escalated';
          } else if (item.status == 'WORKORDER_REOPEN') {
            item['workOrderStatus'] = 'Not Started';
            item['workOrderStatusClass'] = 'not-started';
          } else if (item.status == 'WORKORDER_DRAFT') {
            item['workOrderStatus'] = 'Drafted';
            item['workOrderStatusClass'] = 'drafted';
          }
          const location = [];
          if (item.buildingName)
            location.push(item.buildingName);
          if (item.floorName)
            location.push(item.floorName);
          if (item.departmentName)
            location.push(item.departmentName);
          if (item.roomName)
            location.push(item.roomName);
          if (this.industryId == '1' && item.bedNo) {
            location.push(item.bedNo);
          }
          else if (this.industryId == '2' && item.seatNo) {
            location.push(item.seatNo);
          }
          // location.reverse();
          item.location = location.join(', ');
        })
      } else {
        this.noResult = true;
      }
      this.loaderService.hide();
    },
      (error) => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
  }

  search() {
    if (this.saveSearch !== '') {
      this.commonService.searchText = this.saveSearch;
      this.saveSearch = '';
    }
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
    this.getListing();
  }

  sort(colName: string, sortDirection: any) {
    if (this.pageInfo.sortBy === colName) {
      if (sortDirection === 'ASC') {
        this.pageInfo.sortBy = '';
        this.pageInfo.sortDir = 'DESC';
      } else {
        this.pageInfo.sortDir = sortDirection;
      }
    } else {
      this.pageInfo.sortBy = colName;
      this.pageInfo.sortDir = 'ASC';
    }
    this.getListing();
  }

  // ----------edit---------------------------------------------------------------------------//
  edit(id) {
    this.router.navigate(['admin/hygiene/work-order/edit-work-order/' + id]);
  }

  addWorkOrder(id) {
    this.router.navigate(['admin/hygiene/work-order/add-work-order/' + id]);
  }

  deleteWorkOrder(id) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data:
      {
        yesBtnName: 'Confirm',
        noBtnName: 'Cancel',
        confirmationMesg: `Are you sure you want to delete workorder?`,
        heading: `Confirmation`
      }
    }).afterClosed().subscribe((res: any) => {
      if (res === 1) {
        this.loaderService.show();
        this.workOrderService.deleteWorkOrder([id]).subscribe(res => {
          this.loaderService.hide();
          this.openSuccessDialog(res['description'], 'Success');
        })
      }
    })
  }

  openSuccessDialog(message, heading) {
    this.matDialog.open(SuccessDialogComponent,
      {
        data:
        {
          yesBtnName: 'OK',
          confirmationMesg: message,
          heading: heading
        }
      }).afterClosed().subscribe(res => {
        this.getListing();
      })
  }
  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }

  changeStatus(id, status) {
    this.loaderService.show();
    this.workOrderService.updateStatus(id, status).subscribe(res => {
      this.loaderService.hide();
      this.getListing();
      this.successSnackBar(res['description']);
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    })
  }

  allCheckbox(event: any) {
    const all = event.target.checked;
    if (all) {
      this.checkedWorkorders = [];
      this.element.forEach(element => {
        this.checkedWorkorders.push(element.id);
      });
    } else {
      this.checkedWorkorders = [];
    }
    this.element.forEach(element => {
      element.selected = all;
    });
    this.workOrderService.selectedWorkOrdersList = [...this.checkedWorkorders];
  }

  checkbox(event: any, i: any) {
    const index = this.checkedWorkorders.findIndex(item => { return i == item.id });
    const ticketIndex = this.element.findIndex(item => i == item.id)
    if (index !== -1) {
      this.checkedWorkorders.splice(index, 1);
      this.element[ticketIndex].selected = false;

    } else {
      this.checkedWorkorders.push(this.element[ticketIndex].id);
      this.element[ticketIndex].selected = true;
    }
    if (this.checkedWorkorders.length == this.element.length) {
      this.headCheckBox = true;
    } else {
      this.headCheckBox = false;
    }
    this.workOrderService.selectedWorkOrdersList = [...this.checkedWorkorders];
  }

  auditTrail(id) {
    this.router.navigate(['/admin/hygiene/work-order/audit-trail/' + id]);
  }
}
