import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from 'src/app/core/utilities/format-datepicker';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { NameValidator } from 'src/app/modules/shared/validators/name.validator';
import { DataModel, GenericPicklistModel } from 'src/app/modules/shared/model/picklist.model';
import { PatientService } from '../../../service/patient.service';
import { PatientData, PatientDataModel, PatientDescModel, PatientListingModel, whitelistedVisitorData, whitelistedVisitorModel } from '../../../model/patient-list.model';
import { DialogService } from 'src/app/core/dialog.service';
import { OtpComponent } from 'src/app/modules/shared/component/otp/otp.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ConfigurationFormFieldService } from 'src/app/modules/shared/services/configuration-form-field.service';
import { FormFieldListModel } from 'src/app/modules/shared/model/configuration-form-field';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from 'src/app/modules/shared/component/success-dialog/success-dialog';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { environment } from 'src/app/config/environments/environment';
@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class AddPatientComponent implements OnInit {
  isActive = false;
  patientForm: FormGroup;
  patients: any = [];
  organizations: any = [];
  patientes: any = [];
  buildings: any = [];
  holidayCalendarPicklist: any = [];
  leavePolicyPicklist: any = [];
  id;
  updating = false;
  orgId: any;
  message;
  updateStatus;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  patientList: any = [];
  countryList: any[];
  additionalFields = [];
  doctorList: any[] = [];
  roomList: any[] = [];
  bedList: any[] = [];
  customerId = localStorage.getItem('customerId');
  branchId = localStorage.getItem('branchId');
  departmentList: any[];
  isMobileVerified = false;
  gender: any[];// = ['male', 'female', 'other'];
  todayDate = new Date();
  approvalStatus: any;
  whitelistVisitorDetailsForm: FormGroup;
  careTeamMemberForm: FormGroup;
  selectedIndex: any = 0;
  @ViewChild('phone', { static: false }) phone: ElementRef;
  userStatus: any;
  rolesList: any[] = [];
  roleOptions: any = [];
  changedWhiteList:any = [];
  changedModeraterList:any = [];
  departmentByRoleList: any = [];
  careTeamMemberDataList: any = [];
  oldModeratorList: any[] = [];
  isCareTeamMemberRequired: boolean;
  filteredCountries: Observable<any[]>;
  filteredDoctors: Observable<any[]>;
  filteredGenders: Observable<any[]>;
  filteredDepartments: Observable<any[]>;
  filteredRoomList: Observable<any[]>;
  filteredBedList: Observable<any[]>;
  filteredDoctorList: Observable<any>;
  filteredDepartmentByRoleList: Observable<any[]> = new Observable<any[]>();
  filteredRoleList: Observable<any[]> = new Observable<any[]>();
  whitelistVisitorList: FormArray;
  filteredCountriesCode: Observable<string[]> = new Observable<string[]>();
  newDepartmentList: any[] = [];
  selectedDepartmentFormIndex: any;
  matchedRoomId: any;
  oldPhoneNumber
  newPhoneNumber
  isPhoneNumberUpdated: boolean;
  oldValue:any;
  changedValue:any;
  iswhiteListedValueChange:boolean;
  ismoderaterListedValueChange:boolean;
  uniqueOldModerater: any;
  uniqueNewModerater: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public commonService: CommonServiceService,
    private router: Router,
    private fb: FormBuilder,
    private picklistService: PicklistService,
    private patientService: PatientService,
    private loaderService: LoaderService,
    private matDialog: MatDialog,
    private dialogService: DialogService,
    private configurationFormFieldService: ConfigurationFormFieldService
  ) { }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT');
    this.commonService.callToggleAddEmp.subscribe(() => {
      this.change();
    });
    this.initializeForm();
    this.whitelistVisitorDetailsForm = this.fb.group({
      whitelistVisitors: this.fb.array([]),
    });
    this.careTeamMemberForm = this.fb.group({
      careTeamMembers: this.fb.array([]),
    });
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    this.getCountry();
    this.getGender();
    this.getDepartment();
    // this.getRoomNumber();
    this.getRoles();
    this.checkCareTeamMemberisRequiredOrNot();
    this.patientForm.get('mobileNo').valueChanges.subscribe(value => {
      this.isMobileVerified = false;
    });
    this.patientForm.get('country').setValue(91);
    this.getParams();
  }

  addWhitelistVisitorsFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      name: ['', [Validators.required]],
      countryCode: ['', [Validators.required]],
      countryFilterCtrl: [''],
      phoneNo: ['', [Validators.required, Validators.pattern('^([5-9]{1})([0-9]{5,12})$')]]
    });

  }

  whitelistVisitors(): FormArray {
    return this.whitelistVisitorDetailsForm.get('whitelistVisitors') as FormArray
  }

  addWhitelistVisitor(i) {
    (<FormArray>this.whitelistVisitorDetailsForm.get('whitelistVisitors')).push(this.addWhitelistVisitorsFormGroup());
    let index = this.whitelistVisitorDetailsForm.controls.whitelistVisitors['controls'].length - 1;
    this.whitelistVisitorList = this.whitelistVisitors();
    const formGroup = this.whitelistVisitorList.controls[index] as FormGroup;
    this.filteredCountriesCode[index] = formGroup.controls['countryFilterCtrl'].valueChanges.
      pipe(
        startWith(''),
        map(value => this.commonService.dataFilter(value, this.countryList))
      );
  }

  removeWhitelistVisitor(i) {
    (<FormArray>this.whitelistVisitorDetailsForm.get('whitelistVisitors')).removeAt(i);
  }

  addCareTeamMembersFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      role: ['', [Validators.required]],
      roleFilterCtrl: [''],
      departmentId: [[], [Validators.required]],
      departmentFilterCtrl: [''],
      trackId: ['']
    });
  }

  careTeamMembers(): FormArray {
    return this.careTeamMemberForm.get("careTeamMembers") as FormArray
  }

  addCareTeamMember() {
    (<FormArray>this.careTeamMemberForm.controls['careTeamMembers']).push(this.addCareTeamMembersFormGroup());
    const length = this.careTeamMembers().controls.length;
    this.filteredRoleList[length - 1] = this.careTeamMembers().controls[length - 1]['controls']['roleFilterCtrl'].valueChanges.
      pipe(
        startWith(''),
        map(value => this.commonService.dataFilter(value, this.rolesList))
      );
  }

  removeCareTeamMember(index) {
    this.departmentByRoleList.splice(index, 1);
    (<FormArray>this.careTeamMemberForm.get('careTeamMembers')).removeAt(index);
    this.careTeamMemberForm.controls.careTeamMembers['controls'][this.careTeamMemberForm.controls.careTeamMembers['controls'].length - 1].enable();
  }

  checkCareTeamMemberisRequiredOrNot() {
    this.patientService.checkCareTakeMemberisRequiredOrNot().subscribe((res: any) => {
      this.isCareTeamMemberRequired = + res.data.value ? false : true;
      if (this.isCareTeamMemberRequired && !this.updating) {
        this.addCareTeamMember();
      }
    })
  }

  getRoles(i?) {
    this.loaderService.show();
    this.rolesList = [];
    this.picklistService.getRole(this.customerId)
      .subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          res.data.map((x) => {
            if (x.active == true && (x.roleCode != 'PATIENT' && x.roleCode != 'VISITOR')) {
              this.roleOptions.push({ id: x.id, name: x.name, roleCode: x.roleCode })
              this.rolesList = [...this.roleOptions]
            };
          });
        }
      });
  }

  tabChange(value) {
    this.selectedIndex = value;
  }

  change() {
    this.isActive = !this.isActive;
  }

  getParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.updating = true;
        this.id = params.id;
        this.patchValuesInPatientForm();
      } else {
        this.getFormControlList();
      }
    });
  }

  initializeForm() {
    this.patientForm = this.fb.group({
      patientId: ['', [
        Validators.required,
        NameValidator,
        Validators.minLength(3),
        Validators.maxLength(10),
      ]],
      firstName: ['', [Validators.required, NameValidator]],
      lastName: ['', [Validators.required, NameValidator]],
      doctorName: ['', [Validators.required]],
      doctorFilterCtrl: [''],
      departmentId: ['', [Validators.required]],
      departmentFilterCtrl: [''],
      country: [91, [Validators.required]],
      countryFilterCtrl: [''],
      email: [''],
      mobileNo: ['', [Validators.required, Validators.pattern('^([5-9]{1})([0-9]{5,12})$')]],
      admittedDate: ['', [Validators.required]],
      roomNumber: ['', [Validators.required]],
      roomFilterCtrl: [''],
      bedNumber: ['', [Validators.required]],
      bedFilterCtrl: [''],
      approvalStatus: [''],
      userStatus: [''],
      age: ['', [Validators.required, Validators.min(1)]],
      gender: ['', [Validators.required]],
      genderFilterCtrl: ['']
    });
  }

  getFormControlList(result?) {
    this.loaderService.show();
    this.configurationFormFieldService.getListing(environment.patientApiUrl, 'patient').subscribe((res: FormFieldListModel) => {
      this.loaderService.hide();
      res.data.forEach(item => {
        let control = new FormControl();
        if (item.hidden) {
          this.patientForm.addControl(item.fieldCode, control);
          this.additionalFields.push(item);
        }
      })
      if (result) {
        this.additionalFields.forEach(item => {
          if (item.hidden) {
            this.patientForm.get(item.fieldCode).patchValue(result.data[item.fieldCode]);
          }
        })
      }
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    })
  }

  Upper(data: string) {
    return data.toUpperCase();
  }

  patchValuesInPatientForm() {
    this.loaderService.show();
    this.patientService
      .getPatientDetails(this.id)
      .subscribe((res: PatientDataModel) => {
        this.loaderService.hide();
        this.approvalStatus = res.data.approvalStatus;
        this.userStatus = res.data.userStatus;
        this.oldModeratorList = res.data.moderators;
        let track ='1'
        this.oldModeratorList.forEach(function (element) {
          element.trackId = track.toString();
          track = (parseInt(track)+1).toString();
        });
        this.patchWhitelistedVisitorValues(res.data.patientId);
        res.data && res.data.moderators && res.data.moderators.length ? this.patchCareTeamMemberValues(res.data.moderators) : (this.isCareTeamMemberRequired ? this.addCareTeamMember() : '');
        this.patientForm.get('patientId').patchValue(res.data.patientId);
        this.patientForm.get('firstName').patchValue(res.data.firstName);
        this.patientForm.get('lastName').patchValue(res.data.lastName);
        this.patientForm.get('age').patchValue(res.data.age);
        this.patientForm.get('gender').patchValue(res.data.gender);
        this.patientForm.get('departmentId').patchValue({ id: res.data.departmentId, name: res.data.departmentName });
        this.getDoctor();
        this.patientForm.get('doctorName').patchValue({ id: res.data.doctorId, name: res.data.doctorName });
        this.patientForm.get('email').patchValue(res.data.email);
        this.patientForm.get('mobileNo').patchValue(res.data.mobilePhone);
        res.data && res.data.mobilePhone ? this.isMobileVerified = true : this.isMobileVerified = false;
        this.patientForm.get('country').patchValue({ countryCode: res.data.countryCode, id: res.data.countryId });
        this.getRoomNumber(res.data);
        if(res.data.roomId){
          this.patientForm.get('roomNumber').patchValue({ id: res.data.roomId, name: res.data.roomNumber });
        }
        this.getBedNumber();
        let bed = this.bedList.find(item => item.id == res.data.bedId);
        bed && bed.id ? "" : this.bedList.push({ id: res.data.bedId, name: res.data.bedNumber });
        this.patientForm.get('bedNumber').patchValue({ id: res.data.bedId, name: res.data.bedNumber });
        res.data && res.data.admittedDate ? this.patientForm.get('admittedDate').patchValue(new Date(res.data.admittedDate).toISOString()) : '';
        this.getFormControlList(res);
        !this.userStatus ? this.patientForm.controls.admittedDate.disable() : '';
        this.approvalStatus != 2 ? this.patientForm.get('patientId').disable() : '';
        this.careTeamMemberDataList = res.data.moderators;
        this.oldPhoneNumber = res.data.mobilePhone;
        this.setOldvalues(res.data)
        this.changedWhiteList =[];
        this.changedModeraterList =[];
        this.ismoderaterListedValueChange = false;
        this.iswhiteListedValueChange = false;
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
  }

  setOldvalues(data){
    this.oldValue ={
      firstName: data.firstName,
      lastName: data.lastName,
      age: data.age,
      gender: data.gender,
      departmentId:{ id: data.departmentId, name: data.departmentName },
      doctorName: {id: data.doctorId ,name: data.doctorName},
      email:data.email,
      country:{ countryCode: data.countryCode, id: data.countryId },
      roomNumber:{ id: data.roomId, name: data.roomNumber },
      bedNumber:{id: data.bedId, name: data.bedNumber},
      mobilePhone:data.mobilePhone,
    }
    this.iswhiteListedValueChange = false;
    this.ismoderaterListedValueChange = false;
  }

  patchWhitelistedVisitorValues(patientId) {
    const whitelistedVisitorFormArray = <FormArray>this.whitelistVisitorDetailsForm.controls['whitelistVisitors'];
    this.loaderService.show();
    this.patientService.getWhitelistedVisitors(patientId).subscribe((res: whitelistedVisitorModel) => {
      this.loaderService.hide();
      this.oldValue.whiteListed = res.data;
      const wlVisitorIds = res.data.map((x, index) => {
        whitelistedVisitorFormArray.push(this.patch(x));
        const formGroup = whitelistedVisitorFormArray.controls[index] as FormGroup;
        this.filteredCountriesCode[index] = formGroup.controls['countryFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.commonService.dataFilter(value, this.countryList))
          );
        return x.id;
      });

      if (this.updating === true) {
        // this.whitelistVisitorDetailsForm.disable();

      } else {
        this.whitelistVisitorDetailsForm.enable();
      }
    });
  }


  patch(whitelistedVisitor: whitelistedVisitorData) {
    return this.fb.group({
      id: [whitelistedVisitor.id],
      name: [whitelistedVisitor.name, [Validators.required]],
      countryCode: [{ countryCode: whitelistedVisitor.countryCode, id: whitelistedVisitor.countryId }, [Validators.required]],
      phoneNo: [whitelistedVisitor.phoneNo, [Validators.required, Validators.pattern('^([5-9]{1})([0-9]{5,12})$')]],
      countryFilterCtrl: [''],
    });
  }

  patchCareTeamMemberValues(data) {
    this.careTeamMemberForm.controls['careTeamMembers'].reset();
    const careTeamMemberFormArray = <FormArray>this.careTeamMemberForm.controls['careTeamMembers'];
    data.forEach((x, index) => {
      // if (index > 0)
      //   this.getRoleList(index);
      careTeamMemberFormArray.push(this.patchCareTeamMemberFormValue(x, index));
      this.careTeamMembers().controls[index].valueChanges.subscribe(item => {

      })
      this.filteredRoleList[index] = this.careTeamMembers().controls[index]['controls']['roleFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => this.commonService.dataFilter(value, this.rolesList))
        );
    });
    this.approvalStatus == 0 ? this.careTeamMemberForm.disable() : '';
  }

  patchCareTeamMemberFormValue(careTeamMember: any, index?) {
    this.getDepartmentByRole(index, careTeamMember.roleId);
    let departmentData = [];
    careTeamMember.departmentIds.forEach((item, index) => {
      departmentData.push({
        id: item,
        locationCode: careTeamMember.locationCodes[index]
      })
    })
    return this.fb.group({
      id: [careTeamMember.id],
      role: [{ id: careTeamMember.roleId, roleCode: careTeamMember.roleCode }],
      departmentId: [departmentData],
      departmentFilterCtrl: [''],
      roleFilterCtrl: [''],
      trackId: [careTeamMember.trackId.toString()],
    });
  }

  getRoleList(index) {
    this.rolesList[index] = [];
    const careTeamMemberFormValue = this.careTeamMemberForm.controls.careTeamMembers.value[index - 1]
    const i = this.rolesList[index - 1].findIndex(item => item.id === careTeamMemberFormValue.role.id)
    const roles = [...this.rolesList[index - 1]];
    roles.splice(i, 1);
    this.rolesList[index] = [...roles];
  }

  getCountry() {
    this.loaderService.show()
    this.countryList = [];
    this.picklistService.getCountry().subscribe((res: Array<GenericPicklistModel>) => {
      this.loaderService.hide();
      res.map((x: GenericPicklistModel) => {
        this.countryList.push({ id: x.id, name: x.name, code: x.code, countryCode: x.countryCode });
      });
      this.filteredCountries = this.patientForm.controls['countryFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => this.commonService.dataFilter(value, this.countryList))
        );
      if (!this.id) {
        const defaultCountry = this.countryList.find((element) => element.countryCode === "91");
        this.patientForm.get('country').setValue(defaultCountry);
      }
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }

  getGender() {
    this.gender = [];
    this.loaderService.show();
    this.picklistService.getGender().subscribe((res: any) => {
      this.loaderService.hide();
      if (res.data.length) {
        res.data.map((x) => {
          this.gender.push({ id: x.id, name: x.name });
        });
      }
      this.filteredGenders = this.patientForm.controls['genderFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => { return this.commonService.dataFilter(value, this.gender) })
        );
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }

  getDoctor() {
    this.doctorList = [];
    this.patientForm.controls.doctorName.reset();
    const departmentId = this.patientForm.controls.departmentId.value.id;
    this.loaderService.show();
    if (departmentId) {
      this.picklistService.getDoctor(this.branchId, this.customerId, departmentId).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.length) {
          res.map((x) => {
            this.doctorList.push({ id: x.id, name: 'Dr. ' + x.name });
          });
        }
        this.filteredDoctors = this.patientForm.controls['doctorFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.doctorList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  getDepartment() {
    this.departmentList = [];
    if (this.branchId) {
      this.loaderService.show();
      this.picklistService.getDepartment(this.branchId, this.customerId).subscribe((res: DataModel) => {
        this.loaderService.hide();
        if (res.data.length) {
          res.data.map((x) => {
            this.departmentList.push({ id: x.id, name: x.name });
          });
        }
        this.filteredDepartments = this.patientForm.controls['departmentFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.departmentList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  getDepartmentByRole(index, role?) {
    let roleId = role ? role : this.careTeamMembers().controls[index].value.role.id ? this.careTeamMembers().controls[index].value.role.id : '';
    if (roleId) {
      this.loaderService.show();
      this.departmentByRoleList[index] = [];
      this.picklistService.getDepartmentByRole(this.branchId, roleId).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          res.data.map((x) => {
            this.departmentByRoleList[index].push({ id: x.id, name: x.name, locationCode: x.locationCode });
          });
        }
        this.getRemainingDepartmentList(index);
        this.filteredDepartmentByRoleList[index] = (<FormGroup>this.careTeamMembers().controls[index]).controls['departmentFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => {
              if (this.selectedDepartmentFormIndex != undefined) {
                return this.commonService.dataFilter(value, this.newDepartmentList[this.selectedDepartmentFormIndex]);
              }
              return this.commonService.dataFilter(value, this.newDepartmentList[index]);
            })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error)
      });
    }
  }

  getRoomNumber(matchRoom) {
    this.roomList = [];
    this.patientForm.controls.bedNumber.reset();
    const departmentId = this.patientForm.value.departmentId.id||matchRoom.departmentId;
    if (departmentId) {
      this.loaderService.show();
      this.picklistService.getRoomForPatientByDept(this.customerId, this.branchId, departmentId).subscribe((res: any) => {
        this.loaderService.hide();
        this.roomList = res.data;
        // res.data.map((x) => {
        //   this.roomList.push({ id: x.id, name: x.name });
        // });
        this.matchedRoomId = this.roomList.find(item => item.id == matchRoom.roomId);
        if(matchRoom.roomId){
          this.matchedRoomId ? "" : this.roomList.push({ id: matchRoom.roomId, name: matchRoom.roomNumber });
        }
        this.filteredRoomList = this.patientForm.controls['roomFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.roomList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  //on department change 
  getRoomNumberOnDeptChange() {
    this.roomList = [];
    this.patientForm.controls.bedNumber.reset();
    const departmentId = this.patientForm.value.departmentId.id;
    if (departmentId) {
      this.loaderService.show();
      this.picklistService.getRoomForPatientByDept(this.customerId, this.branchId, departmentId).subscribe((res: any) => {
        this.loaderService.hide();
        this.roomList = res.data;
        this.filteredRoomList = this.patientForm.controls['roomFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.roomList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  getBedNumber() {
    this.bedList = [];
    // const departmentId = this.patientForm.value.departmentId;
    const roomId = this.patientForm.value.roomNumber.id;
    if (roomId) {
      this.loaderService.show();
      this.patientService.getBedByRoom(this.customerId, this.branchId, roomId).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          // this.bedList = res.data;
          res.data.map((x) => {
            //   if (x.status == false) {
            this.bedList.push({ id: x.id, name: x.name });
            //   }
          });
        }
        this.filteredBedList = this.patientForm.controls['bedFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.bedList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  moderatorPayload() {
    let body = {
      moderators: this.careTeamMemberForm.controls.careTeamMembers['controls']
    }
   let Body = body.moderators.map((x) => {
      return {
        id: x.id,
        departmentIds: x.controls.departmentId.value.map(dept => dept.id),
        locationCodes: x.controls.departmentId.value.map(dept => dept.locationCode),
        roleCode: x.controls.role.value.roleCode,
        roleId: x.controls.role.value.id,
        trackId: x.controls.trackId.value.toString()||""
      }
    })
    return Body;
  }

  whitelistVisitorPayload() {
    let body = {
      whitelistVisitors: this.whitelistVisitorDetailsForm.get('whitelistVisitors').value
    }
    let Body = body.whitelistVisitors.map((x) => {
      return {
        id: x.id,
        name: x.name,
        countryCode: x.countryCode.countryCode,
        countryId: x.countryCode.id,
        phoneNo: x.phoneNo,
      }
    })
    return Body;
  }

  removeRole(index, role) {
    this.rolesList[index + 1] = this.rolesList[index];
    this.rolesList[index + 1] = this.rolesList[index + 1].filter(item => item.id != role.id)
  }

  getPatientPayload() {
    const admittedDate = new Date(this.patientForm.controls['admittedDate'].value)
    const body = {
      firstName: this.patientForm.controls['firstName'].value,
      lastName: this.patientForm.controls['lastName'].value,
      doctorId: this.patientForm.controls['doctorName'].value.id,
      age: this.patientForm.controls['age'].value,
      genderId: this.patientForm.controls['gender'].value.id,
      doctorName: this.patientForm.controls['doctorName'].value.name,
      branchId: this.branchId,
      patientId: this.Upper(this.patientForm.controls.patientId.value),
      countryCode: this.patientForm.controls['country'].value.countryCode,
      countryId: this.patientForm.controls['country'].value.id,
      mobilePhone: this.patientForm.controls['mobileNo'].value,
      departmentId: this.patientForm.controls['departmentId'].value.id,
      departmentName: this.patientForm.controls['departmentId'].value.name,
      roomNumber: this.patientForm.controls['roomNumber'].value.name,
      roomId: this.patientForm.controls['roomNumber'].value.id,
      bedNumber: this.patientForm.controls['bedNumber'].value.name,
      bedId: this.patientForm.controls['bedNumber'].value.id,
      admittedDate: admittedDate.getTime(),
      email: this.patientForm.controls['email'].value,
    }
    return body;
  }

  onSubmit3() {
    this.loaderService.show();
    const index = this.gender.indexOf(this.patientForm.controls['gender'].value);
    let body = this.getPatientPayload()
    if (this.additionalFields.length) {
      this.additionalFields.forEach(item => {
        body[item.fieldCode] = this.patientForm.value[item.fieldCode];
      })
    }
    const patientId = this.id;
    if (this.updating && this.approvalStatus !== 2) {
      this.changedValue.whiteListed = this.iswhiteListedValueChange;
      this.changedValue.moderaterListed = this.ismoderaterListedValueChange;
      body['newModerators'] = this.moderatorPayload();
      body['oldModerators'] = this.oldModeratorList;
      body['whitelistVisitors'] = this.whitelistVisitorPayload();
      body['phoneChange'] = this.isPhoneNumberUpdated;
      body['userId'] = parseInt(localStorage.getItem('LogoutId'));
      body['customerId'] = this.customerId;
      body['roleList'] = this.rolesList;
      body['detectChange'] = [{changedValue: this.changedValue,oldValue: this.oldValue, whiteList:this.changedWhiteList, moderaterList:this.changedModeraterList, uniqueModerater:{oldM:this.uniqueOldModerater, newM:this.uniqueNewModerater}}]
      this.patientService.updatePatient(body, patientId)
        .subscribe((res: PatientListingModel) => {
          this.loaderService.hide();
          this.successSnackBar(res.description);
          this.router.navigate(['admin/patient-list/patient']);
        }, (error) => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        });
    } else {
      body['moderators'] = this.moderatorPayload();
      let params = {};
      this.approvalStatus == 2 ? params['rejectedPatientId'] = this.id : null
      this.patientService.addPatient(body, params)
        .subscribe((res: any) => {
          this.loaderService.hide();
          if (this.whitelistVisitorDetailsForm.get('whitelistVisitors').value.length)
            this.submitWhitelistedVisitor();
          this.successSnackBar(res.description);
          this.router.navigate(['admin/patient-list/patient']);
        },
          (error) => {
            this.loaderService.hide();
            this.errorSnackBar(error);
          });
    }
  }

  verifyMobile() {
    this.newPhoneNumber = this.patientForm.value.mobileNo;
    if(this.updating){
      this.setChangedValue()
      this.detectWhiteListChanges()
      this.detectModeraterListChanges()
    }
    if(this.newPhoneNumber != this.oldPhoneNumber){
      this.isPhoneNumberUpdated = true;
      this.loaderService.show();
      this.patientService.sendOtpToVerifyMobileNumber(this.patientForm.value.country.countryCode + "" + this.patientForm.value.mobileNo, this.patientForm.controls.firstName.value + " " + this.patientForm.controls.lastName.value, this.patientForm.controls.email.value)
        .subscribe(res => {
          this.dialogService.openDialog(OtpComponent, { verifyOtp: this.verifyOtpCall, otpMessage:'An OTP has been generated and sent to your new mobile number successfully' })
            .afterClosed().subscribe(otp => {
              if (otp) {
                this.onSubmit3();
              }
            })
          this.loaderService.hide();
        }, (error) => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        })
    }else{
      this.isPhoneNumberUpdated = false;
      this.onSubmit3();
    }
  }

  setChangedValue(){
    this.changedValue ={
      firstName: this.patientForm.controls.firstName.value != this.oldValue.firstName ? true:false,
      lastName: this.patientForm.controls.lastName.value != this.oldValue.lastName ? true:false,
      age: this.patientForm.controls.age.value != this.oldValue.age ? true:false,
      gender: this.patientForm.controls.gender.value != this.oldValue.gender ? true:false,
      departmentId: this.patientForm.controls.departmentId.value.id != this.oldValue.departmentId.id? true:false,
      doctorName: this.patientForm.controls.doctorName.value.id != this.oldValue.doctorName.id? true:false,
      email: this.patientForm.controls.email.value != this.oldValue.email? true:false,
      country: this.patientForm.controls.country.value.id != this.oldValue.country.id ? true:false,
      roomNumber:this.patientForm.controls.roomNumber.value.id != this.oldValue.roomNumber.id ? true:false,
      bedNumber: this.patientForm.controls.bedNumber.value.id != this.oldValue.bedNumber.id ? true:false,
      mobilePhone:this.patientForm.controls.mobileNo.value != this.oldValue.mobilePhone? true:false,
    }
  }


  detectWhiteListChanges(){
    let oldWhiteListed=this.oldValue.whiteListed;
    let newWhiteListed= this.whitelistVisitorPayload();
    let changedWhiteList:any =[]
    let iswhiteListedValueChange
    newWhiteListed.forEach(function (New) {
      oldWhiteListed.forEach(function (old) {
       if(New.id == old.id){ //if chnages in existing
          if(New.name !=old.name || New.phoneNo != old.phoneNo ||  New.countryCode != old.countryCode){
            changedWhiteList.push(New)
            iswhiteListedValueChange= true
          }
       }
       else if(!New.id){    // if new added 
        let index = changedWhiteList.indexOf(New);
        if(index==-1){
          changedWhiteList.push(New)
        }
        iswhiteListedValueChange = true;
       }
      });
    });
    // if deleted
    oldWhiteListed.forEach(old => { 
      let check =newWhiteListed.some(function(New) {
        return New.id === old.id;
      }); 
      if(!check){
        changedWhiteList.push(old)
        iswhiteListedValueChange = true;
      }
    });

    //if old is empty 
    if(oldWhiteListed.length==0 && newWhiteListed.length!=0){
      iswhiteListedValueChange = true;
      changedWhiteList = newWhiteListed;
    }

    if(iswhiteListedValueChange){
      this.iswhiteListedValueChange = iswhiteListedValueChange;
      this.changedWhiteList= changedWhiteList
    }
  }

  detectModeraterListChanges(){
    let oldModeraterListed=this.oldModeratorList;
    let newModeraterListed= this.moderatorPayload();
    let changedModeraterList:any =[]
    let ismoderaterListedValueChange:boolean = false;

    // unique code setup
    newModeraterListed.forEach(function (New) {
      New.departmentIds = _.uniq(New.departmentIds)
      New.locationCodes =_.uniq(New.locationCodes)
    })
    oldModeraterListed.forEach(function (old) {
      old.departmentIds = _.uniq(old.departmentIds)
      old.locationCodes =_.uniq(old.locationCodes)
    })

    newModeraterListed.forEach(function (New) {
      oldModeraterListed.forEach(function (old) {
       if(New.trackId == old.trackId){ //if changes in existing           
          if( New.roleId != old.roleId  || !_.isEqual(_.uniq(New.departmentIds), _.uniq(old.departmentIds))){
            changedModeraterList.push(New)
            ismoderaterListedValueChange= true
          }
       }
       else if(!New.trackId){    // if new added 
        let index = changedModeraterList.indexOf(New);
        if(index==-1){
          changedModeraterList.push(New)
        }
        ismoderaterListedValueChange = true;
       }
      });
    });
    // if deleted
    oldModeraterListed.forEach(old => { 
      let check =newModeraterListed.some(function(New) {
        return New.trackId === old.trackId;
      }); 
      if(!check){
        changedModeraterList.push(old)
        ismoderaterListedValueChange = true;
      }
    });

    //if old is empty 
    if(oldModeraterListed.length==0 && newModeraterListed.length!=0){
      ismoderaterListedValueChange = true;
      changedModeraterList = newModeraterListed;
    }

    if(ismoderaterListedValueChange){
      this.ismoderaterListedValueChange = ismoderaterListedValueChange;
      this.changedModeraterList= changedModeraterList
      this.uniqueNewModerater = newModeraterListed;
      this.uniqueOldModerater = oldModeraterListed;
    }
  }

  objectsAreSame(x, y) {
    let objectsAreSame = true;
    for(let propertyName in x) {
       if(x[propertyName] !== y[propertyName]) {
          objectsAreSame = false;
          break;
       }
    }
    return objectsAreSame;
  }

  verifyOtpCall = (otp) => {
    if (otp) {
      return this.patientService.verifyMobileNumber(this.patientForm.value.country.countryCode + "" + this.patientForm.value.mobileNo, otp)
    }
  }

  submitWhitelistedVisitor() {
    const visitorPayload = {
      patientId: this.Upper(this.patientForm.controls['patientId'].value),
      visitorDTOS: this.whitelistVisitorPayload()
    }
    this.patientService.addWhitelistedVisitor(visitorPayload).subscribe(res => {
    })
  }

  dischargePatient() {
    let body = {};
    if (this.userStatus) {
      body = this.getPatientPayload();
    }
    this.matDialog.open(ConfirmationDialogComponent,
      {
        data:
        {
          yesBtnName: 'Confirm',
          noBtnName: 'Cancel',
          confirmationMesg: `Are you sure you want to  ${!this.userStatus ? 'discharge' : 'admit'} the patient? Please confirm`,
          heading: 'Confirm Update'
        }
      }).afterClosed().subscribe(res => {
        if (res === 1) {
          this.loaderService.show();
          this.patientService.dischargePatient(this.id, +(!this.userStatus), body).subscribe(res => {
            this.loaderService.hide();
            this.matDialog.open(SuccessDialogComponent,
              {
                data:
                {
                  yesBtnName: 'OK',
                  confirmationMesg: res['description'],
                  heading: 'Successfully Update'
                }
              }).afterClosed().subscribe(res => {
                this.router.navigate(['admin/patient-list/patient'])
                // this.patientService.selectCustomer.next(true);
              })
          }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
          });
        }
      })
  }

  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }

  cancel() {
    this.router.navigate(['admin/patient-list/patient']);
  }

  countryComparision(option, value) {
    return option.countryCode == value.countryCode && value.id == option.id;
  }

  objectComparisonFunction(option, value) {
    return option.id === value.id;
  }

  roleComparision(option, value) {
    return option.roleCode === value.roleCode && option.id === value.id;
  }

  departmentComparision(option, value) {
    return option.id == value.id; //&& option.locationCode == value.locationCode;
  }

  getRemainingDepartmentList(ind) {
    const i = this.careTeamMemberForm.controls.careTeamMembers['controls'].length - 1;
    let department = this.departmentByRoleList[ind] && [...this.departmentByRoleList[ind]];
    if (department) {
      const roleId = this.careTeamMemberForm.controls.careTeamMembers['controls'][ind].value.role.id;
      this.careTeamMemberForm.controls.careTeamMembers['controls'].forEach((item, controlIndex) => {
        if (item.value.role.id == roleId && ind !== controlIndex) {
          item.value.departmentId.forEach(dept => {
            const index = department.findIndex(departmentData => departmentData.id == dept.id);
            if (index > -1) {
              department.splice(index, 1);
            }
          })
        }
      })
      this.newDepartmentList[ind] = department;
      return department;
    }
  }

  clickedDepartmentForm(index) {
    this.selectedDepartmentFormIndex = index;
  }
}

