import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/app/config/environments/environment';
import { CommonServiceService } from 'src/app/core/common-service.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterConfigurationServiceService {
  constructor(private http: HttpClient, private router: Router, private commonService: CommonServiceService) { }

  //GET ROOM TYPE 
  getRoomType(customerId) {
    return this.http.get(`${environment
    .cmUrl}api/v1/parameter/config/roomtypedropdown/${customerId}`);
  }

  //GET Param config value BY  romm type ID
  getCMParamConfigByRoomType(customerId,roomTypeId)
  {
    return this.http.get(`${environment.cmUrl}api/v1/parameter/config/${customerId}/${roomTypeId}`);
  }

  //UPDATE DATA
  updateParamConfig(customerId,body,roomTypeId) {
    return this.http.put(`${environment.cmUrl}api/v1/parameter/config/${customerId}/${roomTypeId}`,body);
  }

}
