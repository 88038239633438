import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from 'src/app/core/common-service.service';

@Component({
  selector: 'app-patient-setup-card',
  templateUrl: './patient-setup-card.component.html',
  styleUrls: ['./patient-setup-card.component.scss']
})
export class PatientSetupCardComponent implements OnInit {
  isActive = true;
  permissionsObject = { read: false, create: false, update: false, delete: false };
  ORGANIZATION = false;
  DEPARTMENT = false;
  LOCATION = false;
  ROLE = false;
  SHIFT = false;
  SCHEDULE = false;
  PatientPermissionsObject: { read: boolean; create: boolean; update: boolean; delete: boolean; };
  PATIENT: boolean;
  ApprovalPermissionsObject: { read: boolean; create: boolean; update: boolean; delete: boolean; };
  APPROVAL: boolean;
  ConfigurationPermissionsObject: { read: boolean; create: boolean; update: boolean; delete: boolean; };
  CONFIGURATION: boolean;
  VisitingHourPermissionsObject: { read: boolean; create: boolean; update: boolean; delete: boolean; };
  VISITING_HOUR: boolean;
  BlacklistReasonPermissionsObject: { read: boolean; create: boolean; update: boolean; delete: boolean; };
  BLACKLIST_REASON: boolean;
  BlacklistVisitorPermissionsObject: { read: boolean; create: boolean; update: boolean; delete: boolean; };
  BLACKLIST_VISITOR: boolean;
  FormsFieldConfigurationPermissionsObject: { read: boolean; create: boolean; update: boolean; delete: boolean; };
  FORMS_FIELD_CONFIGURATION: boolean;
  WorkflowPermissionsObject: { read: boolean; create: boolean; update: boolean; delete: boolean; };
  WORKFLOW: boolean;

  constructor(
    private commonService: CommonServiceService,
  ) { }

  ngOnInit() {
    this.PatientPermissionsObject = this.commonService.setPermissions('PATIENT_MANAGEMENT');
    this.permissionsObject = this.commonService.setPermissions('PATIENT');
    this.PATIENT = this.permissionsObject && this.permissionsObject.read ? true : false;
    this.ApprovalPermissionsObject = this.commonService.setPermissions('PATIENT_APPROVAL');
    this.APPROVAL = this.ApprovalPermissionsObject && this.ApprovalPermissionsObject.read ? true : false;
    this.ConfigurationPermissionsObject = this.commonService.setPermissions('PATIENT_CONFIGURATION');
    this.CONFIGURATION = this.ConfigurationPermissionsObject && this.ConfigurationPermissionsObject.read ? true : false;
    this.VisitingHourPermissionsObject = this.commonService.setPermissions('PATIENT_VISITING_HOURS');
    this.VISITING_HOUR = this.VisitingHourPermissionsObject && this.VisitingHourPermissionsObject.read ? true : false;
    this.BlacklistReasonPermissionsObject = this.commonService.setPermissions('PATIENT_BLACKLIST_REASON');
    this.BLACKLIST_REASON = this.BlacklistReasonPermissionsObject && this.BlacklistReasonPermissionsObject.read ? true : false;
    this.BlacklistVisitorPermissionsObject = this.commonService.setPermissions('PATIENT_BLACKLIST_VISITOR');
    this.BLACKLIST_VISITOR = this.BlacklistReasonPermissionsObject && this.BlacklistReasonPermissionsObject.read ? true : false;
    this.FormsFieldConfigurationPermissionsObject = this.commonService.setPermissions('PATIENT_FORMS_FIELD_CONFIGURATION');
    this.FORMS_FIELD_CONFIGURATION = this.FormsFieldConfigurationPermissionsObject && this.FormsFieldConfigurationPermissionsObject.read ? true : false;
    this.WorkflowPermissionsObject = this.commonService.setPermissions('PATIENT_WORKFLOW');
    this.WORKFLOW = this.FormsFieldConfigurationPermissionsObject && this.FormsFieldConfigurationPermissionsObject.read ? true : false;

    this.commonServiceSubscribe();
  }
  commonServiceSubscribe() {
    this.commonService.callToggleSkill.subscribe((data) => {
      this.change();
    });
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
  change() {
    this.isActive = !this.isActive;
  }

}
