import { Injectable } from '@angular/core';
import { environment } from 'src/app/config/environments/environment';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { HttpClient } from '@angular/common/http';
import { CommonServiceService } from 'src/app/core/common-service.service';
@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonServiceService
  ) { }
  DocumentListing(pageInfo: PaginationModel,activeListing) {
    return this.httpClient.get(`${environment.apiUrl}api/v1/employeeDocument/admin?accessType=${activeListing}&page=${pageInfo.
      page}&searchText=${encodeURIComponent(this.commonService.searchText)}&size=${pageInfo.
        size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }
  FolderListing(Value,pageInfo: PaginationModel) {
    return this.httpClient.get(`${environment.apiUrl}api/v1/employeeDocument/pickList/admin?accessType=${localStorage.getItem('activeListing')}&page=${pageInfo.
      page}&selfId=${Value.RowId}&searchText=${encodeURIComponent(this.commonService.searchText)}&size=${pageInfo.
        size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }
  ShareDocument(credentials) {
    return this.httpClient.put(`${environment.apiUrl}api/v1/employeeDocument/share?folderId=${credentials.folderId}&groupId=${credentials.employeeGroupId}`,credentials);
  }
  PostDocument(credentials) {
    return this.httpClient.post(`${environment.apiUrl}api/v1/employeeDocument`, credentials);
  }
  DeleteDocument(id) {
    return this.httpClient.delete(`${environment.apiUrl}api/v1/employeeDocument/deleteEmlployeeItem/{itemId}?itemId=${id}`);
  }
  RenameTitle(id, credentials) {
    let EncryptedId = this.commonService.b64EncodeUnicode(id);
    return this.httpClient.put(`${environment.apiUrl}api/v1/employeeDocument/rename/${EncryptedId}?newName=${credentials.title}`, credentials);
  }
  OpenUpfolder(id, pageInfo: PaginationModel, selfId) {
    let EncryptedId = this.commonService.b64EncodeUnicode(id);
    return this.httpClient.get(`${environment.apiUrl}api/v1/employeeDocument/openFolder/${EncryptedId}?page=${pageInfo.
      page}&selfId=${selfId}&searchText=${encodeURIComponent(this.commonService.searchText)}&size=${pageInfo.
        size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }
  ItemToMove(id,credentials) {
    return this.httpClient.put(`${environment.apiUrl}api/v1/employeeDocument/move?itemId=${id}&moveTo=${credentials.parentId}`,credentials);
  }
  getItemDetails(id){
    let EncryptedId = this.commonService.b64EncodeUnicode(id);
    return this.httpClient.get(`${environment.apiUrl}api/v1/employeeDocument/details/{itemId}?folderId=${EncryptedId}`);
  }
  getDocumentAccessList() {
    return this.httpClient.get(`${environment.apiUrl}api/v1/configs/documentAccess`);
  }
}