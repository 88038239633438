import { Component, OnInit, Inject } from '@angular/core';
import { GenericPicklistModel, EmployeeGroupPicklistModel } from '../../model/picklist.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoaderService } from '../../services/loader.service';
import { PicklistService } from '../../services/picklist.service';
import { ToastService } from '../../services/toaster.service';
import { DocumentsService } from 'src/app/modules/admin/modules/documents/services/documents.service';
import { ErrorToasterComponent } from '../error-toaster/error-toaster.component';
import { ToasterComponent } from '../toaster/toaster.component';
@Component({
  selector: 'app-mat-add-document',
  templateUrl: './mat-add-document.component.html',
  styleUrls: ['./mat-add-document.component.scss']
})
export class MatAddDocumentComponent implements OnInit {
  form: FormGroup;
  LetterTitles: Object;
  populate: any;
  letterPreview: any;
  letterDownload: any;
  showPreviewButton: any;
  DocumentType = [{ id: '1', name: 'URL' }, { id: '2', name: 'File' }];
  FileInput: any = false;
  FolderInput = false;
  file: any;
  fileContent: { mimeType: any, size: any, title: any };
  Rename: boolean = false;
  organization: any = [];
  employeeGroup: any[];
  groups: any[];
  Access = [];
  ShowPublic = false;
  ShowShared = false;
  addFolder: boolean = false;
  accessId: any;
  constructor(
    public dialogRef: MatDialogRef<MatAddDocumentComponent>,
    private loaderService: LoaderService,
    private DocumentService: DocumentsService,
    private fb: FormBuilder,
    private pickListService: PicklistService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (localStorage.getItem('activeListing') == 'Private') {
      this.accessId = 2;
    }
    if (localStorage.getItem('activeListing') == 'Public') {
      this.accessId = 1;
    }
    if (localStorage.getItem('activeListing') == 'Shared') {
      this.accessId = 3;
    }
    if (this.data.value.Type == 'folder') {
      this.FolderInput = true;
      this.addFolder = true;
    } else if (this.data.value.Type == 'Rename') {
      this.FolderInput = true;
      this.Rename = true;
    }
    this.formGenerate();
    this.getDocumentAccess();
  }

  fileBrowseHandler(files) {
    this.fileContent = {
      mimeType: files[0].type,
      size: files[0].size / 1000,
      title: files[0].name
    };
    this.getBase64(files[0]);
  }
  deleteFile() {
    this.fileContent = {
      mimeType: null,
      size: null,
      title: null
    };
  }
  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    let base64;
    reader.onload = () => {
      base64 = reader.result;
      const img = base64.replace(/^data:(image|application)\/(png|jpg|jpeg|pdf);base64,/, '');
      this.file = img;
    };
    reader.onerror = (error) => {
    };
  }
  formGenerate() {
    this.form = this.fb.group({
      // Type: new FormControl(''),
      title: '',
      Url: '',
      organizationId: '',
      employeeGroupId: '',
      accessTypeId: ''
    });
  }
  AccessChange(event) {
    if (event.value == 1) {
      this.ShowPublic = true;
      this.ShowShared = false;
    }
    if (event.value == 3) {
      this.ShowPublic = true;
      this.ShowShared = true;
    } else if (event.value == 2) {
      this.ShowPublic = false;
      this.ShowShared = false;
    }

  }

  viewpreviewButton(event) {
    this.FileInput = event.value == "File" ? true : false;
  }

  check(event) {
    this.populate = event.target.checked;
  }

  RenameTitle() {
    const data = {
      accessTypeId: 2,
      title: this.form.value.title,
    };
    this.loaderService.show();
    this.DocumentService.RenameTitle(this.data.value.RowId, data).subscribe(data => {
      this.loaderService.hide();
      this.dialogRef.close(true);
    });
  }

  onSubmit() {
    if (localStorage.getItem('activeListing') == 'Private') {
      this.accessId = 2;
    }
    if (localStorage.getItem('activeListing') == 'Public') {
      this.accessId = 1;
    }
    if (localStorage.getItem('activeListing') == 'Shared') {
      this.accessId = 3;
    }
    const data = {
      parentId: this.data.value.parentId || null,
      // accessTypeId: this.form.value.accessTypeId,
      accessTypeId: this.accessId,
      organizationId: +this.form.value.organizationId || null,
      employeeGroupId: this.form.value.employeeGroupId || null,
      content: this.file,
      mimeType: this.fileContent.mimeType,
      size: this.fileContent.size,
      title: this.fileContent.title,
      type: 'File',
    };
    this.loaderService.show();
    this.DocumentService.PostDocument(data).subscribe(data => {
      this.loaderService.hide();
      this.dialogRef.close(true);
    });
  }

  AddFolder() {
    const data = {
      accessTypeId: this.accessId,
      employeeGroupId: this.form.value.employeeGroupId || null,
      parentId: this.data.value.parentId || null,
      organizationId: +this.form.value.organizationId || null,
      title: this.form.value.title,
      isDirectory: true,
      type: 'Folder',
    };
    this.loaderService.show();
    this.DocumentService.PostDocument(data).subscribe(data => {
      this.loaderService.hide();
      this.dialogRef.close(true);
    });
  }

  getDocumentAccess() {
    this.DocumentService.getDocumentAccessList().subscribe((data: Array<GenericPicklistModel>) => {
      this.Access = data;
    });
  }
  onOrganizationChange(event) {
    this.groups = [];
    // if (this.form.value.organizationId) {
    //   this.getEmployeeGroup(this.form.value.organizationId);
    // }
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }
}
