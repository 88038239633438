import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    TemplateRef,
    ElementRef,
    OnInit,
    OnDestroy,
    Input,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkOrderService } from '../../services/work-order.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';
import * as _moment from 'moment';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { default as _rollupMoment, Moment } from 'moment';
export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
const moment = _rollupMoment || _moment;

export enum CalendarView {
    Year = "year",
    Month = "month",
    Week = "week",
    Day = "day"
}
@Component({
    selector: 'app-calendar-view',
    templateUrl: './calendar-view.html',
    styleUrls: ['./calendar-view.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class CalendarViewComponent implements OnInit, OnDestroy {
    @Input() filterValueChange: boolean;
    @Input() washroomCategory: boolean;
    @ViewChild('calendarDatePicker', { static: false }) calendarDatePicker: MatDatepicker<Date>;
    eventGuid = 0;
    selectedEvent: any;
    traversedMonthList: any[] = [];
    currentStartDate: any;
    currentEndDate: any;
    eventList: any = [];
    calendarEvents: any = [];
    calendarOptions: CalendarOptions = {
        // dayHeaderFormat :{weekday : 'short', day:'numeric'},
        headerToolbar: {
            // start: 'today',
            left: 'today,prev,next,title,datePickerButton',
            center: 'status,open,started,onhold,completed,closed',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        views: {
            week: {
                // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
                dayHeaderFormat: { day: 'numeric', weekday: 'short' },
                allDaySlot: false,
                displayEventEnd: false,
                dayHeaderContent: function (date) {
                    // return moment(date.date).format('ddd D');
                    let formattedDay = moment(date.date).format('ddd');
                    let formattedDate = moment(date.date).format('D');
                    return {
                        html: `${formattedDay}
                    <span> ${formattedDate} </span>`
                    }
                },
                // dayCellContent: function (date) {
                //     return moment(date.date).format('ddd D')

                // },
            },
            day: {
                dayHeaderFormat: { day: 'numeric', weekday: 'short' },
                allDaySlot: false,
                displayEventEnd: false,
                dayHeaderContent: function (date) {
                    // return moment(date.date).format('ddd D');
                    let formattedDay = moment(date.date).format('ddd');
                    let formattedDate = moment(date.date).format('D');
                    return {
                        html: `${formattedDay}
                    <span> ${formattedDate} </span>`
                    }
                },
                // options apply to dayGridDay and timeGridDay views
            }
        },
        buttonText: {
            today: 'Today',
            month: 'Month',
            week: 'Week',
            day: 'Day'
        },
        // themeSystem: 'bootstrap',
        // bootstrapFontAwesome:{
        //     datePickerButton: "fa fa fa-caret-down"
        // },
        customButtons: {
            datePickerButton: {
                // text: 'DatePicker:',
                icon: 'fa fa fa-caret-down',
                click: () => {
                    this.calendarDatePicker.open();
                }
            },
            status: {
                text: 'Status:',
            },
            open: {
                text: 'Opened/Created',
            },
            started: {
                text: 'Started',
            },
            onhold: {
                text: 'On Hold',
            },
            completed: {
                text: 'Completed',
            },
            // escalated: {
            //     text: 'Escalated',
            // },
            reopen: {
                text: 'Reopened'
            },
            closed: {
                text: 'Closed'
            }
        },
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: true
        },
        fixedWeekCount: false,
        // eventOverlap:false,
        viewDidMount: this.cancel.bind(this),
        initialView: 'dayGridMonth',
        weekends: true,
        editable: false,
        selectable: true,
        selectMirror: false,
        dayMaxEvents: 2,
        eventClick: this.eventClicked.bind(this),
        eventContent: function (arg) {
            const viewType = arg.view.type;
            let arrayOfDomNodes = [];
            // if (viewType == 'dayGridMonth') {
            let parentElement = document.createElement('div');
            let elementIdDetails = document.createElement('div');
            elementIdDetails.innerHTML = arg.event._def.title;
            let timeDetails = document.createElement('div');
            timeDetails.innerHTML = arg.timeText
            parentElement.appendChild(elementIdDetails);
            parentElement.appendChild(timeDetails);
            arrayOfDomNodes.push(parentElement);
            // }
            let categoryDetails = document.createElement('div');
            categoryDetails.innerHTML = arg.event._def.extendedProps.description;
            arrayOfDomNodes.push(categoryDetails);

            return { domNodes: arrayOfDomNodes }
        },
        // events: this.calendarEvnts.bind(this),
        eventDisplay: 'list-item',
        defaultAllDay: false,
        datesSet: (datesSet) => {
            this.view = datesSet.view.type == 'dayGridMonth' ? CalendarView.Month : (datesSet.view.type == 'timeGridWeek' ? CalendarView.Week : CalendarView.Day)
            // if (this.view != CalendarView.Month) {
            //     document.getElementsByClassName("fc-datePickerButton-button")[0].classList.add("d-none");
            // } else {
            //     document.getElementsByClassName("fc-datePickerButton-button")[0].classList.remove("d-none");
            // }
            const currentStartMonth = moment(new Date(datesSet.view.currentStart)).startOf('month').format('DD-MM-YYYY');
            const currentEndMonth = moment(new Date(datesSet.view.currentEnd)).subtract(1, "days").startOf('month').format("DD-MM-YYYY");
            this.currentStartDate = moment(new Date(datesSet.view.currentStart)).startOf(this.view).valueOf();
            this.currentEndDate = moment(new Date(datesSet.view.currentStart)).endOf(this.view).valueOf();

            if (!this.traversedMonthList.includes(currentEndMonth)) {
                this.traversedMonthList.push(currentEndMonth);
                let startDate = moment(currentEndMonth, "DD-MM-YYYY").startOf('month').valueOf();
                let endDate = moment(currentEndMonth, "DD-MM-YYYY").endOf('month').valueOf();
                this.getWorkOrderList(this.view, startDate, endDate);
            }
            else {
                const filteredEvents = this.calendarEvents.filter((item: any, index) => {
                    return (moment(moment(item.start).format("DD-MM-YYYY"), "DD-MM-YYYY").valueOf() >= this.currentStartDate) && (moment(moment(item.end).format("DD-MM-YYYY"), "DD-MM-YYYY").valueOf() <= this.currentEndDate)
                })
                this.workOrderCount = filteredEvents.length;
            }
        }
        // slotEventOverlap : false
    };

    @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent;
    @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
    @ViewChild('infoDialog', { static: false }) infoDialog: ElementRef;
    view: CalendarView = CalendarView.Month;
    isClick: boolean = false;
    targetEventDetails: any = {};
    leftPosition: number;
    topPosition: any;
    industryId: string = localStorage.getItem('industryId');
    workOrderCount: any;
    statusArray: any = [];
    escalateStatusArray: any = [];
    buildingArray: any = [];
    floorArray: any = [];
    roomArray: any = [];
    departmentArray: any = [];
    seatArray: any = [];
    bedArray: any = [];
    categoryArray: any = [];
    subCategoryArray: any = [];
    // currentDate: any = new Date().valueOf();

    constructor(private modal: NgbModal, private workOrderService: WorkOrderService,
        private loaderService: LoaderService, private toastService: ToastService,
        private matDialog: MatDialog, private router: Router, private commonService: CommonServiceService) { }
    date = new FormControl(moment());

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.date.setValue(ctrlValue);
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        this.date.setValue(ctrlValue);
        if (this.view == CalendarView.Month) {
            this.calendarComponent.getApi().gotoDate(this.date.value.toISOString());
            datepicker.close();
        }
    }

    chosenDateHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.date.value;
        this.date.setValue(ctrlValue);
        this.calendarComponent.getApi().gotoDate(this.date.value.toISOString())
        datepicker.close();
    }

    ngOnChanges(event) {
        this.statusArray = [];
        this.escalateStatusArray = [];
        this.buildingArray = [];
        this.floorArray = [];
        this.departmentArray = [];
        this.roomArray = [];
        this.seatArray = [];
        this.bedArray = [];
        this.categoryArray = [];
        this.subCategoryArray = [];
        if (this.filterValueChange) {
            this.filterValueChange['status'].forEach(x => this.statusArray.push(x.code.toUpperCase()));
            this.filterValueChange['escalateStatus'].forEach(x => this.escalateStatusArray.push(x.status));
            this.filterValueChange['buildingIds'].forEach(x => this.buildingArray.push(x.id));
            this.filterValueChange['roomIds'].forEach(x => this.roomArray.push(x.id));
            this.filterValueChange['floorIds'].forEach(x => this.floorArray.push(x.id));
            this.filterValueChange['seatIds'].forEach(x => this.seatArray.push(x.id));
            this.filterValueChange['departmentIds'].forEach(x => this.departmentArray.push(x.id));
            this.filterValueChange['bedIds'].forEach(x => this.bedArray.push(x.id));
            this.filterValueChange['categoryIds'].forEach(x => this.categoryArray.push(x.id));
            this.filterValueChange['subCategoryIds'].forEach(x => this.subCategoryArray.push(x.id));
            if (event && event.filterValueChange && !event.filterValueChange.firstChange)
                this.resetWorkorder();
        }
    }

    ngOnInit() {

    }

    mapKeysOfFilter() {
        const body = {};
        // body['branch'] = this.branchId;
        this.statusArray.length > 0 ? body['status'] = this.statusArray : null;
        this.escalateStatusArray.length > 0 ? body['escalateStatus'] = this.escalateStatusArray : null;
        this.buildingArray.length > 0 ? body['building'] = this.buildingArray : null;
        this.floorArray.length > 0 ? body['floor'] = this.floorArray : null;
        this.departmentArray.length > 0 ? body['department'] = this.departmentArray : null;
        this.roomArray.length > 0 ? body['room'] = this.roomArray : null;
        this.seatArray.length > 0 ? body['seat'] = this.seatArray : null;
        this.bedArray.length > 0 ? body['bed'] = this.bedArray : null;
        this.categoryArray.length > 0 ? body['category'] = this.categoryArray : null;
        if(this.washroomCategory)
        {
            this.subCategoryArray.length > 0 ? body['subCategory'] = this.subCategoryArray : body['subCategory'] = [30];
        }
        else{
            this.subCategoryArray.length > 0 ? body['subCategory'] = this.subCategoryArray : body['subCategory'] = [2,3];
        }
        return body;
    }

    resetWorkorder() {
        this.isClick = false;
        let calendarApi = this.calendarComponent.getApi();
        calendarApi.removeAllEvents();
        this.eventList = [];
        this.calendarEvents = [];
        this.traversedMonthList = [];
        this.cancel();
        const currentEndMonth = moment(new Date(calendarApi.currentData.currentDate)).startOf('month').format("DD-MM-YYYY");
        this.traversedMonthList.push(currentEndMonth);
        let startDate = moment(currentEndMonth, "DD-MM-YYYY").startOf('month').valueOf();
        let endDate = moment(currentEndMonth, "DD-MM-YYYY").endOf('month').valueOf();
        this.getWorkOrderList(this.view, startDate, endDate);
    }

    createEventId() {
        return String(this.eventGuid++);
    }

    getWorkOrderList(view, startDate, endDate) {
        const body = this.mapKeysOfFilter();
        this.loaderService.show();
        this.workOrderService.getWorkOrderListAccordingCompression(view, startDate, endDate, body).subscribe((res: any) => {
            this.loaderService.hide();
            const eventList = Object.values(res.data);
            const events = [];
            eventList.forEach((item: []) => {
                item.forEach(el => {
                    events.push(el);
                    this.eventList.push(el);
                })
            });
            this.addEvents(events);
        }, err => {
            this.errorSnackBar(err);
            this.loaderService.hide();
        })
    }

    addEvents(events) {
        events.forEach((item, index) => {
            const location = [];
            if (item.branchName) {
                location.push(item.branchName);
            }
            if (item.buildingName) {
                location.push(item.buildingName);
            }
            if (item.floorName) {
                location.push(item.floorName);
            }
            if (item.departmentName) {
                location.push(item.departmentName);
            }
            if (item.roomName) {
                location.push(item.roomName);
            }
            if (this.industryId == '1' && item.bedNo) {
                location.push(item.bedNo);
            }
            else if (this.industryId == '2' && item.seatNo) {
                location.push(item.seatNo);
            }
            const startHours = new Date(item.startTime).getHours();
            const startMinute = new Date(item.startTime).getMinutes();
            const startDate = new Date(item.orderDate);
            startDate.setHours(startHours, startMinute);
            const endHours = new Date(item.endTime).getHours();
            const endMinute = new Date(item.endTime).getMinutes();
            const endDate = new Date(item.orderDate);
            endDate.setHours(endHours, endMinute);
            const closureTime = item.sla;
            let duration = 'Duration:';
            if (closureTime / 60 > 0) {
                const hrs = Math.floor(closureTime / 60);
                const mins = (closureTime - hrs * 60).toString();
                if (hrs) {
                    duration = hrs + 'hr'
                }
                if (mins != '0') {
                    duration = mins + 'min';
                }
            } else {
                duration = closureTime + 'min'
            }
            this.calendarEvents.push({
                id: this.createEventId(),
                title: `${item.workOrderId} | ${item.scheduleId}`,
                start: startDate,
                end: endDate,
                classNames: this.eventClassNames(item),
                description: `${item.subCategoryName}, ${item.categoryName}`,
                locationDetails: `${location.join(', ')}`,
                durationTime: `${duration}`,
                editable: false,
            })
        })
        console.log(this.calendarEvents)
        this.calendarOptions.events = [...this.calendarEvents];
        const filteredEvents = this.calendarEvents.filter((item: any, index) => {
            return (moment(moment(item.start).format("DD-MM-YYYY"), "DD-MM-YYYY").valueOf() >= this.currentStartDate) && (moment(moment(item.end).format("DD-MM-YYYY"), "DD-MM-YYYY").valueOf() <= this.currentEndDate)
        })
        this.workOrderCount = filteredEvents.length;
    }

    eventClassNames(item) {
        if (item.status == 'WORKORDER_OPEN' || item.status == 'WORKORDER_CREATED' || item.status == 'WORKORDER_REOPEN') {
            return ['event-class open']
        } else if (item.status == 'WORKORDER_INPROGRESS') {
            return ['event-class started']
        } else if (item.status == 'WORKORDER_HOLD') {
            return ['event-class onHold']
        } else if (item.status == 'WORKORDER_ESCALATED') {
            return ['event-class escalated']
        } else if (item.status == 'WORKORDER_COMPLETED') {
            return ['event-class completed']
        } else if (item.status == 'WORKORDER_REOPEN') {
            return ['event-class reopen']
        } else if (item.status == 'WORKORDER_CLOSED') {
            return ['event-class closed']
        }
    }

    eventClicked(info) {
        this.selectedEvent = info;
        const workOrderId = info.event.title.split(' | ')[0];
        const index = this.eventList.findIndex((item: any) => item.workOrderId == workOrderId);
        if (this.eventList[index]['workOrderId'] == this.targetEventDetails.workOrderId) {
            this.isClick = false;
            document.getElementsByClassName('fc-scroller-liquid-absolute')[0].classList.remove('overflow-hidden');
            document.body.style.overflow = 'auto';
            this.targetEventDetails = {};
        } else {
            this.isClick = true;
            document.body.style.overflow = 'hidden';
            document.getElementsByClassName('fc-scroller-liquid-absolute')[0].classList.add('overflow-hidden');
            this.targetEventDetails = this.eventList[index];
            // this.currentDate = new Date().valueOf();
            // this.targetEventDetails.escalateTime = moment(this.targetEventDetails.startTime).add(this.targetEventDetails.sla, 'minutes').valueOf();
            const location = [];
            if (this.targetEventDetails.branchName)
                location.push(this.targetEventDetails.branchName);
            if (this.targetEventDetails.buildingName)
                location.push(this.targetEventDetails.buildingName);
            if (this.targetEventDetails.floorName)
                location.push(this.targetEventDetails.floorName);
            if (this.targetEventDetails.departmentName)
                location.push(this.targetEventDetails.departmentName);
            if (this.targetEventDetails.roomName)
                location.push(this.targetEventDetails.roomName);
            this.targetEventDetails['location'] = location.join(', ');
            this.leftPosition = info.jsEvent.clientX; //- this.commonService.sideBarWidth;
            this.topPosition = info.jsEvent.clientY; //- info.jsEvent.offsetY;
            if (info.jsEvent.clientX + 302 > window.innerWidth) {
                this.leftPosition = this.leftPosition - 302;
            } if (info.jsEvent.clientY + 260 > window.innerHeight) {
                this.topPosition = Math.abs(this.topPosition - 260);
            }
            if (this.targetEventDetails.status == 'WORKORDER_OPEN' || this.targetEventDetails.status == 'WORKORDER_CREATED' || this.targetEventDetails.status == 'WORKORDER_REOPEN') {
                this.targetEventDetails['workOrderStatus'] = 'Created';
                this.targetEventDetails['workOrderStatusClass'] = 'not-started';
            } else if (this.targetEventDetails.status == 'WORKORDER_INPROGRESS') {
                this.targetEventDetails['workOrderStatus'] = 'Started';
                this.targetEventDetails['workOrderStatusClass'] = 'started';
            } else if (this.targetEventDetails.status == 'WORKORDER_HOLD') {
                this.targetEventDetails['workOrderStatus'] = 'On Hold';
                this.targetEventDetails['workOrderStatusClass'] = 'onHold';
            } else if (this.targetEventDetails.status == 'WORKORDER_ESCALATED') {
                this.targetEventDetails['workOrderStatus'] = 'Escalated';
                this.targetEventDetails['workOrderStatusClass'] = 'escalated';
            } else if (this.targetEventDetails.status == 'WORKORDER_COMPLETED') {
                this.targetEventDetails['workOrderStatusClass'] = 'completed';
                this.targetEventDetails['workOrderStatus'] = 'Completed';
            } else if (this.targetEventDetails.status == 'WORKORDER_CLOSED') {
                this.targetEventDetails['workOrderStatus'] = 'Closed';
                this.targetEventDetails['workOrderStatusClass'] = 'closed';
            }
            // else if (this.targetEventDetails.status == 'WORKORDER_REOPEN') {
            //     this.targetEventDetails['workOrderStatus'] = 'Re-open';
            //     this.targetEventDetails['workOrderStatusClass'] = 'reopen';
            // }
        }
    }

    editWorkOrder(id) {
        document.getElementsByClassName('fc-scroller-liquid-absolute')[0].classList.remove('overflow-hidden');
        document.body.style.overflow = 'auto';
        if(this.washroomCategory)
        {
            this.router.navigate(['admin/hygiene/work-order/edit-work-order/swh/' + id]);
        }
        else{
            this.router.navigate(['admin/hygiene/work-order/edit-work-order/hyg/' + id]);
        }
    }

    deleteWorkOrder(id) {
        this.matDialog.open(ConfirmationDialogComponent, {
            data:
            {
                yesBtnName: 'Confirm',
                noBtnName: 'Cancel',
                confirmationMesg: `Are you sure, you want to delete this workorder?`,
                heading: `Confirmation`
            }
        }).afterClosed().subscribe(res => {
            if (res === 1) {
                this.loaderService.show();
                this.workOrderService.deleteWorkOrder([id]).subscribe((res: any) => {
                    this.loaderService.hide();
                    this.isClick = false;
                    let calendarApi = this.calendarComponent.getApi();
                    calendarApi.removeAllEvents();
                    this.eventList = [];
                    this.calendarEvents = [];
                    this.traversedMonthList = [];
                    this.cancel();
                    const currentEndMonth = moment(new Date(calendarApi.currentData.currentDate)).startOf('month').format("DD-MM-YYYY");
                    this.traversedMonthList.push(currentEndMonth);
                    let startDate = moment(currentEndMonth, "DD-MM-YYYY").startOf('month').valueOf();
                    let endDate = moment(currentEndMonth, "DD-MM-YYYY").endOf('month').valueOf();
                    this.getWorkOrderList(this.view, startDate, endDate);
                }, error => {
                    this.loaderService.hide();
                    this.errorSnackBar(error);
                })
            }
        })
    }

    cancel(info?) {
        this.isClick = false;
        document.body.style.overflow = 'auto';
        document.getElementsByClassName('fc-scroller-liquid-absolute')[0].classList.remove('overflow-hidden');
        this.targetEventDetails = {};
    }

    successSnackBar(message) {
        this.toastService.opentoast(
            { toastName: 'success', data: { name: '', message } },
            ToasterComponent
        );
    }

    errorSnackBar(error) {
        this.toastService.opentoast(
            { toastName: '', data: { errorCode: error.status, error: error.error } },
            ErrorToasterComponent
        )
    }

    ngOnDestroy() {
        document.body.style.overflow = 'auto';
    }
}
