import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddWorkOrderComponent } from './component/add-work-order/add-work-order.component';
import { HygieneAuditTrailComponent } from './component/audit-trail/audit-trail.component';
import { WorkOrderComponent } from './component/work-order/work-order.component';
import { WorkOrderFailureListComponent } from './component/work-order-failure-list/work-order-failure-list.component'

const routes: Routes = [
    {
        path: 'add-work-order/:category/:workOrderId',
        component:AddWorkOrderComponent
    }, 
    {
        path: 'edit-work-order/:category/:workOrderId',
        component:AddWorkOrderComponent
    }, 
    {
        path:':category',
        component:WorkOrderComponent
    },
    {
        path:'audit-trail/:workOrderId',
        component: HygieneAuditTrailComponent
    },
    {
        path:'failure-list',
        component: WorkOrderFailureListComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule]
})
export class WorkOrderRoutingModule { }
