import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../../src/app/config/environments/environment';
import { CommonServiceService } from '../../../../../../../../src/app/core/common-service.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InspectionListService {

  isListView: boolean = true;
  customerList = new Subject<Boolean>();
  customerListObservable = this.customerList.asObservable();

  constructor(private http: HttpClient, private router: Router, private commonService: CommonServiceService) { }


  //GET  Inspection BY CUSTOMER
  getInspectionData(customerID)
  {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swinspection/getList?customerId=${customerID}`);
  }


  //ADD/REMOVE Inspection
  addInspectionData(data)
  {
    return this.http.post(`${environment.hygieneApiUrl}api/v1/swinspection/add`,data);
  }

  removeInspectionData(customerId,id)
  {
    return this.http.delete(`${environment.hygieneApiUrl}api/v1/swinspection/deleteById?customerId=${customerId}&id=${id}`);
  }

}
