import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from '../../services/loader.service';
import { LeaveStatusPicklistModel } from '../../model/picklist.model';
import { PicklistService } from '../../services/picklist.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
@Component({
  selector: 'app-mat-confirm-dialog',
  templateUrl: './mat-confirm-dialog.html',
  styleUrls: ['./mat-confirm-dialog.scss']
})

export class MatConfirmDialogComponent implements OnInit {
  title: string;
  message: any;
  value: string;
  UpdateTicketForm: FormGroup;
  updatedStatusForm: FormGroup;
  updatedLeaveRequestStatusForm: FormGroup;
  RMForm: FormGroup;
  AssignedToForm: FormGroup;
  managerId: any;
  imageChangedEvent: any;
  croppedImage: any;
  url: any;
  transform: any;
  scale = 1;
  containWithinAspectRatio = true;
  past = 1;
  noResult = false;
  filteredOptions1: Observable<Object[]>;
  reportingManagers1: any = [];

  // attendanceHeaders = [{ key: 'userEmail', value: 'Official Email' }, { key: 'userFirstName', value: 'Name' },
  // { key: 'userCode', value: 'Code' }, { key: 'userOrganization', value: 'Organization' },
  // { key: 'userDepartment', value: 'Department' }, { key: 'userReportingManagerName', value: 'Reporting Manager' }, {
  //   key: 'attendanceType', value: 'Attendance type'
  // }, { key: 'inTime', value: 'TimeInOffice' }, { key: 'breakTime', value: 'BreakTime' }
  //   , { key: 'attendanceStatus', value: 'Status' }, { key: 'date', value: 'date' }, { key: 'punchInTime', value: 'fisrt PunchIn time' },
  // { key: 'punchOutTime', value: 'Last punch out time' }];


  employeeHeaders = [{ key: 'Full Name', value: 'Full Name', selected: true, permanentSelected: true, disable: true },
  { key: 'Code', value: 'Employee Code', selected: true, permanentSelected: true, disable: true },
  { key: 'Work Email', value: 'Official Email', selected: true, permanentSelected: true, disable: true },
  { key: 'Organization Name', value: 'Organization', selected: true, permanentSelected: true, disable: true },
  { key: 'Gender', value: 'Gender', selected: false, disable: false },
  { key: 'Date Of Birth', value: 'Date of Birth', selected: false, disable: false }
    , { key: 'Status', value: 'Active/Inactive', selected: false, disable: false },
  { key: 'Employee Status', value: 'Employee status', selected: false, disable: false },
  { key: 'Employment Status', value: 'Employment status', selected: false, disable: false },
  { key: 'Mode Of Employment', value: 'Mode of Employment', selected: false, disable: false },

  { key: 'Date Of Joining', value: 'Date Of Joining', selected: false, disable: false },
  { key: 'Date Of Leaving', value: 'Date Of Leaving', selected: false, disable: false }
    , { key: 'Department Name', value: 'Department', selected: false, disable: false },
  { key: 'Designation Name', value: 'Designation', selected: false, disable: false }
    , { key: 'Location Name', value: 'Location', selected: false, disable: false },
  { key: 'Reporting Manager', value: 'Reporting Manager', selected: false, disable: false }
    , { key: 'Nationality', value: 'Nationality', selected: false, disable: false },
  { key: 'Marital Status', value: 'Marital status', selected: false, disable: false }
    , { key: 'Ethnicity', value: 'Ethnicity', selected: false, disable: false },
  { key: 'Imigration Status', value: 'Imigration Status', selected: false, disable: false },
  { key: 'Passport', value: 'Passport', selected: false, disable: false },
  { key: 'Mobile Number', value: 'Mobile Number', selected: false, disable: false },

  { key: 'Personal Email', value: 'Personal Email', selected: false, disable: false }
    , { key: 'Permanent Address', value: 'Permanent Adress', selected: false, disable: false },
  { key: 'Communication Address', value: 'Communication Address', selected: false, disable: false }
    , { key: 'Emergency Contact1', value: 'Emergency Contact-1 ', selected: false, disable: false }
    , { key: 'Emergency Contact2', value: 'Emergency Contact-2 ', selected: false, disable: false }];


  leaveStatusPicklist: Array<LeaveStatusPicklistModel>;
  checkedHeaderKey: any = ["Full Name", "Work Email", "Code", "Organization Name"];
  flag: any;
  TicketStatusPicklist: Object;
  AssignedTo: any;
  resoultionPicker = '';
  aspectRatio: any;
  constructor(
    public dialogRef: MatDialogRef<MatConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private commonService: CommonServiceService,
    private fb: FormBuilder, private picklistService: PicklistService,
    private loaderService: LoaderService) {
    if (Array.isArray(data.line)) {
      if (data.line.length === 0) {
        this.noResult = true;
      }
    }
    this.title = data.title;
    this.message = data.line;
  }

  ngOnInit() {
    this.UpdateTicketForm = this.fb.group({
      assignedTo: ['', [this.typeCheck]],
      ticketStatus: []
    })
    this.filteredOptions1 = this.UpdateTicketForm.get('assignedTo').valueChanges.
      pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    if (this.title === 'Reporting Manager') {
      this.commonService.reportingManagerIds = this.message;
      this.commonService.sendRMIds();
      this.value = 'Reporting Manager';
    } else if (this.title === 'upload') {
      this.imageChangedEvent = this.message;
      this.value = 'upload';
    } else if (this.title === 'upload-logo') {
      this.resoultionPicker = 'upload-logo';
      this.aspectRatio = 6 / 3;
      this.imageChangedEvent = this.message;
      this.value = 'upload-logo';
    } else if (this.title === 'upload-logo thumbnail') {
      this.resoultionPicker = 'upload-logo thumbnail';
      this.aspectRatio = 154 / 113;
      this.imageChangedEvent = this.message;
      this.value = 'upload-logo';
    } else if (this.title === 'upload-logo image') {
      this.resoultionPicker = 'upload-logo image';
      this.aspectRatio = 693 / 467;
      this.imageChangedEvent = this.message;
      this.value = 'upload-logo';
    }
    else {
      this.value = this.title;
    }
    this.AssignedToForm = this.fb.group({
      AssignedTo: ['', [Validators.required, this.typeCheck]]
    });
    this.RMForm = this.fb.group({
      manager: ['', [Validators.required, this.typeCheck]]
    });

    this.updatedStatusForm = new FormGroup({
      Status: new FormControl('2')
    });

    this.updatedLeaveRequestStatusForm = this.fb.group({
      status: ['', [Validators.required]]
    });

  }
  imageCropped(event: ImageCroppedEvent) {
    this.loaderService.hide();
    this.url = event.base64;
  }
  formatLabel(value: number) {
    if (value >= 1000) {

      // return Math.round(value / 1000) + 'k';
    }
    return value;
  }
  // zoomOut() {
  //     this.scale -= .1;
  //     this.transform = {
  //       ...this.transform,
  //       scale: this.scale
  //     };
  // }
  // zomm($event) {

  // }
  Checked($event, key) {
    this.flag = false;
    let flag = false;
    for (let j = 0; j < this.employeeHeaders.length; j++) {
      if (this.checkedHeaderKey[j] === key) {
        this.checkedHeaderKey.splice(this.checkedHeaderKey.indexOf(key), 1);
        flag = true;
      }
    }
    if (!flag) {
      this.checkedHeaderKey.push(key);
    }
    this.employeeHeaders.forEach(elm => {
      if (elm.key === key) {
        elm.selected = true;
      }
    });
    if (this.checkedHeaderKey.length === this.employeeHeaders.length) {
      this.flag = true;
    }
  }
  allCheckbox(event: any) {
    const all = event.target.checked;
    this.flag = event.target.checked;
    // select all employees
    if (all) {
      this.checkedHeaderKey = [];
      for (let i = 0; i < this.employeeHeaders.length; i++) {
        this.checkedHeaderKey.push(this.employeeHeaders[i].key);
      }
    } else {
      this.checkedHeaderKey = ["Full Name", "Work Email", "Code", "Organization Name"];
    }
    this.employeeHeaders.forEach(elm => {
      elm.selected = all;
    });
  }
  onConfirmExport() {
    this.dialogRef.close(this.checkedHeaderKey);
  }
  zoomIn(event) {
    if (event.value > this.past) {
      for (let i = 0; i < event.value - this.past; i++) {
        this.scale += .1;
        this.transform = {
          ...this.transform,
          scale: this.scale
        };
      }
    } else {
      for (let i = 0; i < this.past - event.value; i++) {
        this.scale -= .1;
        this.transform = {
          ...this.transform,
          scale: this.scale
        };
      }
    }
    this.past = event.value;
  }
  click(event) {
    if (event.checked === true) {
      this.updatedStatusForm.value.Status = 1;
    } else {
      this.updatedStatusForm.value.Status = 2;
    }
  }
  upload() {
    this.loaderService.show();
    this.dialogRef.close(this.url);
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
  typeCheck(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value == null || typeof (control.value.manager) == 'string') {
      return { typeCheck: true };
    }
    return null;
  }
  loadImageFailed() {
    // for future scope
  }
  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);

  }

  OnConfirmStatus(): void {
    const data = {
      value: this.updatedStatusForm.value.Status,
      success: true
    };
    this.dialogRef.close(data);

  }
  OnDismissStatus(): void {
    const data = {
      value: null,
      success: false
    };
    this.dialogRef.close(data);
  }
  OnConfirmAssignedTo(): void {
    this.AssignedTo = this.UpdateTicketForm.value.assignedTo ? this.UpdateTicketForm.value.assignedTo.email : '';
    const data = {
      value: this.UpdateTicketForm.value.ticketStatus,
      email: this.AssignedTo,
      success: true
    };
    this.dialogRef.close(data);

  }
  OnConfirmReportingManager(): void {
    const data = {
      value: this.RMForm.value.manager.manager.id,
      email: this.RMForm.value.manager.manager.email,
      success: true
    };
    this.dialogRef.close(data);

  }
  OnDismissReportingManager(): void {
    const data = {
      value: null,
      success: false
    };
    this.dialogRef.close(false);
  }


  OnConfirmLeaveRequestStatus(): void {
    const data = {
      value: this.updatedLeaveRequestStatusForm.value.status,
      success: true
    };
    this.dialogRef.close(data);

  }

  OnDismissLeaveRequestStatus(): void {
    const data = {
      value: null,
      success: false
    };
    this.dialogRef.close(false);
  }
  // ------------------------------------Assigned To code---------------------------------------//

  filteredOptions() {
    this.filteredOptions1 = this.UpdateTicketForm.get('assignedTo').valueChanges.
      pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  _filter(value: Object): Object[] {
    const filterValue = value;
    return this.reportingManagers1.filter(manager =>
      manager.email.includes(filterValue)
    );
  }

  displayFn(manager) {
    if (manager) {
      return manager.userName + '(' + manager.email + ')';
    }
  }
}
