import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/app/config/environments/environment';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';

@Injectable({
  providedIn: 'root'
})
export class BlacklistVisitorService {
  constructor(private http: HttpClient, private common: CommonServiceService) { }

  visitorList(pageInfo: PaginationModel, branchId, status = '', body = {}) {
    body['branchIds'] = [branchId];
    if (status && status.toString()) {
      body['status'] = status;
    }
    return this.http.post(`${environment.patientApiUrl}api/v1/blacklistvisitor/web?page=${pageInfo.page}&size=${pageInfo.size}&qry=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&order=${pageInfo.sortDir}`, body);
  }


  getVisitorDetail(blackVisitorId) {
    return this.http.get(`${environment.patientApiUrl}api/v1/blacklistvisitor/${blackVisitorId}`);
  }

  approveVisitorDetail(body) {
    return this.http.put(`${environment.patientApiUrl}api/v1/blacklistvisitor/updateBlacklistStatus?id=${body.id}&&status=${body.approvalStatus}`, {});
  }

}
