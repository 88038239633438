import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.html',
  styleUrls: ['./toaster.scss']

})
export class ToasterComponent {
  public model: any;
  constructor(
    public snackBarRef: MatSnackBarRef<ToasterComponent>
  ) {
    this.model = snackBarRef.containerInstance.snackBarConfig['model'];
  }
}
