import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { environment } from 'src/app/config/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(private http: HttpClient, private common: CommonServiceService) { }

  getCountry() {
    return this.http.get(environment.customerApiUrl + 'api/v1/configs/countries');
  }

  getTimezone() {
    return this.http.get(environment.customerApiUrl + 'api/v1/configs/timeZone');
  }

  getTheme() {
    return this.http.get(environment.customerApiUrl + 'api/v1/configs/theme');
  }

  getOrganizationByID(id: any) {
    return this.http.get(environment.customerApiUrl + 'api/v1/customers/' + id);
  }

  customerByID(id: any) {
    return this.http.get(environment.customerApiUrl + 'api/v1/customers/getCustomerById/' + id);
  }

  getCity(stateId: any) {
    return this.http.get(environment.customerApiUrl + 'api/v1/configs/cities?stateId=' + stateId);
  }

  getState(id) {
    return this.http.get(environment.customerApiUrl + 'api/v1/configs/state?countryId=' + id);
  }

  getPackages(industryId) {
    return this.http.get(environment.authApiUrl + '/api/v1/packages' + `?page=0&qry=&size=1000000&by=name&order=ASC&industry=` + industryId);
  }

  updateOrganization(credentials) {
    const customerId = JSON.parse(localStorage.getItem('customerId'));
    return this.http.put(environment.customerApiUrl + 'api/v1/customers/' + customerId, credentials);
  }

  updateCustomer(payload) {
    const customerId = JSON.parse(localStorage.getItem('customerId'));
    return this.http.put(`${environment.customerApiUrl}api/v1/customers/accountsetup/updateCustomer/${customerId}`, payload);
  }

  deleteOrganization(id) {
    return this.http.put(environment.customerApiUrl + 'api/v1/organization/' + id, id);
  }

  getIndustry() {
    return this.http.get(`${environment.customerApiUrl}api/v1/industries`);
  }

  uploadFile(formdata) {
    return this.http.post(`${environment.tpBaseUrl}api/v1/images/uploadFile`, formdata, { responseType: 'text' })
  }

  uploadLogo(formdata) {
    return this.http.post(`${environment.tpBaseUrl}api/v1/images/uploadFile`, formdata, { responseType: 'text' })
  }

  getImage(key) {
    return this.http.get(`${environment.notificationApiUrl}api/v1/images/getImage?key=${key}`, { responseType: 'blob', observe: 'response' });
  }

  //signin with microsoft
  signinMicrosoft() {
    return this.http.get(`${environment.visitorUrl}microsoft/adminOauthRedirect`);
  }

  setTenantId(customerId,tenantId)
  {
    return this.http.post(`${environment.visitorUrl}microsoft/saveTenant?customerId=${customerId}&tenantId=${tenantId}`,{});
  }
}
