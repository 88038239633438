import { Component, Input, OnInit } from '@angular/core';
import { CarousalService } from 'src/app/modules/shared/services/carousal.service';

@Component({
  selector: 'app-image-slide',
  templateUrl: './image-slide.component.html',
  styleUrls: ['./image-slide.component.scss']
})
export class ImageSlideComponent implements OnInit {
  @Input() imgSrc;
  @Input() location;
  @Input() title;
  @Input() slideNumber;
  @Input() hideAddressStrip:Boolean =false;
  constructor( private carousalService: CarousalService,
    ) {

     }

  ngOnInit() {
  }

}
