import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { environment } from 'src/app/config/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  selectedTab = 0;
  staffList = new Subject<Boolean>();
  staffListObservable = this.staffList.asObservable();
  isListView: boolean = true;
  constructor(private http: HttpClient, private common: CommonServiceService, private loader: LoaderService) { }

  employeeList(pageInfo: PaginationModel, customerId, status?) {
    if (status === true || status === false) {
      status = status ? 0 : 1;
      return this.http.get(`${environment.customerApiUrl}api/v1/staff/staffListing?customerId=${customerId}&searchText=${encodeURIComponent(this.common.searchText)}&page=${pageInfo.
        page}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}&status=${status}`);
    } else {
      return this.http.get(`${environment.customerApiUrl}api/v1/staff/staffListing?customerId=${customerId}&searchText=${encodeURIComponent(this.common.searchText.toLowerCase())}&page=${pageInfo.
        page}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
    }
  }

  draftStaffList(pageInfo: PaginationModel, customerId, body?) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staffDraft/staffListing?customerId=${customerId}&searchText=${encodeURIComponent(this.common.searchText)}&page=${pageInfo.
      page}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }
  ExportEmployeeList(pageInfo: PaginationModel, body, columns) {
    return this.http.post(`${environment.customerApiUrl}api/v1/staff/exportSelectiveUserList?columns=${columns}&page=${pageInfo.
      page}&searchText=${encodeURIComponent(this.common.searchText)}&size=${pageInfo.
        size}&sortBy=${this.common.head}&sortDirection=${this.common.sort}`, body);
  }
  EmployeeGrid(pageInfo: PaginationModel, customerId, body) {
    return this.http.post(`${environment.customerApiUrl}api/v1/staff/staffListing?customerId=${customerId}&page=${pageInfo.
      page}&searchText=${encodeURIComponent(this.common.searchText)}&size=${pageInfo.
        size}&sortBy=${this.common.head}&sortDirection=${this.common.sort}`, body);
  }

  deleteEmployee(id) {
    return this.http.put(environment.customerApiUrl + 'api/v1/api/v1/staff/' + id, id);
  }
  searchEmployeeCode(searchText: string, pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/filter?page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${searchText}`);
  }
  getReportingManagerFilterData(searchText: string, pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/filter/managerList?page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${searchText}`);
  }
  getDepartmentFilterData(searchText: string, pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}departments/filter?page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${searchText}`);
  }
  getOrganizationFilterData(searchText: string, pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}organization/filter?page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${searchText}`);
  }
  removeAllTemplateData(csvId: number) {
    return this.http.delete(`${environment.customerApiUrl}api/v1/staff/deleteCsv?csvId=${csvId}`);
  }
  uploadBulkRecords(csvId: number, machineModified) {
    return this.http.post(`${environment.customerApiUrl}api/v1/staff/finishBulkUpload?csvId=${csvId}&machineModified=${machineModified}`, null);
  }
  updateRowOfBulkUpdateError(response: any) {
    return this.http.put(`${environment.customerApiUrl}api/v1/staff/editTempRow/${response.id}`, response);
  }
  getIncorrectRecordCsv(csvId: number, pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/incorrectRecordsCsv?csvId=${csvId}&page=${pageInfo.
      page}&size=${pageInfo.size}`);
  }
  deleteAllBulkUploadErrorRecords(csvId: number) {
    return this.http.delete(`${environment.customerApiUrl}api/v1/staff/deleteIncorrectRows?csvId=${csvId}`);
  }

  downloadSampleCsv() {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/downloadFile`, { responseType: 'blob' as 'json' });
  }

  staffDraft(body) {
    return this.http.post(`${environment.customerApiUrl}api/v1/staffDraft/${body.staffId}`, body);
  }

  registerStaff(body) {
    return this.http.post(`${environment.customerApiUrl}api/v1/staff`, body);
  }
  saveDraft(body) {
    return this.http.post(`${environment.customerApiUrl}api/v1/staffDraft`, body);
  }

  updateDraftStaff(id, body) {
    return this.http.put(`${environment.customerApiUrl}api/v1/staffDraft/${id}`, body)
  }

  discardDraftStaff(id, body?) {
    return this.http.put(`${environment.customerApiUrl}api/v1/staffDraft/discardDraft/${id}`, body)
  }

  getStaffById(staffId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/${staffId}`);
  }

  downloadTemplate() {
    let zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.http.get(`${environment.customerApiUrl}api/v1/bulk-upload/staff-template?zoneId=${zoneId}`, { responseType: 'blob' as 'json' });
  }

  bulkUploadFile(formData) {
    let zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.http.post(`${environment.customerApiUrl}api/v1/bulk-upload/staff-validate?zoneId=${zoneId}`, formData, { observe: 'response', responseType: 'blob' as 'json' });
  }

  uploadBulk(bulkUploadId, formData?) {
    return this.http.post(`${environment.customerApiUrl}api/v1/bulk-upload/staff-excel/${bulkUploadId}`, {});
  }
  bulkActivateDeactivate(ids, status) {
    const filter = status === 'Active' ? true : false;
    const industryId = ids.join(',');
    let params = new HttpParams().append('id', industryId);
    params = params.append('status', status);
    return this.http.put(`${environment.customerApiUrl}api/v1/staff/bulkActivateDeactivateStaff`, {}, { params: params })
  }

  blackListStaffbyId(id, isBlacklist) {
    return this.http.put(`${environment.customerApiUrl}api/v1/staff/blacklistStaff/${id}?isBlacklist=${isBlacklist}`, {})
  }

  bulkBlacklistStaff(ids, isBlacklist) {
    return this.http.put(`${environment.customerApiUrl}api/v1/staff/blacklist?status=${isBlacklist}`, ids)
  }

  reshareSetPasswordLink(userId) {
    return this.http.post(`${environment.authApiUrl}/api/v1/users/${userId}/reShare/reset-password`, {});
  }

  // Sync Microsoft Calendar Users
  synCalendarUsers(customerId)
  {
    return this.http.post(`${environment.visitorUrl}microsoft/saveMicrosoftUsersData?customerId=${customerId}`,{});
  }
}

