import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../src/app/config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TabletserviceService {

  constructor(private http: HttpClient) { }

  //GET WO LIST
  getWorkOrderList(body) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders?by=&order=ASC&page=0&size=10000&searchText=&branchId=${body.branchId}&subCategoryId=30&startDate=${body.startDate}&endDate=${body.endDate}&buildingId=${body.buildingId}&floorId=${body.floorId}&departmentId=${body.departmentId}`);
  }

   //GET WO LIST WITHOUT TOKEN
   getWorkOrderWithoutTokenList(body) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders/withouttoken?by=&order=ASC&page=0&size=10000&searchText=&branchId=${body.branchId}&subCategoryId=30&startDate=${body.startDate}&endDate=${body.endDate}&buildingId=${body.buildingId}&floorId=${body.floorId}&departmentId=${body.departmentId}&customerId=${body.customerId}`);
  }

  //UPDATE WO
  // updateWorkOrder(workorderid,action) {
  //   return this.http.put(`${environment.hygieneApiUrl}api/v1/workorders/status/${workorderid}?action=${action}`, {})
  // }

  updateWorkOrder(workorderid,action) {
    return this.http.put(`${environment.hygieneApiUrl}api/v1/workorders/statusclose/${workorderid}?action=${action}`, {})
  }

  //Feedback-Complaint Submit
  addFeedbackComplaint(body) {
    return this.http.post(`${environment.hygieneApiUrl}api/v1/swfeedback`, body);
  }

  //addConsumables
  addConsumables(body) {
    return this.http.post(`${environment.hygieneApiUrl}api/v1/swconsumables/add`, body);
  }

}