import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonServiceService } from '../../../../../../../../src/app/core/common-service.service';
import { LoginService } from '../../../../../../../../src/app/modules/shared/login/service/login.service';
import { ErrorToasterComponent } from '../../../../../../../../src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from '../../../../../../../../src/app/modules/shared/component/toaster/toaster.component';
import { ToastService } from '../../../../../../../../src/app/modules/shared/services/toaster.service';
import { LoaderService } from '../../../../../../../../src/app/modules/shared/services/loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PicklistService } from '../../../../../../../../src/app/modules/shared/services/picklist.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { TabletserviceService } from '../tabletservice.service';
import moment from 'moment-timezone';
import { smartWashroom } from '../../../../../../../../src/app/config/constant';
import { InspectionListService } from '../../Services/Inspection-List/inspection-list.service';
import { SmartWashroomDashboardServiceService } from '../../Dashboard/smart-washroom-dashboard-service.service';

@Component({
  selector: 'app-workorder-list-link',
  templateUrl: './workorder-list-link.component.html',
  styleUrls: ['./workorder-list-link.component.scss']
})
export class WorkorderListLinkComponent implements OnInit {

  @ViewChild('passcodesModal',{static:false}) passcodeModal: TemplateRef<any>;
  

  public elem;
  isActive: boolean;

  branchId;
  customerId;
  isFullScreen: boolean = false;
  scheduledWOList: any[] = [];
  onDemandWOList: any[] = [];
  allOnDemandWOList: any[] = [];
  allScheduledWOList: any[] = [];
  userType: any='service';
  noResult: boolean = true;
  currentDate = moment().format('Do MMMM, YYYY');
  currentTime = moment().valueOf();
  modalOpen: boolean = false;

  LocationForm: FormGroup;
  Buildings: any[] = [];
  Floors: any[] = [];
  Departments: any[] = [];

  notStartedFilter: boolean = true;
  inProgressFilter: boolean = true;
  completedFilter: boolean = false;

  statusFilter: boolean = true;

  inspectionList: any = [];

  quantity = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  deviceScan: boolean = false;
  afterScanVerify: boolean = false;
  afterScan: boolean = false;
  customerLogo:any;

  passcode;
  passcodeDetails:any={};


  constructor(
    public commonService: CommonServiceService,
    public loggingIn: LoginService,
    private TabletService: TabletserviceService,
    private loaderService: LoaderService,
    public toastService: ToastService,
    public fb: FormBuilder,
    private pickListService: PicklistService,
    private modalService: NgbModal,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private inspectionService: InspectionListService,
    private SmartWashroomDashboardService:SmartWashroomDashboardServiceService
  ) {
  }


  ngOnInit() {
    this.initializeForms();
    this.activatedRoute.params.subscribe(params => {
      if (params.data) {
        let data=JSON.parse(atob(params.data));
        this.customerId = data.customerId;
        this.branchId = data.branchId;
        this.LocationForm.patchValue({
          building: data.buildingId
        });
        this.getCustomerLogo();
        this.getPasscodeDetails();
      }
    });

    window.matchMedia('(display-mode: fullscreen)').addEventListener('change', ({ matches }) => {
      if (matches) {
        this.isFullScreen = true;
        localStorage.setItem('tabletFullScreen', 'true');
      } else {
        this.isFullScreen = false;
        localStorage.setItem('tabletFullScreen', 'false');
      }
    });

    
    setInterval(() => {
      this.currentTime = moment().valueOf();
    }, 1000);

  }

  initializeForms() {
    this.LocationForm = this.fb.group({
      building: ['', [Validators.required]],
      floor: ['all', [Validators.required]],
      department: ['all', [Validators.required]]
    });
  }


  change() {
    this.isActive = !this.isActive;
  }


  openFullscreen() {
    this.elem = document.documentElement;
    this.elem.requestFullscreen();
  }

  exitFullscreen() {
    document.exitFullscreen();
  }

  goTo(path) {
    this.router.navigate([path]);
  }

  getBuilding() {
    if (this.branchId) {
      this.loaderService.show();
      this.pickListService.getBuilding(this.branchId).subscribe((res: any) => {
        this.LocationForm.controls.department.reset();
        this.LocationForm.controls.floor.reset();
        if (res.data.length) {
          this.Buildings = [];
          this.Buildings = res.data.map((x) => {
            return { id: x.id, name: x.name };
          });

          // this.LocationForm.patchValue({
          //   building: this.Buildings[0].id
          // });
            
            this.getFloor();

        }
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
    else
      return;
  }

  getFloor() {

    // this.LocationForm.patchValue({
    //   floor: 'all',
    //   department: 'all'
    // });

    let buildingId = this.LocationForm.controls.building.value ? this.LocationForm.controls.building.value : '';

    if (this.branchId && buildingId) {
      this.loaderService.show();

      this.Departments = [];
      this.pickListService.getFloor(this.branchId, buildingId).subscribe((res: any) => {

        this.Floors = [];
        if (res.data.length) {
          this.Floors = res.data.map((x) => {
            return { id: x.id, name: x.name };
          });
          this.LocationForm.patchValue({
            floor: this.Floors[0].id
          });
          this.getDepartment();
        }
        
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error)
      });
    }
  }

  getDepartment() {
    // this.LocationForm.patchValue({
    //   department: 'all'
    // });
    let buildingId = this.LocationForm.controls.building.value ? this.LocationForm.controls.building.value : '';
    let floorId = this.LocationForm.controls.floor.value ? (this.LocationForm.controls.floor.value === 'all' ? '' : this.LocationForm.controls.floor.value) : '';

    if (this.branchId && buildingId) {

      this.loaderService.show();
      this.pickListService.getDepartmentByBranchBuildingFloor(this.branchId, buildingId, floorId).subscribe((res: any) => {
        if (res.data.length) {
          this.Departments = [];
          this.Departments = res.data.map((x) => {
            return { id: x.id, name: x.name };
          });
          this.LocationForm.patchValue({
            department: this.Departments[0].id
          });
          this.getWorkOrderList();
        }
        
        
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error)
      });
    }
  }

  getWorkOrderList() {
    this.onDemandWOList = [];
    this.allOnDemandWOList = [];
    this.scheduledWOList = [];
    this.allScheduledWOList = [];
    this.loaderService.show();
    let currentDate = new Date().getTime();
    let body = {
      branchId: this.branchId,
      startDate: moment(currentDate).startOf('day').valueOf(),
      endDate: moment(currentDate).endOf('day').valueOf(),
      buildingId: this.LocationForm.controls.building.value,
      floorId: this.LocationForm.controls.floor.value === 'all' ? '' : this.LocationForm.controls.floor.value,
      departmentId: this.LocationForm.controls.department.value === 'all' ? '' : this.LocationForm.controls.department.value,
      customerId:this.customerId
    }

    this.TabletService.getWorkOrderWithoutTokenList(body).subscribe((res: any) => {
      this.loaderService.hide();
      if (res.data.payload.length) {
        this.noResult = false;
        res.data.payload.forEach((item) => {
          let hrs = 0;
          let minutes = 0;
          if (item.sla > 60) {
            hrs = Math.floor(item.sla / 60);
            minutes = item.sla - hrs * 60;
          } else {
            minutes = item.sla;
          }
          item['expectedClosureTime'] = (hrs && minutes) ? `${hrs} hours ${minutes} min` : (hrs ? `${hrs} hours` : minutes ? `${minutes} min` : null);
          item['orderDate'] = item.orderDate ? moment(item.orderDate).format('DD-MM-YYYY') : null;
          item['visibleStatusButtonTime'] = item.startTime ? moment(item.startTime).subtract(1, 'h').valueOf() : null;
          item['escalated'] = moment(item.startTime).add(item.sla, 'minute').fromNow().includes('ago') ? true : false;
          item['startTime'] = item.startTime ? moment(item.startTime).format('hh:mm A') : null;

          if (item['escalated']) {
            item['startClass'] = "escalated";
          }
          if (item.status == 'WORKORDER_CLOSED') {
            item['startClass'] = "validate";
          }


          const location = [];
          if (item.floorName)
            location.push(item.floorName);
          if (item.departmentName)
            location.push(item.departmentName);
          if (item.roomName)
            location.push(item.roomName);
          item.location = location.join(', ');

          if (item.buildingName) {
            location.unshift(item.buildingName);
          }

          item.fullLocation = location.join(', ');
          item['allowExecutionOfWO'] = true;

          item['guidelinesList'] = item.guidelines && item.guidelines.length > 0 ? item.guidelines.split('~') : [];

          let feedbackType = smartWashroom.findIndex(el => el.title === item['guidelinesList'][0]) > -1 ? smartWashroom[smartWashroom.findIndex(el => el.title === item['guidelinesList'][0])].type : '';
          item['cardStatusColor'] = item['field1'] === 'onDemandWoFeedback' ? ((feedbackType === 'suggestions') ? '#fbc41d' : '#f44336') : '#fbc41d';


          if (item.field1) {
            let inspectionListData = JSON.parse(JSON.stringify(this.inspectionList));
            item['guidelinesList'].forEach(element => {
              let inspectionIndex = this.inspectionList.findIndex(el => el.title === element);
              if (inspectionIndex > -1) {
                inspectionListData[inspectionIndex]['requiredField'] = true;
              }
            });
            let listData = [];
            inspectionListData.forEach((element, index) => {
              if (element.room) {

                if (element.room === item.roomType) {
                  listData.push(element);
                }

              }
              else {
                listData.push(element);
              }
            });
            item['inspectionList'] = listData;
            this.onDemandWOList.push(item);
          }
          else {
            let listData = [];
            this.inspectionList.forEach(element => {
              if (element.room) {
                if (element.room === item.roomType) {
                  listData.push(element);
                }
              }
              else {
                listData.push(element);
              }
            });
            item['inspectionList'] = listData;
            this.scheduledWOList.push(item);
          }

          this.allOnDemandWOList = [...this.onDemandWOList];
          this.allScheduledWOList = [...this.scheduledWOList];
        });
        this.filterChangeStatus();
      } else {
        this.noResult = true;
      }

    }, (error) => {
      this.loaderService.hide();
    });
  }

  getHeightButton(type, i) {
    let height = document.getElementById(type + i) ? document.getElementById(type + i).getBoundingClientRect().width + 'px' : '100%';
    return height;
  }


  getInspectionList() {
    this.loaderService.show();
    this.inspectionList = [];
    this.inspectionService.getInspectionData(this.customerId).subscribe((res: any) => {
      this.loaderService.hide();
      if (res.data.feedbackList.length > 0) {
        this.inspectionList = res.data.feedbackList;
        this.inspectionList.forEach(element => {
          let index = smartWashroom.findIndex(el => el.title === element.title);
          if (index > -1 && smartWashroom[index].room) {
            element['room'] = smartWashroom[index].room;
          }
          element.tasks.forEach(el => {
            if (el.field === 'quantity') {
              el['quantity'] = "0";
            }
            else {
              el['checkbox'] = false;
            }
          });
        });
      }
    }, (error) => {
      this.loaderService.hide();
    });
  }

  modalData=[];
  modalOtherDetails={};
  openModal(modal,index,type, data)
  {
    this.modalData=[];
    this.modalOtherDetails={};
    let allow:boolean=(type==='onDemand')? this.onDemandWOList[index].allowExecutionOfWO :this.scheduledWOList[index].allowExecutionOfWO;
    let deviceType=(type==='onDemand')? this.onDemandWOList[index].deviceType :this.scheduledWOList[index].deviceType;
//     if(deviceType==='tablet'){
//     if(allow){
//       this.deviceScan=false;
//       this.afterScan=false;
//       this.modalData=data.inspectionList;
//       this.modalOtherDetails={
//         "id":data.id,
//         "currentIndex":index,
//         "type":type,
//       "workorderId" : data.workOrderId,
//       "location" : data.fullLocation,
//       "locationCode":data.locationCode,
//       "branchId":data.branchId,
//       "buildingId":data.buildingId,
//       "departmentId":data.departmentId,
//       "floorId":data.floorId,
//       "roomId":data.roomId,
//     };
//     if(this.modalData.length>0){
//     this.modalOpen=true;
//   this.modalService.open(modal);
//     }
//     else{
//       this.updateWorkOrder();
//     }
//   }
//   else{
//     this.successSnackBar("Execute the current location's ticket only.");
//   }
// }
// else{
  // const constraints = {
  //   video: true,
  //   audio: false,
 
  //   facingMode : "environment"
  // };
  // navigator.mediaDevices.getUserMedia(constraints);
  this.deviceScan=true;
  this.afterScan=false;
  this.modalData=data.inspectionList;
  this.modalOtherDetails={
    "id":data.id,
    "currentIndex":index,
    "type":type,
  "workorderId" : data.workOrderId,
  "location" : data.fullLocation,
  "locationCode":data.locationCode,
  "branchId":data.branchId,
  "buildingId":data.buildingId,
  "departmentId":data.departmentId,
  "floorId":data.floorId,
  "roomId":data.roomId,
};
this.modalOpen=true;
this.modalService.open(modal);
// }
  }

  getCustomerLogo()
  {
    this.commonService.getLogoImg(this.customerId).subscribe(res => {
      this.loaderService.hide();
      this.customerLogo = res['data'] && res['data']['generalSettingResponse'] && res['data']['generalSettingResponse']['logo'] ? 'data:image/png;base64,' + res['data']['generalSettingResponse']['logo'] : '';
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    })
  }


  updateWorkOrder() {
    this.modalOpen = false;
    this.loaderService.show();

    this.loaderService.show();
    this.TabletService.updateWorkOrder(this.modalOtherDetails['id'], 'WORKORDER_CLOSED').subscribe(res => {
      this.loaderService.hide();

      this.loaderService.hide();
      if (res['description'] === 'Status Closed updated Successfully') {

        if (this.modalOtherDetails['type'] === 'onDemand') {
          let indexAllData = this.allOnDemandWOList.findIndex(el => el.id === this.modalOtherDetails['id']);
          this.onDemandWOList[this.modalOtherDetails['currentIndex']].status = 'WORKORDER_CLOSED';
          this.onDemandWOList[this.modalOtherDetails['currentIndex']]['startClass'] = "validate";
          this.allOnDemandWOList[this.modalOtherDetails['currentIndex']].status = 'WORKORDER_CLOSED';
          this.allOnDemandWOList[indexAllData]['startClass'] = "validate";

          setTimeout(() => {
            this.filterChangeStatus();
          }, 500);
        }
        else {
          let indexAllData = this.allScheduledWOList.findIndex(el => el.id === this.modalOtherDetails['id']);
          this.scheduledWOList[this.modalOtherDetails['currentIndex']].status = 'WORKORDER_CLOSED';
          this.scheduledWOList[this.modalOtherDetails['currentIndex']]['startClass'] = "validate";
          this.allScheduledWOList[this.modalOtherDetails['currentIndex']].status = 'WORKORDER_CLOSED';
          this.allScheduledWOList[indexAllData]['startClass'] = "validate";

          setTimeout(() => {
            this.filterChangeStatus();
          }, 500);
        }
      }

      this.successSnackBar(res['description']);

    }, err => {
      this.loaderService.hide();
      this.errorSnackBar(err);
    });

  }

  filterChangeStatus(filter?) {
    if (filter === 'Not-Started') {
      this.notStartedFilter = !this.notStartedFilter;
    }
    if (filter === 'In-Progress') {
      this.inProgressFilter = !this.inProgressFilter;
    }
    if (filter === 'Completed') {
      this.completedFilter = !this.completedFilter;
    }

    let filteredOnDemand = [...this.allOnDemandWOList];
    this.onDemandWOList = []
    filteredOnDemand.forEach(el => {
      if (this.notStartedFilter && (el.status === 'WORKORDER_OPEN' || el.status === 'WORKORDER_CREATED')) {
        this.onDemandWOList.push(el);
      }
      if (this.inProgressFilter && el.status === 'WORKORDER_INPROGRESS') {
        this.onDemandWOList.push(el);
      }
      if (this.completedFilter && el.status === 'WORKORDER_CLOSED') {
        this.onDemandWOList.push(el);
      }
      if (!this.notStartedFilter && !this.inProgressFilter && !this.completedFilter) {
        this.onDemandWOList.push(el);
      }
    });

    let filteredScheduled = [...this.allScheduledWOList];
    this.scheduledWOList = []
    filteredScheduled.forEach(el => {
      if (this.notStartedFilter && (el.status === 'WORKORDER_OPEN' || el.status === 'WORKORDER_CREATED')) {
        this.scheduledWOList.push(el);
      }
      if (this.inProgressFilter && el.status === 'WORKORDER_INPROGRESS') {
        this.scheduledWOList.push(el);
      }
      if (this.completedFilter && el.status === 'WORKORDER_CLOSED') {
        this.scheduledWOList.push(el);
      }
      if (!this.notStartedFilter && !this.inProgressFilter && !this.completedFilter) {
        this.scheduledWOList.push(el);
      }
    });
  }

  showStatusButtons(visibleStatusButtonTime) {
    return this.currentTime >= visibleStatusButtonTime;
  }

  tabChanged(event) {
    this.statusFilter = event === 0 ? true : false;
  }

  checkModalButtonEnable() {
    let total = 0;
    let actual = 0;
    let data = this.modalData.filter(el => el.requiredField === true);
    data.forEach(el => {
      el.tasks.forEach(e => {
        total++;
        if ((e.field === 'quantity' && e.quantity > 0) || (e.field === 'checkbox' && e.checkbox === true)) {
          actual++;
        }
      });
    });
    console.log(total,actual)
    return (total===actual);
  }

  addConsumables() {
    this.modalService.dismissAll();
    this.loaderService.show();
    let consumables = [];
    this.modalData.forEach(el => {
      el.tasks.forEach(e => {
        consumables.push({
          "customerId": this.customerId,
          "feedbackTitle": el.title,
          "description": e.taskDescription,
          "checkBox": e.field === 'checkbox' && e.checkbox ? e.checkbox : false,
          "quantity": e.field === 'quantity' && e.quantity ? e.quantity : 0,
          "workorderId": this.modalOtherDetails['workorderId'],
          "location": this.modalOtherDetails['location'],
          "branchId": this.modalOtherDetails['branchId'],
          "buildingId": this.modalOtherDetails['buildingId'],
          "floorId": this.modalOtherDetails['floorId'],
          "departmentId": this.modalOtherDetails['departmentId'],
          "roomId": this.modalOtherDetails['roomId']
        })
      });
    });
    this.TabletService.addConsumables(consumables).subscribe(res => {
      this.loaderService.hide();
      if (res['data'] === 'Added Successfully') {
        this.updateWorkOrder();
      }
    }, err => {
      this.loaderService.hide();
      this.errorSnackBar(err);
    });
  }

  afterScanQR(result)
  {
    this.deviceScan=false;
    this.afterScan=true;
    let scanResult=JSON.parse(JSON.parse(result));
    if(scanResult.tagNumber===this.modalOtherDetails['locationCode'])
    {
      this.afterScanVerify=true;
      setTimeout(()=>{
        this.afterScan=false;
        if(this.modalData.length===0){
          this.modalService.dismissAll();
          this.updateWorkOrder();
        }
      },3000);
    }
    else {
      this.afterScanVerify = false;
    }
  }

  //PASSCODE MODAL

  passcodeConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: false,
    placeholder: ''
  };

  verifyPasscode()
  {
    if(this.passcode.length===4)
    {
    this.modalService.dismissAll();
    this.scrollToTop();
    this.loaderService.show();
    let body={
      customerId:parseInt(this.customerId),
      userInputPasscode:parseInt(this.passcode),
      userType:this.userType
    }
    this.SmartWashroomDashboardService.verifyPasscode(body).subscribe((res: any) => {
      this.loaderService.hide();
      if(res.description==="SUCCESS")
      {
        this.getBuilding();
        this.successSnackBar('Verified Passcode Successfully!!!');
        // this.goTo('/admin/smart-washroom/work-order/'+this.userType);
      }
      else{
        this.successSnackBar('Invalid Passcode!!!');
      }
    });
  }
  }

  onPasscodeChange(passcode) {
    this.passcode=passcode;
  }

  openPasscodeModal()
  {
    this.modalService.open(this.passcodeModal,{backdrop : 'static',keyboard : false});
  }

  getPasscodeDetails()
  {
    this.loaderService.show();
    this.SmartWashroomDashboardService.getPasscodeDetails(this.customerId).subscribe((res: any) => {
      this.loaderService.hide();
      this.passcodeDetails=res.data;
      this.getInspectionList();
      this.openPasscodeModal();
    });
  }

  scrollToTop(){
    let scrollDiv=document.getElementById('top').offsetHeight-80;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }

  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }
}

