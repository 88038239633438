import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NameValidator } from 'src/app/modules/shared/validators/name.validator';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component'
import { ConfigurationFormFieldService } from '../../../../services/configuration-form-field.service';
import { Location } from '@angular/common';
import { FormFieldDataModel } from '../../../../model/configuration-form-field';
import { environment } from 'src/app/config/environments/environment';

@Component({
  selector: 'app-edit-configuration-form-field',
  templateUrl: './edit-configuration-form-field.component.html',
  styleUrls: ['./edit-configuration-form-field.component.scss'],
})
export class EditConfigurationFormFieldComponent implements OnInit {
  message;
  isActive = true;
  firstFormGroup: FormGroup;
  updateFieldId: any;
  customerId;
  serviceName;
  permissionsObject = { read: false, create: false, update: false, delete: false };
  fieldType = [{ key: 'INPUT_BOX', value: 'INPUT BOX' }, { key: 'TEXT_AREA', value: 'TEXT AREA' }, { key: 'DATE', value: 'DATE' }];
  activeStatus = [];//[true, false];
  mandatoryList = [];//[true, false];
  fieldName;
  type;
  status;
  isHygieneShow: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private commonService: CommonServiceService,
    private configurationService: ConfigurationFormFieldService,
    private location: Location
  ) { }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('CONF_NOTIFICATION_TEMPLATE');
    this.commonService.callToggleSkill.subscribe(() => {
      this.change();
    });

    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }

    this.route.queryParams.subscribe(res => {
      this.serviceName = res['query'];
    })

    if (this.router.url.includes('hygiene')) {
      this.isHygieneShow = true;
    } else {
      this.isHygieneShow = false;
    }

    if (this.isHygieneShow) {
      this.activeStatus = [
        { value: true, name: "Active" },
        { value: false, name: "Inactive" }
      ]
    } else {
      this.activeStatus = [
        { value: true, name: "True" },
        { value: false, name: "False" }
      ]
    }

    if (this.isHygieneShow) {
      this.mandatoryList = [
        { value: true, name: "Yes" },
        { value: false, name: "No" }
      ]
    }
    this.intitializeForm();
    this.getParams();
  }

  patchValue() {
    this.loaderService.show();
    let urldata='';
    if(this.serviceName==='hygiene')
    {
      urldata=environment.hygieneApiUrl;
    }
    else if(this.serviceName==='visitor')
    {
      urldata=environment.visitorUrl;
    }
    else if(this.serviceName==='patient-service'){
      urldata=environment.patientApiUrl;
    }
    else if(this.serviceName==='inventory'){
      urldata=environment.inventoryApiUrl;
    }

    if(this.serviceName==='visitor')
    {
      this.configurationService.getVisitorFieldById(this.updateFieldId, urldata).subscribe((res: FormFieldDataModel) => {
        this.firstFormGroup.patchValue({
          fieldName: res.data.fieldName,
          type: res.data.fieldType,
          status: res.data.hidden
        })
        if (this.isHygieneShow) {
          this.firstFormGroup.controls.characterLimit.patchValue(res.data.fieldValidation);
          this.firstFormGroup.controls.isMandatory.patchValue(res.data.isMandatory);
        }
        this.loaderService.hide();
      },
        (error) => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        }
      );
    }
    else{
      this.configurationService.getFieldById(this.updateFieldId, urldata).subscribe((res: FormFieldDataModel) => {
        this.firstFormGroup.patchValue({
          fieldName: res.data.fieldName,
          type: res.data.fieldType,
          status: res.data.hidden
        })
        if (this.isHygieneShow) {
          this.firstFormGroup.controls.characterLimit.patchValue(res.data.fieldValidation);
          this.firstFormGroup.controls.isMandatory.patchValue(res.data.isMandatory);
        }
        this.loaderService.hide();
      },
        (error) => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        }
      );
    }
    
  }

  intitializeForm() {
    this.firstFormGroup = this.fb.group({
      fieldName: ['', [Validators.required, Validators.maxLength(150), Validators.minLength(3), NameValidator],],
      status: [''],
      type: ['', [Validators.required]],
      characterLimit: ['',[Validators.min(3)]],
      isMandatory: ['']
    });
  }

  getParams() {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.updateFieldId = params.id;
        this.patchValue();
      }
    });
  }

  change() {
    this.isActive = !this.isActive;
  }

  saveDetails() {
    const body = {
      fieldName: this.firstFormGroup.controls.fieldName.value,
      fieldType: this.firstFormGroup.controls.type.value,
      hidden: this.firstFormGroup.controls.status.value,
      fieldValidation: this.firstFormGroup.controls.characterLimit.value,
      isMandatory: this.firstFormGroup.controls.isMandatory.value
    }
    this.loaderService.show();
    let urldata='';
    if(this.serviceName==='hygiene')
    {
      urldata=environment.hygieneApiUrl;
    }
    else if(this.serviceName==='visitor')
    {
      urldata=environment.visitorUrl;
    }
    else if(this.serviceName==='patient-service'){
      urldata=environment.patientApiUrl;
    }
    else if(this.serviceName==='inventory'){
      urldata=environment.inventoryApiUrl;
    }

    if(this.serviceName==='visitor')
    {
      this.configurationService.UpdateVisitorFieldDetails(this.updateFieldId, body, urldata).subscribe(res => {
        this.loaderService.hide();
        this.successSnackBar(res['description']);
        this.location.back();
      }, err => {
        this.loaderService.hide();
        this.errorSnackBar(err);
      })
    }
    else{
      this.configurationService.UpdateFieldDetails(this.updateFieldId, body, urldata).subscribe(res => {
        this.loaderService.hide();
        this.successSnackBar(res['description']);
        this.location.back();
      }, err => {
        this.loaderService.hide();
        this.errorSnackBar(err);
      })
    }

    
  }

  cancel() {
    this.location.back();
  }

  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

}
