import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ScannerQRCodeConfig, ScannerQRCodeResult, NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-qr-code-scanner',
  templateUrl: './qr-code-scanner.component.html',
  styleUrls: ['./qr-code-scanner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QRCodeScannerComponent implements OnInit, OnDestroy {

  @ViewChild('action',{ static: false }) action!: NgxScannerQrcodeComponent;


  constructor(
    private route: Router, private loaderService:LoaderService
  ) { }

  @Output() afterScan = new EventEmitter();
  @Input() goToPath: any="NIL";
  @Input() allowScanAgain: boolean=false;
  @Input() allowSelectDevice: boolean=false;

  errorMessage:string='';
  deviceId:any='';

  ngOnInit() {}


  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth
      },
    },

  };


  public handle(action: any, fn: string): void {
    // Fix issue #27, #29
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.decode= 'utf-8';
      this.deviceId=device ? device.deviceId : devices[0].deviceId;
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => console.log(fn), alert);
    } else {
      action[fn]().subscribe((r: any) => console.log(fn), alert);
    }
  }
  

  ngAfterViewInit(): void {

      this.action.isReady.subscribe((res: any) => {
    
        this.handle(this.action, 'start');
    
   });

   
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    e && e[0] && e[0].value && action && action.pause();
    if(e && e[0] && typeof JSON.parse(JSON.parse(JSON.stringify(e[0].value)))=='object')
    {
    this.afterScan.emit(JSON.stringify(e[0].value));
    }
    else{
    this.errorMessage="Please Scan Again.";
    }
  }

  // start(){  
  //   this.qrScannerComponent.getMediaDevices().then(devices => {
  //     const videoDevices: MediaDeviceInfo[] = [];
  //     for (const device of devices) {
  //       if (device.kind.toString() === 'videoinput') {
  //         videoDevices.push(device);
  //       }
  //     }
  //     console.log(videoDevices);
  //     if (videoDevices.length > 0) {
  //       let choosenDev;
  //       for (const dev of videoDevices) {
  //         if (dev.label.includes('back')) {
  //           choosenDev = dev;
  //           break;
  //         }
  //       }
  //       if (choosenDev) {
  //         this.qrScannerComponent.chooseCamera.next(choosenDev);
  //       } else {
  //         this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
  //       }
  //     }
  //   });

  //   this.qrScannerComponent.capturedQr.subscribe(result => {
  //     console.log(result)
  //     this.errorMessage='';
  //     let v = document.getElementsByTagName('video')[0];
  //     v.style.display = "none";
  //     if(typeof JSON.parse(JSON.parse(JSON.stringify(result)))=='object')
  //     {
  //     this.afterScan.emit(JSON.stringify(result));
  //     }
  //     else{
  //     this.errorMessage="Please Scan Again.";
  //     }
  //   },(error)=>
  //   {
  //     console.log(error)
  //     this.errorMessage=error;
  //   });
  // }

  cancel()
  {
    this.route.navigate([this.goToPath]);
  }

  ngOnDestroy(){
    this.handle(this.action,'stop');
  }

}




