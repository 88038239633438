import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, MatTabChangeEvent } from '@angular/material';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';
import { PatientService } from '../../service/patient.service';
import { PatientDescModel } from '../../model/patient-list.model';
import { SuccessDialogComponent } from 'src/app/modules/shared/component/success-dialog/success-dialog';
import { DialogService } from 'src/app/core/dialog.service';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';

@Component({
  selector: 'app-patient-tab',
  templateUrl: './patient-tab.component.html',
  styleUrls: ['./patient-tab.component.scss']
})

export class PatientTabComponent implements OnInit, OnDestroy {
  searchText = '';
  isActive = true;
  public selectedIndex: number = 0;
  showMobileTable: boolean;
  screenWidth: number;
  actionsToggle = false;
  selectedPatientList: any = [];
  @ViewChild('actionButtonToggle', { static: false }) actionButtonToggle: ElementRef;
    constructor(
    public toastService: ToastService,
    public snackBar: MatSnackBar,
    public commonService: CommonServiceService,
    private loaderService: LoaderService,
    private dialog: DialogService,
    private patientService: PatientService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    ) {
    this.onResize();
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.actionsToggle) {
        if (!this.actionButtonToggle.nativeElement.contains(e.target)) {
          this.actionsToggle = false;
        }
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }

  ngOnInit() {
    this.commonService.callToggle.subscribe((data) => {
      this.change();
    });
    // ----------menu toggle status------------------------------------------------------------//
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    this.commonService.searchText = '';
    // this.selectedIndex = this.patientService.selectedTab;
  }

  toggleAction() {
    this.actionsToggle = !this.actionsToggle;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.actionsToggle = false;
    this.selectedIndex = tabChangeEvent.index;
    // this.patientService.selectedTab = tabChangeEvent.index;
  }

  patientListSelectionChange(ids) {
    this.selectedPatientList = [...ids];
  }

  isApprove() {
    if (this.selectedPatientList.length) {
      const dialogRef = this.dialog.openDialog(ConfirmationDialogComponent, 'Are you sure you want to approve this request?');
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          this.loaderService.show();
          this.patientService.bulkOnboardApproval(1, this.selectedPatientList)
            .subscribe((res: PatientDescModel) => {
              this.loaderService.hide();
              this.openSuccessDialog(res['description'], 'Success');
              // this.openSnackBar(res.description);
              // this.listing();
            }, error => {
              this.loaderService.hide();
              this.errorSnackbar(error);
            })
        } else if (result === 2) {
          this.loaderService.show();
          this.patientService.bulkOnboardApproval(2, this.selectedPatientList)
            .subscribe((res: PatientDescModel) => {
              this.loaderService.hide();
              this.openSuccessDialog(res['description'], 'Success');
            }, error => {
              this.loaderService.hide();
              this.errorSnackbar(error)
            })
        }
      });
    } else {
      this.matDialog.open(ConfirmationDialogComponent, {
        data:
        {
          heading: ' ',
          confirmationMesg: 'Please select data first',
          yesBtnName: 'Ok'
        }
      }).afterClosed()
        .subscribe((response) => {
          if (response) {
          } else { }
        });
    }
  }

  openSuccessDialog(message, heading) {
    this.matDialog.open(SuccessDialogComponent,
      {
        data:
        {
          yesBtnName: 'OK',
          confirmationMesg: message,
          heading: heading
        }
      }).afterClosed().subscribe(res => {
        this.patientService.unapproveOnboardPatientListing.next(true);
      })
  }

  searchItem() {
    this.commonService.sendFilterCloseEvent();
    this.commonService.searchText = this.searchText.trim();
    this.commonService.sendSearchEvent();
  }

  searchInputChanged() {
    this.commonService.searchText = this.searchText;
  }

  change() {
    this.isActive = !this.isActive;
  }

  tab() {

  }

  ngOnDestroy() {
  }

  errorSnackbar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error }, type: 'error' },
      ErrorToasterComponent
    );
  }

  openSnackBar(message: string) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message }, type: 'success' },
      ToasterComponent
    );
  }

}
