import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { environment } from 'src/app/config/environments/environment';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingRoomService {
  checkedTagNumber: any = [];
  checkTagList: any = [];
  selectedMeetingChange = new Subject<any>();
  selectedMeetingChangeEmitter = this.selectedMeetingChange.asObservable();
  isPrinting: boolean = true;
  constructor(private http: HttpClient, private common: CommonServiceService, private router: Router) { }
  getMeetingRoomDetails(tagNumber) {
    return this.http.get(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/${tagNumber}`);
  }

  organizationList() {
    return this.http.get(`${environment.customerApiUrl}api/v1/organization/pickList`);
  }

  branchList(industryId, customerId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/branch/picklist?industryId=${industryId}&customerId=${customerId}`);
  }

  meetingRoomSave(body) {
    return this.http.post(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom`, body);
  }

  updateMeetingRoom(body, tagNumber) {
    return this.http.put(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/${tagNumber}`, body);
  }

  meetingRoom(customerId: any, pageInfo: PaginationModel, status, filterData) {
    let params = new HttpParams();
    if (filterData.branch) {
      const branchIds = filterData.branch.join(',');
      params = params.append('branch', branchIds)
    }
    if (filterData.building) {
      const buildingIds = filterData.building.join(',');
      params = params.append('building', buildingIds)
    }
    if (filterData.floor) {
      const floorIds = filterData.floor.join(',');
      params = params.append('floor', floorIds);
    }
    if (filterData.department) {
      const departmentIds = filterData.department.join(',');
      params = params.append('department', departmentIds);
    }
    if (filterData.room) {
      const roomIds = filterData.room.join(',');
      params = params.append('room', roomIds);
    }
    if (filterData.meetingRoomType) {
      const meetingRoomType = filterData.meetingRoomType.join(',');
      params = params.append('roomType', meetingRoomType);
    }
    if (filterData.dateTime) {
      params = params.append('dateTime', filterData.dateTime);
    }
    if (status) {
      params = params.append('status', status);
    }
    return this.http.get(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/list?page=${pageInfo.page}&searchText=${encodeURIComponent(this.common.
      searchText)}&size=${pageInfo.size}&by=${pageInfo.sortBy}&order=${pageInfo.sortDir}`,{params:params});

  }

  downloadTemplate() {
    return this.http.get(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/template/download`, { responseType: 'blob' as 'json' });
  }

  bulkUploadFile(formData) {
    return this.http.post(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/mr-validate`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, observe: 'response', responseType: 'blob' as 'json' });
  }

  uploadBulk(bulkUploadId, formData?) {
    return this.http.post(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/upload/${bulkUploadId}`, {});
  }

  departmentList(branchId, customerId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/department/picklist?branchId=${branchId}&customerId=${customerId}`);
  }

  allocateMeetingForIndividual(data) {
    return this.http.post(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/allocate`, data);
  }

  allocateMeetingForDepartment(data) {
    return this.http.post(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/reserve`, data);
  }


  getStaff(customerId, searchText = '') {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/pickList?customerId=${customerId}&searchText=${searchText}`);
  }

  releaseMeeting(data) {
    return this.http.put(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/release`, data);
  }

  printDocument(documentName: string, documentData: string[], route) {
    this.isPrinting = true;
    this.router.navigate([route,
      {
        outlets: {
          'print': ['print', documentName, documentData.join()]
        }
      }]);
  }


  onDataReady(route) {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([route]);
    });
  }

  delegateMeetingForIndividual(data) {
    return this.http.post(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/delegate/individual`, data);
  }

  delegateMeetingForDepartment(data) {
    return this.http.post(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/delegate/department`, data);
  }

  getMeetingRoomInfo(tagNumber) {
    return this.http.get(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/fixed-booking/${tagNumber}`);
  }

  extendMeetingRoomTime(tagNumber, data) {
    return this.http.put(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/fixed-booking/${tagNumber}`, data);
  }

  getFilterList() {
    return this.http.get(`${environment.inventoryApiUrl}api/v1/filters?page=0&size=1000000&type=MEETING_ROOM`);
  }

  getServiceList() {
    return this.http.get(`${environment.inventoryApiUrl}api/v1/service-types?page=0&size=1000000`);
  }

  addFilter(body) {
    return this.http.post(environment.inventoryApiUrl + 'api/v1/filters', body);
  }
  changeMeetingRoomType(body) {
    return this.http.put(`${environment.inventoryApiUrl}api/v1/inventory/meetingroom/meetingtype`, body);
  }
}

