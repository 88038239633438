
export interface FilterSelectedModel {
  id: number;
  name: string;
  code: string;
  status: boolean;
  selected: boolean;
}
export class selectedModel {
  constructor() {
    this.type = 0;
  }
  type: number;
}
export class CustomerStatusFilter {
  constructor() {
    // this.customerStatus = '';
    this.id = 0;
    this.status = '';
    this.selected = false;
  }
  // customerStatus: string;
  id: number;
  status: string;
  selected: boolean;
}
export class DepartmentPayload {
  id: number;
  name: string;
  branchId: number;
  customerId: number;
  branch: string;
  customer: string;
  floor: number;
  noOfPeoplePerDepartment: number;
  buildingId: number;
  buildingName: string;
  floorNumber: string;
}
export class DepartmentModel {
  constructor() {
    this.payload = new Array<DepartmentPayload>();
  }
  payload: DepartmentPayload[];
  totalPage: number;
  totalElements: number;
}

export class DepartmentFilterModel {
  data: DepartmentModel;
  description: string;
}
