import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSetupRoutingModule } from './account-setup.routing';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { OrganizationService } from './organization/service/organization.service';
import { OrganizationComponent } from './organization/organization.component';
// import { OrganizationListViewComponent } from './organization/components/listview/listview.component';
import { AddOrganizationComponent } from './organization/components/add-organization/add-organization.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSortModule } from '@angular/material';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { httpHeaderInterceptor } from 'src/app/core/interceptors/httpHeader.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RefreshTokenInterceptor } from 'src/app/core/interceptors/auth-response.interceptor';

@NgModule({
  declarations: [
    AddOrganizationComponent,
    // OrganizationListViewComponent,
    OrganizationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    MDBBootstrapModule,
    MatSortModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgbModule,
    NgxMatSelectSearchModule,
    AccountSetupRoutingModule
  ],
  // entryComponents:[
  //   AddRoomComponent
  // ],
  providers: [
    OrganizationService,
    { provide: HTTP_INTERCEPTORS, useClass: httpHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
  ]



})
export class AccountSetupModule { }
