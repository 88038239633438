import { Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

@Injectable()
export class DeviceInfoService {
  constructor(
    private deviceDetectorService: DeviceDetectorService
  ) { }

  getDeviceInfo(): DeviceInfo {
    return this.deviceDetectorService.getDeviceInfo();
  }
}
