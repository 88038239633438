import { ValidatorFn, FormControl } from "@angular/forms";

export const NameValidator: ValidatorFn = (control: FormControl) => {
  const ctrlValue = control.value || '';
  if (ctrlValue && (ctrlValue.trim().length === 0)) {
    return {
      trimError: { value: 'Only whitespaces are not allowed' }
    };
  }
  if (ctrlValue && ctrlValue.length < 1) {
    return {
      trimError: { value: 'Use 1 characters or more' }
    }
  }
  if (ctrlValue.startsWith(' ')) {
    return {
      trimError: { value: 'Leading whitespace are not allowed' }
    };
  }
  if (ctrlValue.endsWith(' ')) {
    return {
      trimError: { value: 'Trailing whitespace are not allowed' }
    };
  }
  return null;
};
