import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSpinner, MatProgressSpinner, ProgressSpinnerMode, ThemePalette, MatProgressBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { AppOverlayConfig, OverlayService } from '../../services/overlay.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.html',
  styleUrls: ['./loader.scss']
})
export class LoaderComponent implements OnInit {
  private subscription: Subscription;
  show = false;

  @Input() color: ThemePalette = 'primary';
  @Input() mode: any = 'indeterminate';
  // @Input() value = 10;
  // @Input() bufferValue = 40;

  @Input() backdropEnabled = true;
  @Input() positionGloballyCenter = true;
  @Input() displayProgressLinear:boolean = false;

  @ViewChild('progressLinearRef', { static: true })
  private progressLinearRef: TemplateRef<any>;
  private progressLinearOverlayConfig: AppOverlayConfig;
  private overlayRef: OverlayRef;
  constructor(private vcRef: ViewContainerRef, private overlayService: OverlayService, private loaderService: LoaderService) { }
  ngOnInit() {
    this.progressLinearOverlayConfig = {
      hasBackdrop: this.backdropEnabled
    };
    if (this.positionGloballyCenter) {
      this.progressLinearOverlayConfig['positionStrategy'] = this.overlayService.positionGloballyCenter();
    }
    this.overlayRef = this.overlayService.createOverlay(this.progressLinearOverlayConfig);
    this.subscription = this.loaderService.loaderState
      .subscribe((state) => {

        this.show = state.show;
        if (this.show) {
          this.displayProgressLinear = true;
        }
        else {
          this.displayProgressLinear = false;
        }
      });
  }
  ngDoCheck() {
    // Based on status of displayProgressLinear attach/detach overlay to progress linear template
    if (this.displayProgressLinear && !this.overlayRef.hasAttached()) {
      this.overlayService.attachTemplatePortal(this.overlayRef, this.progressLinearRef, this.vcRef);
    } else if (!this.displayProgressLinear && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
