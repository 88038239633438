import { Component, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { DialogService } from 'src/app/core/dialog.service';

@Component({
  selector: 'app-multi-file-drop-box',
  templateUrl: './multi-file-drop-box.html',
  styleUrls: ['./multi-file-drop-box.scss']
})
export class MultiFileDropBoxComponent {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];
  @Output() filesUploaded = new EventEmitter();
  @Input() FilesFromParent: any;
  url: string;
  previewUrls: any = [];
  constructor(
    public dialog: DialogService,
  ) { }
  /**
   * on file drop handler
   */
  ngOnChanges() {
    this.files = this.FilesFromParent;
  }

  ngOnInit() {
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }

    this.files.splice(index, 1);
    this.filesUploaded.emit({ files: this.files, pos: index });
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 0);
      }
    }, 0);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    // this.filesUploaded.emit(files);
    for (const item of files) {
      item.progress = 0;
      // this.PreviewFile(item);
      this.files.push(item);
    }
    this.filesUploaded.emit({ files: this.files, pos: '' });
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  downloadFile(i) {
    let file = '';
    let contentType = '';
    contentType = this.files[i].type;
    file = this.files[i].file;
    const a = document.createElement('a');
    a.href = `data:${contentType};base64,${file}`;
    this.url = a.href;
    a.download = this.files[i].name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  PreviewFile(i) {
    let file = '';
    let contentType = '';
    if (this.files[i].size) {
      var reader = new FileReader();
      let img;
      if (this.files[i].type == "application/pdf") {
        window.open(URL.createObjectURL(this.files[i]));
        return;
      }
      reader.readAsDataURL(this.files[i]);
      let base64;
      reader.onload = () => {
        base64 = reader.result;
        img = base64.replace(/^data:(image|application)\/(png|jpg|jpeg|pdf);base64,/, '');
        file = img;
        contentType = this.files[i].type;
        this.url = `data:${contentType};base64,${file}`;
        this.dialog.openConfirmDialog('Image-Preview', this.url).afterClosed().subscribe(res => {
          if (res) {
          }
        });
      };
      reader.onerror = (error) => {
      };
    } else {
      if (this.files[i].type == "application/pdf") {
        return;
      }
      file = this.files[i].file;
      contentType = this.files[i].type;
      this.url = `data:${contentType};base64,${file}`;
      this.previewUrls.push(this.url);
      this.dialog.openConfirmDialog('Image-Preview', this.url).afterClosed().subscribe(res => {
        if (res) {
        }
      });
    }
  }

}
