import { Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DialogService } from 'src/app/core/dialog.service';
import { common } from 'src/app/config/enum';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { DeviceInfoService } from 'src/app/modules/shared/services/device-detector.service';
import { NameValidator } from 'src/app/modules/shared/validators/name.validator';
import * as moment from 'moment';
import { GenericPicklistModel } from 'src/app/modules/shared/model/picklist.model';
import { OrganizationService } from '../../service/organization.service';
import { DataModel, OrganizationModel, PackageDataModel } from '../../models/organization.model';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { SuccessDialogComponent } from 'src/app/modules/shared/component/success-dialog/success-dialog';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';
import { LoginService } from 'src/app/modules/shared/login/service/login.service';
import { environment } from 'src/app/config/environments/environment';
import { SSO } from 'src/app/config/constant';
@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.html',
  styleUrls: ['./add-organization.scss'],
})
export class AddOrganizationComponent implements OnInit {
  screenWidth: number;
  showMobileTable: boolean;
  attachment: any = {};
  filteredCountries: Observable<string[]>;
  public countryOptions: any[] = [];
  filteredRO_Countries: Observable<string[]>;
  public RO_CountryOptions: any[] = [];
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  isActive = true;
  state: any = [];
  city: any = [];
  countries: any = [];
  ro_country: any = [];
  ro_state: any = [];
  ro_city: any = [];
  province: any = [];
  timezone: any = [];
  theme: any = [];
  orgPicklist: any = [];
  dateandtime: any = [];
  logo: any;
  logoURL: any;
  selectedCountryId: any;
  organizationForm: FormGroup;
  UpdateOrg = false;
  organizationId: any;
  updateOrgBaddressId: any;
  updateOrgCaddressId: any;
  logoSelected = false;
  updateStatus;
  message;
  status: any;
  value: any;
  checked = false;
  databaseUrl: string;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  formData: any
  generalSettingId: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  url: any = '';
  val = null;
  modules: any = [];
  industry: any = [];
  customerId = JSON.parse(localStorage.getItem('customerId'))
  filesInfoList: any;
  fileUploadingStatus: any[];
  uploadDataBlobList: any[];
  notebookId: string | Blob;
  public timezoneOptions: any[] = [];
  public themeOptions: any[] = [];
  public filteredTimezones: Observable<string[]>;
  public filteredThemes: Observable<string[]>;
  public isNoOfPatient: boolean = false;
  packages: any[] = [];
  contAttachment = '';
  layout: string = '';
  profileImageUrl = '';
  profileImage: any = {};
  filteredStates: Observable<any>;
  filteredROStates: Observable<any>;
  filteredCities: Observable<any>;
  filteredROCities: Observable<any>;
  attachmentType: string = '';
  attachmentFilename: string = '';
  contractAttachment: any = '';
  storeProfileImg: any = '';
  visitorDashboardPermissionsObject = { read: false, create: false, update: false, delete: false };
  showVisitorDashboard: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public commonService: CommonServiceService,
    public loggingIn: LoginService,
    public dialog: DialogService,
    private organizationService: OrganizationService,
    private router: Router,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private pickListService: PicklistService,
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer,
    public activatedRouter: ActivatedRoute
  ) { }

  ngOnInit() {

    this.visitorDashboardPermissionsObject = this.commonService.setPermissions('VISITOR_DASHBOARD'); // ENTERPRISE_DASHBOARD
    if (this.visitorDashboardPermissionsObject && this.visitorDashboardPermissionsObject.read) {
      this.showVisitorDashboard = true;
    }

    this.activatedRouter.queryParamMap.subscribe(params => {
      if (params && params.get('tenant')) {
        this.setTenant(params.get('tenant'));
      }
    });

    this.commonService.callToggleAddEmp.subscribe(() => {
      this.change();
    });
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    this.initializeForm();
    this.getCountry();
    this.getIndustry();
    this.getTimezone();
    this.getTheme();

    this.organizationForm.controls['industryOrProfile'].valueChanges.subscribe(industry => {
      this.onIndustryChange(industry)
    });
  }

  private timezoneFilter(value) {
    const filterValue = value.toLowerCase();
    return value != '' ?
      this.timezoneOptions.filter(option => option.name.toLowerCase().includes(filterValue)) : this.timezoneOptions;
  }

  onIndustryChange(industry) {
    if (industry === 2) {
      this.organizationForm.controls['noOfPatient'].reset();
      this.organizationForm.controls['noOfPatient'].clearValidators();
      this.organizationForm.controls['noOfPatient'].updateValueAndValidity()
      this.isNoOfPatient = false;
    } else {
      this.organizationForm.controls['noOfPatient'].setValidators([Validators.required, Validators.min(1)]);
      this.isNoOfPatient = true;
    }
  }

  getTimezone() {
    this.organizationService.getTimezone().subscribe(
      (response: Array<GenericPicklistModel>) => {
        const len = response.length;
        for (let i = 0; i < len; i++) {
          this.timezone.push(response[i]);
        }
        this.timezoneOptions = this.timezone;
        this.filteredTimezones = this.organizationForm.controls['timezoneFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.timezoneFilter(value))
          );
      },
      (error) => {
        this.errorSnackbar(error);
      }
    );
  }

  public getSantizeUrl(url: string) {
    this.storeProfileImg = url
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private themeFilter(value) {
    const filterValue = value.toLowerCase();
    return value != '' ?
      this.themeOptions.filter(option => option.name.toLowerCase().includes(filterValue)) : this.themeOptions;
  }

  getTheme() {
    this.organizationService.getTheme().subscribe(
      (response: Array<GenericPicklistModel>) => {
        const len = response.length;
        for (let i = 0; i < len; i++) {
          this.theme.push(response[i]);
        }
        this.themeOptions = this.theme;
        this.filteredThemes = this.organizationForm.controls['themeFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.themeFilter(value))
          );
      },
      (error) => {
        this.errorSnackbar(error);
      }
    );
  }
  change() {
    this.isActive = !this.isActive;
  }

  initializeForm() {
    this.organizationForm = this.fb.group({
      accountId: [''],
      accountName: [
        '',
        [
          Validators.required, NameValidator
        ],
      ],
      address1: ['', [Validators.required, NameValidator]],
      address2: [''],
      country: ['', [Validators.required]],
      countryFilterCtrl: [''],
      orgTimezone: ['', [Validators.required]],
      timezoneFilterCtrl: [''],
      businessStartTime: ['', [Validators.required]],
      businessEndTime: ['', [Validators.required]],
      // orgTheme: [''],
      themeFilterCtrl: [''],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zipCode: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]*$'),
        ],
      ],
      isRO_AddressSame: [''],
      RO_Address1: ['', [Validators.required, NameValidator]],
      RO_Address2: [''],
      RO_Country: ['', [Validators.required]],
      ro_countryFilterCtrl: [''],
      RO_State: ['', [Validators.required]],
      RO_City: ['', [Validators.required]],
      RO_ZipCode: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]*$'),
        ],
      ],
      orgLogo: [''],
      industryOrProfile: ['', [Validators.required]],
      noOfFacilityOrBranch: ['', [Validators.required]],
      noOfPatient: ['', [Validators.required, Validators.min(1)]],
      noOfEmployee: ['', [Validators.required, Validators.min(1)]],
      contractStartDate: [''],
      contractEndDate: [''],
      attachment: [''],
      adminName: [
        '',
        [Validators.required,
          NameValidator,
        ],
      ],
      adminPhone: ['', [Validators.required, Validators.pattern('^([5-9]{1})([0-9]{5,12})$')]],
      email: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.minLength(5),
        ],
      ],
      alternateEmail: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.minLength(5),
        ],
      ],
      orderId: [''],
      packageId: ['', Validators.required],
      stateFilterCtrl: [''],
      cityFilterCtrl: [''],
      ro_stateFilterCtrl: [''],
      ro_cityFilterCtrl: ['']
    });
  }


  fillForm() {
    let customerId = JSON.parse(localStorage.getItem('customerId'))

    this.organizationService.customerByID(customerId).subscribe((res: OrganizationModel) => {
      this.loaderService.show();
      this.formData = res.data;
      res.data && res.data.address && res.data.address.countryId ? this.getState(res.data.address.countryId) : '';
      res.data && res.data.address && res.data.address.stateId ? this.getCity(res.data.address.stateId) : '';
      res.data && res.data.address && res.data.registeredOfficeAddress.countryId ? this.getRO_State(res.data.registeredOfficeAddress.countryId) : '';
      res.data && res.data.address && res.data.registeredOfficeAddress.stateId ? this.getRO_City(res.data.registeredOfficeAddress.stateId) : '';

      //timezone conversion
      let index = this.timezone.findIndex(el => el.id === (res.data && res.data.generalSettingResponse && res.data.generalSettingResponse.timezoneId ? res.data.generalSettingResponse.timezoneId : ''));
      let businessStart: any = res.data.businessHourStartTime ? res.data.businessHourStartTime : '18:30';
      let businessEnd: any = res.data.businessHourEndTime ? res.data.businessHourEndTime : '18:29';

      if (index > -1) {
        let timezone = this.timezone[index].urcOffset.split(':');
        let hour = parseInt(timezone[0]);
        let type = "add";
        if (parseInt(timezone[0]) < 0) {
          hour = -parseInt(timezone[0]);
          type = "subtract";
        }
        let obj = {
          hours: hour,
          minutes: parseInt(timezone[1]),
          type: type
        }
        if (obj.type === 'add') {
          businessStart = moment(moment(new Date(`2000-12-12 ${businessStart}`)).format('YYYY-MM-DD HH:mm:ss')).add(obj.hours, "hours").add(obj.minutes, "minutes");
          businessStart = businessStart.format('hh:mm A');

          businessEnd = moment(moment(new Date(`2000-12-12 ${businessEnd}`)).format('YYYY-MM-DD HH:mm:ss')).add(obj.hours, "hours").add(obj.minutes, "minutes");
          businessEnd = businessEnd.format('hh:mm A');
        }
        else {
          businessStart = moment(moment(new Date(`2000-12-12 ${businessStart}`)).format('YYYY-MM-DD hh:mm:ss')).subtract(obj.hours, "hours").subtract(obj.minutes, "minutes");
          businessStart = businessStart.format('hh:mm A');

          businessEnd = moment(moment(new Date(`2000-12-12 ${businessEnd}`)).format('YYYY-MM-DD hh:mm:ss')).subtract(obj.hours, "hours").subtract(obj.minutes, "minutes");
          businessEnd = businessEnd.format('hh:mm A');
        }
      }

      this.organizationForm.patchValue({
        accountId: res.data && res.data.accountId ? res.data.accountId : '',
        accountName: res.data.accountName ? res.data.accountName : '',
        address1: res.data.address && res.data.address.address ? res.data.address.address : '',
        country: res.data.address && res.data.address.countryId ? this.countries.find((element) => element.countryCode == res.data.address.countryCode && element.id == res.data.address.countryId) : this.countries.find((element) => element.countryCode == "91"),
        state: res.data.address && res.data.address.stateId ? res.data.address.stateId : '',
        city: res.data.address && res.data.address.cityId ? res.data.address.cityId : '',
        zipCode: res.data.address && res.data.address.zipcode ? res.data.address.zipcode : '',
        RO_Address1: res.data.registeredOfficeAddress && res.data.registeredOfficeAddress.address ? res.data.registeredOfficeAddress.address : '',
        RO_Country: res.data.registeredOfficeAddress ? this.ro_country.find((element) => element.countryCode == res.data.registeredOfficeAddress.countryCode && element.id == res.data.registeredOfficeAddress.countryId) : this.ro_country.find((element) => element.countryCode == "91"),
        RO_State: res.data.registeredOfficeAddress ? res.data.registeredOfficeAddress.stateId : '',
        RO_City: res.data.registeredOfficeAddress ? res.data.registeredOfficeAddress.cityId : '',
        RO_ZipCode: res.data.registeredOfficeAddress ? res.data.registeredOfficeAddress.zipcode : '',
        industryOrProfile: res.data ? res.data.industryId : '',
        noOfFacilityOrBranch: res.data ? res.data.noOfBranches : '',
        noOfEmployee: res.data && res.data.noOfEmployee ? res.data.noOfEmployee : '',
        noOfPatient: res.data && res.data.noOfPatient ? res.data.noOfPatient : '',
        isRO_AddressSame: res.data ? res.data.registeredOfficeSame : '',
        // orgTheme: res.data && res.data.generalSettingResponse && res.data.generalSettingResponse.themeId ? res.data.generalSettingResponse.themeId : '',
        orgTimezone: res.data && res.data.generalSettingResponse && res.data.generalSettingResponse.timezoneId ? res.data.generalSettingResponse.timezoneId : '',
        contractStartDate: res.data.contractStartDate ? new Date(res.data.contractStartDate).toISOString() : '',
        contractEndDate: res.data.contractEndDate ? new Date(res.data.contractEndDate).toISOString() : '',
        adminName: res.data ? res.data.adminName : '',
        adminPhone: res.data ? res.data.adminPhoneNumber : '',
        email: res.data ? res.data.email : '',
        alternateEmail: res.data ? res.data.alternateEmail : '',
        orderId: res.data ? res.data.orderId : '',
        packageId: res.data.packageId ? res.data.packageId : '',
        businessStartTime: businessStart,
        businessEndTime: businessEnd,
      });
      console.log(this.organizationForm.value)
      if (res.data.attachment) {
        this.getImage(res.data.attachment, 'contract');
      }
      if (res.data.profileImage) {
        this.getImage(res.data.profileImage, 'profile');
      }
      this.getPackages(res.data.industryId);
      res.data && res.data.generalSettingResponse && res.data.generalSettingResponse.logo ? this.url = 'data:image/png;base64,' + res.data.generalSettingResponse.logo : this.url = '';

      if (!this.organizationForm.controls.orgTimezone.value) {
        this.organizationForm.get('orgTimezone').setValue(282);
      }
      if (res.data.registeredOfficeSame) {
        this.organizationForm.get('RO_Address1').disable()
        this.organizationForm.get('RO_Country').disable()
        this.organizationForm.get('RO_State').disable()
        this.organizationForm.get('RO_City').disable()
        this.organizationForm.get('RO_ZipCode').disable()
      }
      this.generalSettingId = res.data && res.data.generalSettingResponse && res.data.generalSettingResponse.id ? res.data.generalSettingResponse.id : '';
      this.loaderService.hide();
    })
    this.organizationForm.get('email').disable();
    this.organizationForm.get('accountId').disable();
    this.organizationForm.controls['address1'].valueChanges.subscribe(address => {
      if (this.organizationForm.controls['isRO_AddressSame'].value) {
        this.organizationForm.get('RO_Address1').setValue(this.organizationForm.get('address1').value)
      }
    })
    this.organizationForm.controls['country'].valueChanges.subscribe(country => {
      if (this.organizationForm.controls['isRO_AddressSame'].value) {
        this.organizationForm.get('RO_Country').setValue(this.organizationForm.get('country').value)
        this.onRO_CountryChange();
      }
    });
    this.organizationForm.controls['state'].valueChanges.subscribe(state => {
      if (this.organizationForm.controls['isRO_AddressSame'].value) {
        this.organizationForm.controls['RO_State'].setValue(this.organizationForm.get('state').value)
        this.onRO_StateChange();
      }
    });
    this.organizationForm.controls['city'].valueChanges.subscribe(city => {
      if (this.organizationForm.controls['isRO_AddressSame'].value) {
        this.organizationForm.get('RO_City').setValue(this.organizationForm.get('city').value)
      }
    });
    this.organizationForm.controls['zipCode'].valueChanges.subscribe(zipcode => {
      if (this.organizationForm.controls['isRO_AddressSame'].value) {
        this.organizationForm.get('RO_ZipCode').setValue(this.organizationForm.get('zipCode').value)
      }
    });

    this.organizationForm.controls['noOfFacilityOrBranch'].disable();
    this.organizationForm.controls['noOfEmployee'].disable();
    this.organizationForm.controls['contractStartDate'].disable();
    this.organizationForm.controls['contractEndDate'].disable();
    this.organizationForm.controls['industryOrProfile'].disable();
    this.organizationForm.controls['noOfPatient'].disable();
    this.organizationForm.controls['attachment'].disable();
    this.organizationForm.controls['packageId'].disable();
    this.organizationForm.controls['orderId'].disable();
  }

  Upper(data: string) {
    return data.toUpperCase();
  }

  fileChange(event: any) {
    if (event[0] != null) {
      let regex = new RegExp('[^.]+$');
      let extension = event[0].name.match(regex);
      if (event[0].size / 1000 < common.ImageSize) {
        if (extension[0] == 'png' || extension[0] == 'jpg' || extension[0] == 'jpeg') {
          this.profileImage = {};
          this.profileImage['name'] = event[0].name;
          let formData: FormData = new FormData();
          formData.append('file', event[0]);
          this.loaderService.show();
          this.pickListService.uploadFile(formData).subscribe(res => {
            this.profileImageUrl = res;
            this.loaderService.hide();
            var reader = new FileReader();
            reader.onload = (event) => {
              this.profileImage['path'] = event['target']['result'];
            }
            reader.readAsDataURL(event[0]);
          });
        }
        else {
          this.matDialog.open(SuccessDialogComponent, {
            data: {
              yesBtnName: 'OK',
              confirmationMesg: 'Error shown regarding the image extension you have uploaded. Kindly select png/jpg/jpeg file format',
              heading: 'Error'
            }
          }).afterClosed().subscribe(res => {
          });
        }
      } else {
        this.matDialog.open(SuccessDialogComponent, {
          data: {
            yesBtnName: 'OK',
            confirmationMesg: 'Error shown regarding the image size you have uploaded. Kindly make the image size as 5MB',
            heading: 'Error'
          }
        }).afterClosed().subscribe(res => {
        });
      }
    }
  }

  private getImage(key, type) {
    if (type === 'profile') {
      this.profileImageUrl = key;
      this.pickListService.getUploadedFile(key).subscribe((res: any) => {
        let filename = res.headers.get('filename');
        this.profileImage['name'] = filename;
        this.patchImage(res.body, type);
      }, error => {
        this.errorSnackbar(error);
      })
    } else {
      this.contAttachment = key
      this.organizationService.getImage(key).subscribe((res) => {
        this.contractAttachment = res.body;
        let filename = res.headers.get('filename');
        this.attachment['name'] = filename;
        this.attachmentType = filename.split(".")[filename.split(".")
          .length - 1];
        this.patchImage(res.body, type);
      }, error => {
        this.errorSnackbar(error);
      })
    }
  }

  patchImage(image: Blob, type) {
    let reader = new FileReader();
    if (type === 'profile') {
      this.profileImage['name'] = '';
    } else {
      // this.attachment['name'] = '';
    }
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      if (type === 'profile') {
        this.profileImage['path'] = event['target']['result'];
      } else {
        this.attachment['path'] = event['target']['result'];
      }
    }
  }

  downloadFile() {
    const a = document.createElement('a');
    const blob = new Blob([this.contractAttachment], { type: 'text/' + this.attachmentType });
    // const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = this.attachment['name'];
    document.body.appendChild(a);
    a.click();
    // }
  }

  getCountry() {
    this.organizationService.getCountry()
      .subscribe((response: Array<DataModel>) => {
        const len = response.length;
        for (let i = 0; i < len; i++) {
          this.countries.push(response[i]);
          this.ro_country.push(response[i]);
        }
        this.countryOptions = this.countries;
        this.RO_CountryOptions = this.ro_country;
        this.filteredCountries = this.organizationForm.controls['countryFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.commonService.dataFilter(value, this.countryOptions))
          );
        this.filteredRO_Countries = this.organizationForm.controls['ro_countryFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.commonService.dataFilter(value, this.RO_CountryOptions))
          );
        this.fillForm();
      },
        (error) => {
          this.errorSnackbar(error);
        }
      )

  }

  getPackages(industryId) {
    this.organizationService.getPackages(industryId)
      .subscribe((response: PackageDataModel) => {
        this.packages = [];
        const len = response.payload.length;
        for (let i = 0; i < len; i++) {
          this.packages.push({
            id: response.payload[i].id,
            name: response.payload[i].name,
            active: response.payload[i].active
          });
        }
      },
        (error) => {
          this.errorSnackbar(error);
        })
  }
  onCountryChange() {
    this.state = [];
    this.city = [];
    if (this.organizationForm.controls['country'].value.id) {
      this.organizationForm.get('state').reset();
      this.organizationForm.get('city').reset();
      this.getState(this.organizationForm.controls['country'].value.id);
    }
  }

  getState(countryId) {
    this.loaderService.show();
    this.organizationService.getState(countryId).subscribe(
      (response: Array<GenericPicklistModel>) => {
        this.loaderService.hide();
        const len = response.length;
        for (let i = 0; i < len; i++) {
          this.state.push(response[i]);
        }
        this.filteredStates = this.organizationForm.controls['stateFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.commonService.dataFilter(value, this.state))
          );
      }, (error) => {
        this.loaderService.hide();
        this.errorSnackbar(error);
      }
    );
  }

  getRO_State(countryId) {
    this.loaderService.show();
    this.organizationService.getState(countryId).subscribe(
      (response: Array<DataModel>) => {
        this.loaderService.hide();
        const len = response.length;
        for (let i = 0; i < len; i++) {
          this.ro_state.push(response[i]);
        }
        this.filteredROStates = this.organizationForm.controls['ro_stateFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.commonService.dataFilter(value, this.ro_state))
          );
      }, (error) => {
        this.loaderService.hide();
        this.errorSnackbar(error);
      }
    );
  }

  getCity(stateId) {
    this.loaderService.show();
    this.organizationService.getCity(stateId)
      .subscribe((response: Array<DataModel>) => {
        this.loaderService.hide();
        const len = response.length;
        for (let i = 0; i < len; i++) {
          this.city.push({
            id: response[i].id,
            name: response[i].name,
          });
        }
        this.filteredCities = this.organizationForm.controls['cityFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.commonService.dataFilter(value, this.city))
          );
      }, (error) => {
        this.loaderService.hide();
        this.errorSnackbar(error);
      });
  }

  getRO_City(stateId) {
    this.loaderService.show();
    this.organizationService.getCity(stateId)
      .subscribe((response: Array<DataModel>) => {
        this.loaderService.hide();
        const len = response.length;
        for (let i = 0; i < len; i++) {
          this.ro_city.push({
            id: response[i].id,
            name: response[i].name,
          });
        }
        this.filteredROCities = this.organizationForm.controls['ro_cityFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.commonService.dataFilter(value, this.ro_city))
          );
      }, (error) => {
        this.loaderService.hide();
        this.errorSnackbar(error);
      });
  }

  onStateChange() {
    this.city = [];
    if (this.organizationForm.controls['state'].value) {
      this.organizationForm.get('city').reset();
      this.getCity(this.organizationForm.controls['state'].value);
    }
  }

  onRO_CountryChange() {
    this.ro_state = [];
    this.ro_city = [];
    if (this.organizationForm.controls['RO_Country'].value.id) {
      this.organizationForm.get('RO_State').reset();
      this.organizationForm.get('RO_City').reset();
      this.getRO_State(this.organizationForm.controls['RO_Country'].value.id)
    }
  }

  onRO_StateChange() {
    this.ro_city = [];
    if (this.organizationForm.controls['RO_State'].value) {
      this.organizationForm.get('RO_City').reset();
      this.getRO_City(this.organizationForm.controls['RO_State'].value);
    }
  }

  isRO_AddressSameChange(e) {
    const address = e.target.checked;
    if (address) {
      this.organizationForm.get('RO_Address1').setValue(this.organizationForm.get('address1').value)
      this.organizationForm.get('RO_Address1').disable()
      // this.organizationForm.get('RO_Address2').setValue(this.organizationForm.get('address2').value)
      // this.organizationForm.get('RO_Address2').disable()
      this.organizationForm.get('RO_Country').setValue(this.organizationForm.get('country').value)
      this.getRO_State(this.organizationForm.controls['RO_Country'].value.id)
      this.organizationForm.get('RO_State').setValue(this.organizationForm.get('state').value)

      this.getRO_City(this.organizationForm.controls['RO_State'].value);
      this.organizationForm.get('RO_City').setValue(this.organizationForm.get('city').value)
      this.organizationForm.get('RO_Country').disable()
      this.organizationForm.get('RO_State').disable()

      this.organizationForm.get('RO_City').disable()

      this.organizationForm.get('RO_ZipCode').setValue(this.organizationForm.get('zipCode').value)
      this.organizationForm.get('RO_ZipCode').disable()

    }
    else {
      this.organizationForm.get('RO_Address1').enable()

      this.organizationForm.controls.RO_Address1.reset();
      // this.organizationForm.get('RO_Address2').enable()

      // this.organizationForm.controls.RO_Address2.reset();
      this.organizationForm.get('RO_Country').enable()

      this.organizationForm.controls.RO_Country.reset();
      this.organizationForm.get('RO_State').enable()

      this.organizationForm.controls.RO_State.reset();
      this.organizationForm.get('RO_City').enable()

      this.organizationForm.controls.RO_City.reset();
      this.organizationForm.get('RO_ZipCode').enable()

      this.organizationForm.controls.RO_ZipCode.reset();
    }
  }

  getIndustry() {
    this.loaderService.show();
    this.organizationService.getIndustry()
      .subscribe((response: Array<{ id, name }>) => {
        this.loaderService.hide();
        const len = response.length;
        for (let i = 0; i < len; i++) {
          this.industry.push({
            id: response[i].id,
            name: response[i].name,
          });
        }
      }, (error) => {
        this.loaderService.hide();
        this.errorSnackbar(error);
      })
  }

  delete() {
    this.matDialog.open(ConfirmationDialogComponent,
      {
        data:
        {
          yesBtnName: 'Confirm',
          noBtnName: 'Cancel',
          confirmationMesg: 'Are you sure you want to delete a logo?',
          heading: 'Delete Logo'
        }
      }).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.url = null;
          this.val = null;
          this.fileInput.nativeElement.value = null;
        } else {
        }
      });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event != null) {
      if (
        this.imageChangedEvent[0].size / 1000 <
        common.ImageSize
      ) {
        let reader = new FileReader();
        reader.readAsDataURL(event[0]);
        reader.onload = (event) => {
          this.url = event['target']['result'];
        }
      } else {
        this.matDialog.open(SuccessDialogComponent, {
          data: {
            yesBtnName: 'OK',
            confirmationMesg: 'Error shown regarding the file size you have uploaded. Kindly make the file size as 5MB',
            heading: 'Error'
          }
        }).afterClosed().subscribe(res => {
          // this.layout = '';
        })
        this.url = '';
      }
    }
  }
  Cancel() {
    this.router.navigate(['/admin/account-setup']);
  }

  reset() {
    this.fillForm();
  }

  custOnboardFormRequestBody() {
    const endate = new Date(this.organizationForm.value.contractEndDate);
    const stdate = new Date(this.organizationForm.value.contractStartDate);
    const customerId = JSON.parse(localStorage.getItem('customerId'));

    //timezone conversion
    let index = this.timezone.findIndex(el => el.id === (this.organizationForm.value.orgTimezone));
    let businessStart: any = this.organizationForm.value.businessStartTime;
    let businessEnd: any = this.organizationForm.value.businessEndTime;

    if (index > -1) {
      let timezone = this.timezone[index].urcOffset.split(':');
      let hour = parseInt(timezone[0]);
      let type = "add";
      if (parseInt(timezone[0]) < 0) {
        hour = -parseInt(timezone[0]);
        type = "subtract";
      }
      let obj = {
        hours: hour,
        minutes: parseInt(timezone[1]),
        type: type
      }
      if (obj.type === 'add') {
        businessStart = moment(moment(new Date(`2000-12-12 ${businessStart}`)).format('YYYY-MM-DD HH:mm:ss')).subtract(obj.hours, "hours").subtract(obj.minutes, "minutes");
        businessStart = businessStart.format('HH:mm');

        businessEnd = moment(moment(new Date(`2000-12-12 ${businessEnd}`)).format('YYYY-MM-DD HH:mm:ss')).subtract(obj.hours, "hours").subtract(obj.minutes, "minutes");
        businessEnd = businessEnd.format('HH:mm');
      }
      else {
        businessStart = moment(moment(new Date(`2000-12-12 ${businessStart}`)).format('YYYY-MM-DD HH:mm:ss')).add(obj.hours, "hours").add(obj.minutes, "minutes");
        businessStart = businessStart.format('HH:mm');

        businessEnd = moment(moment(new Date(`2000-12-12 ${businessEnd}`)).format('YYYY-MM-DD HH:mm:ss')).add(obj.hours, "hours").add(obj.minutes, "minutes");
        businessEnd = businessEnd.format('HH:mm');
      }
    }

    let body = {
      id: customerId,
      accountName: this.organizationForm.value.accountName,
      address: {
        address: this.organizationForm.value.address1,
        cityId: this.organizationForm.value.city,
        countryId: this.organizationForm.value.country.id,
        stateId: this.organizationForm.value.state,
        zipcode: this.organizationForm.value.zipCode,
      },
      generalSettingRequest: {
        id: this.generalSettingId,
        // themeId: this.organizationForm.value.orgTheme,
        timezoneId: this.organizationForm.value.orgTimezone
      },

      adminPhoneNumber: this.organizationForm.value.adminPhone,
      adminName: this.organizationForm.value.adminName,
      alternateEmailAddress: this.organizationForm.value.alternateEmail,
      attachment: this.contAttachment,
      email: this.organizationForm.controls.email.value,
      industryId: this.organizationForm.value.industryOrProfile,
      noOfBranches: this.organizationForm.controls.noOfFacilityOrBranch.value,
      noOfPatient: this.organizationForm.controls.noOfPatient.value,
      noOfEmployee: this.organizationForm.controls.noOfEmployee.value,
      registeredOfficeSame: this.organizationForm.controls['isRO_AddressSame'].value,
      registeredOfficeAddress: {
        address: this.organizationForm.controls['RO_Address1'].value,
        cityId: this.organizationForm.controls['RO_City'].value,
        countryId: this.organizationForm.controls['RO_Country'].value.id,
        stateId: this.organizationForm.controls['RO_State'].value,
        zipcode: this.organizationForm.controls['RO_ZipCode'].value
      },
      packageId: this.organizationForm.controls.packageId.value,
      profileImage: this.profileImageUrl ? this.profileImageUrl : null,
      businessHourStartTime: businessStart,
      businessHourEndTime: businessEnd
    }
    if (this.url) {
      body['generalSettingRequest']['logo'] = this.url.replace(/^data:(image|application)\/(png|jpg|jpeg|pdf);base64,/, '');
    }
    return body;
  }

  submit() {
    this.loaderService.show();
    let body = this.custOnboardFormRequestBody();
    this.organizationService.updateCustomer(body).subscribe((response) => {
      this.loaderService.hide();
      this.generalSettingId = response['data']['generalSettingId'];
      this.commonService.sendUserLogo(this.url);
      this.openSnackBar('success', response['description']);
      localStorage.setItem('profileImg', this.storeProfileImg);
      this.loggingIn.getCustomerCurrentTimeZone(body.id).subscribe(
        (result: any) => {

          if (result.description === "Successfully Retrieved") {
            let timezone = result.data.urcOffset.split(':');
            let hour = parseInt(timezone[0]);
            let type = "add";
            if (parseInt(timezone[0]) < 0) {
              hour = -parseInt(timezone[0]);
              type = "subtract";
            }
            let obj = {
              hours: hour,
              minutes: parseInt(timezone[1]),
              type: type,
              zoneId: result.data.name,
              timezone: result.data.abbreviation

            }
            localStorage.setItem('timeZone', JSON.stringify(obj));

          }
          else {
            let obj = {
              hours: 5,
              minutes: 30,
              type: "add",
              zoneId: 'Asia/Kolkata',
              timezone: 'IST'

            }
            localStorage.setItem('timeZone', JSON.stringify(obj));
          }
        },
        (error) => {

          let obj = {
            hours: 5,
            minutes: 30,
            type: "add",
            zoneId: 'Asia/Kolkata',
            timezone: 'IST'

          }
          localStorage.setItem('timeZone', JSON.stringify(obj));
          this.errorSnackbar(error);
        }
      );
    },
      (error) => {
        this.loaderService.hide();
        this.errorSnackbar(error);
      });
  }

  errorSnackbar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(panelClass: string, message?) {
    if (panelClass === 'success') {
      this.toastService.opentoast(
        { toastName: 'success', data: { name: '', message }, type: 'success' },
        ToasterComponent
      );
    }
  }

  countryComparision(option, value) {
    return option.countryCode == value.countryCode && option.id == value.id;
  }

  signinMicrosoft(){
    window.open(`https://login.microsoftonline.com/common/adminconsent?client_id=${SSO.clientId}&state=caleedo&redirect_uri=${environment.url}/admin/account-setup/add-customer`,'_self')
  }

  setTenant(id) {
    this.loaderService.show();
    this.organizationService.setTenantId(this.customerId, id)
      .subscribe((response: any) => {
        this.loaderService.hide();
        this.openSnackBar('success', 'Successfully Verified!');
        this.router.navigate(['/admin/account-setup/add-customer']);
      },
        (error) => {
          this.errorSnackbar(error);
        })
  }

}

