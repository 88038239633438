import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from 'src/app/core/utilities/format-datepicker';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { NameValidator } from 'src/app/modules/shared/validators/name.validator';
import { DataModel, GenericPicklistModel } from 'src/app/modules/shared/model/picklist.model';
import { BlacklistVisitorService } from '../../../service/blacklist-visitor.service';
import { BlacklistVisitorDetailModel, BlacklistVisitorModel } from '../../../model/blacklist-visitor.model';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ConfigurationFormFieldService } from 'src/app/modules/shared/services/configuration-form-field.service';
import { FormFieldListModel } from 'src/app/modules/shared/model/configuration-form-field';
import { environment } from 'src/app/config/environments/environment';

@Component({
  selector: 'app-blacklist-visitor-approval',
  templateUrl: './blacklist-visitor-approval.html',
  styleUrls: ['./blacklist-visitor-approval.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class BlackListVisitorApprovalComponent implements OnInit {
  isActive;
  blacklistVisitorForm: FormGroup;
  patients: any = [];
  organizations: any = [];
  patientes: any = [];
  buildings: any = [];
  holidayCalendarPicklist: any = [];
  leavePolicyPicklist: any = [];
  id;
  updating = false;
  orgId: any;
  message;
  updateStatus;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  patientList: any = [];
  countryList: any[];
  additionalFields = [];
  customerId = localStorage.getItem('customerId');
  branchId = localStorage.getItem('branchId');
  departmentList: any[];

  constructor(
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public commonService: CommonServiceService,
    private router: Router,
    private fb: FormBuilder,
    private picklistService: PicklistService,
    private blacklistVisitorService: BlacklistVisitorService,
    private loaderService: LoaderService,
    private configurationFormFieldService : ConfigurationFormFieldService
  ) { }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_BLACKLIST_VISITOR');
    this.commonService.callToggleAddEmp.subscribe(() => {
    });
    this.getParams();
    this.getFormControlList();
    this.initializeForm();
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    this.getCountry();
    // this.getDepartment();
    this.blacklistVisitorForm.disable();
  }

  getParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.updating = true;
        this.id = params.id;
        this.patchValuesInBlacklistVisitorForm();
      }
    });
  }

  initializeForm() {
    this.blacklistVisitorForm = this.fb.group({
      patientId: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(10),
      ]],
      patientName: ['', [Validators.required, NameValidator]],
      visitorName: ['', [Validators.required, NameValidator]],
      country: ['', [Validators.required]],
      // email: ['', [Validators.required]],
      mobileNo: ['', [Validators.required]],
      reason: ['', [Validators.required, NameValidator]],
      date: [''],
      bedNumber: [''],
      status: [''],
    });
  }

  patchValuesInBlacklistVisitorForm() {
    this.loaderService.show();
    this.blacklistVisitorService
      .getVisitorDetail(this.id)
      .subscribe((res: BlacklistVisitorModel) => {
        this.loaderService.hide();
        this.blacklistVisitorForm.get('patientId').patchValue(res.data.patientId);
        this.blacklistVisitorForm.get('patientName').patchValue(res.data.patientName);
        this.blacklistVisitorForm.get('visitorName').patchValue(res.data.visitorName);
        this.blacklistVisitorForm.get('reason').patchValue(res.data.reason);
        this.blacklistVisitorForm.get('mobileNo').patchValue(res.data.visitorMobileNo);
        this.blacklistVisitorForm.get('country').patchValue({value: res.data.countryId, countryCode: res.data.countryCode});
        this.blacklistVisitorForm.get('date').patchValue(new Date(res.data.date).toISOString());
        this.blacklistVisitorForm.get('bedNumber').patchValue(res.data.bedNo);
        this.blacklistVisitorForm.get('status').patchValue(res.data.blacklistStatus);
        this.additionalFields.forEach(item => {
          if (item.hidden) {
            this.blacklistVisitorForm.get(item.fieldCode).patchValue(res.data[item.fieldCode]);
          }
        })
      });
  }

  getCountry() {
    this.picklistService.getCountry().subscribe((res: Array<GenericPicklistModel>) => {
      this.countryList = [];
      res.map((x: GenericPicklistModel) => {
        this.countryList.push({ value: x.id, name: x.name, code: x.code, countryCode: x.countryCode });
      });
    });
  }

  getFormControlList() {
    this.configurationFormFieldService.getListing(environment.patientApiUrl, 'blacklist_visitor').subscribe((res: FormFieldListModel) => {
      res.data.forEach(item => {
        let control = new FormControl();
        control.valueChanges.subscribe(x => {
        });
        if (item.hidden) {
          this.blacklistVisitorForm.addControl(item.fieldCode, control);
          this.additionalFields.push(item);
        }
      })
    })
  }

  onboardApproval(approvedStatus) {
    this.blacklistVisitorService.approveVisitorDetail({ "id": this.id, "approvalStatus": approvedStatus })
      .subscribe((res: BlacklistVisitorDetailModel) => {
        this.loaderService.hide();
        this.successSnackBar(res['description']);
        this.router.navigate(['admin/patient-list/blacklist-visitor']);
      },
        (error) => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        })
  }

  // onSubmit3() {
  //   this.loaderService.show();
  //   const admittedDate = new Date(this.blacklistVisitorForm.value.admittedDate)
  //   const body = {
  //     firstName: this.blacklistVisitorForm.value.firstName,
  //     lastName: this.blacklistVisitorForm.value.lastName,
  //     doctorName: this.blacklistVisitorForm.value.doctorName,
  //     branchId: this.branchId,
  //     patientId: this.blacklistVisitorForm.value.patientId,
  //     countryCode: this.blacklistVisitorForm.value.country,
  //     mobilePhone: this.blacklistVisitorForm.value.mobileNo,
  //     departmentId: this.blacklistVisitorForm.value.departmentId,
  //     roomNumber: this.blacklistVisitorForm.value.roomNumber,
  //     bedNumber: this.blacklistVisitorForm.value.bedNumber,
  //     admittedDate: admittedDate.getTime(),
  //     email: this.blacklistVisitorForm.value.email,
  //     staffUserId: 0,
  //     userId: 0,
  //     customerId: this.customerId
  //   }
  //   const patientId = this.id;
  //   if (this.updating) {
  //     this.patientService.updatePatient(body, patientId)
  //       .subscribe((res: PatientDescModel) => {
  //         this.loaderService.hide();
  //         this.successSnackBar(res.description);
  //         this.router.navigate(['admin/patient-list/patient-onboard']);
  //       },
  //         (error) => {
  //           this.loaderService.hide();
  //           this.errorSnackBar(error);
  //         });
  //   } else {
  //     this.patientService.addPatient({ ...body, customerId: this.customerId })
  //       .subscribe((res: PatientDescModel) => {
  //         this.loaderService.hide();
  //         this.successSnackBar(res.description);
  //         this.router.navigate(['admin/patient-list']);
  //       },
  //         (error) => {
  //           this.loaderService.hide();
  //           this.errorSnackBar(error);
  //         });
  //   }
  // }


  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
      this.toastService.opentoast(
        { toastName: '', data: { errorCode: error.status, error: error.error } },
        ErrorToasterComponent
      );
  }

  cancel() {
    this.router.navigate(['admin/patient-list/patient-onboard']);
  }

  countryComparision(option, value) {
    return option.countryCode == value.countryCode && value.id == option.id;
  }
}
