import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PatientService } from '../../service/patient.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';


// ----------component--------------------------------------------------------------------------------------//
@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.html',
  styleUrls: ['./workflow.scss']
})

export class WorkflowComponent implements OnInit {
  searchText = '';
  message;
  actionsToggle = false;
  isActive: boolean = false;
  screenWidth: number;
  showMobileTable: boolean;
  public pageInfo: PaginationModel = new PaginationModel();
  permissionsObject = { read: false, create: false, update: false, delete: false };
  constructor(
    private commonService: CommonServiceService,
    public toastService: ToastService) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }
  toggleAction() {
    this.actionsToggle = !this.actionsToggle;
  }
  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_WORKFLOW');
    this.commonService.searchText = '';

    this.commonService.callToggle.subscribe((data) => {
      this.change();
    });

    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  searchItem() {
    this.commonService.sendFilterCloseEvent();
    this.commonService.searchText = this.searchText.trim();
    this.commonService.sendSearchEvent();

  }
  searchInputChanged() {
    this.commonService.searchText = this.searchText;
  }
  change() {
    this.isActive = !this.isActive;
  }
}
