import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { BlacklistVisitorService } from '../../../service/blacklist-visitor.service';
import { BlackListVisitorListModel } from '../../../model/blacklist-visitor.model';

@Component({
  selector: 'app-blacklist-visitor-list-view',
  templateUrl: './blacklist-visitor-listview.html',
  styleUrls: ['./blacklist-visitor-listview.scss']
})

export class BlackListVisitorListViewComponent implements OnInit {
  @Input() showMobileTable: boolean;
  @Input() filterValueChange: object;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  public pageInfo: PaginationModel = new PaginationModel();
  element: any = [];
  noResult: boolean = true;
  saveSearch: any = '';
  message;
  data;
  showPagination = true;
  pages;
  pageSizeArray = [10, 50, 100];
  permissionsObject = { read: false, create: false, update: false, delete: false };
  pageSize: number = 10;
  @HostListener('click') onMouseEnter() {
    this.commonService.sendFilterCloseEvent();
  }
  constructor(
    public router: Router,
    private visitorService: BlacklistVisitorService,
    public commonService: CommonServiceService,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    private loaderService: LoaderService ) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
  }
  pageSizeChange(event) {
    if (event.value) {
      this.pageInfo.page = 0;
      this.pageInfo.size = event.value;
      this.listing();
    }
  }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_BLACKLIST_VISITOR');
    this.showPagination = true;
    this.listing();
    this.commonService.getSearchEvent().subscribe(res=>{
      this.listing();
    })
  }

 

  SetPage(event: any) {
    this.pageInfo.page = event.page;
    this.pageInfo.size = event.size;
    this.listing();
  }

  search() {
    if (this.saveSearch !== '') {
      this.commonService.searchText = this.saveSearch;
      this.saveSearch = '';
    }
    this.showPagination = true;
    this.pageInfo.page = 0;
    this.listing();
  }

  sort(colName: string, sortDirection: any) {
    if (this.pageInfo.sortBy === colName) {
      if (sortDirection == 'ASC') {
        this.commonService.head = '';
        this.commonService.sort = 'DESC';
        this.pageInfo.sortBy = '';
        this.pageInfo.sortDir = 'DESC';
      } else {
        this.commonService.sort = sortDirection;
        this.pageInfo.sortDir = sortDirection;
      }
    } else {
      this.commonService.head = colName;
      this.commonService.sort = 'ASC';
      this.pageInfo.sortBy = colName;
      this.pageInfo.sortDir = 'ASC';
    }
    this.listing();
  }

  listing() {
    const branchId = localStorage.getItem('branchId');
    // let body = this.mapKeysOfFilter();
    // if (body['status'] && body['status'].length)
    //   body = { ...body, status: body['status'][0] }
    
    this.loaderService.show();
    this.visitorService.visitorList(this.pageInfo, branchId)
      .subscribe((response: BlackListVisitorListModel) => {
        this.loaderService.hide();
        this.pageInfo.TotalRecord = response.totalElements;
        this.pageInfo.totalPages = response.totalPage;
        if (response.payload.length) {
          this.noResult = false;
          this.element = response.payload
          this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
        } else {
          this.noResult = true;
          this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
        }
      }, error => {
        this.loaderService.hide();
        this.errorSnackbar(error);
      });
  }

  editPatient(id: any) {
    this.router.navigate(['admin/patient-list/approve-blacklist-visitor/' + id]);
  }

  errorSnackbar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(panelClass: string, message?) {
    if (panelClass === 'success') {
      this.toastService.opentoast(
        { toastName: 'success', data: { name: '', message }, type: 'success' },
        ToasterComponent
      );
    }
  }



}
