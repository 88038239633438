import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-slider-header',
  templateUrl: './slider-header.component.html',
  styleUrls: ['./slider-header.component.scss']
})
export class SliderHeaderComponent implements OnInit {
  public now: Date = new Date();
  public headerTodaysTime
  public headerTodaysDate
  public customerLogo = localStorage.getItem('logoImg');

  constructor() {
    let timezone = JSON.parse(localStorage.getItem('timeZone'));
    setInterval(() => {
      this.now = new Date();
      let time;
      if(timezone.type==='add')
      {
        time=moment.utc(new Date()).add(timezone.hours,"hours");
        time=moment.utc(time).add(timezone.minutes,"minutes");
        
      }
      else{
        time=moment.utc(new Date()).subtract(timezone.hours,"hours");
        time=moment.utc(time).subtract(timezone.minutes,"minutes");
      }

      // this.headerTodaysTime = moment(new Date().getTime()).format('h:mm 	A');
      this.headerTodaysTime = time.format('h:mm 	A');
      this.headerTodaysDate = moment(new Date().getTime()).format('ddd, D MMM');
    }, 1);
   }

  ngOnInit() {

  }

}
