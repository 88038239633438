import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { environment } from 'src/app/config/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModuleBuiderService {
  public resource = new Subject<any>();
  public selectedCustomerId = [];
  selectCustomer = new Subject<Boolean>();
  selectCustomerObservable = this.selectCustomer.asObservable();
  filters = new Subject<any>();
  filterChangeEmitter = this.filters.asObservable();
  constructor(private http: HttpClient, private commonService: CommonServiceService) { }

  sendResource(packageId?, platform = 'WEB') {
    if (packageId)
      this.resource.next({ packageId, platform });
    else
      this.resource.next();
  }

  get getResourceList(): Observable<any> {
    return this.resource.asObservable();
  }
  addCreatePackage(packageData) {
    return this.http.post(environment.authApiUrl + '/api/v1/packages', packageData);
  }

  getCreatePackage(pageInfo: PaginationModel, status?, industry =[]) {
    if(industry && !industry.length) {
      industry = [1,2]
    }
    if (!status) {
      const industryId = industry.join(',');
      let params = new HttpParams().append('industry', industryId);
      return this.http.get(environment.authApiUrl + '/api/v1/packages' + `?page=${pageInfo.
        page}&qry=${encodeURIComponent(this.commonService.searchText)}&size=${pageInfo.
          size}&by=${pageInfo.sortBy}&order=${pageInfo.sortDir}`,{params:params});
    } else {
      const filter = status === 'Active' ? true : false;
      const industryId = industry.join(',');
      let params = new HttpParams().append('industry', industryId);
      return this.http.get(environment.authApiUrl + '/api/v1/packages' + `?page=${pageInfo.
        page}&qry=${encodeURIComponent(this.commonService.searchText)}&size=${pageInfo.
          size}&by=${pageInfo.sortBy}&order=${pageInfo.sortDir}&status=${filter}`, { params: params});
    }
  }

  updateCreatePackage(id, body) {
    return this.http.put(`${environment.authApiUrl}/api/v1/packages/${id}`, body);

  }
  getCreatePackageById(id) {
    return this.http.get(`${environment.authApiUrl}/api/v1/packages/${id}`);
  }
  getResources(industryId) {
    return this.http.get(`${environment.authApiUrl}/api/v1/industries/${industryId}/modules`);
  }

  getIndustryList() {
    return this.http.get(`${environment.authApiUrl}/api/v1/industries`);
  }

  changeStatus(packageId: any = [], status) {
    packageId = packageId.join(',');
    let params = new HttpParams().append('package-id', packageId);
    params = params.append('status', status);
    return this.http.put(`${environment.authApiUrl}/api/v1/packages/status/multi`, {}, { params: params });
  }
}
