import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { PatientService } from '../../../service/patient.service';
import {
  FilterSelectedModel, OrganisationFilterResultModel,
  DepartmentFilterResultModel, PatientCodeFilterResultModel,
  PatientStatusFilter, selectedModel, ReportingManagerFilterResultModel
} from '../../../model/filter-operations';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
enum FilterType {
  PatientCode = 1,
  PatientStatus,
  Department,
  Organization,
  ReportingManager,
}
@Component({
  selector: 'app-filter-view',
  templateUrl: './filter-view.html',
  styleUrls: ['./filter-view.scss']
})

export class FilterViewComponent implements OnInit, OnChanges {
  FilterType = FilterType;
  pageInfo: PaginationModel = new PaginationModel();
  patientCodeArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  departmentArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  organisationArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  reportingManagerArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  patientStatusArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  patientStatusResponse: Array<PatientStatusFilter> = new Array<PatientStatusFilter>();
  organisationResponse: OrganisationFilterResultModel;
  departmentResponse: DepartmentFilterResultModel;
  searchText: string;
  selectedFilterType: number;
  selectedKeys: selectedModel = new selectedModel();
  @Output() valueChange = new EventEmitter();
  @Output() closeFilterEventWithoutDataChange = new EventEmitter();
  @Output() closeFilterEvent = new EventEmitter();
  @Input() previousFilterValues: any;
  constructor(
    private patientService: PatientService,
    private loaderService: LoaderService,
    private toast: ToastService,
    private common: CommonServiceService,
    private picklistService: PicklistService,
    private toastService: ToastService
  ) {
    this.pageInfo.size = 5;
    this.pageInfo.page = 0;
    this.searchText = '';
  }

  ngOnInit() {
    this.common.getFilterCloseEvent().subscribe(res => {
      this.closeWithoutReset();
    });
  }
  ngOnChanges() {
    if (this.previousFilterValues) {
      this.organisationResponse = new OrganisationFilterResultModel();
      this.departmentResponse = new DepartmentFilterResultModel();
      this.patientStatusResponse = new Array<PatientStatusFilter>();
      this.patientCodeArray = this.previousFilterValues.PatientCode.slice(0);
      this.reportingManagerArray = this.previousFilterValues.reportingManager.slice(0);
      this.organisationArray = this.previousFilterValues.organisation.slice(0);
      this.patientStatusArray = this.previousFilterValues.patientStatus.slice(0);
      this.departmentArray = this.previousFilterValues.department.slice(0);
      this.selectedFilterType = this.FilterType.PatientCode;

    }
  }

  searchPatientStatus() {
    this.selectedFilterType = this.FilterType.PatientStatus;
  }

  selectedInput() {
    this.getData(this.selectedFilterType);
  }

  getData(filterType) {
    this.pageInfo.page = 0;
    this.pageInfo.size = 5;
    this.organisationResponse = new OrganisationFilterResultModel();
    this.departmentResponse = new DepartmentFilterResultModel();
    this.patientStatusResponse = new Array<PatientStatusFilter>();

    if (filterType === this.FilterType.PatientStatus) {
      this.searchPatientStatus();
      return;
    }
  }

  selectedItem(item: any, type) {
    if (FilterType.Organization === type) {
      if (this.organisationArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.organisationArray, this.organisationResponse, item);
      } else {
        this.addItem(this.organisationArray, this.organisationResponse, item);
      }
      return;
    }
    if (FilterType.Department === type) {
      if (this.departmentArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.departmentArray, this.departmentResponse, item);
      } else {
        this.addItem(this.departmentArray, this.departmentResponse, item);
      }
      return;
    }
    if (FilterType.PatientStatus === type) {
      if (this.patientStatusArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.patientStatusArray, this.patientStatusResponse, item);
      } else {
        this.addItem(this.patientStatusArray, this.patientStatusResponse, item);
      }
      return;
    }
  }
  addItem(selectedArray: any, arrayWholeContent: any, item: any) {
    selectedArray.push({
      id: item.id,
    });
    if (arrayWholeContent && arrayWholeContent.content) {
      const resultArray = arrayWholeContent.content.filter(content => content.id === item.id);
      resultArray[0].selected = true;
    } else {
      const resultArray = arrayWholeContent.filter(content => content.id === item.id);
      resultArray[0].selected = true;
    }
  }

  removeItem(selectedArray: any, arrayWholeContent: any, item: any) {
    const ind = selectedArray.findIndex(x => x.id === item.id);
    selectedArray.splice(ind, 1);
    if (arrayWholeContent && arrayWholeContent.content) {
      const resultArray = arrayWholeContent.content.filter(content => content.id === item.id);
      resultArray[0].selected = false;
    } else {
      const resultArray = arrayWholeContent.filter(content => content.id === item.id);
      resultArray[0].selected = false;
    }
  }
  filter() {

    const filterObj = {
      department: this.departmentArray,
      organisation: this.organisationArray,
      patientStatus: this.patientStatusArray,
      crossClicked: false
    };
    this.valueChange.emit(filterObj);
  }
  close() {
    this.patientCodeArray = [];
    this.departmentArray = [];
    this.organisationArray = [];
    this.patientStatusArray = [];
    this.filter();
    // this.getData(this.selectedFilterType);
  }
  closeWithoutReset() {
    // const filterObj = {
    //   department: this.departmentArray,
    //   PatientCode: this.patientCodeArray,
    //   organisation: this.organisationArray,
    //   reportingManager: this.reportingManagerArray,
    //   crossClicked: true
    // };
    // this.closeFilterEvent.emit(filterObj);
    this.closeFilterEventWithoutDataChange.emit();
  }
  onScroll() {
    if (this.pageInfo.page + 1 < this.pageInfo.totalPages) {
      this.pageInfo.page = this.pageInfo.page + 1;

    }
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }
  openSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

}
