import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { environment } from 'src/app/config/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  lastPage: number = 0;
  pageSize: number = 10;
  selectedApprovedCustomerIds: any = [];
  selectCustomer = new Subject<Boolean>();
  selectCustomerObservable = this.selectCustomer.asObservable();

  constructor(private http: HttpClient, private common: CommonServiceService, private loader: LoaderService) { }

  approveCustomerList(pageInfo: PaginationModel, status) {
    return this.http.get(`${environment.customerApiUrl}api/v1/customers/approvedCustomerListing?page=${pageInfo.
      page}&searchText=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&size=${pageInfo.size}&sortDirection=${pageInfo.sortDir}&status=${status}`);
  }

  unapproveCustomerList(pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}api/v1/customers/inProcessCustomerListing?page=${pageInfo.
      page}&searchText=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&size=${pageInfo.size}&sortDirection=${pageInfo.sortDir}`);
  }

  rejectedCustomerList(pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}api/v1/customers/rejectedCustomerListing?page=${pageInfo.
      page}&searchText=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&size=${pageInfo.size}&sortDirection=${pageInfo.sortDir}`);
  }

  customerByID(id: any,approvalType) {
    if(approvalType) {
      return this.http.get(`${environment.customerApiUrl}api/v1/customers/activatedeactivate/getCustomerById/${id}`)
    } else {
      return this.http.get(environment.customerApiUrl + 'api/v1/customers/getCustomerById/' + id);
    }
  }
  getModifiedCustomerDataByID(id: any) {
    return this.http.get(environment.customerApiUrl + 'api/v1/customers/update/getCustomerById/' + id);
  }

  updateCustomer(customerId, payload) {
    return this.http.put(`${environment.customerApiUrl}api/v1/customers/updateCustomer/${customerId}`, payload);
  }

  //UPDATE CUSTOMER CM
  updateCustomerCM(payload) {

    return this.http.post(`${environment.assetUrl}api/v1/assetcustomerdetail/addCustomerDetail`, payload);
  }

  addCustomerOnboard(body) {
    return this.http.post(`${environment.customerApiUrl}api/v1/customers/customerOnBoard`, body);
  }

  getCountry() {
    return this.http.get(`${environment.customerApiUrl}api/v1/configs/countries`);
  }

  getState(countryId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/configs/state?countryId=${countryId}`);
  }

  getCity(stateId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/configs/cities?stateId=${stateId}`);
  }

  getIndustry() {
    return this.http.get(`${environment.authApiUrl}/api/v1/industries`);
  }

  getResources(packageId, platform = 'WEB') {
    return this.http.get(`${environment.customerApiUrl}api/v1/modules/packageResources/${packageId}?platform=${platform}`);
  }

  getPackages(customerId, industryId) {
    if (!customerId) {
      return this.http.get(environment.authApiUrl + '/api/v1/packages' + `?page=0&qry=&size=1000000&by=name&order=ASC&status=true&industry=` + industryId);
    } else {
      return this.http.get(environment.authApiUrl + '/api/v1/packages' + `?page=0&qry=&size=1000000&by=name&order=ASC&industry=` + industryId);
    }
  }

  changeCustomerBulkStatus(status) {
    return this.http.put(`${environment.customerApiUrl}api/v1/customers/change-status?status=${status}`, this.selectedApprovedCustomerIds)
  }

  uploadFile(formData) {
    return this.http.post(`${environment.notificationApiUrl}api/v1/images/uploadFile`, formData, { responseType: 'text' })
  }

  activateCustomer(body) {
    return this.http.put(`${environment.customerApiUrl}api/v1/customers/onboard/activateCustomer`, body);
  }

  modifiedCustomerUpdateStatus(customerId, reason, status, body={}) {
    return this.http.put(`${environment.customerApiUrl}api/v1/customers/update/approveCustomer?customerId=${customerId}&reason=${reason}&status=${status}`, body);
  }

  changeCustomerStatus(customerId, status) {
    return this.http.put(`${environment.customerApiUrl}api/v1/customers/status/${customerId}?status=${status}`, {});
  }

  getStaff(customerId, searchText = '') {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/pickList?customerId=${customerId}&searchText=${searchText}`);
  }

  getApprovalList(customerId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/customers/approvalHeirarchyList/${customerId}`);
  }

  getApprovalStaff() {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/approverList`);
  }

  getTenureMonth() {
    return this.http.get(`${environment.customerApiUrl}api/v1/tenureMonths/pickList`);
  }

  getImage(key) {
    return this.http.get(`${environment.notificationApiUrl}api/v1/images/getImage?key=${key}`, { responseType: 'blob', observe: 'response' });
  }
}

