import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/app/config/environments/environment';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThresholdLimitsServiceService {

  constructor(private http: HttpClient, private router: Router, private commonService: CommonServiceService) { }

  //GET ROOM TYPE 
  getRoomType() {
    return this.http.get(`${environment.cmUrl}api/v1/thresholdaqi/roomtypedropdown`);
  }

  //GET Threshold value BY Customer & romm type ID
  getDeviceThresholdByRoomType(roomTypeId,customerId?)
  {
    return this.http.get(`${environment.cmUrl}api/v1/thresholdaqi/aqi/${roomTypeId}?customerId=${customerId}`);
  }

  //UPDATE THRESHOLD DATA
  updateThreshold(body,roomTypeId) {
    return this.http.put(`${environment.cmUrl}api/v1/thresholdaqi/aqi/${roomTypeId}`,body);
  }


}
