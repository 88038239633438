import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private http: HttpClient) { }


  forgotPassword(credentials: any) {
    return this.http.post(environment.newApiUrl+ 'forgotPassword' , credentials);
  }

  resetPassword(credentials: any) {
    return this.http.post(environment.newApiUrl + 'resetForgotPassword' , credentials);
  }

  changePassword(credentials: any) {
    return this.http.post(environment.newApiUrl + 'changePassword', credentials);
  }
  verifyCode(body){
    return this.http.post(environment.newApiUrl+'verifyForgotPasswordOtp',body);
  }
  verifyToken(token){
  return this.http.get(environment.newApiUrl+'verifyToken/'+token)
}
}
