import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  yesButtonName: string;
  noButtonName: string;
  confirmationMesg: string;
  confirmationMesg2 : string;
  heading:string;
  
  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any
  ) {
    if (_.isObject(message)) {
      this.yesButtonName = message && message.yesBtnName ? message.yesBtnName : '';
      this.noButtonName = message && message.noBtnName ? message.noBtnName : '';
      this.confirmationMesg = message && message.confirmationMesg ? message.confirmationMesg : '';
      this.confirmationMesg2 = message && message.confirmationMesg2 ? message.confirmationMesg2 : null;
      this.heading = message && message.heading ? message.heading : '';
    } else {
      this.yesButtonName = 'Accept';
      this.noButtonName = 'Reject';
      this.confirmationMesg = message;
      this.heading = 'Confirm'
    }

  }

  closeDialog():void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close(2);
  }

  onYesClick(): void {
    this.dialogRef.close(1);
  }
}
