import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { WorkOrderService } from '../../services/work-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { WorkDetailsModel } from '../../models/work-order.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { DataModel } from 'src/app/modules/shared/model/picklist.model';
import { ConfigurationFormFieldService } from 'src/app/modules/shared/services/configuration-form-field.service';
import { FormFieldListModel } from 'src/app/modules/shared/model/configuration-form-field';
import { RaceSubscriber } from 'rxjs/internal/observable/race';
import { environment } from 'src/app/config/environments/environment';

@Component({
    selector: 'app-add-work-order',
    templateUrl: './add-work-order.html',
    styleUrls: ['./add-work-order.scss']
})

export class AddWorkOrderComponent implements OnInit {
    isActive: any;
    addWorkOrderForm: FormGroup;
    staffList: any = [];
    assigneList: any = [];
    scheduleId: any;
    selectedFile: any = '';
    minutes = [];//['00', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
    hours = [];//['0', '1', '2', '3', '4', '5'];
    workOrderStatusList = [];
    washroomCategory: boolean = false;
    WO_StatusList = [
        {
            "id": 1,
            "code": "WORKORDER_OPEN",
            "name": "Not Started",
            "status": true
        },
        {
            "id": 2,
            "code": "WORKORDER_INPROGRESS",
            "name": "Started",
            "status": true
        },
        {
            "id": 3,
            "code": "WORKORDER_HOLD",
            "name": "On Hold",
            "status": true
        },
        {
            "id": 4,
            "code": "WORKORDER_COMPLETED",
            "name": "Completed",
            "status": true
        },
        {
            "id": 5,
            "code": "WORKORDER_CLOSED",
            "name": "Closed",
            "status": true
        },
        {
            "id": 6,
            "code": "WORKORDER_REOPEN",
            "name": "Reopened",
            "status": true
        },
        {
            "id": 7,
            "code": "WORKORDER_DRAFT",
            "name": "Drafted",
            "status": true
        }
    ];
    filteredWorkOrderStatusList: Observable<any>;
    categoryList = [];
    workOrderId: any
    isEdit: any;
    url = {};
    subCategoryList: any[] = [];
    selectedCategory: any;
    selectedSubCategory: any;
    industryId = localStorage.getItem('industryId');
    bedList: any[] = [];
    seatList: any[] = [];
    roomList: any[] = [];
    branchId = localStorage.getItem('branchId');
    buildingOptions: any[];
    filterBuildings: Observable<any>;
    departmentList: any[];
    floorOptions: any;
    filteredFloors: Observable<any>;
    filteredDepartment: Observable<any>;
    filteredRooms: Observable<any>;
    filteredSeats: Observable<any>;
    filteredBeds: Observable<any>;
    customerId = localStorage.getItem('customerId');
    customerName = localStorage.getItem('customerName');
    customerAdminCode = localStorage.getItem('customerAdminCode');
    userId = localStorage.getItem('LogoutId');
    filteredAssigneeList: Observable<any>;
    filteredOwnerList: Observable<any>;
    workOrderQRData: any //= JSON.stringify({ abc: "abc" });
    parentWorkOrderId: any;
    isModeShow: boolean = false;
    additionalFields = [];
    isAccordionOpen: boolean = true;
    currentDate = new Date();
    currentTime = null;
    isDraft: boolean;
    searchText: any = '';
    workOrderStatus: any;
    workOrderStartTime: string = '';
    workOrderStartDate: string = '';
    workOrderOrderDate: Date;
    isStartTimeDisable: boolean = false;
    workOrderAdditionalFieldsData: any[] = [];
    workOrderData: any = [];
    parentId: number;
    isFailedWorkOrder: any;
    constructor(private formBuilder: FormBuilder, private loaderService: LoaderService,
        private toastService: ToastService, private el: ElementRef,
        private workOrderService: WorkOrderService, private activatedRoute: ActivatedRoute,
        private router: Router, private sanitizer: DomSanitizer, private commonService: CommonServiceService, private pickListService: PicklistService,
        private configurationFormFieldService: ConfigurationFormFieldService) {
    }
    ngOnInit() {
        for (let i = 0; i <= 24; i++) {
            this.hours.push(i.toString());
        }
        for (let i = 0; i < 60; i++) {
            this.minutes.push(i.toString());
        }
        this.commonService.searchText = ''
        this.createForm();
        // this.getStatusList();
        this.getStaffList();
        this.getAssigneeList();
        this.getBuilding();
        const currentUrl = this.router.url.split('/');
        this.activatedRoute.params.subscribe(res => {
            this.washroomCategory = res['category'] === 'swh' ? true : false;
            this.isFailedWorkOrder = JSON.parse(localStorage.getItem('FailedWorkOrder'));
            this.isModeShow = false;
            const workOrderId = this.workOrderId;
            this.workOrderId = res['workOrderId'];
            if (workOrderId && workOrderId != this.workOrderId) {
                this.getWorkOrderDetails(this.workOrderId);
            }
        })
        this.getWorkOrderDetails(this.workOrderId);
        if (currentUrl[currentUrl.length - 3] === 'edit-work-order') {
            this.isEdit = true;
            this.addWorkOrderForm.disable();
            // if (this.customerAdminCode == 'CUSAD' || this.customerAdminCode == 'BRANCH_ADMIN' || this.customerAdminCode == 'BRANCH_MANAGER') {
            this.addWorkOrderForm.controls.status.enable();
            this.addWorkOrderForm.controls.statusFilterCtrl.enable();
            this.addWorkOrderForm.controls.comment.enable();
            // }
        } else {
            this.getFormControlList();
            this.fetchCategoryList();
            this.isEdit = false;
        }

        if (localStorage.getItem('isActive') === 'true') {
            this.isActive = true;
        } else {
            this.isActive = false;
        }

        this.commonService.callToggle.subscribe((data) => {
            this.change();
        });

        this.addWorkOrderForm.controls.startDate.valueChanges.subscribe(res => {
            const date = res ? new Date(res) : new Date();
            if (!(date.getDate() - this.currentDate.getDate())) {
                this.currentTime = moment().format('hh:mm A');
            } else {
                this.currentTime = null;
            }
            if (!this.isEdit || (this.isDraft)
                || (this.isEdit && (this.workOrderStatus != 'WORKORDER_REOPEN' && this.addWorkOrderForm.controls.status.value && this.addWorkOrderForm.controls.status.value.code == 'WORKORDER_REOPEN'))
            ) {
                this.isStartTimeDisable = false;
                this.addWorkOrderForm.controls.startTime.enable();
                // this.addWorkOrderForm.controls.startTime.updateValueAndValidity();
            }
        })

        this.addWorkOrderForm.controls.mode.valueChanges.subscribe((res) => {
            if (res == 'multiple') {
                this.addWorkOrderForm.controls['frequencyTimeHrs'].setValidators([Validators.required]);
                this.addWorkOrderForm.controls['frequencyTimeHrs'].updateValueAndValidity();
                this.addWorkOrderForm.controls['frequencyTimeMinutes'].setValidators([Validators.required]);
                this.addWorkOrderForm.controls['frequencyTimeMinutes'].updateValueAndValidity();
            } else if (res) {
                this.addWorkOrderForm.controls['frequencyTimeHrs'].clearValidators();
                this.addWorkOrderForm.controls['frequencyTimeHrs'].updateValueAndValidity();
                this.addWorkOrderForm.controls['frequencyTimeMinutes'].clearValidators();
                this.addWorkOrderForm.controls['frequencyTimeMinutes'].updateValueAndValidity();
            }
            if (!this.isEdit || this.isDraft) {
                this.addWorkOrderForm.controls.frequencyTimeHrs.setValue('0');
                this.addWorkOrderForm.controls.frequencyTimeHrs.markAsUntouched();
                this.addWorkOrderForm.controls.frequencyTimeMinutes.setValue('0');
                this.addWorkOrderForm.controls.frequencyTimeMinutes.markAsUntouched();
            }
        })
        this.addWorkOrderForm.controls.frequencyTimeHrs.valueChanges.subscribe((res) => {
            if (res == '24') {
                this.addWorkOrderForm.controls.frequencyTimeMinutes.setValue('0');
                this.addWorkOrderForm.controls.frequencyTimeMinutes.disable();
            } else if (res && (!this.isEdit || this.isDraft)) {
                this.addWorkOrderForm.controls.frequencyTimeMinutes.enable();
            }
            if (this.addWorkOrderForm.controls.mode.value == 'multiple') {
                if (res == '0') {
                    this.addWorkOrderForm.controls.frequencyTimeMinutes.setValidators([Validators.required, Validators.min(1)]);
                    this.addWorkOrderForm.controls.frequencyTimeMinutes.updateValueAndValidity();
                } else {
                    this.addWorkOrderForm.controls.frequencyTimeMinutes.clearValidators();
                    this.addWorkOrderForm.controls.frequencyTimeMinutes.updateValueAndValidity();
                }
            }
        })
        this.addWorkOrderForm.controls.slaTimeHrs.valueChanges.subscribe((res) => {
            // if (this.addWorkOrderForm.controls.slaTimeMinutes.value < this.addWorkOrderForm.controls.frequencyTimeMinutes.value) {
            if (!this.isEdit || this.isDraft) {
                this.addWorkOrderForm.controls.frequencyTimeMinutes.reset();
                this.addWorkOrderForm.controls.frequencyTimeHrs.reset();
            }
            this.addWorkOrderForm.controls.frequencyTimeMinutes.disable();
            if (res == '24') {
                this.addWorkOrderForm.controls.slaTimeMinutes.setValue('0');
                this.addWorkOrderForm.controls.slaTimeMinutes.disable();
            } else {
                // this.addWorkOrderForm.controls.slaTimeMinutes.enable();
            }
            if (res == '0') {
                this.addWorkOrderForm.controls.slaTimeMinutes.setValidators([Validators.required, Validators.min(1)]);
                this.addWorkOrderForm.controls.slaTimeMinutes.updateValueAndValidity();
            } else {
                this.addWorkOrderForm.controls.slaTimeMinutes.setValidators([Validators.required]);
                this.addWorkOrderForm.controls.slaTimeMinutes.updateValueAndValidity();
            }
        })
        this.addWorkOrderForm.controls.slaTimeMinutes.valueChanges.subscribe((res) => {
            if (!this.isEdit || this.isDraft)
                this.addWorkOrderForm.controls.frequencyTimeMinutes.reset();
        })
        this.addWorkOrderForm.controls.status.valueChanges.subscribe((res) => {
            if (this.workOrderStatus != 'WORKORDER_REOPEN' && res && res.code == 'WORKORDER_REOPEN') {
                // this.addWorkOrderForm.controls.startDate.setValue(new Date());
                this.currentDate = new Date();
                this.addWorkOrderForm.controls.startDate.enable();
                if (this.workOrderData && this.workOrderData.data) {
                    this.addWorkOrderForm.controls.comment.setValue(this.workOrderData.data.comment);
                }
                this.addWorkOrderForm.controls.comment.enable();
                this.additionalFields.forEach(item => {
                    if (item.hidden) {
                        this.addWorkOrderForm.controls[item.fieldCode].enable();
                    }
                })
            } else if (res && !this.isDraft) {
                this.currentDate = this.workOrderOrderDate;
                this.addWorkOrderForm.controls.startDate.setValue(this.workOrderStartDate);
                this.addWorkOrderForm.controls.startDate.disable();
                this.isStartTimeDisable = true;
                this.addWorkOrderForm.controls.startTime.setValue(this.workOrderStartTime);
                this.addWorkOrderForm.controls.startTime.disable();
                if (this.workOrderData && this.workOrderData.data) {
                    this.addWorkOrderForm.controls.comment.setValue(this.workOrderData.data.comment);
                }
                if (this.workOrderStatus == 'WORKORDER_COMPLETED' || this.workOrderStatus == 'WORKORDER_CLOSED')
                    this.addWorkOrderForm.controls.comment.disable();
                this.additionalFields.forEach(item => {
                    if (item.hidden) {
                        this.addWorkOrderForm.controls[item.fieldCode].setValue(this.workOrderData.data[item.fieldCode]);
                        if (this.workOrderStatus == 'WORKORDER_COMPLETED' || this.workOrderStatus == 'WORKORDER_CLOSED')
                            this.addWorkOrderForm.controls[item.fieldCode].disable();
                    }
                })
            }
        })
    }

    change() {
        this.isActive = !this.isActive;
    }

    createForm() {
        this.addWorkOrderForm = this.formBuilder.group({
            scheduleId: [''],
            workOrderId: [''],
            slaTimeHrs: ['0', [Validators.required]],
            slaTimeMinutes: ['0', [Validators.required]],
            startTime: ['', [Validators.required]],
            assignee: ['', [Validators.required]],
            owner: [+this.userId, [Validators.required]],
            assetId: '',
            attachment: [''],
            description: [''],
            guideLines: [''],
            status: [''],
            ownerFilterCtrl: [''],
            assigneeFilterCtrl: [''],
            buildingFilterCtrl: [''],
            building: ['', [Validators.required]],
            floor: [''],
            department: [''],
            room: [''],
            floorFilterCtrl: [''],
            departmentFilterCtrl: [''],
            roomFilterCtrl: [''],
            seat: [''],
            seatFilterCtrl: [''],
            bed: [''],
            bedFilterCtrl: [''],
            mode: ['single', [Validators.required]],
            frequencyTimeMinutes: ['0'],
            frequencyTimeHrs: ['0'],
            customerName: [this.customerName],
            statusFilterCtrl: [''],
            startDate: ['', [Validators.required]],
            comment: [''],
            parentWorkOrderId: ['']
        })
        this.addWorkOrderForm.controls.scheduleId.disable();
        this.addWorkOrderForm.controls.workOrderId.disable();
        this.addWorkOrderForm.controls.parentWorkOrderId.disable();
        this.addWorkOrderForm.controls.customerName.disable();
        this.isStartTimeDisable = true;
        this.addWorkOrderForm.controls.startTime.disable();
    }

    fetchCategoryList(parentId?) {
        let status: any = '';
        if (this.isDraft || !this.isEdit) {
            status = 'true';
        }
        this.loaderService.show();
        this.workOrderService.getCategoryList(parentId, status).subscribe((res: any) => {
            if (parentId) {
                this.subCategoryList = [];
                res.payload.forEach((el: any) => {
                    if (el.categoryCode !== 'WASHROOM_HYGIENE' && !this.washroomCategory) {
                        el.selected = false;
                        el.logoKey = el.logoKey ? el.logoKey : '../../../../assets/images/symbol.png';
                        this.subCategoryList.push(el);
                    }
                    if (el.categoryCode === 'WASHROOM_HYGIENE' && this.washroomCategory) {
                        el.selected = false;
                        el.logoKey = el.logoKey ? el.logoKey : '../../../../assets/images/symbol.png';
                        this.subCategoryList.push(el);
                    }
                });
                if (this.workOrderId) {
                    const item = this.subCategoryList.find(item => item.id === this.selectedSubCategory);
                    if (item) {
                        item.selected = true;
                        if (item.categoryCode == 'AIR_QUALITY') {
                            this.isModeShow = true;
                        }
                    }
                }
            } else {
                this.categoryList = [...res.payload];
                this.categoryList.forEach(res => {
                    res.selected = false;
                    res.logoKey = res.logoKey ? res.logoKey : '../../../../assets/images/symbol.png'
                })
                if (this.workOrderId) {
                    const index = this.categoryList.findIndex(item => item.id === this.selectedCategory);
                    if (index !== -1)
                        this.categoryList[index].selected = true;
                }
            }
            this.loaderService.hide();
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    getStaffList() {
        this.loaderService.show();
        this.pickListService.getStaff(this.customerId, '').subscribe(res => {
            this.staffList = res['data'];
            this.filteredOwnerList = this.addWorkOrderForm.controls['ownerFilterCtrl'].valueChanges.
                pipe(
                    startWith(''),
                    map(value => {
                        return this.commonService.dataFilter(value, this.staffList)
                    })
                );
            this.loaderService.hide();
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    getAssigneeList() {
        this.loaderService.show();
        this.pickListService.getSupervisorStaff(this.customerId).subscribe(res => {
            this.assigneList = res['data'];
            this.filteredAssigneeList = this.addWorkOrderForm.controls['assigneeFilterCtrl'].valueChanges.
                pipe(
                    startWith(''),
                    map(value => {
                        return this.commonService.dataFilter(value, this.assigneList)
                    })
                );
            this.loaderService.hide();
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    addChildWorkOrder() {
        const body = this.createBody();
        this.loaderService.show();
        if (this.additionalFields.length) {
            this.additionalFields.forEach(item => {
                body[item.fieldCode] = this.addWorkOrderForm.value[item.fieldCode];
            })
        }
        if ((this.isDraft) && (moment(moment(new Date(this.addWorkOrderForm.controls.startDate.value)).format('DD-MM-YYYY'), "DD-MM-YYYY").valueOf() == moment(moment().format('DD-MM-YYYY'), "DD-MM-YYYY").valueOf()) && !(moment(moment(this.addWorkOrderForm.controls.startTime.value, 'hh:mm A').format('hh:mm A'), "hh:mm A").valueOf() >= moment(moment().format('hh:mm A').valueOf(), "hh:mm A").valueOf())) {
            this.addWorkOrderForm.controls['startTime'].setErrors({ 'incorrect': true });
            this.addWorkOrderForm.controls['startTime'].markAsTouched();
            this.currentTime = moment().format('hh:mm A');
            return
        }
        this.workOrderService.addNewWorkOrder(this.isDraft ? this.parentId : this.workOrderId, body).subscribe(res => {
            this.loaderService.hide();
            this.successSnackBar(res['description']);
            if(this.washroomCategory)
            {
                this.router.navigate(['/admin/hygiene/work-order/swh']);
            }
            else{
                this.router.navigate(['/admin/hygiene/work-order/hyg']);
            }
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    saveAsDraft() {
        const body = this.createBody();
        this.loaderService.show();
        if (this.additionalFields.length) {
            this.additionalFields.forEach(item => {
                body[item.fieldCode] = this.addWorkOrderForm.value[item.fieldCode];
            })
        }
        this.workOrderService.saveAsDraft(this.workOrderId, body).subscribe(res => {
            this.loaderService.hide();
            this.successSnackBar(res['description']);
            // this.router.navigate(['/admin/hygiene/work-order']);
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    // getStatusList() {
    //     this.workOrderService.getStatusList(this.customerId).subscribe((res: any) => {
    //         // this.workOrderStatusList = [...res.data];
    //         this.workOrderStatusList = []
    //         res.data.forEach(item => {
    //             // if (item.status == true) {
    //             this.workOrderStatusList.push(item);
    //             // }
    //         });
    //         this.filteredWorkOrderStatusList = this.addWorkOrderForm.controls['statusFilterCtrl'].valueChanges.
    //             pipe(
    //                 startWith(''),
    //                 map(value => {
    //                     return this.commonService.dataFilter(value, this.workOrderStatusList)
    //                 })
    //             );
    //     })
    // }

    selectCategory(type, index) {
        if (type === 'category') {
            this.categoryList.forEach((item, i) => {
                if (i === index) {
                    item.selected = true;
                    this.selectedCategory = item.id;
                } else {
                    item.selected = false;
                }
            })
            this.fetchCategoryList(this.selectedCategory);
        } else {
            this.subCategoryList.forEach((item, i) => {
                if (i === index) {
                    item.selected = true;
                    this.selectedSubCategory = item.id;
                    if (item.categoryCode == 'AIR_QUALITY') {
                        this.isModeShow = true;
                    } else {
                        this.isModeShow = false;
                    }
                } else {
                    item.selected = false;
                }
            })
        }
    }

    getBuilding() {
        if (this.branchId) {
            this.loaderService.show();
            this.pickListService.getBuilding(this.branchId).subscribe((res: DataModel) => {
                if (res.data.length) {
                    this.buildingOptions = [];
                    this.buildingOptions = res.data.map((x) => {
                        return { id: x.id, name: x.name };
                    });
                    this.filterBuildings = this.addWorkOrderForm.controls['buildingFilterCtrl'].valueChanges.
                        pipe(
                            startWith(''),
                            map(value => {
                                return this.commonService.dataFilter(value, this.buildingOptions)
                            })
                        );
                }
                this.loaderService.hide();
            }, error => {
                this.loaderService.hide();
                this.errorSnackBar(error);
            });
        }
        else
            return;
    }

    getFloor() {
        let buildingId = this.addWorkOrderForm.controls.building.value ? this.addWorkOrderForm.controls.building.value.id : '';
        if (this.branchId && buildingId) {
            this.loaderService.show();
            this.departmentList = []
            this.roomList = []
            this.pickListService.getFloor(this.branchId, buildingId).subscribe((res: DataModel) => {
                if (res.data.length) {
                    this.floorOptions = res.data.map((x) => {
                        return { id: x.id, name: x.name };
                    });
                    this.filteredFloors = this.addWorkOrderForm.controls['floorFilterCtrl'].valueChanges.
                        pipe(
                            startWith(''),
                            map(value => {
                                return this.commonService.dataFilter(value, this.floorOptions)
                            })
                        );
                }
                this.loaderService.hide();
            }, error => {
                this.loaderService.hide();
                this.errorSnackBar(error)
            });
        }
    }

    getDepartment() {
        let buildingId = this.addWorkOrderForm.controls.building.value ? this.addWorkOrderForm.controls.building.value.id : '';
        let floorId = this.addWorkOrderForm.controls.floor.value ? this.addWorkOrderForm.controls.floor.value.id : '';
        if (this.branchId && buildingId && floorId) {
            this.loaderService.show();
            this.pickListService.getDepartmentByBranchBuildingFloor(this.branchId, buildingId, floorId).subscribe((res: DataModel) => {
                if (res.data.length) {
                    this.departmentList = [];
                    this.departmentList = res.data.map((x) => {
                        return { id: x.id, name: x.name };
                    });
                    this.filteredDepartment = this.addWorkOrderForm.controls['departmentFilterCtrl'].valueChanges.
                        pipe(
                            startWith(''),
                            map(value => {
                                return this.commonService.dataFilter(value, this.departmentList)
                            })
                        );
                }
                this.loaderService.hide();
            }, error => {
                this.loaderService.hide();
                this.errorSnackBar(error)
            });
        }
    }

    getRoom() {
        const buildingId = this.addWorkOrderForm.controls.building.value ? this.addWorkOrderForm.controls.building.value.id : '';
        const floorId = this.addWorkOrderForm.controls.floor.value ? this.addWorkOrderForm.controls.floor.value.id : '';
        const departmentId = this.addWorkOrderForm.controls.department.value ? this.addWorkOrderForm.controls.department.value.id : '';
        if (this.branchId && buildingId && floorId && departmentId) {
            this.roomList = [];
            this.loaderService.show();
            this.pickListService.getRoom(this.branchId, buildingId, departmentId, floorId, this.industryId).subscribe((res: DataModel) => {
                if (res.data.length) {
                    res.data.map((x) => {
                        this.roomList.push({ id: x.id, name: x.name });
                    });
                    this.filteredRooms = this.addWorkOrderForm.controls['roomFilterCtrl'].valueChanges.
                        pipe(
                            startWith(''),
                            map(value => {
                                return this.commonService.dataFilter(value, this.roomList)
                            })
                        );
                }
                this.loaderService.hide();
            }, error => {
                this.loaderService.hide();
                this.errorSnackBar(error)
            });
        }
    }

    getSeat() {
        const roomId = this.addWorkOrderForm.controls.room.value ? this.addWorkOrderForm.controls.room.value.id : '';
        if (roomId) {
            this.seatList = [];
            this.loaderService.show();
            this.pickListService.getSeatPicklist(roomId).subscribe((res: any) => {
                this.loaderService.hide();
                if (res.data && res.data.length) {
                    res.data.map((x) => {
                        this.seatList.push({ id: x.id, name: x.name });
                    });
                    this.filteredSeats = this.addWorkOrderForm.controls['seatFilterCtrl'].valueChanges.
                        pipe(
                            startWith(''),
                            map(value => {
                                return this.commonService.dataFilter(value, this.seatList)
                            })
                        );
                }
            }, error => {
                this.loaderService.hide();
                // this.errorSnackBar(error)
            });
        }
    }

    getBed() {
        const departmentId = this.addWorkOrderForm.controls.department.value ? this.addWorkOrderForm.controls.department.value.id : '';
        const roomId = this.addWorkOrderForm.controls.room.value ? this.addWorkOrderForm.controls.room.value.id : '';
        if (departmentId && roomId) {
            this.loaderService.show();
            this.bedList = [];
            this.pickListService.getBedByRoom(this.customerId, this.branchId, departmentId, roomId).subscribe((res: DataModel) => {
                if (res.data.length) {
                    res.data.map((x) => {
                        this.bedList.push({ id: x.id, name: x.name });
                    });
                    this.filteredBeds = this.addWorkOrderForm.controls['bedFilterCtrl'].valueChanges.
                        pipe(
                            startWith(''),
                            map(value => {
                                return this.commonService.dataFilter(value, this.seatList)
                            })
                        );
                }
                this.loaderService.hide();
            }, error => {
                this.loaderService.hide();
                this.errorSnackBar(error)
            });
        }
    }

    createBody() {
        const body = {
            "assetId": this.addWorkOrderForm.controls.assetId.value ? this.addWorkOrderForm.controls.assetId.value : null,
            "assigneeUserId": this.addWorkOrderForm.controls.assignee.value ? this.addWorkOrderForm.controls.assignee.value : null,
            "attachment": this.selectedFile ? this.selectedFile : null,
            "description": this.addWorkOrderForm.controls.description.value ? this.addWorkOrderForm.controls.description.value : null,
            "sla": (this.addWorkOrderForm.controls.slaTimeHrs.value && this.addWorkOrderForm.controls.slaTimeMinutes.value) ? (+this.addWorkOrderForm.controls.slaTimeHrs.value) * 60 + (+this.addWorkOrderForm.controls.slaTimeMinutes.value) : null,
            "ownerId": this.addWorkOrderForm.controls.owner.value ? this.addWorkOrderForm.controls.owner.value : null,
            "scheduleId": this.scheduleId,
            "buildingId": this.addWorkOrderForm.controls.building.value ? this.addWorkOrderForm.controls.building.value.id : null,
            "floorId": this.addWorkOrderForm.controls.floor.value ? this.addWorkOrderForm.controls.floor.value.id : null,
            "departmentId": this.addWorkOrderForm.controls.department.value ? this.addWorkOrderForm.controls.department.value.id : null,
            "roomId": this.addWorkOrderForm.controls.room.value ? this.addWorkOrderForm.controls.room.value.id : null,
            "buildingName": this.addWorkOrderForm.controls.building.value ? this.addWorkOrderForm.controls.building.value.name : null,
            "floorName": this.addWorkOrderForm.controls.floor.value ? this.addWorkOrderForm.controls.floor.value.name : null,
            "departmentName": this.addWorkOrderForm.controls.department.value ? this.addWorkOrderForm.controls.department.value.name : null,
            "roomName": this.addWorkOrderForm.controls.room.value ? this.addWorkOrderForm.controls.room.value.name : null,
            "orderDate": this.addWorkOrderForm.controls.startDate.value ? new Date(this.addWorkOrderForm.controls.startDate.value).getTime() : new Date().getTime(),
            "branchId": this.branchId,
            "guidelines": this.addWorkOrderForm.controls.guideLines.value ? this.addWorkOrderForm.controls.guideLines.value : null,
            "categoryId": this.selectedCategory,
            "subCategoryId": this.selectedSubCategory,
            "comment": this.addWorkOrderForm.controls.comment.value ? this.addWorkOrderForm.controls.comment.value : null,
            "frequency": (this.addWorkOrderForm.controls.frequencyTimeHrs.value && this.addWorkOrderForm.controls.frequencyTimeMinutes.value) ? (+this.addWorkOrderForm.controls.frequencyTimeHrs.value) * 60 + (+this.addWorkOrderForm.controls.frequencyTimeMinutes.value) : null,
            "status": this.addWorkOrderForm.controls.status.value ? this.addWorkOrderForm.controls.status.value.code : null,
            "startTime": this.addWorkOrderForm.controls.startTime.value ? (moment(this.addWorkOrderForm.controls.startTime.value, 'hh:mm a').valueOf()) : ''
        }
        if (this.industryId == '1') {
            body['bedId'] = this.addWorkOrderForm.controls.bed.value ? this.addWorkOrderForm.controls.bed.value.id : null;
            body['bedNo'] = this.addWorkOrderForm.controls.bed.value ? this.addWorkOrderForm.controls.bed.value.name : null;
        }
        else {
            body['seatId'] = this.addWorkOrderForm.controls.seat.value ? this.addWorkOrderForm.controls.seat.value.id : null;
            body['seatNo'] = this.addWorkOrderForm.controls.seat.value ? this.addWorkOrderForm.controls.seat.value.name : null;
        }
        if (this.isModeShow) {
            body['mode'] = this.addWorkOrderForm.controls.mode.value ? this.addWorkOrderForm.controls.mode.value.toUpperCase() : null;
        }
        if (this.isDraft) {
            body['draftWorkOrderId'] = this.workOrderId;
        }
        // if (this.addWorkOrderForm.controls.status.value.code == "WORKORDER_REOPEN" && this.workOrderStatus != 'WORKORDER_REOPEN') {
        body["startDateTime"] = this.addWorkOrderForm.controls.startDate.value && this.addWorkOrderForm.controls.startTime.value ? moment(moment(this.addWorkOrderForm.controls.startDate.value).format('YYYY-MM-DD') + ' ' + moment(this.addWorkOrderForm.controls.startTime.value, 'hh:mm a').format('hh:mm a')).valueOf() : '';
        // }
        return body;
    }

    async fileSelected(event) {
        let file = event.target.files[0];
        let formData: FormData = new FormData();
        formData.append('file', file);
        this.selectedFile = await this.workOrderService.uploadFile(formData).toPromise();
    }

    successSnackBar(message) {
        this.toastService.opentoast(
            { toastName: 'success', data: { name: '', message } },
            ToasterComponent
        );
    }

    errorSnackBar(error) {
        this.toastService.opentoast(
            { toastName: '', data: { errorCode: error.status, error: error.error } },
            ErrorToasterComponent
        )
    }

    getWorkOrderDetails(workOrderId) {
        this.loaderService.show();
        this.workOrderService.getWorkOrderDetail(workOrderId).subscribe((res: WorkDetailsModel) => {
            this.addWorkOrderForm.reset();
            this.loaderService.hide();
            this.parentId = res.data.parentId;
            this.parentWorkOrderId = res.data.workOrderId;
            this.addWorkOrderForm.controls.parentWorkOrderId.patchValue(res.data.workOrderId);
            if (this.isEdit) {
                this.isDraft = res.data.status == 'WORKORDER_DRAFT' ? true : false
                this.patchWorkOrderDetails(res);
            } else {
                this.addWorkOrderForm.patchValue({
                    building: { id: res.data.buildingId, name: res.data.buildingName },
                    floor: { id: res.data.floorId, name: res.data.floorName },
                    department: { id: res.data.departmentId, name: res.data.departmentName },
                    room: { id: res.data.roomId, name: res.data.roomName },
                    owner: +this.userId,
                    mode: 'single',
                    slaTimeHrs: '0',
                    slaTimeMinutes: '0',
                    frequencyTimeMinutes: '0',
                    frequencyTimeHrs: '0',
                    customerName: this.customerName,
                })
                this.getFloor();
                this.getDepartment();
                this.getRoom();
                if (this.industryId == '1') {
                    this.getBed();
                    this.addWorkOrderForm.controls.bed.patchValue({ id: res.data.bedId, name: res.data.bedNo });
                }
                else {
                    this.getSeat();
                    this.addWorkOrderForm.controls.bed.patchValue({ id: res.data.seatId, name: res.data.seatNo })
                }
            }
        }, error => {
            this.loaderService.hide();
            this.errorSnackBar(error);
        })
    }

    patchWorkOrderDetails(res) {
        this.workOrderStatus = res.data.status;
        this.handleStatus(this.workOrderStatus);
        this.workOrderStartDate = moment(res.data.orderDate).toISOString();
        this.workOrderStartTime = res.data.startTime ? moment(res.data.startTime).format('hh:mm A') : null;
        this.workOrderOrderDate = new Date(res.data.orderDate)
        const closureTime = res.data.sla;
        if (closureTime / 60 > 0) {
            const hrs = Math.floor(closureTime / 60);
            const mins = (closureTime - hrs * 60).toString();
            this.addWorkOrderForm.patchValue({ slaTimeHrs: hrs.toString(), slaTimeMinutes: mins });
        } else
            this.addWorkOrderForm.patchValue({ slaTimeHrs: '0', slaTimeMinutes: '0' });
        this.currentDate = res.data.orderDate ? new Date(res.data.orderDate) : new Date();
        if (this.isDraft) {
            this.currentDate = new Date();
        }
        this.addWorkOrderForm.patchValue({
            scheduleId: res.data.scheduleId,
            workOrderId: res.data.workOrderId,
            startDate: moment(res.data.orderDate).toISOString(),
            startTime: res.data.startTime ? moment(res.data.startTime).format('hh:mm A') : null,
            assignee: res.data.assigneeUserId ? res.data.assigneeUserId : null,
            owner: res.data.ownerUserId ? res.data.ownerUserId : +this.userId,
            customerName: this.customerName,
            assetId: res.data.assetId ? res.data.assetId : null,
            description: res.data.description ? res.data.description : null,
            guideLines: res.data.guidelines ? res.data.guidelines : null,
            building: { id: res.data.buildingId, name: res.data.buildingName },
            floor: { id: res.data.floorId, name: res.data.floorName },
            department: { id: res.data.departmentId, name: res.data.departmentName },
            room: { id: res.data.roomId, name: res.data.roomName },
            // mode: res.data.mode ? res.data.mode.toLowerCase() : null,
            status: { code: res.data.status, name: res.data.status },
            comment: res.data.comment
        })
        // if (this.isModeShow) {
        this.addWorkOrderForm.patchValue({
            mode: res.data.mode ? res.data.mode.toLowerCase() : 'single',
        })
        // }
        // if (!this.isDraft)
        //     this.addWorkOrderForm.patchValue({ status: this.workOrderStatusList.find(data => data.code == res.data.status) })
        if (res.data.mode) {
            if (res.data.mode.toLowerCase() == 'multiple') {
                if (res.data.frequency) {
                    const frequencyTime = res.data.frequency;
                    if (frequencyTime / 60 > 0) {
                        const hrs = Math.floor(frequencyTime / 60);
                        let mins = (frequencyTime - hrs * 60).toString();
                        if (mins == '0') {
                            mins = '0';
                        }
                        this.addWorkOrderForm.patchValue({ frequencyTimeHrs: hrs.toString(), frequencyTimeMinutes: mins });
                        this.addWorkOrderForm.controls['frequencyTimeHrs'].setValidators([Validators.required]);
                        this.addWorkOrderForm.controls['frequencyTimeHrs'].updateValueAndValidity();
                        // this.addWorkOrderForm.controls['frequencyTimeMinutes'].setValidators([Validators.required]);
                        // this.addWorkOrderForm.controls['frequencyTimeMinutes'].updateValueAndValidity();
                    }
                }
            }
        } else {
            this.addWorkOrderForm.patchValue({ frequencyTimeHrs: '0', frequencyTimeMinutes: '0' });
        }
        this.workOrderQRData = JSON.stringify({
            workOrderNo: res.data.workOrderId,
            subCategoryId: res.data.subCategoryId,
            subCategoryName: res.data.subCategoryName,
            startDate: res.data.orderDate,
            startTime: res.data.startTime,
            mode: res.data.mode,
            duration: res.data.sla,
            frequency: res.data.frequency,
            locationCode: res.data.locationCode,
            locationType: res.data.locationType,
            qrtype: "WORKORDER"
        })

        this.selectedCategory = res.data.categoryId;
        this.selectedSubCategory = res.data.subCategoryId;
        this.workOrderData = res;
        this.getFormControlList(res);
        this.fetchCategoryList();
        this.fetchCategoryList(res.data.categoryId);
        this.getFloor();
        this.getDepartment();
        this.getRoom();
        // this.getSeat();
        if (this.industryId == '1') {
            this.getBed();
            this.addWorkOrderForm.controls.bed.patchValue({ id: res.data.bedId, name: res.data.bedNo })
        } else {
            this.getSeat();
            this.addWorkOrderForm.controls.seat.patchValue({ id: res.data.seatId, name: res.data.seatNo })
        }
        if (this.workOrderStatus == 'WORKORDER_CLOSED' || this.workOrderStatus == 'WORKORDER_COMPLETED') {
            this.addWorkOrderForm.disable();
            this.addWorkOrderForm.controls.status.enable();
            this.addWorkOrderForm.controls.statusFilterCtrl.enable();
            // this.addWorkOrderForm.controls.comment.enable();
        }
        if (this.isDraft) {
            this.addWorkOrderForm.enable();
            this.addWorkOrderForm.controls.scheduleId.disable();
            this.addWorkOrderForm.controls.customerName.disable();
            this.addWorkOrderForm.controls.workOrderId.disable();
        }
        if (this.isFailedWorkOrder.isEdit == false) {
            this.addWorkOrderForm.controls.comment.disable();
            this.addWorkOrderForm.controls.status.disable();
        }
        // })
    }

    private handleStatus(workOrderStatus) {
        let toRemoveStatus = [];
        let workOrderStatusTempList = [];
        this.workOrderStatusList = this.WO_StatusList;
        if (workOrderStatus == 'WORKORDER_OPEN') {
            toRemoveStatus = ['WORKORDER_OPEN', 'WORKORDER_INPROGRESS'];
        }
        // if (workOrderStatus == 'WORKORDER_INPROGRESS') {
        //     toRemoveStatus = ['WORKORDER_INPROGRESS', 'WORKORDER_HOLD', 'WORKORDER_COMPLETED'];
        // }
        if (workOrderStatus == 'WORKORDER_INPROGRESS') {
            toRemoveStatus = ['WORKORDER_INPROGRESS'];
        }
        // if (workOrderStatus == 'WORKORDER_HOLD') {
        //     toRemoveStatus = ['WORKORDER_HOLD', 'WORKORDER_INPROGRESS', 'WORKORDER_COMPLETED'];
        // }
        if (workOrderStatus == 'WORKORDER_HOLD') {
            toRemoveStatus = ['WORKORDER_HOLD'];
        }
        // if (workOrderStatus == 'WORKORDER_COMPLETED') {
        //     toRemoveStatus = ['WORKORDER_COMPLETED', 'WORKORDER_CLOSED', 'WORKORDER_REOPEN'];
        // }
        if (workOrderStatus == 'WORKORDER_COMPLETED') {
            toRemoveStatus = ['WORKORDER_COMPLETED'];
        }
        if (workOrderStatus == 'WORKORDER_CLOSED') {
            toRemoveStatus = ['WORKORDER_CLOSED'];
        }
        if (workOrderStatus == 'WORKORDER_REOPEN') {
            toRemoveStatus = ['WORKORDER_REOPEN', 'WORKORDER_INPROGRESS'];
        }
        if (workOrderStatus == 'WORKORDER_DRAFT') {
            toRemoveStatus = ['WORKORDER_DRAFT'];
        }
        toRemoveStatus.forEach(item => {
            workOrderStatusTempList.push(this.workOrderStatusList.find(data => data.code == item));
        });

        this.workOrderStatusList = [];
        this.workOrderStatusList = workOrderStatusTempList;
        this.filteredWorkOrderStatusList = this.addWorkOrderForm.controls['statusFilterCtrl'].valueChanges.
            pipe(
                startWith(''),
                map(value => {
                    return this.commonService.dataFilter(value, this.workOrderStatusList)
                })
            );
    }

    editWorkOrder() {
        const body = this.createBody();
        if (this.additionalFields.length) {
            this.additionalFields.forEach(item => {
                body[item.fieldCode] = this.addWorkOrderForm.controls[item.fieldCode].value;
            })
        }
        if (this.isEdit)
            if ((this.workOrderStatus != 'WORKORDER_REOPEN' && this.addWorkOrderForm.controls.status.value && this.addWorkOrderForm.controls.status.value.code == 'WORKORDER_REOPEN') && (moment(moment(new Date(this.addWorkOrderForm.controls.startDate.value)).format('DD-MM-YYYY'), "DD-MM-YYYY").valueOf() == moment(moment().format('DD-MM-YYYY'), "DD-MM-YYYY").valueOf()) && !(moment(moment(this.addWorkOrderForm.controls.startTime.value, 'hh:mm A').format('hh:mm A'), "hh:mm A").valueOf() >= moment(moment().format('hh:mm A').valueOf(), "hh:mm A").valueOf())) {
                this.addWorkOrderForm.controls['startTime'].setErrors({ 'incorrect': true });
                this.addWorkOrderForm.controls['startTime'].markAsTouched();
                this.currentTime = moment().format('hh:mm A');
                return
            }
        this.loaderService.show();
        this.workOrderService.editWorkOrder(body, this.workOrderId).subscribe(res => {
            this.loaderService.hide();
            this.successSnackBar(res['description']);
            if(this.washroomCategory)
            {
                this.router.navigate(['/admin/hygiene/work-order/swh']);
            }
            else{
                this.router.navigate(['/admin/hygiene/work-order/hyg']);
            }
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    getFormControlList(result?) {
        this.loaderService.show();
        this.additionalFields = [];
        this.configurationFormFieldService.getListing(environment.hygieneApiUrl, 'hygiene_calender').subscribe((res: FormFieldListModel) => {
            this.loaderService.hide();
            res.data.forEach(item => {
                if (item.hidden) {
                    let control = new FormControl('');
                    if (item.isMandatory || item.fieldValidation) {
                        if (item.isMandatory && !item.fieldValidation)
                            control.setValidators(Validators.required);
                        else if (!item.isMandatory && item.fieldValidation) {
                            control.setValidators(Validators.maxLength(item.fieldValidation))
                        } else {
                            control.setValidators([Validators.required, Validators.maxLength(item.fieldValidation)])
                        }
                    }
                    this.addWorkOrderForm.addControl(item.fieldCode, control);
                    this.additionalFields.push(item);
                    if (this.workOrderStatus == 'WORKORDER_CLOSED' || this.workOrderStatus == 'WORKORDER_COMPLETED') {
                        this.addWorkOrderForm.disable();
                        this.addWorkOrderForm.controls.status.enable();
                        this.addWorkOrderForm.controls.statusFilterCtrl.enable();
                        // this.addWorkOrderForm.controls.comment.enable();
                    }
                }
            })
            if (result) {
                this.additionalFields.forEach(item => {
                    if (item.hidden) {
                        this.addWorkOrderForm.get(item.fieldCode).patchValue(result.data[item.fieldCode]);
                    }
                })
            }
        }, error => {
            this.loaderService.hide();
            this.errorSnackBar(error);
        })
    }

    closeAccordion() {
        this.isAccordionOpen = !this.isAccordionOpen;
    }

    createWorkOrder() {
        if(this.washroomCategory)
        {
            this.router.navigate(['admin/hygiene/work-order/add-work-order/swh/' + this.workOrderId]);
        }
        else{
            this.router.navigate(['admin/hygiene/work-order/add-work-order/hyg/' + this.workOrderId]);
        }
    }

    cancel() {
        
        if(this.washroomCategory)
        {
            this.router.navigate(['/admin/hygiene/work-order/swh']);
        }
        else{
            this.router.navigate(['/admin/hygiene/work-order/hyg']);
        }
    }

    public getSantizeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    objectComparisonFunction(option, value) {
        return option.id === value.id;
    }

    statusComparisonFunction(option, value) {
        return option.code === value.code;
    }

    searchInputChanged() {
        this.commonService.searchText = this.searchText;
    }

    searchItem() {
        this.commonService.sendFilterCloseEvent();
        this.commonService.searchText = this.searchText.trim();
        this.commonService.sendSearchEvent();
    }
}
