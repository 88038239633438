import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/app/config/environments/environment';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';

@Injectable({
  providedIn: 'root'
})
export class CMReportService {

  constructor(private http: HttpClient, private router: Router, private commonService: CommonServiceService) { }

  //GET Threshold value BY Customer & romm type ID
  getIAQITrend(body)
  {
    return this.http.get(`${environment.cmUrl}api/v1/aqindex/getbuildingindex?branchId=${body.branchId}&fromDate=${body.startDate}&toDate=${body.endDate}&customerId=${body.customerId}`);
  }

      //Device health REPORT

      getDeviceHealthReport(pageInfo:PaginationModel, filterData){
        let params = new HttpParams();
        if (filterData.branch) {
          params = params.append('branchId', filterData.branch)
        }
        if (filterData.building) {
          const buildingIds = filterData.building.join(',');
          params = params.append('buildingId', buildingIds)
        }
        if (filterData.floor) {
          const floorIds = filterData.floor.join(',');
          params = params.append('floorId', floorIds);
        }
        if (filterData.department) {
          const departmentIds = filterData.department.join(',');
          params = params.append('departmentId', departmentIds);
        }
        if (filterData.room) {
          const roomIds = filterData.room.join(',');
          params = params.append('roomId', roomIds);
        }
        if (filterData.startDate) {
          params = params.append('startDate', filterData.startDate);
        }
        if (filterData.endDate) {
          params = params.append('endDate', filterData.endDate);
        }
        if (filterData.customerId) {
          params = params.append('customerId', filterData.customerId);
        }
        params = params.append('cmsAllowedParameters', filterData.allowedParameters)
        
    
    
        return this.http.get(`${environment.cmUrl}api/v1/report/deviceHealthStatus?sortBy=${pageInfo.sortBy}&order=${pageInfo.sortDir}&page=${pageInfo.page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.commonService.searchText)}`, { params: params });
      }

      exportDeviceHealthReport(exportAs, pageInfo: PaginationModel, filterData: any = {}){
        let params = new HttpParams();
      if (filterData.branch) {
        params = params.append('branchId', filterData.branch)
      }
      if (filterData.building) {
        const buildingIds = filterData.building.join(',');
        params = params.append('buildingId', buildingIds)
      }
      if (filterData.floor) {
        const floorIds = filterData.floor.join(',');
        params = params.append('floorId', floorIds);
      }
      if (filterData.department) {
        const departmentIds = filterData.department.join(',');
        params = params.append('departmentId', departmentIds);
      }
      if (filterData.room) {
        const roomIds = filterData.room.join(',');
        params = params.append('roomId', roomIds);
      }
      if (filterData.startDate) {
        params = params.append('startDate', filterData.startDate);
      }
      if (filterData.endDate) {
        params = params.append('endDate', filterData.endDate);
      }
      if (filterData.customerId) {
        params = params.append('customerId', filterData.customerId);
      }

      params = params.append('cmsAllowedParameters', filterData.allowedParameters)
    
          let zoneId = JSON.parse(localStorage.getItem('timeZone')).zoneId;
          params = params.append('zoneId', zoneId);
          let timezone=JSON.parse(localStorage.getItem('timeZone')).timezone;
          params = params.append('timeZone', timezone);
      
          return this.http.get(`${environment.cmUrl}api/v1/report/deviceHealthStatus/download?sortBy=${pageInfo.sortBy}&order=${pageInfo.sortDir}`, { responseType: 'blob' as 'json', params: params });   
        
      }

      getBuildingListByBranch(customerId,branchId) {
        return this.http.get(`${environment.cmUrl}api/v1/facilitymapping/getLocations?branchId=${branchId}&customerId=${customerId}`);
      }

      getParameterComparisonTrend(startDate,endDate,floorId,customerId){
        return this.http.get(`${environment.cmUrl}api/v1/dashboard/hourlyParameterComparison?from=${startDate}&to=${endDate}&floorid=${floorId}&customerid=${customerId}`);
      }

      exportParameterComparisonDownload(startDate,endDate,floorId,customerId,parameter){
        return this.http.get(`${environment.cmUrl}api/v1/dashboard/hourlyParameterComparison/download?from=${startDate}&to=${endDate}&floorid=${floorId}&customerid=${customerId}&parameter=${parameter}`,{ responseType: 'blob' as 'json'});
      }

       //GET CUSTOMER LIST NOT IN ENTITY

  getCustomerListEntity() {
    return this.http.get(`${environment.customerApiUrl}api/v1/customers/approvedCustomerListing?page=0&searchText=&sortBy=&size=100&sortDirection=ASC&status=`);
  }

    //GET BRANCH LIST BY CUSTOMER
    getBranchByCustomer(customerId) {
      return this.http.get(`${environment.customerApiUrl}api/v1/branch/picklist?customerId=${customerId}`);
    }
  
    // GET LOCATION LIST BY BRANCH
    getLocationByCustomerBranch(customerId,branchId) {
      return this.http.get(`${environment.cmUrl}api/v1/facilitymapping/getLocations?customerId=${customerId}&branchId=${branchId}`);
    }
  
       //GET DEVICE HEALTH DETAILS
       getDeviceHealthTrendData(body) {
        return this.http.get(`${environment.cmUrl}api/v1/report/deviceHealthTrend?customerId=${body.customerId}&branchId=${body.branchId}&buildingId=${body.buildingId}&floorId=${body.floorId}&roomId=${body.roomId}&startDateTime=${body.startDate}&endDateTime=${body.endDate}`);
      }

      //Excel DEVICE HEALTH DETAILS
      exportDeviceHealthTrendData(body){
        return this.http.get(`${environment.cmUrl}api/v1/report/deviceHealthTrend/download?customerId=${body.customerId}&branchId=${body.branchId}&buildingId=${body.buildingId}&floorId=${body.floorId}&roomId=${body.roomId}&startDateTime=${body.startDate}&endDateTime=${body.endDate}`,{ responseType: 'blob' as 'json'});
      }

      //Excel all DEVICE HEALTH DETAILS
      exportAllDeviceHealthTrendData(body){
        return this.http.get(`${environment.cmUrl}api/v1/report/getAllDevicesIaq/download?customerId=${body.customerId}&startDateTime=${body.startDate}&endDateTime=${body.endDate}&cmsAllowedParameters=${body.allowedParam}`,{ responseType: 'blob' as 'json'});
      }

      //mIS
      getMISReportData(body) {
        return this.http.get(`${environment.cmUrl}api/v1/report/getMISReport?customerId=${body.customerId}&branchId=${body.branchId}&buildingId=${body.buildingId}&startDateTime=${body.startDate}&endDateTime=${body.endDate}`);
      }

      //IAQ parameter report pdf
      getMonthlyIAQParamReportData(body) {
        return this.http.get(`${environment.cmUrl}api/v1/report/getParameterReport?customerId=${body.customerId}&branchId=${body.branchId}&buildingId=${body.buildingId}&floorId=${body.floorId}&startDateTime=${body.startDate}&endDateTime=${body.endDate}`);
      }

      //cms data min, max, avg

      exportCmsDataReport(body){
        return this.http.get(`${environment.cmUrl}api/v1/data/downloadProcessedData?customerId=${body.customerId}&branchId=${body.branchId}&buildingId=${body.buildingId}&floorId=${body.floorId}&roomId=${body.roomId}&startDateTime=${body.startDate}&endDateTime=${body.endDate}&interval=${body.interval}`,{ responseType: 'blob' as 'json'});
      }


}
