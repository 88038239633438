import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NameValidator } from 'src/app/modules/shared/validators/name.validator';
import { WashroomCalculatorServiceService } from './washroom-calculator-service.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';

@Component({
  selector: 'app-washroom-calculator',
  templateUrl: './washroom-calculator.component.html',
  styleUrls: ['./washroom-calculator.component.scss']
})
export class WashroomCalculatorComponent implements OnInit {

  constructor(public fb:FormBuilder,public service:WashroomCalculatorServiceService,
    private toastService: ToastService,
    private loaderService: LoaderService,
  ) { }

  infoForm:FormGroup;
  washroomForm:FormGroup;
  filteredCountryCode: any;
  countryCodeData: any = null;
  showCalculator:boolean=false;
  configData:any={};

  ngOnInit() {
    this.getconfigData();
    this.getCountryCode();
    this.initializeForm();

    this.infoForm.get('countryCode')
    .valueChanges.subscribe((res: any) => {
      if (this.countryCodeData) {

        this.filteredCountryCode = this.countryCodeData.filter(
          (option: any) => {
            return (
              `${option.name}`
                        .toLowerCase()
                        .indexOf(res.toLowerCase()) > -1 || `${option.countryCode}`
                        .toLowerCase()
                        .indexOf(res.toLowerCase()) > -1
            );
          }
        );
      }
    });
  }

  getCountryCode(){
    this.service.getCountryCode().subscribe((data:any) => {
      this.filteredCountryCode = this.countryCodeData = data;
    });
  }

  initializeForm() {
    this.infoForm = this.fb.group({
      name: [
        '',
        [
          Validators.required, NameValidator
        ],
      ],
      mobile: ['', [Validators.required, Validators.pattern('^([5-9]{1})([0-9]{5,12})$')]],
      email: [
        '',
        [
          Validators.required,
          NameValidator,
          Validators.maxLength(100),
          Validators.minLength(5),
        ],
      ],
      countryCode:['91',[]]
    });

    this.washroomForm = this.fb.group({
      noOfShifts: ['',[Validators.required]],
      users: ['',[Validators.required]],
      workHour: ['',[Validators.required]],
      workingDays: ['',[Validators.required]],
      noOfWashroomMen: ['',[Validators.required]],
      noOfWashroomWomen: ['',[Validators.required]],
      touchUpCleaning: ['',[Validators.required]],
      deepCleaning: ['',[Validators.required]],
      floorArea: ['',[Validators.required]],
      manpowerCost: ['',[Validators.required]],
      waterRate: ['',[Validators.required]]
    });
  }

  getconfigData() {
    this.configData = {};
    this.service.getCalculatorConfigData().subscribe((res: any) => {
        this.configData = res.data;
    }, (error) => {
      this.configData = {};
    });
  }

  validateConfigData(){
    if(this.configData && this.configData.fixtures){
    let fixtures= this.configData.fixtures.length?this.configData.fixtures.filter(el=>el.washroomType!=='common').filter(el=> !el.customerValue):[];
    let consumables= this.configData.consumables.length?this.configData.consumables.filter(el=> !el.customerValue):[]
    console.log(fixtures,consumables,(fixtures.length>0 || consumables.length>0)?false:true);
    return (fixtures.length>0 || consumables.length>0)?false:true;
    }
    else{
      return false;
    }
  }

  result:any={};
  submit(){
    this.loaderService.show();
    let body={...this.infoForm.value,...this.washroomForm.value,...this.configData}
    this.service.washroomCalculate(body).subscribe((res: any) => {
      this.loaderService.hide();
      this.result=res.data;
  }, (error) => {
    this.loaderService.hide();
    this.errorSnackbar(error);
  });
  }

  errorSnackbar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error }, type: 'error' },
      ErrorToasterComponent
    );
  }


}
