import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonServiceService } from '../../../../../../../src/app/core/common-service.service';
import { PaginationModel } from '../../../../../../../src/app/modules/shared/model/pagination-model';
import { environment } from '../../../../../../../src/app/config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmartWashroomDashboardServiceService {

  constructor(private http: HttpClient,public common: CommonServiceService) { }

  //VERIFY PASSCODE 
  verifyPasscode(body) {
    return this.http.post(`${environment.hygieneApiUrl}api/v1/swpasscode`, body);
  }

  //GET PASSCODE DATA
  getPasscodeDetails(customerId) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swpasscode/${customerId}`);
  }

    //GET Facility
    getFacilityList(customerId, branchId) {
      return this.http.get(`${environment.hygieneApiUrl}api/v1/swfacilitymapping/getFacilityList?customerid=${customerId}&branchid=${branchId}`);
    }

  //GET BUILDING
  getBuilding(customerId, branchId) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swfacilitymapping/getBuildingList?customerid=${customerId}&branchid=${branchId}`);
  }

  //GET BUILDING WISE STATUS
  getBuildingWiseStatus(customerId, branchId,buildingId,currentStartDateTime,currentEndDateTime,floorId) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/washroomStatus?customerId=${customerId}&branchId=${branchId}&buildingId=${buildingId}&currentStartDateTime=${currentStartDateTime}&currentEndDateTime=${currentEndDateTime}&floorId=${floorId}`);
  }

  //GET BUILDING WISE Peak Traffic
  getBuildingWisePeakTraffic(customerId, branchId,buildingId,currentStartDateTime,currentEndDateTime,floorId) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/peakTraffic?customerId=${customerId}&branchId=${branchId}&buildingId=${buildingId}&startDateTime=${currentStartDateTime}&endDateTime=${currentEndDateTime}&floorId=${floorId}`);
  }

  //GET Floor
  getFloor(customerId, branchId, buildingId) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swfacilitymapping/getFloorList?customerid=${customerId}&branchid=${branchId}&buildingid=${buildingId}`);
  }

  //GET Department
  getDepartment(customerId, branchId, buildingId, floorId) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swfacilitymapping/getDepartmentList?customerid=${customerId}&branchid=${branchId}&buildingid=${buildingId}&floorid=${floorId}`);
  }

  //GET Room
  getRoom(customerId, branchId, buildingId, floorId, departmentId) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swfacilitymapping/getRoomList?customerid=${customerId}&branchid=${branchId}&buildingid=${buildingId}&floorid=${floorId}&departmentid=${departmentId}`);
  }

    //GET Room
    getFeedbackRoom(roomId) {
      return this.http.get(`${environment.customerApiUrl}api/v1/room/${roomId}`);
    }

  //Tablet dashboard Get Data
  getTabletDashboardData(body) {
    return this.http.post(`${environment.hygieneApiUrl}api/v1/swdashboard/tabdashboard`,body);
  }

   //Dashboard Get Data
   getOverviewData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/overview/count?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

   getOverviewTrendData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/overview/trend?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

  getFootfallData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/footfall?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

  getFeedbackData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/feedback?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

  getComplaintsData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/complaints?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

  getWorkOrderData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/workorder?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

  getOdourLevelData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/odour/data?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

  getOdourLevelTrendData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/odour/trend?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

  getOdourLevelRunHourData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/odour/runhour?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

  getConsumableData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/v1/consumables?branchId=${data.branchId}&buildingId=${data.buildingId}&floorId=${data.floorId}&departmentId&roomId=${data.roomId}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&currentStartDateTime=${data.currentStartDateTime}&currentEndDateTime=${data.currentEndDateTime}&customerId=${data.customerId}`);
  }

  getDeviceHealthData(data) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/swdashboard/devicehealth?customerId=${data.customerId}&buildingId=${data.buildingId}`);
  }

  getDeviceHealthReport(room,startDate,endDate,customerId){
    let params = new HttpParams();
    
    if (room) {
      params = params.append('roomId', room);
    }
    if (startDate) {
      params = params.append('startDate', startDate);
    }
    if (endDate) {
      params = params.append('endDate', endDate);
    }
    if (customerId) {
      params = params.append('customerId', customerId);
    }


    return this.http.get(`${environment.hygieneApiUrl}api/v1/swreport/deviceHealthStatus?sortBy=&order=&page=0&size=&searchText=`, { params: params });
  }

}
