import { Component, LOCALE_ID, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { HttpHandlerProvider } from '../core/http-handler.provider';
import { LoginService } from '../modules/shared/login/service/login.service';
import { error } from 'protractor';
import { CommonService } from '../modules/employee/shared/services/common.service';
import { CommonServiceService } from '../core/common-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.html',
  styleUrls: ['./app.scss']
})

export class AppComponent implements OnInit {

  title = 'Caleedo';              // title of peoject
  languageList = [{ code: 'en', label: 'English' }, { code: 'es', label: 'Spanish' }];
  isActive = false;  // for toggle of menu
  isLogged = false;  // login status of user
  res = [];
  r = false;
  show = false;
  credentials;
  setTimeOutSubscriber =[];

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    public httpHandlerProviderService: HttpHandlerProvider,
    public common: CommonServiceService,
    public loggingIn: LoginService) {
  }
  ngOnInit() {
    this.isLogged = this.httpHandlerProviderService.isLogged;
    this.isLoggedInUser();
  }
  isLoggedInUser() {
    const val = localStorage.getItem('isLogged');
    return true ? val : false;
  }

}
