import { Component, OnInit,Input } from '@angular/core';
import { CarousalService } from 'src/app/modules/shared/services/carousal.service';
import * as moment from 'moment';
@Component({
  selector: 'app-dashboard-weather-updates',
  templateUrl: './dashboard-weather-updates.component.html',
  styleUrls: ['./dashboard-weather-updates.component.scss']
})
export class DashboardWeatherUpdatesComponent implements OnInit {
  @Input() slideNumber;
  @Input() location;
  @Input() airPollutionData;
  @Input() currentWeatherData:any={};
  @Input() weatherBackgroundImage = 'No_Data.jpg';
  @Input() weatherPrecipitation;
  @Input() hideAddressStrip:Boolean = false;
  constructor( private carousalService: CarousalService,
    ) { }

  ngOnInit() {
  }

  getIcon(){
    return `http://openweathermap.org/img/wn/${this.currentWeatherData.weather_icon}@2x.png`;
  }

}
