import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { environment } from 'src/app/config/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {
  selectedTab = 0;
  checkedIds: any = [];
  isPrinting = false;
  location = {};
  constructor(private http: HttpClient,
    private router: Router,
    private common: CommonServiceService,
    private loader: LoaderService) { }


  printDocument(documentName: string, documentData: string[],type?) {
    this.isPrinting = true;
    if(type && type==='Feedback')
    {
      
      this.router.navigate(['admin/facility/print-qr-code',
      {
        outlets: {
          'print': ['print', documentName,'feedback', documentData.join()]
        }
      }]);
    }
    else if(type && type==='Ticket')
    {
      this.router.navigate(['admin/facility/print-qr-code',
      {
        outlets: {
          'print': ['print', documentName,'ticket', documentData.join()]
        }
      }]);
    }
    else if(type && type==='check-in')
    {
      
      this.router.navigate(['admin/facility/print-qr-code',
      {
        outlets: {
          'print': ['print', documentName,type, documentData.join()]
        }
      }]);
    }
    else if(type && type==='check-out')
    {
      
      this.router.navigate(['admin/facility/print-qr-code',
      {
        outlets: {
          'print': ['print', documentName,type, documentData.join()]
        }
      }]);
    }
    else{
    this.router.navigate(['admin/facility/print-qr-code',
      {
        outlets: {
          'print': ['print', documentName, documentData.join()]
        }
      }]);
    }
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate(['admin/facility']);
    }, 2000);
  }

  getBranchDetail(branchId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/branch/${branchId}`);
  }

  getBuildingDetail(buildingId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/building/${buildingId}`);
  }

  getFloorDetail(floorId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/floor/${floorId}`);
  }

  getDepartmentDetail(deptId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/department/${deptId}`);
  }

  getRoomDetail(roomId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/room/${roomId}`);
  }

  downloadTemplate(countryId) {
    const industryId = localStorage.getItem('industryId');
    return this.http.get(`${environment.customerApiUrl}api/v1/bulk-upload/template?industryId=${industryId}&countryId=${countryId}`, { responseType: 'blob' as 'json' });
  }

  bulkUploadFile(formData) {
    const industryId = localStorage.getItem('industryId');
    return this.http.post(`${environment.customerApiUrl}api/v1/bulk-upload/validate?industryId=${industryId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, observe: 'response', responseType: 'blob' as 'json' });
  }

  uploadBulk(bulkUploadId, formData?) {
    const industryId = localStorage.getItem('industryId');
    return this.http.post(`${environment.customerApiUrl}api/v1/bulk-upload/${bulkUploadId}?industryId=${industryId}`, {});
  }

  getAllBranch() {
    return this.http.get(`${environment.authApiUrl}/api/v1/users/getAllBranch`);
  }
}

