import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { common } from 'src/app/config/enum';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { DialogService } from 'src/app/core/dialog.service';
import { MyAccountService } from 'src/app/modules/admin/modules/my-account/service/my-account.service';
import { LoaderService } from '../../services/loader.service';
import { ToastService } from '../../services/toaster.service';
import { ErrorToasterComponent } from '../error-toaster/error-toaster.component';
import { ToasterComponent } from '../toaster/toaster.component';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit, OnDestroy {
  imageSubscription: Subscription;
  // tslint:disable-next-line: ban-types
  @Output() imageupload = new EventEmitter();
  message: string;
  show = false;
  constructor(
    private dialog: DialogService,
    private loaderService: LoaderService,
    private commonService: CommonServiceService,
    private toastService: ToastService,
    public myAccountService: MyAccountService
  ) {
    this.imageSubscription = this.commonService.getSavedProfileImage().subscribe(data => {
      if (this.commonService.savedprofileImage) {
        this.url = 'data:image/png;base64,' + this.commonService.savedprofileImage;
      }
    });
  }
  imageChangedEvent: any = '';
  croppedImage: any = '';
  // tslint:disable-next-line: max-line-length
  url: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (event != null) {
      if (this.imageChangedEvent.target.files[0].size / 1000 < common.ImageSize) {
        this.imageCropped(event);
        this.loaderService.show();
      }
      else {
        this.openSnackBar('Image Size/Resolution Not Supported(Max Size: 5MB (1380x770 pixels))');
        this.url = '';
      }
    }
  }
  
  imageCropped(event: ImageCroppedEvent) {
    const title = 'upload';
    this.loaderService.hide();
    this.dialog.openConfirmDialog(title, this.imageChangedEvent)
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.croppedImage = event.base64;
          this.url = response;
          if ('C' == this.url.charAt(this.url.length - 1)) {
            this.url = this.url.slice(0, this.url.length - 1) + '=';
          }
          // const imageSize = ((3 * (this.url.length / 4)) / 1000);
          // if (imageSize > common.ImageSize) {
          //   this.openSnackBar(this.message, 'Imagesize-not-supported');
          //   this.url = '';
          // }
          this.imageupload.emit(response);
        }
        this.loaderService.hide();
      });
  }

  delete() {
    if (this.url !== '') {
      const title = 'Image';
      this.dialog.openConfirmDialog(title, name).afterClosed().subscribe(res => {
        //
        if (res) {
          this.url = '';
          this.imageupload.emit(this.url);
        } else {
        }
      });
    }
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  ngOnInit() {
    this.myAccountService.profileEdit.subscribe(() => {
      this.edit();
    });
    this.commonService.profileEditOnClick.subscribe((data) => {
      this.edit1();
    });
  }

  edit1() {
    if (!this.commonService.profileEditing) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  edit() {
    if (!this.myAccountService.editing) {
      this.show = false;
    } else {
      this.show = true;
    }
    // this.cdr.detectChanges();
  }


  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }
  openSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  ngOnDestroy() {
    this.imageSubscription.unsubscribe();
  }
}
