export class PaginationModel {
  sortDir: string;
  constructor() {
    this.sortDir = 'ASC';
    this.sortBy = '';
    this.order = 'ASC';
    this.by = '';
    this.atv = true;
    this.rec = 'WEEKLY'
  }
  public page: number;
  public TotalRecord: number;
  public size: number;
  public totalPages: number;
  public sortBy?: string;
  public atv: boolean;
  public by?: string;
  public order?: 'ASC' | 'DESC';
  public rec:string;
}
