import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import {
  CustomerStatusFilter, selectedModel
} from '../../models/filter-operations';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';

enum FilterType {
  CustomerCode = 1,
  status
}
@Component({
  selector: 'app-filter-view',
  templateUrl: './filter-view.html',
  styleUrls: ['./filter-view.scss']
})

export class CategoryFilterViewComponent implements OnInit, OnChanges {
  FilterType = FilterType;
  pageInfo: PaginationModel = new PaginationModel();
  customerStatusResponse: Array<CustomerStatusFilter> = new Array<CustomerStatusFilter>();
  searchText: string;
  selectedFilterType: number;
  selectedKeys: selectedModel = new selectedModel();
  @Output() valueChange = new EventEmitter();
  @Output() closeFilterEventWithoutDataChange = new EventEmitter();
  @Output() closeFilterEvent = new EventEmitter();
  @Input() previousFilterValues: any;
  status: any;
  statusResponse = [];
  statusArray: any;
  constructor(
    private toast: ToastService,
  ) {
    this.pageInfo.size = 5;
    this.pageInfo.page = 0;
    this.searchText = '';
  }

  ngOnInit() {
    this.getData(FilterType.status);
  }
  ngOnChanges() {
    if (this.previousFilterValues != null || this.previousFilterValues != undefined) {
      this.statusArray = this.previousFilterValues;
      this.searchFilter();
    }
  }

  searchFilter() {
    this.selectedFilterType = this.FilterType.status;
  }

  selectedInput() {
    this.getData(this.selectedFilterType);
  }

  getData(filterType) {
    this.pageInfo.page = 0;
    this.pageInfo.size = 5;
    this.statusResponse = [
      {
        id: 0,
        name: 'Initiated',
        status: false
      },
      {
        id: 1,
        name: 'Failure',
        status: false
      }
    ];
    if (this.previousFilterValues === false || this.previousFilterValues === true) {
      this.previousFilterValues ? this.statusResponse[0].selected = true : this.statusResponse[1].selected = true;
    }
    if (filterType === this.FilterType.status) {
      this.searchFilter();
      return;
    }
  }

  selectedItem(item: any, type) {
    if (FilterType.status === type) {
      const index = this.statusResponse.findIndex(item => item.selected === true);
      if (index !== -1) {
        this.statusResponse[index].selected = false;
      }
      if (this.statusArray != undefined && this.statusArray != null && this.statusArray == !(!!item.id)) {
        const index = this.statusResponse.findIndex(item => this.statusArray == !(!!item.id));
        this.statusResponse[index].selected = false;
        this.statusArray = null;
      } else {
        this.statusArray = !(!!item.id);
        const index = this.statusResponse.findIndex(item => this.statusArray == !(!!item.id));
        this.statusResponse[index].selected = true;
      }

      return;
    }
  }
  filter() {
    this.valueChange.emit(this.statusArray);
  }
  close() {
    this.statusArray = null;
    this.filter();
  }
  openSnackBar(message) {
    this.toast.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }
  errorSnackBar(error) {
    this.toast.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

}
