import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/config/environments/environment';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';

@Injectable({
    providedIn: 'root'
})
export class ReasonService {
    constructor(private http: HttpClient, private common: CommonServiceService, private loader: LoaderService) { }

    reasonList(branchId, customerId, pageInfo?: PaginationModel, body?) {
        // return this.http.post(`${environment.patientApiUrl}api/v1/patient/patientListing?flag=None&customerId=${customerId}&page=${pageInfo.
        //     page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`, body);
        return this.http.get(`${environment.patientApiUrl}api/v1/reasons?branchId=${branchId}&customerId=${customerId}`);
    }

    getReasonById(reasonId) {
        return this.http.get(`${environment.patientApiUrl}api/v1/reasons/${reasonId}`);
    }

    addReason(body) {
        return this.http.post(`${environment.patientApiUrl}api/v1/reasons`, body);
    }

    updateReason(body, reasonId) {
        return this.http.put(`${environment.patientApiUrl}api/v1/reasons/${reasonId}`, body);
    }
}