import { Component, OnInit, Input, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'read-more',
  template: `
      <div [class.collapsed]="!isCollapsed" [class.not-collapsed]="isCollapsed">
          <ng-content></ng-content>
      </div>
      <a href="javascript:void(0)"  (click)="isCollapsed = !isCollapsed">Read {{!isCollapsed ? 'More' : 'Less'}}</a>

  `,
  styles: [`
      div.collapsed {
          height: 20px;
          overflow: hidden;

      }
      div.not-collapsed {
      }
  `]
})
export class ReadMoreComponent implements AfterViewInit {


  // the text that need to be put in the container
  @Input() text: string;

  // maximum height of the container
  @Input() maxHeight: number = 100;

  // set these to false to get the height of the expended container
  public isCollapsed: boolean = false;
  public isCollapsable: boolean = false;

  constructor(private elementRef: ElementRef, private cdRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    let currentHeight = this.elementRef.nativeElement.getElementsByTagName('div')[0].offsetHeight;
    // collapsable only if the contents make container exceed the max height
    if (currentHeight > this.maxHeight) {
      this.isCollapsed = true;
      this.isCollapsable = true;
      this.cdRef.detectChanges();

    }
  }
  // ngAfterViewChecked() {
  //   let show = this.isShowExpand();
  //   if (show != this.show) { // check if it change, tell CD update view
  //     this.show = show;
  //   }
  // }

}
