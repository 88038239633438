import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
// import { ReasonService } from '../../../service/patient.service';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PatientListingModel, ReasonListingModel } from '../../../model/patient-list.model';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ReasonService } from '../../../service/reason.service';

@Component({
  selector: 'app-reason-list-view',
  templateUrl: './reason-listview.html',
  styleUrls: ['./reason-listview.scss']
})

export class ReasonListViewComponent implements OnInit, OnDestroy {
  @Input() showMobileTable: boolean;
  @Input() filterValueChange: object;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  public pageInfo: PaginationModel = new PaginationModel();
  element: any = [];
  noResult: boolean = false;
  searchEventSubscription: Subscription;
  saveSearch: any = '';
  message;
  data;
  showPagination = true;
  checkedPatientsId: any = [];
  headCheckBox;
  bulkActionDropDown: any = '';
  allChecked = false;
  pages;
  private subscriptions: Subscription[] = [];
  patientCodeArray: any = [];
  departmentArray: any = [];
  branchArray: any = [];
  reportingManagerArray: any = [];
  pageSizeArray = [10, 50, 100];
  patientStatusArray: any = [];
  associatedwith: any = 'user';
  permissionsObject = { read: false, create: false, update: false, delete: false };
  pageSize: number = 10;
  branchIds = localStorage.getItem('branchIds') ? JSON.parse(localStorage.getItem('branchIds')) : [];
  branchId = localStorage.getItem('branchId');
  // departmentIds = JSON.parse(localStorage.getItem('departmentIds'));
  customerId = localStorage.getItem('customerId')
  @HostListener('click') onMouseEnter() {
    this.commonService.sendFilterCloseEvent();
  }
  constructor(
    public router: Router,
    private reasonService: ReasonService,
    public commonService: CommonServiceService,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    private dialog: DialogService,
    private loaderService: LoaderService,
    public matDialog: MatDialog) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
  }
  pageSizeChange(event) {
    if (event.value) {
      this.pageInfo.page = 0;
      this.pageInfo.size = event.value;
      this.listing();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_BLACKLIST_REASON');

    this.headCheckBox = false;
    this.subscriptions.push(this.commonService.getSearchEvent().subscribe(() => {
      this.search();
    }));
    this.showPagination = true;
    this.commonService.head = '';
    this.listing();
  }

  mapData(item, headers) {
    return {
      heading: headers,
      content: item
    };
  }

  allCheckbox(event: any) {
    const all = event.target.checked;
    // select all patients
    if (all) {
      this.checkedPatientsId = [];
      this.element.forEach(element => {
        this.checkedPatientsId.push(element.id);
      });
    } else {
      this.checkedPatientsId = [];
    }
    this.element.forEach(element => {
      element.selected = all;
    });
  }

  checkbox(event: any, i: any) {
    let flag = false;
    for (let j = 0; j < this.pageInfo.size; j++) {
      if (this.checkedPatientsId[j] === i) {
        this.checkedPatientsId.splice(this.checkedPatientsId.indexOf(i), 1);
        flag = true;
      }
    }
    if (!flag) {
      this.checkedPatientsId.push(i);
    }
    this.element.forEach(elm => {
      if (elm.id === i) {
        elm.selected = true;
      }
    });
  }

  SetPage(event: any) {
    this.pageInfo.page = event.page;
    this.pageInfo.size = event.size;
    this.listing();
  }

  search() {
    if (this.saveSearch !== '') {
      this.commonService.searchText = this.saveSearch;
      this.saveSearch = '';
    }
    this.showPagination = true;
    this.pageInfo.page = 0;
    this.listing();
  }

  sort(colName: string, sortDirection: any) {
    if (this.pageInfo.sortBy === colName) {
      if (sortDirection == 'ASC') {
        this.commonService.head = '';
        this.commonService.sort = 'DESC';
        this.pageInfo.sortBy = '';
        this.pageInfo.sortDir = 'DESC';
      } else {
        this.commonService.sort = sortDirection;
        this.pageInfo.sortDir = sortDirection;
      }
    } else {
      this.commonService.head = colName;
      this.commonService.sort = 'ASC';
      this.pageInfo.sortBy = colName;
      this.pageInfo.sortDir = 'ASC';
    }
    this.listing();
  }

  // --------pagination------------------------------------------------------------------------------//


  listing() {
    // const body = this.mapKeysOfFilter();
    let body;
    this.branchIds.length ? body = { branch: this.branchIds } : body = {}
    this.headCheckBox = false;
    this.checkedPatientsId = [];
    // body = { branch: this.branchIds }
    // const body = { }
    this.loaderService.show();
    this.noResult = true;
    this.reasonService.reasonList(this.branchId, this.customerId, this.pageInfo, body)
      .subscribe((response: ReasonListingModel) => {
        this.loaderService.hide();
        // this.pageInfo.TotalRecord = response.data.totalElements;
        // this.pageInfo.totalPages = response.data.totalPage;
        // if (response.data.payload.length) {
        if (response.data.length) {
          this.noResult = false;
          this.element = response.data
          this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
        }
        else {
          this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
          this.noResult = true;
        }
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      })
  }

  editReason(id: any) {
    this.router.navigate(['admin/patient-list/add-blacklist-reason/' + id]);
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(message: string, panelClass: string) {
    if (panelClass === 'delete-success') {
      this.toastService.opentoast({ toastName: 'deletePatientSuccess', data: { name: '' } });
    } else if (panelClass === 'Error-Code-400') {
      this.toastService.opentoast({ toastName: 'error400', data: { name: '' } });
    } else if (panelClass === 'Error-Code-401') {
      this.toastService.opentoast({ toastName: 'error401', data: { name: '' } });
    } else if (panelClass === 'Error-Code-404') {
      this.toastService.opentoast({ toastName: 'error404', data: { name: '' } });
    } else if (panelClass === 'Error-Code-409') {
      this.toastService.opentoast({ toastName: 'emplisterror409', data: { name: '' } });
    } else if (panelClass === 'Error-Code-500') {
      this.toastService.opentoast({ toastName: 'error500', data: { name: '' } });
    } else if (panelClass === 'BulkUpdateSuccess') {
      this.toastService.opentoast({ toastName: 'BulkUpdateSuccess', data: { name: '' } });
    }

  }




}
