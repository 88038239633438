import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WashroomCalculatorServiceService {

  constructor(private http: HttpClient) { }

  //GET Country Code 
  getCountryCode() {
    return this.http.get(`${environment.customerApiUrl}api/v1/configs/countries`);
  }

  getCalculatorConfigData() {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/washroom-calculator/getFormFields`);
  }

  //UPDATE THRESHOLD DATA
  washroomCalculate(body) {
    return this.http.post(`${environment.hygieneApiUrl}api/v1/washroom-calculator/calculate`,body);
  }
}
