import { Component, ElementRef, forwardRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonServiceService } from '../../../../core/common-service.service';
import { PasswordValidator } from '../../validators/password.validator';

export interface PasswordFormValues {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.html',
  styleUrls: ['./confirm-password.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfirmPasswordComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConfirmPasswordComponent),
      multi: true
    }
  ]
})
export class ConfirmPasswordComponent implements OnInit, OnDestroy {
  title = 'Caleedo System';
  resetForm: FormGroup;
  message;
  key: any;
  email: any;
  check6Char: boolean = false;
  check1Num: boolean = false;
  check1Upper: boolean = false;
  check1Lower: boolean = false;
  check1SpecialChar: boolean = false;
  checkPasswordMatch: boolean = false;
  checkLength: boolean = false;
  password1: any;
  password2: any;
  subscriptions: Subscription[] = [];
  fieldTextType: boolean = false;
  fieldTextType2: boolean = false;
  constructor(
    public router: Router,
    private fb: FormBuilder,
    public commonService: CommonServiceService,
    public snackBar: MatSnackBar,
    private el: ElementRef) {
  }

  get value(): PasswordFormValues {
    return this.resetForm.value;
  }

  set value(value: PasswordFormValues) {
    this.resetForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get passwordControl() {
    return this.resetForm.controls.password1;
  }

  get confirmPasswordControl() {
    return this.resetForm.controls.password2;
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
      event.preventDefault();

    }, 1)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.resetForm.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.resetForm.valid ? null : { passwords: { valid: false } };
  }

  ngOnInit() {
    this.resetForm = this.fb.group({
      password1: ['', [Validators.required, PasswordValidator, Validators.maxLength(32)]],
      password2: ['', [Validators.required, PasswordValidator, Validators.maxLength(32)]]
    }, { validator: this.PasswordMatch });

    this.subscriptions.push(
      this.resetForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  PasswordMatch(group: FormGroup): { [key: string]: boolean } | null {
    if (group.value.password1 != group.value.password2) {
      return { 'matchPassword': true };
    }
    return null;
  }
}
