import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PatientService } from '../../service/patient.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';


// ----------component--------------------------------------------------------------------------------------//
@Component({
  selector: 'app-blacklist-visitor',
  templateUrl: './blacklist-visitor.html',
  styleUrls: ['./blacklist-visitor.scss']
})

export class BlackListVisitorComponent implements OnInit {
  searchText = '';
  message;
  isActive: boolean = false;
  screenWidth: number;
  showMobileTable: boolean;
  public pageInfo: PaginationModel = new PaginationModel();
  constructor(
    private commonService: CommonServiceService,
    private router: Router,
    private loaderService: LoaderService,
    public toastService: ToastService,
    private patientService: PatientService,
    private dialog: DialogService) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }
  ngOnInit() {
    this.commonService.searchText = '';

    this.commonService.callToggle.subscribe((data) => {
      this.change();
    });

    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }

  }

  searchItem() {
    this.commonService.sendFilterCloseEvent();
    this.commonService.searchText = this.searchText.trim();
    this.commonService.sendSearchEvent();

  }
  searchInputChanged() {
    this.commonService.searchText = this.searchText;
  }

  // -----toggle--------------------------------------------------------------------------------------//
  change() {
    this.isActive = !this.isActive;
  }

  errorSnackbar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(panelClass: string, message?) {
    if (panelClass === 'success') {
      this.toastService.opentoast(
        { toastName: 'success', data: { name: '', message }, type: 'success' },
        ToasterComponent
      );
    }
  }
}
