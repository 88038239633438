import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from 'src/app/core/utilities/format-datepicker';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { NameValidator } from 'src/app/modules/shared/validators/name.validator';
import * as moment from 'moment';
import { DataModel, GenericPicklistModel } from 'src/app/modules/shared/model/picklist.model';
import { PatientService } from '../../../service/patient.service';
import { PatientDataModel, PatientDescModel, PatientListingModel, whitelistedVisitorData, whitelistedVisitorModel } from '../../../model/patient-list.model';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ConfigurationFormFieldService } from 'src/app/modules/shared/services/configuration-form-field.service';
import { FormFieldListModel } from 'src/app/modules/shared/model/configuration-form-field';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/app/config/environments/environment';

@Component({
  selector: 'app-update-onboard-approval',
  templateUrl: './update-onboard-approval.component.html',
  styleUrls: ['./update-onboard-approval.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class UpdateOnboardApprovalComponent implements OnInit {
  isActive;
  patientForm: FormGroup;
  patients: any = [];
  organizations: any = [];
  patientes: any = [];
  buildings: any = [];
  id;
  updating = false;
  orgId: any;
  message;
  updateStatus;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  patientList: any = [];
  countryList: any[];
  customerId = localStorage.getItem('customerId');
  branchId = localStorage.getItem('branchId');
  departmentList: any[];
  doctorList: any[];
  roomList: any[];
  bedList: any[];
  gender: any[];
  additionalFields = [];
  approvalStatus: any = 0;
  whitelistVisitorDetailsForm: FormGroup;
  careTeamMemberForm: FormGroup;
  selectedIndex: any = 0;
  departmentByRoleList: any = [];
  careTeamMemberDataList: any = [];
  rolesList: any[] = [];
  isCareTeamMemberRequired: boolean;
  filteredBedList: Observable<string[]>;
  filteredRoomList: Observable<string[]>;
  filteredDoctors: Observable<string[]>;
  filteredGenders: Observable<string[]>;
  filteredDepartments: Observable<string[]>;
  filteredCountries: Observable<string[]>;
  filteredDepartmentByRoleList: Observable<string[]> = new Observable<string[]>();
  filteredRoleList: Observable<any[]> = new Observable<any[]>();
  whitelistVisitorList: FormArray;
  filteredCountriesCode: Observable<string[]> = new Observable<string[]>();

  constructor(
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public commonService: CommonServiceService,
    private router: Router,
    private fb: FormBuilder,
    private picklistService: PicklistService,
    private patientService: PatientService,
    private loaderService: LoaderService,
    private configurationFormFieldService: ConfigurationFormFieldService
  ) { }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_APPROVAL');
    this.commonService.callToggleAddEmp.subscribe(() => {
    });
    this.getParams();
    this.initializeForm();
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    this.getGender();
    this.getCountry();
    this.getDepartment();
    this.checkCareTeamMemberisRequiredOrNot();
    this.getRoles();
    this.whitelistVisitorDetailsForm = this.fb.group({
      whitelistVisitors: this.fb.array([]),
    });
    this.careTeamMemberForm = this.fb.group({
      careTeamMembers: this.fb.array([]),
    });
  }

  getParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.updating = true;
        this.id = params.id;
        this.patchValuesInPatientForm();
      }
    });
  }

  initializeForm() {
    this.patientForm = this.fb.group({
      patientId: ['', [
        Validators.required,
      ]],
      firstName: ['', [Validators.required, NameValidator]],
      lastName: ['', [Validators.required, NameValidator]],
      age: ['', [Validators.required, Validators.min(1)]],
      gender: ['', [Validators.required]],
      genderFilterCtrl: [''],
      doctorName: ['', [Validators.required]],
      doctorFilterCtrl: [''],
      departmentId: ['', [Validators.required]],
      departmentFilterCtrl: [''],
      country: ['', [Validators.required]],
      countryFilterCtrl: [''],
      email: [''],
      mobileNo: ['', [Validators.required]],
      admittedDate: [''],
      roomNumber: ['', [Validators.required]],
      roomFilterCtrl: [''],
      bedNumber: ['', [Validators.required]],
      bedFilterCtrl: [''],
      approvalStatus: [''],
      userStatus: ['']
    });
  }

  addWhitelistVisitorsFormGroup(): FormGroup {
    return this.fb.group({
      name: [''],
      countryCode: ['', [Validators.required]],
      countryFilterCtrl: [''],
      phoneNo: ['', [Validators.required, Validators.pattern('^([5-9]{1})([0-9]{5,12})$')]]
    });
  }

  checkCareTeamMemberisRequiredOrNot() {
    this.loaderService.show();
    this.patientService.checkCareTakeMemberisRequiredOrNot().subscribe((res: any) => {
      this.loaderService.hide();
      this.isCareTeamMemberRequired = + res.data.value ? false : true;
      if (this.isCareTeamMemberRequired && !this.updating) {
        this.addCareTeamMember();
      }
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    })
  }

  whitelistVisitors(): FormArray {
    return this.whitelistVisitorDetailsForm.get('whitelistVisitors') as FormArray
  }

  addWhitelistVisitor() {
    (<FormArray>this.whitelistVisitorDetailsForm.get('whitelistVisitors')).push(this.addWhitelistVisitorsFormGroup());
    let index = this.whitelistVisitorDetailsForm.controls.whitelistVisitors['controls'].length - 1;
    this.whitelistVisitorList = this.whitelistVisitors();
    const formGroup = this.whitelistVisitorList.controls[index] as FormGroup;
    this.filteredCountriesCode[index] = formGroup.controls['countryFilterCtrl'].valueChanges.
      pipe(
        startWith(''),
        map(value => this.commonService.dataFilter(value, this.countryList))
      );
  }

  removeWhitelistVisitor(i) {
    (<FormArray>this.whitelistVisitorDetailsForm.get('whitelistVisitors')).removeAt(i);
  }

  addCareTeamMembersFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      role: ['', [Validators.required]],
      roleFilterCtrl: [''],
      departmentId: ['', [Validators.required]],
      departmentFilterCtrl: ['']
    });
  }

  careTeamMembers(): FormArray {
    return this.careTeamMemberForm.get("careTeamMembers") as FormArray
  }

  addCareTeamMember() {
    (<FormArray>this.careTeamMemberForm.controls['careTeamMembers']).push(this.addCareTeamMembersFormGroup());
    const index = this.careTeamMembers().controls.length - 1;
    this.filteredRoleList[index] = this.careTeamMembers().controls[index]['controls']['roleFilterCtrl'].valueChanges.
      pipe(
        startWith(''),
        map(value => this.commonService.dataFilter(value, this.rolesList))
      );
  }

  removeCareTeamMember(i) {
    (<FormArray>this.careTeamMemberForm.get('careTeamMembers')).removeAt(i);
  }

  patchValuesInPatientForm() {
    this.loaderService.show();
    this.patientService
      .getPatientDetails(this.id)
      .subscribe((res: PatientDataModel) => {
        this.loaderService.hide();
        this.patchWhitelistedVisitorValues(res.data.whiteListed);
        this.patchCareTeamMemberValues(res.data.moderators);
        this.patientForm.get('patientId').patchValue(res.data.patientId);
        this.patientForm.get('firstName').patchValue(res.data.firstName);
        this.patientForm.get('lastName').patchValue(res.data.lastName);
        this.patientForm.get('age').patchValue(res.data.age);
        this.patientForm.get('gender').patchValue(res.data.gender);
        this.patientForm.get('departmentId').patchValue({ id: res.data.departmentId, name: res.data.departmentName });
        this.getDoctor();
        this.patientForm.get('doctorName').patchValue({ id: res.data.doctorId, name: res.data.doctorName });
        this.patientForm.get('email').patchValue(res.data.email);
        this.patientForm.get('mobileNo').patchValue(res.data.mobilePhone);
        this.patientForm.get('country').patchValue({ countryCode: res.data.countryCode, id: res.data.countryId });
        this.getRoomNumber();
        let room = this.roomList.find(item => item.id == res.data.roomId);
        room && room.id ? "" : this.roomList.push({ id: res.data.roomId, name: res.data.roomNumber });
        this.patientForm.get('roomNumber').patchValue({ id: res.data.roomId, name: res.data.roomNumber });
        this.getBedNumber();
        let bed = this.bedList.find(item => item.id == res.data.bedId);
        bed && bed.id ? "" : this.bedList.push({ id: res.data.bedId, name: res.data.bedNumber });
        this.patientForm.get('bedNumber').patchValue({ id: res.data.bedId, name: res.data.bedNumber });
        this.getFormControlList(res);
        res.data && res.data.admittedDate ? this.patientForm.get('admittedDate').patchValue(new Date(res.data.admittedDate).toISOString()) : '';
        this.patientForm.controls.mobileNo.disable();
        this.patientForm.controls.admittedDate.disable();
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
  }

  patchWhitelistedVisitorValues(data) {
    const whitelistedVisitorFormArray = <FormArray>this.whitelistVisitorDetailsForm.controls['whitelistVisitors'];
    data.forEach((x, index) => {
      whitelistedVisitorFormArray.push(this.patch(x));
      const formGroup = whitelistedVisitorFormArray.controls[index] as FormGroup;
      this.filteredCountriesCode[index] = formGroup.controls['countryFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => this.commonService.dataFilter(value, this.countryList))
        );
    });
  }

  patch(whitelistedVisitor: whitelistedVisitorData) {
    return this.fb.group({
      id: [whitelistedVisitor.id],
      name: [whitelistedVisitor.name, [Validators.required]],
      countryCode: [{ countryCode: whitelistedVisitor.countryCode, id: whitelistedVisitor.countryId }, [Validators.required]],
      phoneNo: [whitelistedVisitor.phoneNo, [Validators.required]],
      countryFilterCtrl: [''],
    });
  }

  patchCareTeamMemberValues(data) {
    const careTeamMemberFormArray = <FormArray>this.careTeamMemberForm.controls['careTeamMembers'];
    data.forEach((x, index) => {
      careTeamMemberFormArray.push(this.patchCareTeamMemberFormValue(x));
      this.getDepartmentByRole(index);
      this.filteredRoleList[index] = this.careTeamMembers().controls[index]['controls']['roleFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => this.commonService.dataFilter(value, this.rolesList))
        );
    });
  }

  patchCareTeamMemberFormValue(careTeamMember: any) {
    let departmentData = [];
    careTeamMember.departmentIds.forEach((item, index) => {
      departmentData.push({
        id: item,
        locationCode: careTeamMember.locationCodes[index]
      })
    })
    return this.fb.group({
      id: [careTeamMember.id],
      role: [{ id: careTeamMember.roleId, roleCode: careTeamMember.roleCode }, [Validators.required]],
      roleFilterCtrl: [''],
      departmentId: [departmentData, [Validators.required]],
      departmentFilterCtrl: [''],
    });

  }

  getRoles(i?) {
    this.loaderService.show();
    this.rolesList = [];
    this.picklistService.getRole(this.customerId)
      .subscribe((res: any) => {
        if (res.data.length) {
          res.data.map((x) => {
            if (x.active == true && (x.roleCode != 'PATIENT' && x.roleCode != 'VISITOR')) {
              this.rolesList.push({ id: x.id, name: x.name, roleCode: x.roleCode })
            };
          });
        }
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
  }

  getDepartmentByRole(index) {
    let roleId = this.careTeamMembers().controls[index].value.role.id
    if (roleId) {
      this.loaderService.show();
      this.departmentByRoleList[index] = [];
      this.picklistService.getDepartmentByRole(this.branchId, roleId).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          res.data.map((x) => {
            this.departmentByRoleList[index].push({ id: x.id, name: x.name, locationCode: x.locationCode });
          });
        }
        this.filteredDepartmentByRoleList[index] = (<FormGroup>this.careTeamMembers().controls[index]).controls['departmentFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => this.commonService.dataFilter(value, this.departmentByRoleList[index]))
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error)
      });
    }
  }

  objectComparisonFunction(option, value) {
    return option.id === value.id;
  }

  getCountry() {
    this.countryList = [];
    this.loaderService.show();
    this.picklistService.getCountry().subscribe((res: Array<GenericPicklistModel>) => {
      this.loaderService.hide();
      res.map((x: GenericPicklistModel) => {
        this.countryList.push({ id: x.id, name: x.name, code: x.code, countryCode: +x.countryCode });
      });
      this.filteredCountries = this.patientForm.controls['countryFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => this.commonService.dataFilter(value, this.countryList))
        );
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }

  getDepartment() {
    this.departmentList = [];
    this.loaderService.show();
    this.picklistService.getDepartment(this.branchId, this.customerId).subscribe((res: DataModel) => {
      this.loaderService.hide();
      if (res.data.length) {
        res.data.map((x) => {
          this.departmentList.push({ id: x.id, name: x.name });
        });
      }
      this.filteredDepartments = this.patientForm.controls['departmentFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => { return this.commonService.dataFilter(value, this.departmentList) })
        );
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }

  getGender() {
    this.gender = [];
    this.loaderService.show();
    this.picklistService.getGender().subscribe((res: any) => {
      this.loaderService.hide();
      if (res.data.length) {
        res.data.map((x) => {
          this.gender.push({ id: x.id, name: x.name });
        });
      }
      this.filteredGenders = this.patientForm.controls['genderFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => { return this.commonService.dataFilter(value, this.gender) })
        );
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }

  getFormControlList(result) {
    this.loaderService.show();
    this.configurationFormFieldService.getListing(environment.patientApiUrl, 'patient').subscribe((res: FormFieldListModel) => {
      this.loaderService.hide();
      res.data.forEach(item => {
        let control = new FormControl();
        if (item.hidden) {
          this.patientForm.addControl(item.fieldCode, control);
          this.additionalFields.push(item);
        }
      })
      this.additionalFields.forEach(item => {
        if (item.hidden) {
          this.patientForm.get(item.fieldCode).patchValue(result.data[item.fieldCode]);
        }
      })
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    })
  }

  getDoctor() {
    this.doctorList = [];
    this.loaderService.show();
    this.picklistService.getDoctor(this.branchId, this.customerId, this.patientForm.controls.departmentId.value.id).subscribe((res: any) => {
      this.loaderService.hide();
      if (res.length) {
        res.map((x) => {
          this.doctorList.push({ id: x.id, name: 'Dr. ' + x.name });
        });
      }
      this.filteredDoctors = this.patientForm.controls['doctorFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => { return this.commonService.dataFilter(value, this.doctorList) })
        );
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }

  getRoomNumber() {
    this.roomList = [];
    this.patientForm.controls.bedNumber.reset();
    const departmentId = this.patientForm.value.departmentId.id;
    if (departmentId) {
      this.loaderService.show();
      this.picklistService.getRoomForPatientByDept(this.customerId, this.branchId, departmentId).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          // this.roomList = res.data;
          res.data.map((x) => {
            this.roomList.push({ id: x.id, name: x.name });
          });
        }
        this.filteredRoomList = this.patientForm.controls['roomFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.roomList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  getBedNumber() {
    this.bedList = [];
    // const departmentId = this.patientForm.value.departmentId;
    const roomId = this.patientForm.value.roomNumber.id;
    if (roomId) {
      this.loaderService.show();
      this.patientService.getBedByRoom(this.customerId, this.branchId, roomId).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          // this.bedList = res.data;
          res.data.map((x) => {
            this.bedList.push({ id: x.id, name: x.name });
          });
        }
        this.filteredBedList = this.patientForm.controls['bedFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.bedList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  onboardApproval(approvedStatus) {
    const patientId = this.patientForm.getRawValue().patientId;
    const body = this.getPatientPayload();
    if (this.additionalFields.length) {
      this.additionalFields.forEach(item => {
        body[item.fieldCode] = this.patientForm.value[item.fieldCode];
      })
    }
    this.loaderService.show();
    this.patientService.onboardApproval({ "patientId": patientId, "approvalStatus": approvedStatus, patientByStaffRequestDto: body })
      .subscribe((res: PatientDescModel) => {
        this.loaderService.hide();
        this.successSnackBar(res.description);
        this.router.navigate(['admin/patient-list/patient-onboard']);
      },
        (error) => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        })
  }

  getPatientPayload() {
    const admittedDate = new Date(this.patientForm.controls['admittedDate'].value)
    const body = {
      firstName: this.patientForm.controls['firstName'].value,
      lastName: this.patientForm.controls['lastName'].value,
      doctorId: this.patientForm.controls['doctorName'].value.id,
      age: this.patientForm.controls['age'].value,
      genderId: this.patientForm.controls['gender'].value.id,
      doctorName: this.patientForm.controls['doctorName'].value.name,
      branchId: this.branchId,
      patientId: this.Upper(this.patientForm.controls.patientId.value),
      countryCode: this.patientForm.controls['country'].value.countryCode,
      countryId: this.patientForm.controls['country'].value.id,
      mobilePhone: this.patientForm.controls['mobileNo'].value,
      departmentId: this.patientForm.controls['departmentId'].value.id,
      departmentName: this.patientForm.controls['departmentId'].value.name,
      roomNumber: this.patientForm.controls['roomNumber'].value.name,
      roomId: this.patientForm.controls['roomNumber'].value.id,
      bedNumber: this.patientForm.controls['bedNumber'].value.name,
      bedId: this.patientForm.controls['bedNumber'].value.id,
      admittedDate: admittedDate.getTime(),
      email: this.patientForm.controls['email'].value,
      moderators: this.moderatorPayload(),
      visitorDTOS: this.whitlistVisitorPayload()
    }
    return body;
  }

  Upper(data: string) {
    return data.toUpperCase();
  }

  moderatorPayload() {
    let body = {
      moderators: this.careTeamMemberForm.get('careTeamMembers').value
    }
    body = body.moderators.map((x) => {
      return {
        id: x.id,
        departmentIds: x.departmentId.map(dept => dept.id),
        locationCodes: x.departmentId.map(dept => dept.locationCode),
        roleCode: x.role.roleCode,
        roleId: x.role.id
      }
    })
    return body;
  }

  whitlistVisitorPayload() {
    let body = {
      moderators: this.whitelistVisitorDetailsForm.get('whitelistVisitors').value
    }
    body = body.moderators.map((x) => {
      return {
        id: x.id,
        countryId: x.countryCode.id,
        countryCode: x.countryCode.countryCode,
        name: x.name,
        phoneNo: x.phoneNo
      }
    })
    return body
  }

  tabChange(value) {
    this.selectedIndex = value;
  }

  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }
  roleComparision(option, value) {
    return option.roleCode === value.roleCode && option.id === value.id;
  }

  cancel() {
    this.router.navigate(['admin/patient-list/patient-onboard']);
  }

  departmentComparision(option, value) {
    return option.id == value.id;// && option.locationCode == value.locationCode;
  }
  countryComparision(option, value) {
    return option.countryCode == value.countryCode && value.id == option.id;
  }
}
