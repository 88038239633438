import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { FilterSelectedModel, selectedModel, CustomerStatusFilter, DepartmentFilterModel, DepartmentModel } from '../../models/filter-operations';
import { FilterPicklistService } from '../../services/filter-picklist.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { WorkOrderServices } from '../../services/work-order.services';
import * as moment from 'moment';

enum FilterType {
  status = 1,
  escalateStatus,
  Date,
  department,
  branch,
  room,
  building,
  floor,
  category,
  subCategory,
  seat,
  bed
}
@Component({
  selector: 'app-work-order-filter-view',
  templateUrl: './work-order-filter-view.html',
  styleUrls: ['./work-order-filter-view.scss']
})

export class WorkOrderFilterViewComponent implements OnInit, OnChanges {
  FilterType = FilterType;
  pageInfo: PaginationModel = new PaginationModel();
  customerStatusResponse: Array<CustomerStatusFilter> = new Array<CustomerStatusFilter>();
  searchText: string;
  selectedFilterType: number;
  selectedKeys: selectedModel = new selectedModel();
  @Output() valueChange = new EventEmitter();
  @Output() closeFilterEventWithoutDataChange = new EventEmitter();
  @Output() closeFilterEvent = new EventEmitter();
  @Input() previousFilterValues: any;
  @Input() isWorkorderListView: any;
  @Output() checkCalendarOpen = new EventEmitter();
  @Input() washroomCategory: boolean;
  maxDate: any;
  minDate: any;
  status: any;
  statusResponse: any;
  statusArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  departmentResponse: DepartmentModel;
  departmentArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  branchResponse: DepartmentModel;
  branchArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  roomResponse: DepartmentModel;
  roomArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  buildingResponse: DepartmentModel;
  buildingArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  floorResponse: DepartmentModel;
  floorArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  seatResponse: DepartmentModel;
  seatArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  bedResponse: DepartmentModel;
  bedArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  categoryResponse: DepartmentModel;
  categoryArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  subCategoryResponse: DepartmentModel;
  subCategoryArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  customerId = localStorage.getItem('customerId');
  industryId = localStorage.getItem('industryId');
  branchId = localStorage.getItem('branchId');
  filterDateForm: FormGroup;
  escalateStatus: any;
  escalateStatusResponse = [];
  escalateStatusArray: Array<FilterSelectedModel> = new Array<FilterSelectedModel>();
  constructor(
    private loaderService: LoaderService,
    private toast: ToastService,
    private common: CommonServiceService,
    private fb: FormBuilder,
    private filterPicklistService: FilterPicklistService,
    private workOrderServices: WorkOrderServices

  ) {
    this.pageInfo.size = 5;
    this.pageInfo.page = 0;
    this.searchText = '';
  }

  ngOnInit() {
    this.getData(FilterType.status);
    this.common.getFilterCloseEvent().subscribe(res => {
      this.closeWithoutReset();
    });
    this.filterDateForm = this.fb.group({
      fromdate: new FormControl(''),
      todate: new FormControl('')
    });
  }

  streamOpened() {
    this.checkCalendarOpen.emit(true);
  }

  streamClosed() {
    this.checkCalendarOpen.emit(false);
  }

  closeWithoutReset() {
    this.closeFilterEventWithoutDataChange.emit();
  }

  ngOnChanges() {
    if (this.previousFilterValues) {
      this.statusArray = JSON.parse(JSON.stringify(this.previousFilterValues.status.slice(0)));
      this.escalateStatusArray = JSON.parse(JSON.stringify(this.previousFilterValues.escalateStatus));
      this.departmentResponse = new DepartmentModel();
      this.departmentArray = JSON.parse(JSON.stringify(this.previousFilterValues.departmentIds.slice(0)));
      this.branchResponse = new DepartmentModel();
      this.branchArray = JSON.parse(JSON.stringify(this.previousFilterValues.branchIds.slice(0)));
      this.roomResponse = new DepartmentModel();
      this.roomArray = JSON.parse(JSON.stringify(this.previousFilterValues.roomIds.slice(0)));
      this.floorResponse = new DepartmentModel();
      this.floorArray = JSON.parse(JSON.stringify(this.previousFilterValues.floorIds.slice(0)));
      this.buildingResponse = new DepartmentModel();
      this.buildingArray = JSON.parse(JSON.stringify(this.previousFilterValues.buildingIds.slice(0)));
      this.seatResponse = new DepartmentModel();
      this.seatArray = JSON.parse(JSON.stringify(this.previousFilterValues.seatIds.slice(0)));
      this.bedResponse = new DepartmentModel();
      this.bedArray = JSON.parse(JSON.stringify(this.previousFilterValues.bedIds.slice(0)));
      this.categoryResponse = new DepartmentModel();
      this.categoryArray = JSON.parse(JSON.stringify(this.previousFilterValues.categoryIds.slice(0)));
      this.subCategoryResponse = new DepartmentModel();
      this.subCategoryArray = JSON.parse(JSON.stringify(this.previousFilterValues.subCategoryIds.slice(0)));
      this.minDate = this.previousFilterValues.startDate;
      this.maxDate = this.previousFilterValues.endDate;
      this.searchFilter(this.previousFilterValues);
    }
  }

  searchFilter(status) {
    this.selectedFilterType = status;
  }

  // searchStatus() {
  //   this.selectedFilterType = this.FilterType.status;
  //   this.workOrderService.getStatusList(this.customerId)
  //     .subscribe((res: any) => {
  //       this.loaderService.hide();
  //       if (this.statusResponse.length > 0) {
  //         res.data.forEach(elm => this.statusResponse.push(elm));
  //       } else {
  //         this.statusResponse = res.data;
  //       }
  //       this.pageInfo.TotalRecord = res.data.totalElements;
  //       this.pageInfo.totalPages = res.data.totalPage;
  //       this.statusResponse.forEach((item: any) => {
  //         item.selected = false;
  //         this.statusArray.forEach(seletedIds => {
  //           if (seletedIds.id === item.id) {
  //             item.selected = true;
  //           }
  //         });
  //       });
  //     }, err => {
  //       this.loaderService.hide();
  //       this.errorSnackBar(err);
  //     });
  // }

  selectedInput() {
    this.getData(this.selectedFilterType);
  }

  getData(filterType) {
    this.pageInfo.page = 0;
    this.pageInfo.size = 5;
    this.statusResponse = [];
    this.escalateStatusResponse = [];
    this.departmentResponse = new DepartmentModel();
    // this.branchResponse = new DepartmentModel();
    this.roomResponse = new DepartmentModel();
    this.buildingResponse = new DepartmentModel();
    this.floorResponse = new DepartmentModel();
    this.seatResponse = new DepartmentModel();
    this.bedResponse = new DepartmentModel();
    this.categoryResponse = new DepartmentModel();
    this.subCategoryResponse = new DepartmentModel();
    if (!this.statusResponse.length) {
      this.statusResponse = [
        {
          id: 0,
          code: 'WORKORDER_OPEN',
          name: 'Not Started',
          status: false
        }, {
          id: 1,
          code: 'WORKORDER_INPROGRESS',
          name: 'Started',
          status: false
        }, 
        // {
        //   id: 2,
        //   code: 'WORKORDER_HOLD',
        //   name: 'On Hold',
        //   status: false
        // }, {
        //   id: 3,
        //   code: 'WORKORDER_COMPLETED',
        //   name: 'Completed',
        //   status: false
        // }, 
        {
          id: 2,
          code: 'WORKORDER_CLOSED',
          name: 'Closed',
          status: false
        }, 
        // {
        //   id: 5,
        //   code: 'WORKORDER_REOPEN',
        //   name: 'Reopened',
        //   status: false
        // }, {
        //   id: 6,
        //   code: 'WORKORDER_ESCALATE',
        //   name: 'Escalated',
        //   status: false
        // }
      ];
    }
    if (!this.escalateStatusResponse.length) {
      this.escalateStatusResponse = [
        {
          id: 0,
          name: 'Escalated',
          status: true
        },
        {
          id: 1,
          name: 'Non-Escalated',
          status: false
        }
      ];

    }
    if (this.previousFilterValues) {
      this.statusArray.forEach(item => {
        const index = this.statusResponse.findIndex(el => item.name === el.name);
        if (index !== -1) {
          this.statusResponse[index].selected = item.selected;
        }
      })
      this.escalateStatusArray.forEach(item => {
        const index = this.escalateStatusResponse.findIndex(el => item.name === el.name);
        if (index !== -1) {
          this.escalateStatusResponse[index].selected = item.selected;
        }
      })
    }
    if (filterType === this.FilterType.status) {
      this.searchFilter(filterType);
      // this.searchStatus();
      return;
    }
    if (filterType === this.FilterType.escalateStatus) {
      this.searchFilter(filterType);
      return;
    }
    if (filterType === this.FilterType.Date) {
      this.setFilterOnDate();
      return;
    }

    if (filterType === this.FilterType.department) {
      this.searchDepartment();
      return;
    }
    // if (filterType === this.FilterType.branch) {
    //   this.searchBranch();
    //   return;
    // }
    if (filterType === this.FilterType.room) {
      this.searchRoom();
      return;
    }

    if (filterType === this.FilterType.building) {
      this.searchBuilding();
      return;
    }
    if (filterType === this.FilterType.floor) {
      this.searchFloor();
      return;
    }
    if (filterType === this.FilterType.seat) {
      this.searchSeat();
      return;
    }
    if (filterType === this.FilterType.bed) {
      this.searchBed();
      return;
    }
    if (filterType === this.FilterType.category) {
      this.searchCategory(false);
      return;
    }
    if (filterType === this.FilterType.subCategory) {
      this.searchCategory(true);
      return;
    }
  }

  selectedItem(item: any, type) {
    let index;

    if (FilterType.status === type) {
      if (this.statusArray && this.statusArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.statusArray, this.statusResponse, item);
      } else {
        this.addItem(this.statusArray, this.statusResponse, item);
      }
      return;
    }
    if (FilterType.escalateStatus === type) {
      if (this.escalateStatusArray && this.escalateStatusArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.escalateStatusArray, this.escalateStatusResponse, item);
      } else {
        if (type === FilterType.escalateStatus) {
          index = this.escalateStatusResponse.findIndex(item => item.selected === true);
          if (index !== -1) {
            this.escalateStatusResponse[index].selected = false;
            this.escalateStatusArray = [];
          }
        }
        this.addItem(this.escalateStatusArray, this.escalateStatusResponse, item);
      }
      return;
    }

    if (FilterType.department === type) {
      if (this.departmentArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.departmentArray, this.departmentResponse.payload, item);
        // this.roomArray = [];
      } else {
        this.addItem(this.departmentArray, this.departmentResponse.payload, item);
      }
      return;
    }
    if (FilterType.branch === type) {
      if (this.branchArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.branchArray, this.branchResponse.payload, item);
      } else {
        this.addItem(this.branchArray, this.branchResponse.payload, item);
      }
      return;
    }
    if (FilterType.building === type) {
      if (this.buildingArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.buildingArray, this.buildingResponse.payload, item);
      } else {
        this.addItem(this.buildingArray, this.buildingResponse.payload, item);
      }
      return;
    }
    if (FilterType.floor === type) {
      if (this.floorArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.floorArray, this.floorResponse.payload, item);
      } else {
        this.addItem(this.floorArray, this.floorResponse.payload, item);
      }
      return;
    }
    if (FilterType.room === type) {
      if (this.roomArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.roomArray, this.roomResponse.payload, item);
      } else {
        this.addItem(this.roomArray, this.roomResponse.payload, item);
      }
      return;
    }
    if (FilterType.seat === type) {
      if (this.seatArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.seatArray, this.seatResponse.payload, item);
      } else {
        this.addItem(this.seatArray, this.seatResponse.payload, item);
      }
      return;
    }
    if (FilterType.bed === type) {
      if (this.bedArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.bedArray, this.bedResponse.payload, item);
      } else {
        this.addItem(this.bedArray, this.bedResponse.payload, item);
      }
      return;
    }
    if (FilterType.category === type) {
      if (this.categoryArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.categoryArray, this.categoryResponse.payload, item);
      } else {
        this.addItem(this.categoryArray, this.categoryResponse.payload, item);
      }
      return;
    }
    if (FilterType.subCategory === type) {
      if (this.subCategoryArray.find(({ id }) => id === item.id)) {
        this.removeItem(this.subCategoryArray, this.subCategoryResponse.payload, item);
      } else {
        this.addItem(this.subCategoryArray, this.subCategoryResponse.payload, item);
      }
      return;
    }
  }
  addItem(selectedArray: any[], arrayWholeContent: any, item: any) {
    selectedArray.push(item);
    if (arrayWholeContent) {
      const resultArray = arrayWholeContent.filter(content => content.id === item.id);
      resultArray[0].selected = true;
    } else {
      const resultArray = arrayWholeContent.filter(content => content.id === item.id);
      resultArray[0].selected = true;
    }
  }

  removeItem(selectedArray: any, arrayWholeContent: any, item: any) {
    const ind = selectedArray.findIndex(x => x.id === item.id);
    selectedArray.splice(ind, 1);
    if (arrayWholeContent && arrayWholeContent.content) {
      const resultArray = arrayWholeContent.content.filter(content => content.id === item.id);
      resultArray[0].selected = false;
    } else {
      const resultArray = arrayWholeContent.filter(content => content.id === item.id);
      resultArray[0].selected = false;
    }
  }

  filter() {
    const filterObj = {
      status: this.statusArray,
      escalateStatus: this.escalateStatusArray,
      departmentIds: this.departmentArray,
      branchIds: this.branchArray,
      roomIds: this.roomArray,
      floorIds: this.floorArray,
      buildingIds: this.buildingArray,
      categoryIds: this.categoryArray,
      subCategoryIds: this.subCategoryArray,
      bedIds: this.bedArray,
      seatIds: this.seatArray,
      startDate: this.minDate,
      endDate: this.maxDate
    };
    this.valueChange.emit(filterObj);
  }

  // searchBranch() {
  //   this.selectedFilterType = this.FilterType.branch;
  //   this.loaderService.show();
  //   this.filterPicklistService.branchList(this.searchText, this.pageInfo)
  //     .subscribe((res: DepartmentFilterModel) => {
  //       this.loaderService.hide();
  //       if (this.branchResponse.payload.length > 0) {
  //         res.data.payload.forEach(elm => this.branchResponse.payload.push(elm));
  //       } else {
  //         this.branchResponse = res.data;
  //       }
  //       this.pageInfo.TotalRecord = res.data.totalElements;
  //       this.pageInfo.totalPages = res.data.totalPage;
  //       this.branchResponse.payload.forEach((item: any) => {
  //         item.selected = false;
  //         this.branchArray.forEach(seletedIds => {
  //           if (seletedIds.id === item.id) {
  //             item.selected = true;
  //           }
  //         });
  //       });
  //     }, err => {
  //       this.loaderService.hide();
  //       this.errorSnackBar(err);
  //     });
  // }

  setFilterOnDate() {
    this.selectedFilterType = this.FilterType.Date;
    this.filterDateForm.patchValue({
      fromdate: this.minDate ? moment(this.minDate).toISOString() : '',
      todate: this.maxDate ? moment(this.maxDate).toISOString() : '',
    });
  }

  searchBuilding() {
    this.selectedFilterType = this.FilterType.building;
    this.loaderService.show();
    const body = { branchIds: this.branchId }
    this.filterPicklistService.buildingList(this.searchText, this.pageInfo, body)
      .subscribe((res: DepartmentFilterModel) => {
        this.loaderService.hide();
        if (this.buildingResponse.payload.length > 0) {
          res.data.payload.forEach(elm => this.buildingResponse.payload.push(elm));
        } else {
          this.buildingResponse = res.data;
        }
        this.pageInfo.TotalRecord = res.data.totalElements;
        this.pageInfo.totalPages = res.data.totalPage;
        this.buildingResponse.payload.forEach((item: any) => {
          item.selected = false;
          this.buildingArray.forEach(seletedIds => {
            if (seletedIds.id === item.id) {
              item.selected = true;
            }
          });
        });
      }, err => {
        this.loaderService.hide();
        this.errorSnackBar(err);
      });
  }

  searchFloor() {
    this.selectedFilterType = this.FilterType.floor;
    this.loaderService.show();
    const body = {
      branchIds: this.branchId,
      buildingIds: this.buildingArray.map(x => x.id)
    }
    this.filterPicklistService.floorList(this.searchText, this.pageInfo, body)
      .subscribe((res: DepartmentFilterModel) => {
        this.loaderService.hide();
        if (this.floorResponse.payload.length > 0) {
          res.data.payload.forEach(elm => this.floorResponse.payload.push(elm));
        } else {
          this.floorResponse = res.data;
        }
        this.pageInfo.TotalRecord = res.data.totalElements;
        this.pageInfo.totalPages = res.data.totalPage;
        this.floorResponse.payload.forEach((item: any) => {
          item.selected = false;
          this.floorArray.forEach(seletedIds => {
            if (seletedIds.id === item.id) {
              item.selected = true;
            }
          });
        });
      }, err => {
        this.loaderService.hide();
        this.errorSnackBar(err);
      });
  }

  searchDepartment() {
    this.selectedFilterType = this.FilterType.department;
    this.loaderService.show();
    const body = {
      branchIds: this.branchId,
      buildingIds: this.buildingArray.map(x => x.id),
      floorIds: this.floorArray.map(x => x.id)
    }
    this.filterPicklistService.departmentList(this.searchText, this.pageInfo, body)
      .subscribe((res: DepartmentFilterModel) => {
        this.loaderService.hide();
        if (this.departmentResponse.payload.length > 0) {
          res.data.payload.forEach(elm => this.departmentResponse.payload.push(elm));
        } else {
          this.departmentResponse = res.data;
        }
        this.pageInfo.TotalRecord = res.data.totalElements;
        this.pageInfo.totalPages = res.data.totalPage;
        this.departmentResponse.payload.forEach((item: any) => {
          item.selected = false;
          this.departmentArray.forEach(seletedIds => {
            if (seletedIds.id === item.id) {
              item.selected = true;
            }
          });
        });
      }, err => {
        this.loaderService.hide();
        this.errorSnackBar(err);
      });
  }

  searchRoom() {
    this.selectedFilterType = this.FilterType.room;
    this.loaderService.show();
    const body = {
      branchIds: this.branchId,
      buildingIds: this.buildingArray.map(x => x.id),
      floorIds: this.floorArray.map(x => x.id),
      departmentIds: this.departmentArray.map(x => x.id)
    }
    this.filterPicklistService.roomList(this.searchText, this.pageInfo, body)
      .subscribe((res: DepartmentFilterModel) => {
        this.loaderService.hide();
        if (this.roomResponse.payload.length > 0) {
          res.data.payload.forEach(elm => this.roomResponse.payload.push(elm));
        } else {
          this.roomResponse = res.data;
        }
        this.pageInfo.TotalRecord = res.data.totalElements;
        this.pageInfo.totalPages = res.data.totalPage;
        this.roomResponse.payload.forEach((item: any) => {
          item.selected = false;
          this.roomArray.forEach(seletedIds => {
            if (seletedIds.id === item.id) {
              item.selected = true;
            }
          });
        });
      }, err => {
        this.loaderService.hide();
        this.errorSnackBar(err);
      });
  }

  searchBed() {
    this.loaderService.show();
    const body = {
      branchIds: this.branchId,
      buildingIds: this.buildingArray.map(x => x.id),
      floorIds: this.floorArray.map(x => x.id),
      departmentIds: this.departmentArray.map(x => x.id),
      roomIds: this.roomArray.map(x => x.id)
    }
    this.filterPicklistService.getBed(this.searchText, this.customerId, this.pageInfo, body)
      .subscribe((res: DepartmentFilterModel) => {
        this.loaderService.hide();
        if (this.bedResponse.payload.length > 0) {
          res.data.payload.forEach(elm => this.bedResponse.payload.push(elm));
        } else {
          this.bedResponse = res.data;
        }
        this.pageInfo.TotalRecord = res.data.totalElements;
        this.pageInfo.totalPages = res.data.totalPage;
        this.bedResponse.payload.forEach((item: any) => {
          item.selected = false;
          this.bedArray.forEach(seletedIds => {
            if (seletedIds.id === item.id) {
              item.selected = true;
            }
          });
        });
      }, err => {
        this.loaderService.hide();
        this.errorSnackBar(err);
      });
  }
  searchSeat() {
    this.selectedFilterType = this.FilterType.seat;
    this.loaderService.show();
    const body = {
      branchIds: this.branchId,
      buildingIds: this.buildingArray.map(x => x.id),
      floorIds: this.floorArray.map(x => x.id),
      departmentIds: this.departmentArray.map(x => x.id),
      roomIds: this.roomArray.map(x => x.id)
    }
    this.filterPicklistService.getSeat(this.searchText, this.pageInfo, body)
      .subscribe((res: DepartmentFilterModel) => {
        this.loaderService.hide();
        if (this.seatResponse.payload.length > 0) {
          res.data.payload.forEach(elm => this.seatResponse.payload.push(elm));
        } else {
          this.seatResponse = res.data;
          this.seatResponse.payload.forEach((item: any) => {
            item.id = item.tagNumber;
          })
        }
        this.pageInfo.TotalRecord = res.data.totalElements;
        this.pageInfo.totalPages = res.data.totalPage;
        this.seatResponse.payload.forEach((item: any) => {
          item.selected = false;
          this.seatArray.forEach(seletedIds => {
            // item.id = item.tagNumber;
            if (seletedIds.id === item.id) {
              item.selected = true;
            }
          });
        });
      }, err => {
        this.loaderService.hide();
        this.errorSnackBar(err);
      });
  }

  searchCategory(isSubCategory) {
    this.selectedFilterType = isSubCategory ? this.FilterType.subCategory : this.FilterType.category;
    this.loaderService.show();
    const categoryIds = this.categoryArray.map(item => item.id);
    this.filterPicklistService.getCategoryList(this.searchText, this.pageInfo, isSubCategory, categoryIds, true)
      .subscribe((res: any) => {
        this.loaderService.hide();
        if (isSubCategory) {
          if (this.subCategoryResponse.payload.length > 0) {
            res.payload.forEach(elm => this.subCategoryResponse.payload.push(elm));
          } else {
            this.subCategoryResponse = res;
          }
          this.pageInfo.TotalRecord = res.totalElements;
          this.pageInfo.totalPages = res.totalPage;
          if(this.subCategoryArray.length>0)
          {
          this.subCategoryResponse.payload.forEach((item: any) => {
            item.selected = false;
            this.subCategoryArray.forEach(seletedIds => {
              if (seletedIds.id === item.id) {
                item.selected = true;
              }
            });
          });
        }
        else{
          this.subCategoryResponse.payload.forEach((item: any) => {
            item.selected = false;
            if(!this.washroomCategory && item.categoryCode!=='WASHROOM_HYGIENE')
            {
              this.selectedItem(item, FilterType.subCategory);
            }
            if(this.washroomCategory && item.categoryCode==='WASHROOM_HYGIENE')
            {
              this.selectedItem(item, FilterType.subCategory);
            }
          });
        }
        } else {
          if (this.categoryResponse.payload.length > 0) {
            res.payload.forEach(elm => this.categoryResponse.payload.push(elm));
          } else {
            this.categoryResponse = res;
          }
          this.pageInfo.TotalRecord = res.totalElements;
          this.pageInfo.totalPages = res.totalPage;
          this.categoryResponse.payload.forEach((item: any) => {
            item.selected = false;
            this.categoryArray.forEach(seletedIds => {
              if (seletedIds.id === item.id) {
                item.selected = true;
              }
            });
          });
        }
      }, err => {
        this.loaderService.hide();
        this.errorSnackBar(err);
      });
  }


  onScroll() {
    if (this.pageInfo.page + 1 < this.pageInfo.totalPages) {
      this.pageInfo.page = this.pageInfo.page + 1;
      // if (this.selectedFilterType === this.FilterType.branch) {
      //   this.searchBranch();
      //   return;
      // }
      if (this.selectedFilterType === this.FilterType.building) {
        this.searchBuilding();
        return;
      }
      if (this.selectedFilterType === this.FilterType.floor) {
        this.searchFloor();
        return;
      }
      if (this.selectedFilterType === this.FilterType.department) {
        this.searchDepartment();
        return;
      }
      if (this.selectedFilterType === this.FilterType.room) {
        this.searchRoom();
        return;
      }
      if (this.selectedFilterType === this.FilterType.bed) {
        this.searchBed();
        return;
      }
      if (this.selectedFilterType === this.FilterType.seat) {
        this.searchSeat();
        return;
      }
      if (this.selectedFilterType === this.FilterType.category) {
        this.searchCategory(false);
        return;
      }
      if (this.selectedFilterType === this.FilterType.subCategory) {
        this.searchCategory(true);
        return;
      }
    }
  }
  close() {
    this.statusArray = [];
    this.departmentArray = [];
    this.branchArray = [];
    this.roomArray = [];
    this.buildingArray = [];
    this.floorArray = [];
    this.bedArray = [];
    this.categoryArray = [];
    this.subCategoryArray = [];
    this.seatArray = [];
    this.minDate = null;
    this.maxDate = null;
    this.filter();
  }


  dateFrom(e: any) {
    console.log(e.target.value,"mindate")
    this.minDate = (new Date(e.target.value)).getTime();
  }

  dateTo(e: any) {
    this.maxDate = (new Date(e.target.value).getTime());
  }

  successSnackBar(message) {
    this.toast.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
    this.toast.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }
}
