import { Component, OnInit, OnDestroy,Renderer2, Input,ViewChild ,ElementRef,HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component'
import { SuccessDialogComponent } from 'src/app/modules/shared/component/success-dialog/success-dialog';
import { WorkOrderService } from '../../services/work-order.service';
import * as moment from 'moment';
@Component({
  selector: 'app-work-order-failure-list',
  templateUrl: './work-order-failure-list.component.html',
  styleUrls: ['./work-order-failure-list.component.scss']
})
export class WorkOrderFailureListComponent implements OnInit {
  isActive:boolean = false;
  noResult: boolean;
  listData:any;
  filterView: boolean = false;
  filterValue: any;
  isFilterImplemented: boolean = false;
  searchText = ''; 
  actionsToggle = false;
  headCheckBox:boolean = false;
  checkedWorkorders:any;
  inside:boolean
  pageSizeArray = [10, 50, 100];
  @ViewChild('actionButtonToggle', { static: false }) actionButtonToggle: ElementRef;
  @ViewChild('filterMenuToggle', { static: false }) filterMenuToggle: ElementRef;
  public pageInfo: PaginationModel = new PaginationModel();
  constructor(
    public router: Router,
    public toastService: ToastService,
    private commonService: CommonServiceService,
    private loaderService: LoaderService,
    public matDialog: MatDialog,
    public workOrderService: WorkOrderService,
    private renderer: Renderer2,
  ) { 
    this.pageInfo.sortBy = '';
    this.pageInfo.sortDir = 'ASC';
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
  }

  ngOnInit() {
    this.getFailedWorkOrderList('','')
    this.commonService.callToggle.subscribe((data) => {
      this.change();
    });
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    localStorage.getItem('FailedWorkOrder')?localStorage.removeItem("FailedWorkOrder"):'';

    this.renderer.listen('window', 'click', (e: Event) => {
      // if (this.isList) {
      if (this.actionsToggle) {
        if (!this.actionButtonToggle.nativeElement.contains(e.target)) {
          this.actionsToggle = false;
        }
      }
      if (this.filterView) {
        if (!this.filterMenuToggle.nativeElement.contains(e.target)) {
          this.filterView = false;
        }
      }
      // }
    });

  }

  SetPage(event: any) {
    this.pageInfo.page = event.page;
    this.pageInfo.size = event.size;
    this.getFailedWorkOrderList('','')
  }

  pageSizeChange(event) {
    if (event.value) {
      this.pageInfo.page = 0;
      this.pageInfo.size = event.value;
    }
    this.getFailedWorkOrderList('','');
  }

  getFailedWorkOrderList(query,status){
    this.headCheckBox = false
    let body ={
      searchText: query||this.searchText,
      sortby: this.pageInfo.sortBy,
      order: this.pageInfo.sortDir,
      page: this.pageInfo.page,
      size: this.pageInfo.size,
      status: status
    }
    this.workOrderService.getWorkOrderFailureList(body).subscribe((res:any)=>{
      this.pageInfo.TotalRecord = res.data.totalElements;
      this.pageInfo.totalPages = res.data.totalPage;
      this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
      if (res.data.payload.length) {
        this.noResult = false;
        this.listData = res.data.payload;
        this.listData.forEach(item => {
          item['startDate'] = item.startDate ? moment(item.startDate).format('DD-MM-YYYY') : null;
          item['status'] = item.status =='SYNC_FAILURE' ? 'Failure' : 'Initiated';
          item['selected'] = false;
          if(this.headCheckBox){
            item['selected'] = true;
          }
        })
        this.loaderService.hide();
        this.dummyClick();
      } else {
        this.noResult = true;
      }
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }

  
  openSuccessDialog(message, heading) {
    this.matDialog.open(SuccessDialogComponent,
      {
        data:
        {
          yesBtnName: 'OK',
          confirmationMesg: message,
          heading: heading
        }
      }).afterClosed().subscribe(res => {
      })
  }

  sort(colName: string, sortDirection: any) {
    if (this.pageInfo.sortBy === colName) {
      if (sortDirection === 'ASC') {
        this.pageInfo.sortBy = '';
        this.pageInfo.sortDir = 'DESC';
      } else {
        this.pageInfo.sortDir = sortDirection;
      }
    } else {
      this.pageInfo.sortBy = colName;
      this.pageInfo.sortDir = 'ASC';
    }
    this.getFailedWorkOrderList('','');
  }

  filterValueChange(event) {
    this.filterView = false;
    this.isFilterImplemented = true;
    this.filterValue = event;
    if(event == true){
      event='SYNC_INITIATED';
    }
    if(event == false){
      event='SYNC_FAILURE';
    }
    this.getFailedWorkOrderList('',event)
    this.checkIfFilterImplemented();
  }

  filterClosed(event) {
    this.filterView = false;
    this.filterValue = event;
    this.checkIfFilterImplemented();
  }

  closeFilterEventWithoutDataChange() {
    this.filterView = !this.filterView;
  }

  checkIfFilterImplemented() {
    if (this.filterValue) {
      if ((this.filterValue.length > 0)) {
        this.isFilterImplemented = true;
      } else {
        this.isFilterImplemented = false;
      }
    }
  }


  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }

  openSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  } 

  searchInputChanged() {
    this.commonService.searchText = this.searchText;
  }
  
  searchItem() {
    this.getFailedWorkOrderList(this.searchText.trim(),'')
  }

  change() {
    this.isActive = !this.isActive;
  }

  viewService(id){
    let FailedWorkOrder:any= {
      id:id,
      isEdit:false
    };
    localStorage.setItem('FailedWorkOrder',JSON.stringify(FailedWorkOrder));
    this.router.navigate(['admin/hygiene/work-order/edit-work-order/' + id]);
  }
  editService(id) {
    let FailedWorkOrder:any= {
      id:id,
      isEdit:true
    };
    localStorage.setItem('FailedWorkOrder',JSON.stringify(FailedWorkOrder));
    this.router.navigate(['admin/hygiene/work-order/edit-work-order/' + id]);
  }

  syncService(id){
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        yesBtnName: 'Confirm',
        noBtnName: 'Cancel',
        confirmationMesg: `Are you sure you want to Sync the selected Workorders?`,
        heading: 'Confirm Update'
      }
    })
    .afterClosed().subscribe(res => {
      if (res == 1) {
        this.workOrderService.syncFailedWorkOrder(id).subscribe((res:any)=>{
            this.getFailedWorkOrderList('','')
            this.openSnackBar(res.description);
            this.loaderService.hide();
          } , error => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        });
      if(res == 2) {
      }
    }
    })
  }

  deleteService(id){
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        yesBtnName: 'Confirm',
        noBtnName: 'Cancel',
        confirmationMesg: `Are you sure you want to Delete the selected Workorders?`,
        heading: 'Confirm Update'
      }
    })
    .afterClosed().subscribe(res => {
      if (res == 1) {
        this.workOrderService.deleteFailedWorkOrder(id).subscribe((res:any)=>{
          this.getFailedWorkOrderList('','')
          this.openSnackBar(res.description);
          this.loaderService.hide();
        } , error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
      if(res == 2) {
      }
    }
    })
  }

  toggleAction() {
    this.actionsToggle = !this.actionsToggle;
  }

  allCheckbox() {
    let data:any =[]
    this.headCheckBox= !this.headCheckBox;
    if (this.headCheckBox) {
      if(!this.listData){
        return;
      }
      this.listData.forEach(item => {
        if(item.status == 'Failure'){
          item['selected'] = true
          data.push(item);
        }
      })
      this.listData = data;
    } else {
      this.getFailedWorkOrderList('','');
    }
  }

  blukAction(action){
    this.filterView=false;
    let selectedWorkOrder=[];
    if(!this.listData){
      return;
    }
    this.listData.forEach(item => {
      if(item.status == 'Failure' && item['selected'] == true){
        action=="delete"?selectedWorkOrder.push(item.wid):selectedWorkOrder.push(item.workorderId)
      }
    })
    if(!selectedWorkOrder.length){
      this.matDialog.open(ConfirmationDialogComponent,{
        data: {
          heading: ' ',
          yesBtnName: 'ok',
          confirmationMesg2: `Please select data first`,
        }
      })
      .afterClosed().subscribe(res => {
        return;
      })
    }else if(selectedWorkOrder.length){
      this.matDialog.open(ConfirmationDialogComponent,{
        data: {
          yesBtnName: 'Confirm',
          noBtnName: 'Cancel',
          confirmationMesg: `Are you sure you want to ${action} the selected Workorders?`,
          heading: 'Confirm Update'
        }
      })
      .afterClosed().subscribe(res => {
        if (res == 1) {
          if(action=="delete"){
            this.workOrderService.bulkDeleteWorkOrder(selectedWorkOrder).subscribe((res:any)=>{
              this.getFailedWorkOrderList('','')
              this.openSnackBar(res.description);
              this.loaderService.hide();
              } , error => {
              this.loaderService.hide();
              this.errorSnackBar(error);
            });
          }else{
            this.workOrderService.bulkSyncWorkOrder(selectedWorkOrder).subscribe((res:any)=>{
              this.getFailedWorkOrderList('','')
              this.openSnackBar(res.description);
              this.loaderService.hide();
              } , error => {
              this.loaderService.hide();
              this.errorSnackBar(error);
            });
          }
      }
      })
    }
  }
 

  dummyClick(){
    document.getElementById("dummyClick").click();
  }
  
}
