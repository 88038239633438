import { ValidatorFn, FormControl } from "@angular/forms";

export const PasswordValidator: ValidatorFn = (control: FormControl) => {
  const ctrlValue = control.value || '';
  const  pwdPattern  = '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,32}$';
  if(ctrlValue.length && !ctrlValue.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?#.^+=&])[A-Za-z\d$@$!%*?#.^+=&].{7,32}/g))
  {
      return { pattern:'error'}
  }
  return null;
};
