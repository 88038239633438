import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from 'src/app/core/utilities/format-datepicker';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DataModel, KeyModel } from 'src/app/modules/shared/model/picklist.model';
import { PatientService } from '../../../service/patient.service';
import { PatientData, PatientDescModel } from '../../../model/patient-list.model';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { CustomerService } from '../../../../customer/service/customer.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-work-flow',
  templateUrl: './add-work-flow.component.html',
  styleUrls: ['./add-work-flow.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class AddWorkFlowComponent implements OnInit {
  isActive;
  workflowForm: FormGroup;
  id;
  updating = false;
  message;
  updateStatus;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  customerId = localStorage.getItem('customerId');
  branchId = localStorage.getItem('branchId');
  departmentList: any[];
  roleList: Observable<string[]>;
  roleOption: any[] = [];
  isShow: boolean;
  isInput: boolean;
  isCareTeamAllowedVisitor: boolean;
  careTeamAllowedVisitorValueList = [
    { key: 'ONLY_PRE_APPROVED_VISITOR', value: 'ONLY PRE APPROVED VISITOR' },
    { key: 'ALL', value: 'ALL' }
  ]
  constructor(
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public commonService: CommonServiceService,
    private router: Router,
    private fb: FormBuilder,
    private picklistService: PicklistService,
    private patientService: PatientService,
    private loaderService: LoaderService,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_WORKFLOW');
    this.commonService.callToggleAddEmp.subscribe(() => {
      this.change();
    });
    this.initializeForm();
    this.getRoleList();
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    this.getParams();
  }

  private value2Filter(value) {
    const filterValue = value.toLowerCase();
    return value != '' ?
      this.roleOption.filter(option => option.name.toLowerCase().includes(filterValue)) : this.roleOption;
  }

  change() {
    this.isActive = !this.isActive;
  }

  getParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.updating = true;
        this.id = params.id;
        this.patchValuesInworkflowForm();
      }
    });
  }

  initializeForm() {
    this.workflowForm = this.fb.group({
      name: ['', [Validators.required]],
      value1: [''],
      value2: [''],
      value3: [''],
      value4: [''],
      value2filtrCtrl: ['']
    });
  }

  patchValuesInworkflowForm() {
    this.loaderService.show();
    this.patientService
      .getWorkFlowById(this.id)
      .subscribe((res: any) => {
        this.loaderService.hide();
        this.workflowForm.get('name').patchValue(res.data.name);
        if (res.data.type == "PATIENT_APPROVAL_BY_STAFF_IS_ENABLE" || res.data.type == 'PATIENT_CARETEAM_FLOW_MANDATORY') {
          this.isShow = true;
          this.workflowForm.get('value1').patchValue(res.data.value);
          this.workflowForm.controls.value1.setValidators(Validators.required);
          this.workflowForm.controls.value1.updateValueAndValidity();
        } else if (res.data.type == 'PATIENT_ONBOARDING_APPROVAL') {
          this.isShow = false
          const approvalList = res.data.values.map(item => +item);
          this.workflowForm.get('value2').patchValue(approvalList);
          this.workflowForm.controls.value2.setValidators(Validators.required);
          this.workflowForm.controls.value2.updateValueAndValidity();
        } else if (res.data.type == 'MODERATOR_NOTIFICATION_BEFORE_IN_MINS' || res.data.type == 'MODERATOR_ADVANCE_BOOKING_IN_DAYS' || res.data.type == 'VISITOR_NOTIFICATION_BEFORE_IN_MINS') {
          this.isInput = true;
          this.workflowForm.get('value3').patchValue(res.data.value);
          this.workflowForm.controls.value3.setValidators(Validators.required);
          this.workflowForm.controls.value3.updateValueAndValidity();
        } else if (res.data.type == "CARE_TEAM_ALLOWED_VISITOR") {
          this.isCareTeamAllowedVisitor = true;
          this.workflowForm.get('value4').patchValue(res.data.value);
          this.workflowForm.controls.value4.setValidators(Validators.required);
          this.workflowForm.controls.value4.updateValueAndValidity();
        }
        this.workflowForm.get('name').disable();
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
  }

  getRoleList() {
    const customerId = localStorage.getItem('customerId');
    this.loaderService.show();
    this.picklistService.getRole(customerId).subscribe(res => {
      this.loaderService.hide();
      this.roleOption = res['data'];
      this.roleList = this.workflowForm.controls['value2filtrCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => this.value2Filter(value))
        );
    }, err => {
      this.loaderService.hide();
      this.errorSnackBar(err);
    })
  }

  onSubmit3() {
    this.loaderService.show();
    const body = {
      id: this.id,
      name: this.workflowForm.controls.name.value,
    }
    if (this.isInput) {
      body['value'] = this.workflowForm.controls.value3.value;
    }
    else if (this.isShow) {
      body['value'] = this.workflowForm.controls.value1.value;
    }
    else if (this.isCareTeamAllowedVisitor) {
      body['value'] = this.workflowForm.controls.value4.value;
    } else {
      // body['value'] = this.workflowForm.controls.value2.value
      body['values'] = this.workflowForm.controls.value2.value
    }
    this.patientService.updateWorkFlow(body)
      .subscribe((res: any) => {
        this.loaderService.hide();
        this.openSnackBar('success', res.description);
        this.router.navigate(['admin/patient-list/workflow']);
      },
        (error) => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        });
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error }, type: 'error' },
      ErrorToasterComponent
    );
  }

  openSnackBar(panelClass: string, message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message: message }, type: 'success' },
      ToasterComponent
    );
  }

  cancel() {
    this.router.navigate(['admin/patient-list/workflow']);
  }
}
