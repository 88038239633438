import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import {
  HttpClient, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { environment } from '../config/environments/environment';
import { CommonServiceService } from './common-service.service';
// import { publicKey } from './config';
// import { JSEncrypt } from 'jsencrypt';
// import * as uuid from 'uuid';


let user = localStorage.getItem('user');
// let user = localStorage.getItem('user');

let httpOptions;
let httpOptions1;
let httpOptionsLogin;

@Injectable({
  providedIn: 'root'
})

export class HttpHandlerProvider {
  isLogged: boolean;
  api = environment.apiBaseUrl;
  // $encrypt: any;
  // json = {
  //   'uid': 1,
  //   'key': uuid.v4()
  // };
  // cypherText;

  constructor(private http: HttpClient, private common: CommonServiceService) {
    user = localStorage.getItem('user');
    // user = localStorage.getItem('user');

    // this.$encrypt = new JSEncrypt();
    // this.http.get(`${environment.apiUrl}api/v1/employee/filtercount`, this.common.httpOptions).subscribe(
    //   res => {
    //     this.common.totalPage = res;

    //   }
    // );
    // const text = JSON.stringify(this.json);
    // this.$encrypt.setPublicKey(publicKey);
    // this.cypherText = this.$encrypt.encrypt(text);
    // this.common.cipher = this.cypherText;

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user}`,
        // 'Custom': `${this.common.cipher}`,
        // 'Security': `Bearer ${this.common.cipher}`
      })
    };

    httpOptionsLogin = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Security': `Bearer ${this.common.cipher}`
      })
    };

  }

  // get(credentials) {
  //   return this.http.get(environment.apiUrl, httpOptionsLogin).pipe(catchError(this.errorHandler));
  // }

  // post(credentials) {

  //   return this.http.post(environment.apiUrl + 'oauth/token', credentials, httpOptions1)
  //     .pipe(catchError(this.errorHandler));
  // }

  // getSave() {

  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/users?page=' +
  //       this.common.pageNo + '&size=' + this.common.size, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/users?page=' +
  //       this.common.pageNo + '&size=' + this.common.size, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // departmentData() {

  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/departments?page=' +
  //       this.common.pageNo + '&size' + this.common.size, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/departments?page=' +
  //       this.common.pageNo + '&size=' + this.common.size, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }



  // postSave(credentials) {
  //   if (this.common.isHeader4 === true) {
  //     // this.common.isHeader4=false;
  //     return this.http.post(environment.apiUrl + 'api/v1/users', credentials, this.common.httpOptions)
  //       .pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.post(environment.apiUrl + 'api/v1/users', credentials, httpOptions)
  //       .pipe(catchError(this.errorHandler));
  //   }

  // }
  // getDeptDetails(departmentId) {
  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/departments/' +
  //       departmentId, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/departments/' + departmentId, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // updateDept(credentials) {
  //   if (this.common.isHeader === true) {

  //     return this.http.put(environment.apiUrl + 'api/v1/departments',
  //       credentials, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.put(environment.apiUrl + 'api/v1/departments', credentials, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }


  // deptSave(credentials) {

  //   if (this.common.isHeader4 === true) {
  //     // this.common.isHeader4=false;
  //     return this.http.post(environment.apiUrl + 'api/v1/departments', credentials, this.common.httpOptions)
  //       .pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.post(environment.apiUrl + 'api/v1/departments', credentials, httpOptions)
  //       .pipe(catchError(this.errorHandler));
  //   }


  // }



  // getDepartment() {

  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/configs/departments?page=' +
  //       this.common.pageNo + '&size=' + this.common.size, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/departments?page=' +
  //       this.common.pageNo + '&size=' + this.common.size, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // getSkills() {
  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/configs/skills', this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/skills', httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // addSkill(skill: any) {
  //   if (this.common.isHeader === true) {

  //     return this.http.post(environment.apiUrl + 'api/v1/configs/skills',
  //       skill, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.post(environment.apiUrl + 'api/v1/configs/skills', skill, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // getLocations() {
  //   if (this.common.isHeader === true) {
  //     return this.http.get(environment.apiUrl + 'api/v1/location', this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/location', httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }
  // departmentList(id) {
  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/departments/pickList/' + id, httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/departments/pickList/' + id, httpOptions).pipe(catchError(this.errorHandler));
  //   }

  // }

  // // getOrganizationPicklist() {
  // //   if (this.common.isHeader === true) {
  // //     return this.http.get(environment.apiUrl + 'api/v1/organization/pickList',
  // //       this.common.httpOptions).pipe(catchError(this.errorHandler));
  // //   } else {
  // //     return this.http.get(environment.apiUrl + 'api/v1/organization/pickList', httpOptions).pipe(catchError(this.errorHandler));
  // //   }
  // // }

  // organizationList() {
  //   if (this.common.isHeader === true) {
  //     return this.http.get(environment.apiUrl + 'api/v1/organization/pickList', httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/organization/pickList', httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // getOrganizationByID(id: any) {
  //   if (this.common.isHeader === true) {
  //     return this.http.get(environment.apiUrl + 'api/v1/organization/' + id, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/organization/' + id, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // getOrganizations() {
  //   if (this.common.isHeader === true) {
  //     return this.http.get(environment.apiUrl + 'api/v1/organization?page=' +
  //       this.common.pageNo + '&size=' + this.common.size, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/organization?page=' +
  //       this.common.pageNo + '&size=' + this.common.size, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }


  // addOrganization(credentials) {
  //   if (this.common.isHeader === true) {
  //     return this.http.post(environment.apiUrl + 'api/v1/organization',
  //       credentials, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.post(environment.apiUrl + 'api/v1/organization', credentials, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }



  // errorHandler = (error: HttpErrorResponse) => {
  //   return throwError(error);
  // }


  // updateOrganization(credentials) {
  //   if (this.common.isHeader === true) {
  //     return this.http.put(environment.apiUrl + 'api/v1/organization',
  //       credentials, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.put(environment.apiUrl + 'api/v1/organization', credentials, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // getCountry() {
  //   if (this.common.isHeader === true) {
  //     // ?page=0&size=195
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/countries', this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/countries', httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // getState(id) {
  //   if (this.common.isHeader === true) {
  //     // ?page=0&size=195
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/state?countryId=' +
  //       id, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/state?countryId=' + id, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // getCity(stateId: any) {
  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/configs/cities?stateId=' +
  //       stateId, this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/cities?stateId=' +
  //       stateId, httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }



  // getDateAndTimeFormat() {
  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/configs/dateTimeFormat',
  //       this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/dateTimeFormat', httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // getTimezone() {
  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/configs/timeZone', this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/timeZone', httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }

  // getTheme() {
  //   if (this.common.isHeader === true) {

  //     return this.http.get(environment.apiUrl + 'api/v1/configs/themes', this.common.httpOptions).pipe(catchError(this.errorHandler));
  //   } else {
  //     return this.http.get(environment.apiUrl + 'api/v1/configs/themes', httpOptions).pipe(catchError(this.errorHandler));
  //   }
  // }
}



