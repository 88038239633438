import { Directive, HostListener, ElementRef, Input } from '@angular/core';
@Directive({
  selector: '[appPinCode]'
})
export class PinCodeDirective {

  regexStr = '^[0-9]*$';
  
  @Input() isNumeric: boolean;

  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

}
