import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuardService } from '../core/guards/auth-guard.service';
import { ComplaintFeedbackLinkComponent } from '../modules/admin/modules/Smart-Washroom/TabletComponents/complaint-feedback-link/complaint-feedback-link.component';
import { RoutePaths } from './routing-constant';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import { SmsUrlComponent } from './sms-url/sms-url.component';
import { WorkorderListLinkComponent } from '../modules/admin/modules/Smart-Washroom/TabletComponents/workorder-list-link/workorder-list-link.component';
import { WashroomCalculatorComponent } from '../modules/admin/modules/Smart-Washroom/Services/washroom-calculator/washroom-calculator.component';

const routes: Routes = [

  {
    path: '', pathMatch: 'full', redirectTo: RoutePaths.admin
  },
  {
    path:'washroom-feedback/:data',component:ComplaintFeedbackLinkComponent,
  },
  {
    path:'washroom-ticket/:data',component:WorkorderListLinkComponent,
  },
  {
    path:'hcstaff/resetPassword',component:SetNewPasswordComponent,
  },
  {
    path:'entstaff/resetPassword',component:SetNewPasswordComponent,
  },
  {
    path:'hcstaff',component:SmsUrlComponent,
  },
  {
    path:'entstaff',component:SmsUrlComponent,
  },
  {
    path:'entconsumer',component:SmsUrlComponent,
  },
  {
    path:'hcconsumer',component:SmsUrlComponent,
  },
  {
    path:'washroom-calculator',component:WashroomCalculatorComponent,
  },
  {
    path: RoutePaths.authentication,
    loadChildren: () => import('../modules/Authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: RoutePaths.admin,
    loadChildren: () => import('../modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuardService]
  },
  {
    path: RoutePaths.employee,
    loadChildren: () => import('../modules/employee/employee.module').then(m => m.EmployeeModule),
    canActivate: [AuthGuardService]
  },
  {
    path: '**',
    redirectTo: 'admin/dashboard'
  }
  // https://qa.caleedo.net/hcstaff/resetPassword?userId=STAFF123456&token=50R609

];
// {preloadingStrategy: PreloadAllModules}
@NgModule({
  imports: [RouterModule.forRoot(routes, )],
  exports: [RouterModule]
})

export class AppRoutingModule { }
