import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { PatientService } from '../../../service/patient.service';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PatientListingModel } from '../../../model/patient-list.model';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from '../../../../../../shared/component/toaster/toaster.component';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';

@Component({
  selector: 'app-workflow-list-view',
  templateUrl: './workflow-listview.html',
  styleUrls: ['./workflow-listview.scss']
})

export class WorkflowListViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showMobileTable: boolean;
  @Input() filterValueChange: object;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  public pageInfo: PaginationModel = new PaginationModel();
  element: any = [];
  noResult: boolean = false;
  searchEventSubscription: Subscription;
  saveSearch: any = '';
  data;
  showPagination = true;
  checkedPatientsId: any = [];
  headCheckBox;
  allChecked = false;
  pages;
  private subscriptions: Subscription[] = [];
  patientCodeArray: any = [];
  departmentArray: any = [];
  pageSizeArray = [10, 50, 100];
  patientStatusArray: any = [];
  permissionsObject = { read: false, create: false, update: false, delete: false };
  pageSize: number = 10;
  branchId = localStorage.getItem('branchId');
  customerId = localStorage.getItem('customerId')
  roleList: any = [];
  workflowForm: any;
  @HostListener('click') onMouseEnter() {
    this.commonService.sendFilterCloseEvent();
  }
  constructor(
    public router: Router,
    private patientService: PatientService,
    public commonService: CommonServiceService,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    private dialog: DialogService,
    private loaderService: LoaderService,
    public matDialog: MatDialog,
    private picklistService: PicklistService) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
  }
  pageSizeChange(event) {
    if (event.value) {
      this.pageInfo.page = 0;
      this.pageInfo.size = event.value;
      this.listing();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  ngOnChanges(event) {
    this.patientCodeArray = [];
    this.departmentArray = [];
    this.patientStatusArray = [];
    if (this.filterValueChange) {
      this.filterValueChange['PatientCode'].forEach(x => this.patientCodeArray.push(x.id));
      this.filterValueChange['department'].forEach(x => this.departmentArray.push(x.id));
      this.filterValueChange['patientStatus'].forEach(x => this.patientStatusArray.push(x.id));
      this.listing();
    }

  }

  ngOnInit() {
    this.getRoleList();
    this.permissionsObject = this.commonService.setPermissions('PATIENT_WORKFLOW');
    this.headCheckBox = false;
    this.subscriptions.push(this.commonService.getSearchEvent().subscribe(() => {
      this.search();
    }));
    this.showPagination = true;
    this.commonService.head = 'patientName';
    // this.listing();
  }

  mapData(item, headers) {
    return {
      heading: headers,
      content: item
    };
  }

  allCheckbox(event: any) {
    const all = event.target.checked;
    // select all patients
    if (all) {
      this.checkedPatientsId = [];
      this.element.forEach(element => {
        this.checkedPatientsId.push(element.id);
      });
    } else {
      this.checkedPatientsId = [];
    }
    this.element.forEach(element => {
      element.selected = all;
    });
  }

  checkbox(event: any, i: any) {
    let flag = false;
    for (let j = 0; j < this.pageInfo.size; j++) {
      if (this.checkedPatientsId[j] === i) {
        this.checkedPatientsId.splice(this.checkedPatientsId.indexOf(i), 1);
        flag = true;
      }
    }
    if (!flag) {
      this.checkedPatientsId.push(i);
    }
    this.element.forEach(elm => {
      if (elm.id === i) {
        elm.selected = true;
      }
    });
  }

  SetPage(event: any) {
    this.pageInfo.page = event.page;
    this.pageInfo.size = event.size;
    this.listing();
  }

  search() {
    if (this.saveSearch !== '') {
      this.commonService.searchText = this.saveSearch;
      this.saveSearch = '';
    }
    this.showPagination = true;
    this.pageInfo.page = 0;
    this.listing();
  }

  sort(colName: string, sortDirection: any) {
    if (this.pageInfo.sortBy === colName) {
      if (sortDirection == 'ASC') {
        this.commonService.head = '';
        this.commonService.sort = 'DESC';
        this.pageInfo.sortBy = '';
        this.pageInfo.sortDir = 'DESC';
      } else {
        this.commonService.sort = sortDirection;
        this.pageInfo.sortDir = sortDirection;
      }
    } else {
      this.commonService.head = colName;
      this.commonService.sort = 'ASC';
      this.pageInfo.sortBy = colName;
      this.pageInfo.sortDir = 'ASC';
    }
    this.listing();
  }

  listing() {
    this.headCheckBox = false;
    this.checkedPatientsId = [];
    this.loaderService.show();
    this.patientService.getWorkFlowList(this.pageInfo)
      .subscribe((response: any) => {
        this.loaderService.hide();
        this.pageInfo.TotalRecord = response.data.totalElements;
        this.pageInfo.totalPages = response.data.totalPage;
        if (response.data.payload.length) {
          this.noResult = false;
          this.element = response.data.payload;
          this.element.forEach(item => {
            // if (item.type === 'PATIENT_APPROVAL_BY_STAFF_IS_ENABLE' || item.type == 'PATIENT_CARETEAM_FLOW_MANDATORY') {
            // item.value = +item.value ? 'False' : 'True';
            // }
            let valueList = [];
            if (item.type === 'PATIENT_ONBOARDING_APPROVAL') {
              item.values.forEach(element => {
                let index = this.roleList.findIndex(role => role.id == element);
                valueList.push(this.roleList[index].name);
              });
            } else if (item.type == "PATIENT_APPROVAL_BY_STAFF_IS_ENABLE" || item.type == 'PATIENT_CARETEAM_FLOW_MANDATORY') {
              item.values.forEach(element => {
                valueList.push(element == "0" ? 'True' : 'False');
              });
            }
            else {
              item.values.forEach(element => {
                valueList.push(element);
              });
            }
            item.value = valueList.join(", ");
          })
          this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
        } else {
          this.noResult = true;
          this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
        }
      }, error => {
        this.loaderService.hide();
        this.errorSnackbar(error);
      })
  }

  getRoleList() {
    this.loaderService.show();
    const customerId = localStorage.getItem('customerId');
    this.picklistService.getRole(customerId).subscribe(res => {
      this.loaderService.hide();
      this.roleList = res['data'];
      this.listing();
    }, err => {
      this.loaderService.hide();
      this.errorSnackbar(err);
    })
  }

  editWorkFlow(id: any) {
    this.router.navigate(['admin/patient-list/edit-workflow/' + id]);
  }

  printQRCode(id) {
    this.dialog.openPrintQRCodePopUp(id)
      .afterClosed()
      .subscribe(response => {
        if (response) {
        } else {

        }
      });
  }

  errorSnackbar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error }, type: 'error' },
      ErrorToasterComponent
    );
  }

  openSnackBar(message: string) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message }, type: 'success' },
      ToasterComponent
    );
  }
}
