import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import io from "socket.io-client";
import { environment } from '../../../../src/app/config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  socket:any;

  constructor() { 
    // this.socket = io(environment.apiBaseUrl, {
    //   path:'/hygiene/ws/',transports: ["websocket","polling"]
    //   });

    // this.socket.on('connect',  (val)=> {
    //   console.log('connected to server',val);
    // });
    // this.socket.on('disconnect',  (val)=> {
    //   console.log('disconnected to server',val);
    // });

    // this.socket.on("connect_error", (err) => {
    //   console.log("IN Connect Error",err)
    //   // this.socket.auth.token = localStorage.getItem('refreshToken');
    //   this.socket.on('connect')
    // });
    
  }

  listen(eventName:string){
    console.log("IN SOCKET",eventName);
    return new Observable((subscriber)=>{
      this.socket.on(eventName,(data)=>{
        subscriber.next(data);
      })
    })
  }

  emit(eventName:string, data:any){
    this.socket.emit(eventName,data);
  }

  

}
