import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ToasterComponent } from '../component/toaster/toaster.component';

export class ToastConfig {
  toastName: string;
  data: any;
  type?: string;
}

export class SnackBarConfig extends MatSnackBarConfig {
  model: ToastConfig;
}

@Injectable()
export class ToastService {
  constructor(
    public snackBar: MatSnackBar
  ) {
  }
  opentoast(toastConfig: ToastConfig, componentToPass = ToasterComponent) {
    let config = new SnackBarConfig();
    config.duration = 3500;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.panelClass = toastConfig.type === 'success' ? ['success-snackbar'] : null;
    config.model = toastConfig;
    this.snackBar.openFromComponent(componentToPass, config);
    
  }

}
