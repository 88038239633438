import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';

@Component({
  selector: 'app-error-toaster',
  templateUrl: './error-toaster.html',
  styleUrls: ['./error-toaster.scss']

})
export class ErrorToasterComponent {
  public model: any;
  constructor(
    public snackBarRef: MatSnackBarRef<ErrorToasterComponent>
  ) {
    this.model = snackBarRef.containerInstance.snackBarConfig['model'];
   }


}
