import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { PatientService } from '../../../service/patient.service';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PatientListingModel, PatientPayload } from '../../../model/patient-list.model';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';

@Component({
  selector: 'app-visiting-list-view',
  templateUrl: './visiting-listview.html',
  styleUrls: ['./visiting-listview.scss']
})

export class VisitingListViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showMobileTable: boolean;
  @Input() filterValueChange: object;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  public pageInfo: PaginationModel = new PaginationModel();
  element: any = [];
  noResult: boolean = false;
  searchEventSubscription: Subscription;
  saveSearch: any = '';
  message;
  data;
  showPagination = true;
  bulkUpdateActions: any = [];
  checkedPatientsId: any = [];
  headCheckBox;
  bulkActionDropDown: any = '';
  allChecked = false;
  pages;
  private subscriptions: Subscription[] = [];
  patientCodeArray: any = [];
  departmentArray: any = [];
  organisationArray: any = [];
  reportingManagerArray: any = [];
  pageSizeArray = [10, 50, 100];
  patientStatusArray: any = [];
  associatedwith: any = 'user';
  permissionsObject = { read: false, create: false, update: false, delete: false };
  pageSize: number = 10;
  branchId = localStorage.getItem('branchId');
  customerId = localStorage.getItem('customerId')
  @HostListener('click') onMouseEnter() {
    this.commonService.sendFilterCloseEvent();
  }
  constructor(
    public router: Router,
    private patientService: PatientService,
    public commonService: CommonServiceService,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    private dialog: DialogService,
    private loaderService: LoaderService,
    public matDialog: MatDialog) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
  }
  pageSizeChange(event) {
    if (event.value) {
      this.pageInfo.page = 0;
      this.pageInfo.size = event.value;
      this.listing();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(event) {
    this.patientCodeArray = [];
    this.departmentArray = [];
    this.organisationArray = [];
    this.reportingManagerArray = [];
    this.patientStatusArray = [];
    if (this.filterValueChange) {
      this.filterValueChange['PatientCode'].forEach(x => this.patientCodeArray.push(x.id));
      this.filterValueChange['department'].forEach(x => this.departmentArray.push(x.id));
      this.filterValueChange['organisation'].forEach(x => this.organisationArray.push(x.id));
      this.filterValueChange['reportingManager'].forEach(x => this.reportingManagerArray.push(x.id));
      this.filterValueChange['patientStatus'].forEach(x => this.patientStatusArray.push(x.id));
      this.listing();
    }
  }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_VISITING_HOURS');

    this.headCheckBox = false;
    this.subscriptions.push(this.commonService.getSearchEvent().subscribe(() => {
      this.search();
    }));
    this.showPagination = true;
    this.commonService.head = 'name';
    this.listing();
  }

  mapData(item, headers) {
    return {
      heading: headers,
      content: item
    };
  }

  allCheckbox(event: any) {
    const all = event.target.checked;
    // select all patients
    if (all) {
      this.checkedPatientsId = [];
      this.element.forEach(element => {
        this.checkedPatientsId.push(element.id);
      });
    } else {
      this.checkedPatientsId = [];
    }
    this.element.forEach(element => {
      element.selected = all;
    });
  }

  checkbox(event: any, i: any) {
    let flag = false;
    for (let j = 0; j < this.pageInfo.size; j++) {
      if (this.checkedPatientsId[j] === i) {
        this.checkedPatientsId.splice(this.checkedPatientsId.indexOf(i), 1);
        flag = true;
      }
    }
    if (!flag) {
      this.checkedPatientsId.push(i);
    }
    this.element.forEach(elm => {
      if (elm.id === i) {
        elm.selected = true;
      }
    });
  }

  SetPage(event: any) {
    this.pageInfo.page = event.page;
    this.pageInfo.size = event.size;
    this.listing();
  }

  search() {
    if (this.saveSearch !== '') {
      this.commonService.searchText = this.saveSearch;
      this.saveSearch = '';
    }
    this.showPagination = true;
    this.pageInfo.page = 0;
    this.listing();
  }

  sort(colName: string, sortDirection: any) {
    if (this.pageInfo.sortBy === colName) {
      if (sortDirection == 'ASC') {
        this.commonService.head = '';
        this.commonService.sort = 'DESC';
        this.pageInfo.sortBy = '';
        this.pageInfo.sortDir = 'DESC';
      } else {
        this.commonService.sort = sortDirection;
        this.pageInfo.sortDir = sortDirection;
      }
    } else {
      this.commonService.head = colName;
      this.commonService.sort = 'ASC';
      this.pageInfo.sortBy = colName;
      this.pageInfo.sortDir = 'ASC';
    }
    this.listing();
  }

  // --------pagination------------------------------------------------------------------------------//

  mapKeysOfFilter() {
    const body = {};
    this.departmentArray.length > 0 ? body['departments'] = this.departmentArray : null;
    this.organisationArray.length > 0 ? body['organizations'] = this.organisationArray : null;
    this.reportingManagerArray.length > 0 ? body['reportingManager'] = this.reportingManagerArray : null;
    this.patientCodeArray.length > 0 ? body['users'] = this.patientCodeArray : null;
    this.patientStatusArray.length > 0 ? body['patientStatus'] = this.patientStatusArray : null;
    return body;
  }

  listing() {
    // const body = this.mapKeysOfFilter();
    this.checkedPatientsId = [];
    this.headCheckBox = false;
    this.loaderService.show();
    this.patientService.visitingInfoList(this.pageInfo, this.branchId, this.customerId)
      .subscribe((response: PatientPayload) => {
        this.loaderService.hide();
        this.pageInfo.TotalRecord = response.totalElements;
        this.pageInfo.totalPages = response.totalPage;
        this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
        if (response.payload.length) {
          this.noResult = false;
          this.element = response.payload
        } else {
          this.noResult = true;
        }
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      })
  }

  editVisitingHours(id: any) {
    this.router.navigate(['admin/patient-list/add-visiting-hours/' + id]);
  }

  printQRCode(id) {
    this.dialog.openPrintQRCodePopUp(id)
      .afterClosed()
      .subscribe(response => {
        if (response) {
        } else {

        }
      });
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(message: string) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message }, type: 'success' },
      ToasterComponent
    );
  }
}
