import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkOrderService } from '../../services/work-order.service';
import { MatStepper } from '@angular/material';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';

/**
 * @title Stepper that displays errors in the steps
 */
@Component({
  selector: 'audit-trail-example',
  templateUrl: 'audit-trail.html',
  styleUrls: ['audit-trail.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class HygieneAuditTrailComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  workOrderId;
  isActive: boolean = true;
  statusTrailList = [];
  index:number;
  @ViewChild('stepper',{static:true}) stepper: MatStepper;
  constructor(private _formBuilder: FormBuilder, private router:Router, private activateRoute: ActivatedRoute, 
    private workOrderService: WorkOrderService, private loaderService: LoaderService) {}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
  } else {
      this.isActive = false;
  }
    this.activateRoute.params.subscribe(params=>{
      this.workOrderId = params.workOrderId;
      this.getAuditTrailDetails();
    })
  }

  getAuditTrailDetails() {
    this.loaderService.show();
    this.workOrderService.getAuditTrailDetails(this.workOrderId).subscribe(res=>{
      const data= res['data'];
      this.statusTrailList = [...data];
      this.index = this.statusTrailList.findIndex((item)=>{
          return item.endTime === null;
      })
    })
    this.loaderService.hide();
    
  }
}
