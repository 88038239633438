import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatCheckboxModule, MatDatepickerModule, MatDialog, MatDialogModule, MatDialogRef, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatSelectModule, MatTabsModule } from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { WorkOrderComponent } from './component/work-order/work-order.component';
import { WorkOrderListComponent } from './component/listview/listview.component';
import { WorkOrderRoutingModule } from './work-order.routing.module';
import { AddWorkOrderComponent } from './component/add-work-order/add-work-order.component';
import { CalendarViewComponent } from './component/calendar-view/calendar-view.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { AngularCalendarYearViewModule } from './library/angular-calendar-year-view/src/public-api';
import { AddEventComponent } from './component/add-event/add-event.component';
import { MatStepperModule } from '@angular/material/stepper';
import { HygieneAuditTrailComponent } from './component/audit-trail/audit-trail.component';
import { ChildWorkOrderListComponent } from './component/child-work-order-listview/child-work-order-listview.component'
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { WorkOrderFilterViewComponent } from './component/work-order-filter-view/work-order-filter-view';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RefreshTokenInterceptor } from 'src/app/core/interceptors/auth-response.interceptor';
import {WorkOrderFailureListComponent} from './component/work-order-failure-list/work-order-failure-list.component';
import { CategoryFilterViewComponent } from '../work-order/component/filter-view/filter-view';
@NgModule({
  declarations: [
    WorkOrderComponent,
    WorkOrderListComponent,
    AddWorkOrderComponent,
    CalendarViewComponent,
    AddEventComponent,
    HygieneAuditTrailComponent,
    ChildWorkOrderListComponent,
    WorkOrderFilterViewComponent,
    WorkOrderFailureListComponent,
    CategoryFilterViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatTabsModule,
    FontAwesomeModule,
    MatExpansionModule,
    NgxPaginationModule,
    NgxMatSelectSearchModule,
    SharedModule,
    NgbModalModule,
    WorkOrderRoutingModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FlatpickrModule.forRoot(),
    // AngularCalendarYearViewModule
    AngularCalendarYearViewModule.forRoot(),
    MatStepperModule,
    FullCalendarModule,
    MatDialogModule,
    NgxQRCodeModule,
    FontAwesomeModule,
    InfiniteScrollModule
  ],
  entryComponents: [AddEventComponent],
  providers: [
    { provide: MatDialogRef },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
  ]

})
export class WorkOrderModule { }
