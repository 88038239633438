import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatConfirmDialogComponent } from '../modules/shared/component/mat-confirm-dialog/mat-confirm-dialog.component';
import { MatMoveDocumentComponent } from '../modules/shared/component/mat-move-document/mat-move-document.component';
import { MatAddDocumentComponent } from '../modules/shared/component/mat-add-document/mat-add-document.component';
import { MatShareDocumentComponent } from '../modules/shared/component/mat-share-document/mat-share-document.component';
import { OtpComponent } from '../modules/admin/modules/patient/components/patient/otp/otp.component';
import { PrintQrCodeComponent } from '../modules/admin/modules/patient/components/patient/print-qr-code/print-qr-code.component';
import { QrCodeComponent } from '../modules/admin/modules/patient/components/patient/qr-code/qr-code.component';
@Injectable({
  providedIn: 'root'
})
export class DialogService {
  
  constructor(private dialog: MatDialog) { }
  openConfirmDialog(list, line) {
    return this.dialog.open(MatConfirmDialogComponent, {
      width: '390',
      disableClose: true,
      data: {
        title: list,
        line: line
      }
    });
  }

  openReissueQRPopUp(id, mobileNo, data: any) {
    return this.dialog.open(OtpComponent, {
      width: 'auto',
      // disableClose: true,
      // autoFocus: false,
      maxHeight: 'auto', //you can adjust the value as per your view
      data: {
        id, mobileNo, patientData: data
      }
    });
  }

  public openDialog(component, data): any { 
    return this.dialog.open(component, {
      data: data
    });
  }

  openPrintQRCodePopUp(id) {
    return this.dialog.open(PrintQrCodeComponent, {
      width: '90vh',
      // disableClose: true,
      // autoFocus: false,
      maxHeight: '70vh', //you can adjust the value as per your view
      data: {
        id
        // , patientData : data
      }
    });
  }

  openQRCodePopUp(attendantData, visitorData?) {
    return this.dialog.open(QrCodeComponent, {
      width: 'auto',
      // disableClose: true,
      // autoFocus: false,
      maxHeight: 'auto', //you can adjust the value as per your view
      data: {
        attendantData,
        visitorData
      }
      // , patientData : data
      // ?
    });
  }
  openAddDocumentPopUp(value: any) {
    return this.dialog.open(MatAddDocumentComponent, {
      width: '100vh',
      disableClose: true,
      autoFocus: false,
      maxHeight: '70vh', //you can adjust the value as per your view
      data: {
        value
      }
    });
  }
  openMoveDocumentPopUp(value: any) {
    return this.dialog.open(MatMoveDocumentComponent, {
      width: '80vh',
      disableClose: true,
      autoFocus: false,
      maxHeight: '70vh', //you can adjust the value as per your view
      data: {
        value
      }
    });
  }
  openShareDocumentPopUp(value: any) {
    return this.dialog.open(MatShareDocumentComponent, {
      width: '80vh',
      disableClose: true,
      autoFocus: false,
      maxHeight: '70vh', //you can adjust the value as per your view
      data: {
        value
      }
    });
  }
}
