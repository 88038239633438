import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from 'src/app/core/utilities/format-datepicker';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { NameValidator } from 'src/app/modules/shared/validators/name.validator';
import * as moment from 'moment';
import { DataModel, GenericPicklistModel } from 'src/app/modules/shared/model/picklist.model';
import { PatientService } from '../../../service/patient.service';
import { PatientData, PatientDescModel } from '../../../model/patient-list.model';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { map, startWith } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
@Component({
  selector: 'app-add-visiting-hours',
  templateUrl: './add-visiting-hours.component.html',
  styleUrls: ['./add-visiting-hours.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class AddVisitingHoursComponent implements OnInit {
  isActive;
  patientForm: FormGroup;
  patients: any = [];
  organizations: any = [];
  patientes: any = [];
  buildings: any = [];
  id;
  updating = false;
  message;
  updateStatus;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  branchList: any = [];
  customerId = localStorage.getItem('customerId');
  branchId = localStorage.getItem('branchId');
  departmentList: any[];
  inTime = '00:00';
  outTime = '00:00';
  min: any;
  max: any;
  visitTypeList: any[] = [];
  rolesList: any[];
  filteredDepartments: Observable<any[]>;
  filteredRolesList: Observable<any>;
  filteredVisitTypeList: Observable<any>;
  // roomList: any[];
  // filteredRooms: Observable<any[]>;
  constructor(
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public commonService: CommonServiceService,
    private router: Router,
    private fb: FormBuilder,
    private picklistService: PicklistService,
    private patientService: PatientService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_VISITING_HOURS');
    this.commonService.callToggleAddEmp.subscribe(() => {
      this.change();
    });
    this.initializeForm();
    this.getParams();
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    this.getBranch();
    this.getDepartment();
    this.getVisitType();
  }

  getParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.updating = true;
        this.id = params.id;
        this.patchValuesInPatientForm();
      }
    });
  }

  initializeForm() {
    this.patientForm = this.fb.group({
      departmentId: ['', [Validators.required]],
      departmentFilterCtrl: [''],
      // roomId: ['', [Validators.required]],
      // roomFiltrCtrl: [''],
      entryTime: ['', [Validators.required]],
      exitTime: ['', [Validators.required]],
      visitType: ['', [Validators.required]],
      visitTypeFilterCtrl: [''],
      role: ['', [Validators.required]],
      roleFilterCtrl: [''],
    });
  }

  change() {
    this.isActive = !this.isActive;
  }

  setMinvalue() {
    if (this.patientForm.value.entryTime) {
      this.min = this.patientForm.value.entryTime;
    }
  }

  setMaxValue() {
    if (this.patientForm.value.exitTime) {
      this.max = this.patientForm.value.exitTime;
    }
  }

  msToTime(duration) {
    if (!duration) {
      return '';
    }
    var seconds = Math.floor((duration / 1000) % 60);
    var minutes = Math.floor((duration / (1000 * 60)) % 60);
    var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    var hour = (hours < 10) ? '0' + hours : hours;
    var minute = (minutes < 10) ? '0' + minutes : minutes;
    var second = (seconds < 10) ? '0' + seconds : seconds;
    return hour + ':' + minute;
  }
  getBranch() {
    this.picklistService.getBranchByIndustry("1", this.customerId)
      .subscribe((res: DataModel) => {
        this.branchList = [];
        if (res.data.length) {
          res.data.map((x) => {
            this.branchList.push({ value: x.id, name: x.name });
          });
        }
      })
  }

  patchValuesInPatientForm() {
    this.loaderService.show();
    this.patientService
      .getVisitingHoursById(this.id)
      .subscribe((res: PatientData) => {
        this.loaderService.hide();
        this.patientForm.get('departmentId').patchValue(res.data.departmentId);
        // this.getRoom();
        // this.patientForm.get('roomId').patchValue(res.data.roomId);
        this.inTime = this.timeFormat(res.data.inTime);
        this.outTime = this.timeFormat(res.data.outTime);
        this.patientForm.get('entryTime').patchValue(this.inTime);
        this.patientForm.get('exitTime').patchValue(this.outTime);
        this.patientForm.get('visitType').patchValue(res.data.visitType);
        if (this.patientForm.controls.visitType.value === 'EVISIT_MODERATOR') {
          this.getRoles();
          this.patientForm.get('role').patchValue({ id: res.data.roleId });
        } else {
          this.patientForm.get('role').clearValidators();
          this.patientForm.get('role').updateValueAndValidity();
        }
      });
  }

  timeFormat(hms) {
    const a = hms.split(':'); // split it at the colons
    const hr = a[0];
    const min = a[1];
    return `${hr}:${min}`;
  }

  getDepartment() {
    this.departmentList = [];
    this.picklistService.getDepartment(this.branchId, this.customerId).subscribe((res: DataModel) => {
      if (res.data.length) {
        res.data.map((x) => {
          this.departmentList.push({ value: x.id, name: x.name });
        });
      }
      this.filteredDepartments = this.patientForm.controls['departmentFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => { return this.commonService.dataFilter(value, this.departmentList) })
        );
    },error=>{

    });
  }

  // getRoom() {
  //   let branchId = this.branchId;
  //   let departmentId = this.patientForm.value.departmentId;
  //   if (branchId && departmentId) {
  //     this.patientForm.controls.roomId.reset();
  //     this.loaderService.show();
  //     this.picklistService.getRoomByDept(this.customerId, branchId, departmentId).subscribe((res: DataModel) => {
  //       this.loaderService.hide();
  //       this.roomList = [];
  //       this.filteredRooms = of([]);
  //       if (res.data.length) {
  //         res.data.map((x) => {
  //           this.roomList.push({ value: x.id, name: x.name });
  //         });
  //         this.filteredRooms = this.patientForm.controls['roomFiltrCtrl'].valueChanges.
  //           pipe(
  //             startWith(''),
  //             map(value => this.dataFilter(value, this.roomList))
  //           );
  //       }
  //     }, error => {
  //       this.loaderService.hide();
  //       this.errorSnackBar(error);
  //     });
  //   }
  // }

  dataFilter(value, DataOptions) {
    const filterValue = value.toLowerCase();
    return value != '' ?
      DataOptions.filter(option => option.name.toLowerCase().includes(filterValue)) : DataOptions;
  }

  getVisitType() {
    this.visitTypeList = [];
    this.loaderService.show();
    this.patientService.getVisitType().subscribe((res: any) => {
      this.loaderService.hide();
      this.visitTypeList = res.data;
      this.filteredVisitTypeList = this.patientForm.controls['visitTypeFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => {
            const filterValue = value.toLowerCase();
            return value != '' ?
              this.visitTypeList.filter(option => option.toLowerCase().includes(filterValue)) : this.visitTypeList
          })
        );
    }, error => {
      this.errorSnackBar(error);
      this.loaderService.hide();
    })
  }

  getRoles() {
    this.loaderService.show();
    this.patientForm.get('role').reset();
    this.rolesList = [];
    this.picklistService.getRole(this.customerId)
      .subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          res.data.map((x) => {
            if (x.active == true && (x.roleCode != 'PATIENT' && x.roleCode != 'VISITOR')) {
              this.rolesList.push({ id: x.id, name: x.name, roleCode: x.roleCode })
            };
          });
        }
        this.filteredRolesList = this.patientForm.controls['roleFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.rolesList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
  }

  selectionChange(value) {
    if (value === 'EVISIT_MODERATOR') {
      this.patientForm.get('role').setValidators([Validators.required]);
      this.patientForm.get('role').updateValueAndValidity();
      this.getRoles();
    } else {
      this.patientForm.get('role').clearValidators();
      this.patientForm.get('role').reset();
    }
  }

  onSubmit3() {
    this.loaderService.show();
    const body = {
      branchId: this.branchId,
      departmentId: this.patientForm.value.departmentId,
      // roomId: this.patientForm.controls.roomId.value,
      inTime: this.patientForm.value.entryTime,
      outTime: this.patientForm.value.exitTime,
      visitType: this.patientForm.value.visitType,
      roleId: this.patientForm.value.role ? this.patientForm.value.role.id : '',
      customerId: this.customerId
    }
    const visitingInfoId = this.id;
    if (this.updating) {
      this.patientService.updateVisitingHoursById(visitingInfoId, body)
        .subscribe((res: PatientDescModel) => {
          this.loaderService.hide();
          this.openSnackBar('success', res.description);
          this.router.navigate(['admin/patient-list/visiting-hours']);
        },
          (error) => {
            this.loaderService.hide();
            this.errorSnackBar(error);
          });
    } else {
      this.patientService.addVisitingHours({ ...body, customerId: this.customerId })
        .subscribe((res: PatientDescModel) => {
          this.loaderService.hide();
          this.openSnackBar('success', res.description);

          this.router.navigate(['admin/patient-list/visiting-hours']);
        },
          (error) => {
            this.loaderService.hide();
            this.errorSnackBar(error);
          });
    }
  }

  roleComparision(option, value) {
    return option.id === value.id;
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error }, type: 'error' },
      ErrorToasterComponent
    );
  }

  openSnackBar(panelClass: string, message) {
    if (panelClass === 'success') {
      this.toastService.opentoast(
        { toastName: 'success', data: { name: '', message: message }, type: 'success' },
        ToasterComponent
      );
    }
  }

  cancel() {
    this.router.navigate(['admin/patient-list/visiting-hours']);
  }
}
