import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { httpHeaderInterceptor } from '../core/interceptors/httpHeader.interceptor';
import { RefreshTokenInterceptor} from '../core/interceptors/auth-response.interceptor';
import { AccountSetupModule } from '../modules/admin/modules/account-setup/account-setup.module';
import { SharedModule } from '../modules/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { OtpComponent } from '../modules/admin/modules/patient/components/patient/otp/otp.component';
import { PatientModule } from '../modules/admin/modules/patient/patient.module';
import { PrintQrCodeComponent } from '../modules/admin/modules/patient/components/patient/print-qr-code/print-qr-code.component';
import { QrCodeComponent } from '../modules/admin/modules/patient/components/patient/qr-code/qr-code.component';
import { WorkOrderModule } from '../modules/admin/modules/work-order/work-order.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MatDialogModule } from '@angular/material';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import { SmsUrlComponent } from './sms-url/sms-url.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AsyncPipe } from "@angular/common";
import { environment } from "../config/environments/environment";
import { MessagingService } from '../modules/shared/services/messaging.service';
import { ComplaintFeedbackLinkComponent } from '../modules/admin/modules/Smart-Washroom/TabletComponents/complaint-feedback-link/complaint-feedback-link.component';
import { WorkorderListLinkComponent } from '../modules/admin/modules/Smart-Washroom/TabletComponents/workorder-list-link/workorder-list-link.component';
import {MatChipsModule} from '@angular/material/chips';

import { SSO } from '../../../src/app/config/constant';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { MsalUserServiceService } from '../modules/shared/msal-user-service.service';
import { WashroomCalculatorComponent } from '../modules/admin/modules/Smart-Washroom/Services/washroom-calculator/washroom-calculator.component';
  
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
]);

@NgModule({
  declarations: [
    AppComponent,
    SetNewPasswordComponent,
    SmsUrlComponent,
    ComplaintFeedbackLinkComponent,
    WorkorderListLinkComponent,
    WashroomCalculatorComponent
  ],

  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AccountSetupModule,
    NgOtpInputModule,
    PatientModule,
    WorkOrderModule,
    FlexLayoutModule,
    FullCalendarModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    SharedModule,
    MatChipsModule,
    MsalModule.forRoot({
      clientID: SSO.clientId,
      // authority: "https://login.microsoftonline.com/" +SSO.tenantId,
      // redirectUri: 'http://localhost:4200/auth/login',
    })
  ],
  entryComponents:[
    OtpComponent,
    PrintQrCodeComponent,
    QrCodeComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MessagingService,
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: httpHeaderInterceptor, multi: true },
    MsalUserServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
      ],
  bootstrap: [AppComponent]
})
export class AppModule { }
