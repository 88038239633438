import { Component, OnInit, ViewChild, OnDestroy, Inject, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { PatientService } from '../../../service/patient.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { DialogService } from 'src/app/core/dialog.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { PrintQRCodeModel } from '../../../model/patient-list.model';
@Component({
  selector: 'app-print-qr-code',
  templateUrl: './print-qr-code.component.html',
  styleUrls: ['./print-qr-code.component.scss']
})
export class PrintQrCodeComponent implements OnInit, OnDestroy {
  public QRTypeForm: FormGroup;
  public isVerified = false;
  public resend = false;
  private display: string;
  private time;
  public isOtpVerified = false;
  isValidOtp = false;
  private otp: string = '';
  public showOtpComponent = true;
  public keyTypeList: any = [{ value: "ATTENDANT", name: "Attendant" }, { value: "VISITOR", name: "Visitor" }, { value: "BOTH", name: "Both" }];
  attendantQRData: any = JSON.stringify(this.data);
  visitorQRData: any = JSON.stringify(this.data);
  imageChangedEvent: any = '';
  croppedImage: any = '';
  url: any = '';
  databaseUrl: string;
  val = null;
  @ViewChild('fileinput', { static: false }) fileInput: ElementRef;
  @ViewChild('attendedParent', { static: false }) attendedParent: ElementRef;
  @ViewChild('visitorParent', { static: false }) visitorParent: ElementRef;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  selectedKey = '';
  attendantKey = '';
  visitorKey = '';
  isImageLoading: boolean;
  imageToShow: any;
  isGenerateQrCode: boolean;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    public toastService: ToastService,
    private patientService: PatientService,
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<PrintQrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  public ngOnInit() {
    // this.attendantQRData = `Patient ID: ${this.data.patientId}, Patient Name: ${this.data.patientName}, Doctor Name: ${this.data.doctorName}, Card Nature: PATIENT`
    // this.visitorQRData = `Patient ID: ${this.data.patientId}, Patient Name: ${this.data.patientName}, Doctor Name: ${this.data.doctorName}, Card Nature: PATIENT`
    let attendantData = {
      customerId: localStorage.getItem('customerId'),
      attendantCode: this.data.attendantCode,
      deptName: this.data.departmentName,
      admittedDoctorName: this.data.doctorName,
      mobileNo: this.data.mobileNo,
      patientId: this.data.patientId,
      patientName: this.data.patientFirstName,
      roomNo: this.data.roomNumber,
      otp: this.data.otp,
      natureOfCard: "attendant",
    }
    this.attendantQRData = JSON.stringify(attendantData);
    let visitorData = {
      customerId: localStorage.getItem('customerId'),
      visitorCode: this.data.visitorCode,
      deptName: this.data.departmentName,
      admittedDoctorName: this.data.doctorName,
      mobileNo: this.data.mobileNo,
      patientId: this.data.patientId,
      patientName: this.data.patientFirstName,
      roomNo: this.data.roomNumber,
      otp: this.data.otp,
      natureOfCard: "visitor",
    }
    this.visitorQRData = JSON.stringify(visitorData);
    this.initialiseForm();
  }

  private initialiseForm() {
    this.QRTypeForm = this.formBuilder.group({
      keyType: ['', [Validators.required]]
    });
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  getKey() {
    this.selectedKey = this.QRTypeForm.value.keyType;
  }

  getPrintQrData(printSectionId: string) {
    this.patientService.printQRCode(this.data.id, this.selectedKey)
      .subscribe((res: PrintQRCodeModel) => {
        this.attendantKey = res.data.attendantQrCodeKey;
        this.visitorKey = res.data.visitorQrCodeKey;
        this.getImage(this.attendantKey, this.visitorKey);
      })
  }

  generateQrCode() {
    this.isGenerateQrCode = true;
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.loaderService.hide();
      if (this.selectedKey == 'ATTENDANT') {
        this.saveAsImage(this.attendedParent, '');
      } else if (this.selectedKey == 'VISITOR') {
        this.saveAsImage('', this.visitorParent)
      } else {
        this.saveAsImage(this.attendedParent, this.visitorParent)
      }
    }, 50)
  }

  async saveAsImage(attendedParent, visitorParent?) {
    let attendantBlobData;
    let visitorBlobData;
    this.patientService.isPrinting = true;
    // fetches base 64 date from image
    if (this.selectedKey == 'ATTENDANT' || this.selectedKey == 'BOTH') {
      const attendentElement = attendedParent.qrcElement.nativeElement.querySelector("img").src;
      attendantBlobData = this.convertBase64ToBlob(attendentElement);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
        window.navigator.msSaveOrOpenBlob(attendantBlobData, 'Qrcode');
      } else { // chrome
        const blob = new Blob([attendantBlobData], { type: "image/png" });
        let formData: FormData = new FormData();
        formData.append('file', blob);
        this.attendantKey = await this.patientService.uploadFile(formData).toPromise();
      }
    }
    if (this.selectedKey == 'VISITOR' || this.selectedKey == 'BOTH') {
      const visitorElement = visitorParent.qrcElement.nativeElement.querySelector("img").src;

      visitorBlobData = this.convertBase64ToBlob(visitorElement);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
        window.navigator.msSaveOrOpenBlob(visitorBlobData, 'Qrcode');
      } else { // chrome
        const blob = new Blob([visitorBlobData], { type: "image/png" });
        let formData: FormData = new FormData();
        formData.append('file', blob);
        this.visitorKey = await this.patientService.uploadFile(formData).toPromise();
      }
    }
    this.getImage(this.attendantKey, this.visitorKey);
  }

  async getImage(key1, key2?) {
    let attendantQR;
    let visitorQR;
    if (key1) {
      this.loaderService.show();
      attendantQR = await this.patientService.getImage(key1).toPromise();
      this.loaderService.hide();
    } if (key2) {
      this.loaderService.show();
      visitorQR = await this.patientService.getImage(key2).toPromise();
      this.loaderService.hide();
    }
    this.dialogService.openQRCodePopUp({ attendantQR, patientData: this.data }, { visitorQR, patientData: this.data })
    this.dialogRef.close();
  }

  createImageFromBlob(image: Blob, printSectionId: string) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  public onOtpChange(otp) {
    this.otp = otp;
    if (this.otp.length === 4) {
      this.isValidOtp = true;
    } else {
      this.isValidOtp = false;
    }
  }

  private startTimer(duration) {
    this.display = "02:00";
    this.resend = false;
    var timer = duration, minutes, seconds;
    this.time = setInterval(() => {
      minutes = Math.floor(timer / 60);
      seconds = timer % 60;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      this.display = minutes + ":" + seconds;
      document.getElementById("time").innerHTML = this.display;
      if (--timer < 0) {
        this.resend = true;
        window.clearInterval(this.time);
      }
    }, 1000);
  }

  public verifyOtp() {
    if (this.otp) {
      this.patientService.verifyOTP(this.data.mobileNo, this.otp)
        .subscribe((res) => {
          this.isOtpVerified = true;
        }, (error) => {
        })
    }
  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }

  public cancel() {
    this.isVerified = false;
    this.resend = false;
    window.clearInterval(this.time);
  }

  openSnackBar(panelClass: string) {
    if (panelClass === 'Imagesize-not-supported') {
      this.toastService.opentoast({
        toastName: 'Image-size-not-supported',
        data: { name: '' },
      });
    }
  }

  public ngOnDestroy() {
    // this.patientService.isPrinting = false;
    window.clearInterval(this.time);
  }
}
