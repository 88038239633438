import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
@Injectable({
    providedIn: "root",
  })
@Injectable()
export class MessagingService {
    public Token;
    public notificationCount = 0;
    currentMessage = new BehaviorSubject(null);
    constructor(private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messages.subscribe(
            (_messaging: AngularFireMessaging) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        );
    }

    
    requestInboxPermission() {
        return this.angularFireMessaging.requestToken;
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
            this.notificationCount = parseInt(localStorage.getItem('notificationCount')) + parseInt('1');
            localStorage.setItem('notificationCount',this.notificationCount.toString());
            this.currentMessage.next(payload);
            this.showCustomNotification(payload);
        })
    }

    showCustomNotification(payload: any) {
        let notify_data = payload["notification"];
        let title = notify_data["title"];
        let options = {
          body: notify_data["body"],
          Icon: "",
          badge: "",
          Image: "",
        };
        let notify: Notification = new Notification(title, options);
    
        notify.onclick = (event) => {
          event.preventDefault();
        //   window.location.href = this.inboxUrl;
        };
      }

    getNotificationCount(){
        return localStorage.getItem('notificationCount');
    }



}