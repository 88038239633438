import { Component, Input, OnInit } from '@angular/core';
import { CarousalService } from '../../../../../../../src/app/modules/shared/services/carousal.service';
import * as moment from 'moment';

@Component({
  selector: 'app-slide-air-quality',
  templateUrl: './slide-air-quality.component.html',
  styleUrls: ['./slide-air-quality.component.scss']
})
export class SlideAirQualityComponent implements OnInit {
  @Input() aqiData: any
  @Input() title: any
  @Input() slideNumber;
  @Input() location;
  @Input() aqiUpdateDate:any='';
  @Input() colorAndStatus:boolean=false;
  @Input() hideAddressStrip:Boolean = false;
  public aqiDataS5 = [];
  allowTvoc:boolean;

  constructor( private carousalService: CarousalService,
    ) { }

    deviceAqi100: boolean = false;
    deviceAtp100: boolean = false;
    deviceAqi200: boolean = false;
    deviceAqi300: boolean = false;
    deviceAtp300: boolean = false;
  deviceSWH400: boolean = false;
  ngOnInit() {

    this.checkDeviceCategory();
    
    // if(localStorage.getItem("allowTvoc")=== "true")
    // {
    //   this.allowTvoc=true;
    //   this.aqiDataS5 = [{
    //     title: 'PM 2.5',
    //     key: 'pm25',
    //     status:'',
    //     value: '',
    //   }, {
    //     title: 'PM 10',
    //     key: 'pm10',
    //     status:  '',
    //     value:  '',
    //   }, {
    //     title: 'CO2',
    //     key: 'co2',
    //     status:  '',
    //     value: '',
    //   }, {
    //     title: 'RH',
    //     key: 'humidity',
    //     status: '',
    //     value:  '',
    //   }, {
    //     title: 'Temperature',
    //     key: 'air-temperature',
    //     status:  '',
    //     value: '',
    //   }, {
    //     title: 'TVOC',
    //     key: 'tvoc',
    //     status:  '',
    //     value: '',  
    //   }];
    // }
    // else{
    //   this.allowTvoc=false;
    //   this.aqiDataS5 = [{
    //     title: 'PM 2.5',
    //     key: 'pm25',
    //     status:'',
    //     value: '',
    //   }, {
    //     title: 'PM 10',
    //     key: 'pm10',
    //     status:  '',
    //     value:  '',
    //   }, {
    //     title: 'CO2',
    //     key: 'co2',
    //     status:  '',
    //     value: '',
    //   }, {
    //     title: 'RH',
    //     key: 'humidity',
    //     status: '',
    //     value:  '',
    //   }, {
    //     title: 'Temperature',
    //     key: 'air-temperature',
    //     status:  '',
    //     value: '',
    //   }];
    // }
    this.aqiDataS5=[];
    this.allowTvoc = localStorage.getItem("allowTvoc") === "true" ? true : false;
    let paramList = ['pm1', 'pm25', 'pm10', 'co2', 'humidity', 'temperature', 'tvoc', 'co', 'hcho', 'no2', 'o3'];

      paramList.forEach((el) => {
        if (el === 'pm1') {
          if (this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'PM 1',
          key: 'pm1',
          status:'',
          value: '',
            })
        }
      }
        else if (el === 'pm25') {
          if (this.deviceAqi100 || this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'PM 2.5',
              key: 'pm25',
              status:'',
              value: '',
            });
  
          }
        }
        else if (el === 'pm10') {
          if (this.deviceAqi100 || this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'PM 10',
              key: 'pm10',
              status:'',
              value: '',
            });
           
          }
        }
        else if (el === 'co2') {
          if (this.deviceAqi100 || this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'CO2',
          key: 'co2',
          status:'',
          value: '',
            });
           
          }
        }
        else if (el === 'humidity') {
          if (this.deviceAqi100 || this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'RH',
              key: 'humidity',
              status:'',
              value: '',
            });
           
          }
        }
        else if (el === 'temperature') {
          if (this.deviceAqi100 || this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'Temperature',
              key: 'air-temperature',
              status:'',
              value: '',
            });
       
          }
        }
        else if (el === 'tvoc') {
          if (this.deviceAqi300 || (this.deviceAqi100 && this.allowTvoc)) {
            this.aqiDataS5.push({
              title: 'TVOC',
              key: 'tvoc',
              status:'',
              value: '',
            });
         
          }
        }
        else if (el === 'hcho') {
          if (this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'HCHO',
              key: 'hcho',
              status:'',
              value: '',
            });
         
          }
        }
        else if (el === 'co') {
          if (this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'CO',
              key: 'co',
              status:'',
              value: '',
            });
       
          }
        }
        else if (el === 'no2') {
          if (this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'NO2',
              key: 'no2',
              status:'',
              value: '',
            });
          
          }
        }
        else if (el === 'o3') {
          if (this.deviceAqi300) {
            this.aqiDataS5.push({
              title: 'O3',
              key: 'o3',
              status:'',
              value: '',
            });
         
          }
        }
      });
    
    
  }



checkDeviceCategory() {
  let getDeviceCategories = JSON.parse(localStorage.getItem("categories"));
  if(getDeviceCategories){
  this.deviceAqi100 = getDeviceCategories.includes('s100_aqi') ? true : false;
  this.deviceAtp100 = getDeviceCategories.includes('s100_atp') ? true : false;
  this.deviceAqi200 = getDeviceCategories.includes('s200_aqi') ? true : false;
  this.deviceAqi300 = getDeviceCategories.includes('s300_aqi') ? true : false;
  this.deviceAtp300 = getDeviceCategories.includes('s300_atp') ? true : false;
this.deviceSWH400 = getDeviceCategories.includes('s400_swh') ? true : false;
  }
  // var element = document.getElementById('devClass');
  // element.style.setProperty('--devCat', this.deviceAqi300 ? '9.5%' : '16%');
}
}