import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { environment } from 'src/app/config/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BedService {
  isPrinting = false;
  constructor(private http: HttpClient,
    private router: Router,
    private common: CommonServiceService) { }
  getBedDetails(bedId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/seatBed/${bedId}`);
  }

  organizationList() {
    return this.http.get(`${environment.customerApiUrl}api/v1/organization/pickList`);
  }

  bedList(id) {
    return this.http.get(`${environment.customerApiUrl}api/v1/seatBed/pickList/${id}`);
  }

  bedSave(credentials) {
    return this.http.post(`${environment.customerApiUrl}api/v1/seatBed`, credentials);
  }

  updateBed(credentials, seatBedId) {
    return this.http.put(`${environment.customerApiUrl}api/v1/seatBed/${seatBedId}`, credentials);
  }

  Bed(customerId: any, pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}api/v1/seatBed/seatBedListing?customerId=${customerId}&page=${pageInfo.page}&searchText=${encodeURIComponent(this.common.
      searchText)}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }

  getBranch(industryId, customerId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/branch/picklist?industryId=${industryId}&customerId=${customerId}`);
  }

  deleteBranch(id) {
    return this.http.put(`${environment.customerApiUrl}api/v1/seatBed/${id}`, id);
  }

  downloadTemplate() {
    return this.http.get(`${environment.customerApiUrl}api/v1/bulk-upload/bed-template`, { responseType: 'blob' as 'json' });
  }

  bulkUploadFile(formData) {
    return this.http.post(`${environment.customerApiUrl}api/v1/bulk-upload/bed-validate`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, observe: 'response', responseType: 'blob' as 'json' });
  }

  uploadBulk(bulkUploadId, formData?) {
    return this.http.post(`${environment.customerApiUrl}api/v1/bulk-upload/bed-excel/${bulkUploadId}`, {});
  }

  getAllBranch() {
    return this.http.get(`${environment.authApiUrl}/api/v1/users/getAllBranch`);
  }

  // printDocument(documentName: string, documentData: string[]) {
  //   this.isPrinting = true;
  //   this.router.navigate(['admin/facility/print-qr-code',
  //     {
  //       outlets: {
  //         'print': ['print', documentName, documentData.join()]
  //       }
  //     }]);
  // }
  printDocument(documentName: string, documentData: string[], route) {
    this.isPrinting = true;
    this.router.navigate([route,
      {
        outlets: {
          'print': ['print', documentName, documentData.join()]
        }
      }]);
  }
  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate(['admin/bed-inventory']);
    }, 2000);
  }

}
