//add enum to shared

export enum login { title= 'Caleedo System'};
export enum common { page= 0, size= 5, gridSize= 8, pageSize= 3 , ImageSize = 5000 }
// tslint:disable-next-line: max-line-length
// make enum capital case
export enum sortHeaderStateDefault { code= 'one_code', name= 'one_Name', organization= 'one_Organization', modifiedBy= 'one_Modified By', modifiedOn= 'one_Modified On'};
// tslint:disable-next-line: max-line-length
export enum SortHeaderStateAsc { Code= 'two_code', Name= 'two_Name', Organization= 'two_Organization', ModifiedBy= 'two_Modified By', ModifiedOn= 'two_Modified On'}
// tslint:disable-next-line: max-line-length
export enum SortHeaderStateDesc { Code= 'three_code', Name= 'three_Name', Organization= 'three_Organization', ModifiedBy= 'three_Modified By', ModifiedOn= 'three_Modified On'}
