import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sms-url',
  templateUrl: './sms-url.component.html',
  styleUrls: ['./sms-url.component.scss']
})
export class SmsUrlComponent implements OnInit {
  app
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    let url=this.router.url.split("/");
    this.app = url.includes("hcstaff")?'hcstaff':url.includes("entstaff")?'entstaff':url.includes("hcconsumer")?'hcconsumer':url.includes("entconsumer")?'entconsumer':'';
    this.getMobileOperatingSystem()
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor //|| window.opera;
    if (/android/i.test(userAgent)) {
        if(this.app == 'hcstaff'){
          location.href = 'https://play.google.com/store/apps/details?id=com.caleedo.healthcarestaff'
        }else if(this.app == 'entstaff'){
          location.href = 'https://play.google.com/store/apps/details?id=com.caleedo.entstaff'
        }else if(this.app == 'hcconsumer'){
          location.href = 'https://play.google.com/store/apps/details?id=com.caleedo.hcconsumer'
        }else if(this.app == 'entconsumer'){
          location.href = 'https://play.google.com/store/apps/details?id=com.caleedo.entvisitor'
        }
    } else if (/iPad|iPhone|iPod/.test(userAgent) ) {
        if(this.app == 'hcstaff'){
          location.href = 'https://apps.apple.com/app/id1546714081'
        }else if(this.app == 'entstaff'){
          location.href = 'https://apps.apple.com/app/id1547073364'
        }else if(this.app == 'hcconsumer'){
          location.href = 'https://apps.apple.com/app/id1544948868'
        }else if(this.app == 'entconsumer'){
          location.href = 'https://apps.apple.com/app/id1546714096'
        }
    }else {
      this.router.navigate(['login']);
    }
  }


}
