import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PatientService } from '../../service/patient.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';


// ----------component--------------------------------------------------------------------------------------//
@Component({
  selector: 'app-onboard-approval',
  templateUrl: './onboard-approval.html',
  styleUrls: ['./onboard-approval.scss']
})

export class OnboardApprovalComponent implements OnInit {
  searchText = '';
  message;
  actionsToggle = false;
  deleteButton: boolean;
  isActive: boolean = false;
  isList = true;
  screenWidth: number;
  showMobileTable: boolean;
  ipAddress: any;
  filterValue: any;
  filterView: boolean = false;
  isFilterImplemented: boolean = false;
  patientCodeArray = [];
  departmentArray = [];
  public pageInfo: PaginationModel = new PaginationModel();
  patientStatusArray: any = [];
  permissionsObject = { read: false, create: false, update: false, delete: false };
  @ViewChild('filterMenuToggle', { static: true }) filterMenuToggle: ElementRef;
  constructor(
    private commonService: CommonServiceService,
    private router: Router,
    private loaderService: LoaderService,
    public toastService: ToastService,
    private patientService: PatientService,
    private dialog: DialogService,
    private renderer: Renderer2
    ) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
    this.onResize();
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.filterView) {
        if (!this.filterMenuToggle.nativeElement.contains(e.target)) {
          this.filterView = false;
        }
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }

  toggleAction() {
    this.actionsToggle = !this.actionsToggle;
  }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_APPROVAL');
    this.commonService.searchText = '';
    this.commonService.callToggle.subscribe((data) => {
      this.change();
    });
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = false;
    } else {
      this.isActive = false;
    }
  }

  searchItem() {
    this.commonService.sendFilterCloseEvent();
    this.commonService.searchText = this.searchText.trim();
    this.commonService.sendSearchEvent();
  }

  searchInputChanged() {
    this.commonService.searchText = this.searchText;
  }

  // -----toggle--------------------------------------------------------------------------------------//
  change() {
    this.isActive = !this.isActive;
  }
  listGridView() {
    this.isList = !this.isList;
  }

  mapKeysOfFilter() {
    const body = {};
    this.departmentArray.length > 0 ? body['departments'] = this.departmentArray : null;
    this.patientCodeArray.length > 0 ? body['users'] = this.patientCodeArray : null;
    this.patientStatusArray.length > 0 ? body['patientStatus'] = this.patientStatusArray : null;
    return body;
  }
  // -------function to navigate to add patient page--------------------------------------------------//
  // addPatient() {
  //   this.commonService.sendFilterCloseEvent();
  //   this.router.navigate(['admin/patient-list/add-patient']);
  // }

  filterValueChange(event) {
    this.filterView = false;
    this.isFilterImplemented = true;
    this.filterValue = event;
    this.checkIfFilterImplemented();
  }

  filterClosed(event) {
    this.filterView = false;
    this.filterValue = event;
    this.checkIfFilterImplemented();
  }

  closeFilterEventWithoutDataChange() {
    this.filterView = !this.filterView;
  }

  checkIfFilterImplemented() {
    if (this.filterValue) {
      if ((this.filterValue.PatientCode.length > 0 ||
        this.filterValue.department.length > 0 ||
        this.filterValue.reportingManager.length > 0 ||
        this.filterValue.organisation.length > 0 ||
        this.filterValue.patientStatus.length > 0)) {
        this.isFilterImplemented = true;
      } else {
        this.isFilterImplemented = false;
      }
    }
  }
  
  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }
  
  openSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  addPatient() {
    this.router.navigate(['admin/patient-list/add-patient']);
  }
}
