import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonServiceService } from '../../../../../../../../src/app/core/common-service.service';
import { LoginService } from '../../../../../../../../src/app/modules/shared/login/service/login.service';
import { ErrorToasterComponent } from '../../../../../../../../src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from '../../../../../../../../src/app/modules/shared/component/toaster/toaster.component';
import { ToastService } from '../../../../../../../../src/app/modules/shared/services/toaster.service';
import { LoaderService } from '../../../../../../../../src/app/modules/shared/services/loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TabletserviceService } from '../tabletservice.service';
import { CustomerComplaintsServiceService} from '../../Services/Customer-Complaints/customer-complaints-service.service';
import { MatDialog } from '@angular/material';
import { SuccessDialogComponent } from 'src/app/modules/shared/component/success-dialog/success-dialog';
import {smartWashroom} from '../../../../../../config/constant';
import { SmartWashroomDashboardServiceService } from '../../Dashboard/smart-washroom-dashboard-service.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-complaint-feedback-link',
  templateUrl: './complaint-feedback-link.component.html',
  styleUrls: ['./complaint-feedback-link.component.scss']
})
export class ComplaintFeedbackLinkComponent implements OnInit {

  @ViewChild('formListData',{static:true}) formListData: ElementRef;
  @ViewChild('suggestionsListData',{static:true}) suggestionsListData: ElementRef;
  @ViewChild('complaintsListData',{static:true}) complaintsListData: ElementRef;

  public elem;
  isActive: boolean;
  feedbackForm:FormGroup;
  thankyouPage:boolean=false;
  customerId:any;
  branchId :any;
  branchName:any;
  buildingId:any;
  buildingName:any;
  floorId:any;
  floorName:any;
  departmentId:any;
  departmentName:any;
  roomId:any;
  roomName:any;
  isFullScreen:boolean=false;
  ratingchecked:boolean=false;
  complaintsList:any[]=[];
  suggestionsList:any[]=[];
  othersChecked:boolean=false;
  complaintsChecked:number=0;
  suggestionsChecked:number=0;
  showCompliment:boolean=true;
  showSuggestion:boolean=false;
  showComplaint:boolean=false;
  selectedLocation:any[]=JSON.parse(localStorage.getItem('Tablet-Location'))?JSON.parse(localStorage.getItem('Tablet-Location')):[];
  locationCard:boolean=false;
  currentLocation:any={};
  currentRate:any='Clean';
  submittting:boolean=false;
  floors:any=[];
  departments:any=[];
  rooms:any=[];
  customerLogo:any;
  currDateTime:any=moment(new Date()).format('DD MMM YYYY, h:mm A');
  thankYouMessage='';
  thankYouImg='';

  constructor(
    public commonService: CommonServiceService,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    public toastService: ToastService,
    public fb: FormBuilder,
    private tabletService: TabletserviceService,
    public router: Router,
    public matDialog:MatDialog,
    public SmartWashroomDashboardService:SmartWashroomDashboardServiceService
  ) {}


  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      if (params.data) {
        // let data=atob(params.data).split('-');
        // this.customerId =data[0];
        // this.branchId=data[1];
        // this.branchName=data[2].replace(""," ");
        // this.buildingId=data[3];
        // this.buildingName=data[4].replace(""," ");
        this.roomId=atob(params.data);
        this.getFeedbackRoom();
      }
    });

  this.locationCard=this.selectedLocation.length>1 ? true : false;
  this.currentLocation=this.locationCard?{}:this.selectedLocation[0];
  this.initializeForm();

  this.getSuggestionComplaintList();
  setInterval(()=>{
    this.currDateTime=moment(new Date()).format('Do MMMM YYYY, h:mm A');
  },1000)

  }

  getFloor() {
    if (this.branchId && this.buildingId) {
      this.loaderService.show();

      this.SmartWashroomDashboardService.getFloor(this.customerId,this.branchId, this.buildingId).subscribe((res: any) => {

        this.floors = [];
        if (res.data.length) {
          let floors = res.data.map((x) => {
            return { id: x[0], name: x[1],check:false };
          });
          this.floorId=floors[0].id;
          this.floorName=floors[0].name;
          floors[0].check=true;

          this.floors=this.sliceIntoChunks(floors,2);

          

          this.getDepartment(this.floorId);

        }
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error)
      });
    }
  }

  getDepartment(floorId) {

    if (this.branchId && this.buildingId && floorId) {

      this.rooms = [];
      this.loaderService.show();
      this.SmartWashroomDashboardService.getDepartment(this.customerId,this.branchId, this.buildingId, floorId).subscribe((res: any) => {
        if (res.data.length) {
          let departments = res.data.map((x) => {
            return { id: x[0], name: x[1],check:false };
          });
          this.departmentId=departments[0].id;
          this.departmentName=departments[0].name;
          departments[0].check=true;

          this.departments=this.sliceIntoChunks(departments,2);

          

          this.getRoom(this.departmentId);

        }
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error)
      });
    }
  }

  getRoom(departmentId) {
    if (this.branchId && this.buildingId && this.floorId && departmentId) {

      this.loaderService.show();
      this.SmartWashroomDashboardService.getRoom(this.customerId,this.branchId, this.buildingId, this.floorId, departmentId).subscribe((res: any) => {

        this.rooms = [];
        if (res.data.length) {
          let rooms = res.data.map((x) => {
            return { id: x[0], name: x[1], roomType:x[2], check:false };
          });
          this.roomId=rooms[0].id;
          this.roomName=rooms[0].name;
          rooms[0].check=true;

          this.rooms=this.sliceIntoChunks(rooms,2);

        }
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error)
      });
    }
  }

  getFeedbackRoom() {
    if (this.roomId) {

      this.loaderService.show();
      this.SmartWashroomDashboardService.getFeedbackRoom(this.roomId).subscribe((res: any) => {
        if (res.data) {
          this.customerId=res.data.customer.id;
          this.branchId=res.data.branch.id;
          this.branchName=res.data.branch.name;
          this.buildingId=res.data.building.id;
          this.buildingName=res.data.building.name;
          this.floorId=res.data.floor.id;
          this.floorName=res.data.floor.name;
          this.departmentId=res.data.department.id;
          this.departmentName=res.data.department.name;
          this.roomId=res.data.id;
          this.roomName=res.data.name;
          this.getCustomerLogo();

        }
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
        this.matDialog.open(SuccessDialogComponent, {
          data: {
            button:'no',
            confirmationMesg: "Invalid Link!",
            heading: 'Warn'
          }
        }).afterClosed().subscribe(res => {
            this.router.navigate(['/']);
          });

      });
    }
    else{
      this.matDialog.open(SuccessDialogComponent, {
        data: {
          button:'no',
          confirmationMesg: "Invalid Link!",
          heading: 'Warn'
        }
      }).afterClosed().subscribe(res => {
          this.router.navigate(['/']);
        });
    }
  }

  sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}


  getSuggestionComplaintList()
  {
    smartWashroom.forEach(element => {
      if(element.type==='complaints')
      {
        this.complaintsList.push({...element,status:false});
      }
      else{
        this.suggestionsList.push({...element,status:false});
      }
    });
  }

  onLocationSelect(type,i,j){
    if(type==='FLOOR')
    {
      if(!this.floors[i][j].check){
      this.floors.forEach(el=>{
        el.forEach(e=>{
          e.check=false;
        })
      })
      this.floorId=this.floors[i][j].id;
      this.floorName=this.floors[i][j].name;
      this.floors[i][j].check=true;
      this.getDepartment(this.floorId);
      }
    }
    if(type==='DEPARTMENT')
    {
      if(!this.departments[i][j].check){
        this.departments.forEach(el=>{
          el.forEach(e=>{
            e.check=false;
          })
        })
        this.departmentId=this.departments[i][j].id;
        this.departmentName=this.departments[i][j].name;
        this.departments[i][j].check=true;
        this.getRoom(this.departmentId);
        }
    }
    if(type==='ROOM')
    {
      if(!this.rooms[i][j].check){
        this.rooms.forEach(el=>{
          el.forEach(e=>{
            e.check=false;
          })
        })
        this.roomId=this.rooms[i][j].id;
        this.roomName=this.rooms[i][j].name;
        this.rooms[i][j].check=true;
        }
    }
  }

  initializeForm()
  {
    this.feedbackForm = this.fb.group({
      fullName: ['', []],
      phone: ['', [Validators.pattern('^([5-9]{1})([0-9]{5,12})$')]],
      feedback:['',[]]
    });
  }

  onRatingChanged(event)
  {

   this.showCompliment=event==='Compliment'?true:false;
   this.showSuggestion=event==='Suggestion'?true:false;
   this.showComplaint=event==='Complaint'?true:false;
   this.currentRate=event==='Compliment'?'Clean':(event==='Suggestion'?'Need Service':'Dirty');
   setTimeout(()=>{
    if(event==="Compliment"){
      this.scrollToComplaints("formListData");
    }
    else if(event==="Suggestion"){
      this.scrollToComplaints("suggestionsListData");
    }
    else{
      this.scrollToComplaints("complaintsListData")
    }
   },500)

  }

  onChangeCurrentLocation(loc)
  {
    this.currentLocation=loc;
    this.locationCard=false;
  }

  getCustomerLogo()
  {
    this.commonService.getLogoImg(this.customerId).subscribe(res => {
      this.loaderService.hide();
      this.customerLogo = res['data'] && res['data']['generalSettingResponse'] && res['data']['generalSettingResponse']['logo'] ? 'data:image/png;base64,' + res['data']['generalSettingResponse']['logo'] : '';
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    })
  }

  onSubmit(type)
  {
    if((type==='suggestion' && this.suggestionsChecked===0)||(type==='complaint' && this.complaintsChecked===0))
    {
      this.matDialog.open(SuccessDialogComponent, {
        data: {
          button:'no',
          confirmationMesg: "Please let us know where we fell short of your expectations.",
          heading: 'Warn'
        }
      });
      setTimeout(()=>{
        this.matDialog.closeAll();
      },2000);
    }
    else{
    this.submittting=true;
    this.loaderService.show();
    let body:any={
      feedbackType:type,
      guestName:this.feedbackForm.controls.fullName.value,
      guestNumber:this.feedbackForm.controls.phone.value,
      guestFeedback:this.feedbackForm.controls.feedback.value,
      ratingName:this.currentRate,
      customerId:this.customerId,
      branchid:this.branchId,
      branchname:this.branchName,
      buildingid:this.buildingId,
      buildingname:this.buildingName,
      floorid:this.floorId,
      floorname:this.floorName,
      departmentid:this.departmentId,
      departmentname:this.departmentName,
      roomid:this.roomId,
      roomname:this.roomName
    };
    if(type==='compliment'){
      body={...body,complaintList:'Clean'};
      this.thankYouMessage="We appreciate your feedback!";
      this.thankYouImg='assets/images/clean.gif';
    }
    if(type==='complaint')
    {
      let complaintList=this.complaintsList.filter(el=> el.status===true).map(element=> element.title).join('~');
      body={...body,
        complaintList : complaintList
      };
      this.thankYouMessage="We apologise for not meeting your expectations. I'm hoping for a better experience next time.";
      this.thankYouImg='assets/images/dirty.gif';
    }
    if(type==='suggestion')
    {
      let suggestionList=this.suggestionsList.filter(el=> el.status===true).map(element=> element.title).join('~');
      body={...body,
        complaintList : suggestionList
      };
      this.thankYouMessage="We apologise for not meeting your expectations. I'm hoping for a better experience next time!";
      this.thankYouImg='assets/images/needservice.gif'; 
    }
    this.tabletService.addFeedbackComplaint(body)
    .subscribe((res: any) => {
      this.loaderService.hide();
      this.thankyouPage=true;
    },
      (error) => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  complaintToggle(index)
  {
    this.complaintsList[index].status=!this.complaintsList[index].status;
    this.complaintsChecked=this.complaintsList[index].status?this.complaintsChecked+1:this.complaintsChecked-1;
  }

  suggestionToggle(index)
  {
    this.suggestionsList[index].status=!this.suggestionsList[index].status;
    this.suggestionsChecked=this.suggestionsList[index].status?this.suggestionsChecked+1:this.suggestionsChecked-1;
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }

  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }
  scrollToComplaints(id){
    let scrollDiv=document.getElementById(id).offsetHeight;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
  }
}
