import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { environment } from 'src/app/config/environments/environment';
import { Subject } from 'rxjs';
import * as moment from 'moment-timezone';
@Injectable({
  providedIn: 'root'
})
export class WorkOrderServices {
  selectedWorkOrders = new Subject<any>();
  selectedWorkOrdersObservable = this.selectedWorkOrders.asObservable();
  selectedWorkOrdersList = [];
  constructor(private http: HttpClient, private common: CommonServiceService) { }

  getWorkOrderList(pageInfo, filterData, workOrderId?) {
    let params = new HttpParams();
    if (filterData.branch) {
      // const branchIds = filterData.branch.join(',');
      params = params.append('branchId', filterData.branch)
    }
    if (filterData.building) {
      const buildingIds = filterData.building.join(',');
      params = params.append('buildingId', buildingIds)
    }
    if (filterData.floor) {
      const floorIds = filterData.floor.join(',');
      params = params.append('floorId', floorIds);
    }
    if (filterData.department) {
      const departmentIds = filterData.department.join(',');
      params = params.append('departmentId', departmentIds);
    }
    if (filterData.room) {
      const roomIds = filterData.room.join(',');
      params = params.append('roomId', roomIds);
    }
    if (filterData.seat) {
      const seat = filterData.seat.join(',');
      params = params.append('seatId', seat);
    }
    if (filterData.bed) {
      const bed = filterData.bed.join(',');
      params = params.append('bedId', bed);
    }
    if (filterData.category) {
      const category = filterData.category.join(',');
      params = params.append('categoryId', category);
    }
    if (filterData.subCategory) {
      const subCategory = filterData.subCategory.join(',');
      params = params.append('subCategoryId', subCategory);
    }
    if (filterData.status) {
      const tempStatus = filterData.status.slice(0);
      if (tempStatus.includes('WORKORDER_ESCALATE')) {
        const index = tempStatus.indexOf('WORKORDER_ESCALATE');
        if (index > -1) {
          tempStatus.splice(index, 1);
        }
        params = params.append('isEscalated', "true");
      }
      if (tempStatus.includes('WORKORDER_REOPEN')) {
        const index = tempStatus.indexOf('WORKORDER_REOPEN');
        if (index > -1) {
          tempStatus.splice(index, 1);
        }
        params = params.append('isReopen', "true");
      }
      if (tempStatus.length) {
        const status = tempStatus.join(',');
        params = params.append('status', status);
      }
    }
    if (filterData.startDate) {
      params = params.append('startDate', filterData.startDate);
    }
    if (filterData.endDate) {
      params = params.append('endDate', filterData.endDate);
    }
    if (workOrderId) {
      params = params.append('isChild', "true");
      params = params.append('workOrderId', workOrderId);
    }
    // if (filterData.escalateStatus) {
    //   const escalateStatus = filterData.escalateStatus.join(',');
    //   params = params.append('isEscalated', escalateStatus);
    // }
    return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders?by=${pageInfo.sortBy}&order=${pageInfo.sortDir}&page=${pageInfo.page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.common.searchText)}`, { params: params });
  }
  getStaff(customerId, searchText = '') {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/pickList?customerId=${customerId}&searchText=${searchText}`);
  }
  uploadFile(formData) {
    return this.http.post(`${environment.notificationApiUrl}api/v1/images/uploadFile`, formData, { responseType: 'text' })
  }

  addNewWorkOrder(workOrderId, body) {
    let timezone = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
    let zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    body['timezone'] = timezone;
    body['zoneId'] = zoneId;
    return this.http.post(`${environment.hygieneApiUrl}api/v1/workorders/child/${workOrderId}`, body);
  }

  getcalendarDetails(id) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/hygiene-calender/${id}`);
  }

  getWorkOrderDetail(id) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders/${id}`);
  }
  editWorkOrder(body, workorderid) {
    let timezone = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
    let zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    body['timezone'] = timezone;
    body['zoneId'] = zoneId;
    return this.http.put(`${environment.hygieneApiUrl}api/v1/workorders/${workorderid}`, body);
  }
  getChildWorkOrder(id) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders/${id}`);
  }

  updateStatus(workorderid, action) {
    return this.http.put(`${environment.hygieneApiUrl}api/v1/workorders/status/${workorderid}?action=${action}`, {})
  }

  getWorkOrderListAccordingCompression(compression, startDate, endDate, filterData:any={}) {
    let params = new HttpParams();
    params = params.append('branchId', localStorage.getItem('branchId'));
    params = params.append('compression', compression.toUpperCase());
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    if (filterData.building) {
      const buildingIds = filterData.building.join(',');
      params = params.append('buildingId', buildingIds)
    }
    if (filterData.floor) {
      const floorIds = filterData.floor.join(',');
      params = params.append('floorId', floorIds);
    }
    if (filterData.department) {
      const departmentIds = filterData.department.join(',');
      params = params.append('departmentId', departmentIds);
    }
    if (filterData.room) {
      const roomIds = filterData.room.join(',');
      params = params.append('roomId', roomIds);
    }
    if (filterData.seat) {
      const seat = filterData.seat.join(',');
      params = params.append('seatId', seat);
    }
    if (filterData.bed) {
      const bed = filterData.bed.join(',');
      params = params.append('bedId', bed);
    }
    if (filterData.category) {
      const category = filterData.category.join(',');
      params = params.append('categoryId', category);
    }
    if (filterData.subCategory) {
      const subCategory = filterData.subCategory.join(',');
      params = params.append('subCategoryId', subCategory);
    }
    if (filterData.status) {
      const tempStatus = filterData.status.slice(0);
      if (tempStatus.includes('WORKORDER_ESCALATE')) {
        const index = tempStatus.indexOf('WORKORDER_ESCALATE');
        if (index > -1) {
          tempStatus.splice(index, 1);
        }
        params = params.append('isEscalated', "true");
      }
      if (tempStatus.includes('WORKORDER_REOPEN')) {
        const index = tempStatus.indexOf('WORKORDER_REOPEN');
        if (index > -1) {
          tempStatus.splice(index, 1);
        }
        params = params.append('isReopen', "true");
      }
      if (tempStatus.length) {
        const status = tempStatus.join(',');
        params = params.append('status', status);
      }
    }
    return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders/listbyuserbycompression`, { params: params })
  }

  getAuditTrailDetails(workOrderId) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders/${workOrderId}/trail`);
  }

  getCategoryList(parentId?, status = 'true') {
    let url = `${environment.hygieneApiUrl}api/v1/hygiene-categories`;
    if (parentId) {
      url = url + `?parentId=${parentId}`;
    }
    let params = new HttpParams();
    if (status) {
      params = params.append('active', status);
    }
    return this.http.get(url, { params: params });
  }

  getStatusList(customerId) {
    return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders/status?customerId=${customerId}`);
    // return this.http.get(`./assets/api-json/workorder-status.json`);
  }

  saveAsDraft(workOrderId, body) {
    let timezone = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
    let zoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    body['timezone'] = timezone;
    body['zoneId'] = zoneId;
    return this.http.post(`${environment.hygieneApiUrl}api/v1/workorders/${workOrderId}/draft`, body);
  }

  deleteWorkOrder(workOrderIds) {
    let params = new HttpParams();
    const workOrderId = workOrderIds.join(',');
    params = params.append('workOrderIds', workOrderId);
    return this.http.delete(`${environment.hygieneApiUrl}api/v1/workorders/bulkDelete`, { params: params });
  }

  getWorkOrderFailureList(body){
    if(body.status){
      return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders-sync?by=${body.sortby}&searchText=${body.searchText}&order=${body.order}&page=${body.page}&size=${body.size}&status=${body.status}`);
    }else{
      return this.http.get(`${environment.hygieneApiUrl}api/v1/workorders-sync?by=${body.sortby}&searchText=${body.searchText}&order=${body.order}&page=${body.page}&size=${body.size}`);
    }
  }

  syncFailedWorkOrder(id){
    return this.http.post(`${environment.hygieneApiUrl}api/v1/workorders-sync/sync?workOrderId=${id}`,'');
  }

  deleteFailedWorkOrder(id){
    return this.http.delete(`${environment.hygieneApiUrl}api/v1/workorders-sync/delete?workOrderId=${id}`);
  }

  bulkSyncWorkOrder(selectedWorkOrder){
    let params = new HttpParams();
    for (const id of selectedWorkOrder) {
      params = params.append('workOrderIds', id);
    }
    return this.http.post(`${environment.hygieneApiUrl}api/v1/workorders-sync/bulkSync?${params}`,'');
  }

  bulkDeleteWorkOrder(selectedWorkOrder){  
    return this.http.delete(`${environment.hygieneApiUrl}api/v1/workorders-sync/bulkDelete`,{ params: { workOrderIds: selectedWorkOrder } });
  }
}
