import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { WorkOrderService } from '../../services/work-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { WorkDetailsModel } from '../../models/work-order.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { HttpHandlerProvider } from 'src/app/core/http-handler.provider';

@Component({
    selector: 'app-add-event',
    templateUrl: './add-event.html',
    styleUrls: ['./add-event.scss']
})

export class AddEventComponent implements OnInit {
    isActive: any;
    addWorkOrderForm: FormGroup;
    staffList: any = [];
    assigneList: any = [];
    scheduleId: any;
    selectedFile: any = '';
    selectedAssigneeId: any;
    minutes = ['10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
    hours = ['0', '1', '2', '3', '4', '5'];
    selectedOwnerId: any;
    workOrderId: any
    isEdit: any;

    constructor(private formBuilder: FormBuilder, private loaderService: LoaderService,
        private toastService: ToastService,
        private workOrderService: WorkOrderService, private activatedRoute: ActivatedRoute,
        private router: Router,
        public dialogRef: MatDialogRef<AddEventComponent>,
        @Inject(MAT_DIALOG_DATA) public data) {
        this.workOrderId = data.id;
    }

    ngOnInit() {
        this.createForm();
        const currentUrl = this.router.url.split('/');
        // if(currentUrl[currentUrl.length-2] === 'edit-work-order') {
        //     this.isEdit = true;
        // } else {
        //     this.isEdit = false;
        // }
        // this.activatedRoute.params.subscribe(res => {
        //     this.workOrderId = res['workOrderId'];
        // })
        this.getWorkOrderDetails(this.workOrderId);

        this.addWorkOrderForm.controls.assignee.valueChanges
            .pipe(debounceTime(500))
            .subscribe(searchText => {
                if (searchText !== '') {
                    this.getAssigneeList(searchText);
                }
            });

        this.addWorkOrderForm.controls.owner.valueChanges
            .pipe(debounceTime(500))
            .subscribe(searchText => {
                if (searchText !== '') {
                    this.getStaffList(searchText);
                }
            });

        if (localStorage.getItem('isActive') === 'true') {
            this.isActive = true;
        } else {
            this.isActive = false;
        }
    }

    assigneeIdChange(value) {
        this.selectedAssigneeId = value;
        this.staffList = [];
    }

    createForm() {
        this.addWorkOrderForm = this.formBuilder.group({
            scheduleId: [''],
            workOrderId: [''],
            slaTimeHrs: [''],
            slaTimeMinutes: [''],
            startTime: [''],
            assignee: [''],
            owner: ['', [Validators.required]],
            assetId: '',
            attachment: [''],
            description: [''],
            guideLines: ['']
        })
        this.addWorkOrderForm.controls.scheduleId.disable();
        this.addWorkOrderForm.controls.workOrderId.disable();
        this.addWorkOrderForm.controls.owner.disable();
        this.addWorkOrderForm.controls.description.disable();
    }

    getStaffList(searchText) {
        this.loaderService.show();
        const customerId = localStorage.getItem('customerId');
        this.workOrderService.getStaff(customerId, searchText).subscribe(res => {
            this.staffList = res['data'];
            this.loaderService.hide();
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    getAssigneeList(searchText) {
        this.loaderService.show();
        const customerId = localStorage.getItem('customerId');
        this.workOrderService.getStaff(customerId, searchText).subscribe(res => {
            this.assigneList = res['data'];
            this.loaderService.hide();
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    addChildWorkOrder() {
        const body = this.createBody();
        this.loaderService.show();
        this.workOrderService.addNewWorkOrder(this.workOrderId, body).subscribe(res => {
            this.loaderService.hide();
            this.successSnackBar(res['description']);
            this.router.navigate(['/admin/hygiene/work-order']);
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    ownerIdChange(value) {
        this.selectedOwnerId = value;
        this.staffList = [];
    }

    createBody() {
        const body = {
            "assetId": this.addWorkOrderForm.controls.assetId.value ? this.addWorkOrderForm.controls.assetId.value : null,
            "assigneeUserId": this.selectedAssigneeId ? this.selectedAssigneeId : null,
            "attachment": this.selectedFile ? this.selectedFile : null,
            // "comment": this.addWorkOrderForm.controls.comment.value ? this.addWorkOrderForm.controls.comment.value : null,
            "description": this.addWorkOrderForm.controls.description.value ? this.addWorkOrderForm.controls.description.value : null,
            "sla": (this.addWorkOrderForm.controls.slaTimeHrs.value && this.addWorkOrderForm.controls.slaTimeMinutes.value) ? (+this.addWorkOrderForm.controls.slaTimeHrs.value) * 60 + (+this.addWorkOrderForm.controls.slaTimeMinutes.value) : null,
            "ownerId": this.selectedOwnerId ? this.selectedOwnerId : null,
            // "startTime": this.addWorkOrderForm.controls.startTime.value ? (moment(this.addWorkOrderForm.controls.startTime.value, 'hh:mm a').valueOf()) : null,
            "scheduleId": this.scheduleId,
            orderDate: new Date().getTime(),
            guidelines: this.addWorkOrderForm.controls.guideLines.value ? this.addWorkOrderForm.controls.guideLines.value : null,
        }
        return body;
    }

    async fileSelected(event) {
        let file = event.target.files[0];
        let formData: FormData = new FormData();
        formData.append('file', file);
        this.selectedFile = await this.workOrderService.uploadFile(formData).toPromise();
    }

    successSnackBar(message) {
        this.toastService.opentoast(
            { toastName: 'success', data: { name: '', message } },
            ToasterComponent
        );
    }

    errorSnackBar(error) {
        this.toastService.opentoast(
            { toastName: '', data: { errorCode: error.status, error: error.error } },
            ErrorToasterComponent
        )
    }

    getWorkOrderDetails(workOrderId) {
        this.loaderService.show();
        this.workOrderService.getWorkOrderDetail(workOrderId).subscribe((res: WorkDetailsModel) => {
            const closureTime = res.data.sla;
            if (closureTime / 60 > 0) {
                const hrs = Math.floor(closureTime / 60);
                const mins = (closureTime - hrs * 60).toString();
                this.addWorkOrderForm.patchValue({ slaTimeHrs: hrs.toString(), slaTimeMinutes: mins });
            }
            this.addWorkOrderForm.patchValue({
                scheduleId: res.data.scheduleId,
                workOrderId: res.data.workOrderId,
                startTime: res.data.startTime ? moment(res.data.startTime).format('hh:mm A') : null,
                assignee: res.data.assigneeUserName ? res.data.assigneeUserName : null,
                owner: res.data.ownerUserName ? res.data.ownerUserName : null,
                assetId: res.data.assetId ? res.data.assetId : null,
                description: res.data.description ? res.data.description : null,
                guideLines: res.data.guidelines ? res.data.guidelines : null
            })
            this.selectedAssigneeId = res.data.assigneeUserId;
            this.selectedOwnerId = res.data.ownerUserId;
            this.loaderService.hide();
        }, error => {
            this.loaderService.hide();
            this.errorSnackBar(error);
        })
    }

    editWorkOrder() {
        const body = this.createBody();
        this.loaderService.show();
        this.workOrderService.editWorkOrder(body, this.workOrderId).subscribe(res => {
            this.loaderService.hide();
            this.successSnackBar(res['description']);
            // this.router.navigate(['/admin/hygiene/work-order']);
            this.dialogRef.close();
        }, err => {
            this.loaderService.hide();
            this.errorSnackBar(err);
        })
    }

    cancel() {
        // this.router.navigate(['/admin/hygiene/work-order']);
        this.dialogRef.close();
    }

}