import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { CommonServiceService } from "../common-service.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpHandlerProvider } from '../http-handler.provider';
import { LoaderService } from "src/app/modules/shared/services/loader.service";

import { Router } from "@angular/router";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private refreshTokenObservable = this.refreshTokenSubject.asObservable();
  constructor(private commonService: CommonServiceService, private router: Router, private httpHandlerService: HttpHandlerProvider, private loaderService : LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      map((event: any) => {
        return event;
      }),
      catchError(error => {
        if (
          request.url.includes("refreshToken") ||
          request.url.includes("login")
        ) {
          if (request.url.includes("refreshToken")) {
            localStorage.clear();
            this.httpHandlerService.isLogged = false;
            this.loaderService.hide();
            this.router.navigate(["/auth/login"]);
          }
          return throwError(error);
        }

        if (error.status !== 401) {
          return throwError(error);
        }

        if (error.status == 401) {
          if (this.refreshTokenInProgress) {
            this.refreshTokenObservable.subscribe(res => {
              if (res !== null) {
                return next.handle(this.addAuthenticationToken(request));
              }
            })
          } else {
            const credentials = { 'applicationType': "STAFF", "refreshToken": localStorage.getItem('refreshToken') }
            this.refreshTokenInProgress = true;
            let updatedRequest;
            return this.commonService.getRefreshToken(credentials).pipe(switchMap((res: any) => {
              this.refreshTokenInProgress = false;
              localStorage.setItem('refreshToken', res.data.refresh_token);
              localStorage.setItem('user', res.data.access_token);
              localStorage.setItem('expires_in', res.data.expires_in);
              this.refreshTokenSubject.next(res.data.refresh_token)
              return next.handle(this.addAuthenticationToken(request));
            })
            )
          }
        }
      }));
  }


  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.commonService.getAccessToken();
    if (!accessToken) {
      return request;
    }
    return request.clone({
      setHeaders: {
        Authorization: 'bearer' + accessToken
      }
    });
    // return next.handle(customerReq);
  }
}