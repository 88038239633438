import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.html',
  styleUrls: ['./organization.scss']
})
export class OrganizationComponent implements OnInit {
  searchText = '';
  isActive = true;
  screenWidth: number;
  showMobileTable: boolean;
  permissionsObject = {read: false, create: false, update: false, delete: false};

  constructor(private commonService: CommonServiceService, private router: Router) {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }
  ngOnInit() {
    this.permissionsObject =  this.commonService.setPermissions('ORGANIZATION');

    this.commonService.searchText = '';

    this.commonService.callToggle.subscribe(() => {
      this.change();
    });
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  searchItem() {
    this.commonService.searchText = this.searchText.trim();
    this.commonService.sendSearchEvent();
  }

  change() {
    this.isActive = !this.isActive;
  }

  addOrganization() {
    this.router.navigate(['admin/account-setup/add-organization']);
  }
}
