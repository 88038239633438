import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationFormFieldService {
    field ;
    constructor(private http: HttpClient){}
    getListing(serviceName,type){
        return this.http.get(`${serviceName}api/v1/field-config?type=${type.toUpperCase()}`);
    }
    getVisitorListing(serviceName,type){
        return this.http.get(`${serviceName}field-config?type=${type.toUpperCase()}`);
    }
    UpdateFieldDetails(id,body,serviceName) {
        return this.http.put(`${serviceName}api/v1/field-config/${id}`,body);
    }
    UpdateVisitorFieldDetails(id,body,serviceName) {
        return this.http.put(`${serviceName}field-config/${id}`,body);
    }

    getFieldById(id,serviceName) {
        return this.http.get(`${serviceName}api/v1/field-config/${id}`);
    }

    getVisitorFieldById(id,serviceName) {
        return this.http.get(`${serviceName}field-config/${id}`);
    }
}

