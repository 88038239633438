import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-multi-file-drop-box-progress',
  templateUrl: './multi-file-drop-box-progress.html',
  styleUrls: ['./multi-file-drop-box-progress.scss']
})
export class MultiFileDropBoxProgressComponent implements OnInit {
  @Input() progress = 0;
  constructor() { }

  ngOnInit() { }
}
