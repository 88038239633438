import { Component, OnInit, ViewChild, OnDestroy, Inject, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { PatientService } from '../../../service/patient.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { DialogService } from 'src/app/core/dialog.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit, OnDestroy {
  public QRTypeForm: FormGroup;
  public isVerified = false;
  public resend = false;
  private display: string;
  private time;
  public isOtpVerified = false;
  isValidOtp = false;
  private otp: string = '';
  public showOtpComponent = true;
  public keyTypeList: any = [{ value: "ATTENDANT", name: "Attendant" }, { value: "VISITOR", name: "Visitor" }, { value: "BOTH", name: "Both" }];
  attendantQRData = '';
  visitorQRData = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  url: any = '';
  databaseUrl: string;
  val = null;
  @ViewChild('fileinput', { static: false }) fileInput: ElementRef;
  @ViewChild('attendedParent', { static: true }) attendedParent: ElementRef;
  @ViewChild('visitorParent', { static: true }) visitorParent: ElementRef;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  selectedKey = '';
  attendantKey = '';
  visitorKey = '';
  isImageLoadingAttendant: boolean = false;
  isImageLoadingVisitor: boolean = false;
  imageToShowAttendant: any;
  imageToShowVisitor: any;
  hospitalName = localStorage.getItem('customerName');
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    public toastService: ToastService,
    private patientService: PatientService,
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<QrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.data.attendantData.attendantQR) {
        this.createImageFromBlobAttendant(this.data.attendantData.attendantQR)
    }
    if (this.data.visitorData.visitorQR) {
      this.createImageFromBlobVisitor(this.data.visitorData.visitorQR)
    }
    
    let scope = this;
    setTimeout(() => {
      window.print();
    }, 2000);
    window.onafterprint = function () {
      scope.dialogRef.close();
    }
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  createImageFromBlobAttendant(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      // this.isImageLoadingAttendant = true;
      this.imageToShowAttendant = reader.result;
    }, false)

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  createImageFromBlobVisitor(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      // this.isImageLoadingVisitor = true;
      this.imageToShowVisitor = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getKey() {
    this.selectedKey = this.QRTypeForm.value.keyType;
  }

  public onOtpChange(otp) {
    this.otp = otp;
    if (this.otp.length === 4) {
      this.isValidOtp = true;
    } else {
      this.isValidOtp = false;
    }
  }

  private startTimer(duration) {
    this.display = "02:00";
    this.resend = false;
    var timer = duration, minutes, seconds;
    this.time = setInterval(() => {
      minutes = Math.floor(timer / 60);
      seconds = timer % 60;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      this.display = minutes + ":" + seconds;
      document.getElementById("time").innerHTML = this.display;
      if (--timer < 0) {
        this.resend = true;
        window.clearInterval(this.time);
      }
    }, 1000);
  }

  public cancel() {
    this.isVerified = false;
    this.resend = false;
    window.clearInterval(this.time);
  }

  openSnackBar(panelClass: string) {
    if (panelClass === 'Imagesize-not-supported') {
      this.toastService.opentoast({
        toastName: 'Image-size-not-supported',
        data: { name: '' },
      });
    }
  }

  public ngOnDestroy() {
    window.clearInterval(this.time);
  }
}
