import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';

@Component({
  selector: 'app-configuration-form-field',
  templateUrl: './configuration-form-field.html',
  styleUrls: ['./configuration-form-field.scss']
})

export class ConfigurationFormFieldComponent implements OnInit {
  @Input() type: string;
  @Input() serviceName: string;
  @Input() url: string;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  isActive = true;
  searchText = '';
  max: null;
  screenWidth: number;
  showMobileTable: boolean;
  constructor(
    private router: Router,
    private commonService: CommonServiceService,
    private activatedRoute: ActivatedRoute
  ) {
    this.onResize();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }
  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('TEMPLATE');
    this.commonService.callToggleSkill.subscribe((data) => {
      this.change();
    });
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
  searchItem() {
    this.commonService.searchText = this.searchText.trim();
    this.commonService.sendSearchEvent();
  }

  change() {
    this.isActive = !this.isActive;
  }
  searchInputChanged() {
    this.commonService.searchText = this.searchText;
  }
}