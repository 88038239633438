import { Component, Input, OnInit } from '@angular/core';
import { CarousalService } from '../../../../../../../src/app/modules/shared/services/carousal.service';
import * as moment from 'moment';

@Component({
  selector: 'app-slide-air-quality-ambient-weather',
  templateUrl: './slide-air-quality-ambient-weather.component.html',
  styleUrls: ['./slide-air-quality-ambient-weather.component.scss']
})
export class SlideAirQualityAmbientWeatherComponent implements OnInit {
  @Input() aqiData: any[]=[];
  @Input() location;
  @Input() colorAndStatus:boolean=false;
  @Input() castingData={};
  @Input() customerId:any;

  //Ambient Weather
  @Input() airPollutionData;
  @Input() currentWeatherData:any={};
  @Input() weatherPrecipitation;
  allowTvoc:boolean;

  constructor( private carousalService: CarousalService,
    ) { }

    deviceAqi100: boolean = false;
    deviceAtp100: boolean = false;
    deviceAqi200: boolean = false;
    deviceAqi300: boolean = false;
    deviceAtp300: boolean = false;
  deviceSWH400: boolean = false;
   interval=7000;
   HCLNoida:boolean=false;
  ngOnInit() {
    this.checkDeviceCategory();
    if(localStorage.getItem("allowTvoc")=== "true")
    {
      this.allowTvoc=true;
    }
    else{
      this.allowTvoc=false;
    }
    if(this.customerId==='307'|| this.customerId==='308'){
      this.HCLNoida=true;
    }
    
  }

    checkDeviceCategory() {
      let getDeviceCategories = JSON.parse(localStorage.getItem("categories"));
      if(getDeviceCategories){
      this.deviceAqi100 = getDeviceCategories.includes('s100_aqi') ? true : false;
      this.deviceAtp100 = getDeviceCategories.includes('s100_atp') ? true : false;
      this.deviceAqi200 = getDeviceCategories.includes('s200_aqi') ? true : false;
      this.deviceAqi300 = getDeviceCategories.includes('s300_aqi') ? true : false;
      this.deviceAtp300 = getDeviceCategories.includes('s300_atp') ? true : false;
this.deviceSWH400 = getDeviceCategories.includes('s400_swh') ? true : false;
      }
    }

}
