import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from 'src/app/core/utilities/format-datepicker';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DataModel, GenericPicklistModel, KeyModel } from 'src/app/modules/shared/model/picklist.model';
import { PatientService } from '../../../service/patient.service';
import { PatientData, PatientDescModel } from '../../../model/patient-list.model';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-add-patient-configuration',
  templateUrl: './add-patient-configuration.component.html',
  styleUrls: ['./add-patient-configuration.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class AddPatientConfigurationComponent implements OnInit {
  isActive;
  patientForm: FormGroup;
  patients: any = [];
  organizations: any = [];
  patientes: any = [];
  buildings: any = [];
  id;
  updating = false;
  orgId: any;
  message;
  updateStatus;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  branchList: any = [];
  patientList: any = [];
  countryList: any[];
  keyList: any = [];
  customerId = localStorage.getItem('customerId');
  branchId = localStorage.getItem('branchId');
  roleList: Observable<string[]>;
  roleOption: any[] = [];
  departmentList: any[];

  constructor(
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public commonService: CommonServiceService,
    private router: Router,
    private fb: FormBuilder,
    private picklistService: PicklistService,
    private patientService: PatientService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_CONFIGURATION');

    this.getKeyList();
    this.getRoleList()
    this.getDepartment();
    this.commonService.callToggleAddEmp.subscribe(() => {
      this.change();
    });
    this.getParams();
    this.initializeForm();
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  change() {
    this.isActive = !this.isActive;
  }

  getParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.updating = true;
        this.id = params.id;
        this.patchValuesInPatientForm();
      }
    });
  }

  initializeForm() {
    this.patientForm = this.fb.group({
      key: ['', [Validators.required]],
      departmentId: ['', [Validators.required]],
      keyValue: ['', [Validators.required]],
      value2filtrCtrl : ['']
    });
  }

  getKeyList() {
    this.picklistService.getKeyPicklist(this.customerId)
      .subscribe((res: KeyModel) => {
        this.keyList = [];
        if (res.data.length) {
          res.data.map((x) => {
            this.keyList.push({ value: x.key });
          });
        }
      })
  }
  getBranch() {
    // let index = this.locationDetails.value.locations.length - 1;
    this.picklistService.getBranchByIndustry("1", this.customerId)
      .subscribe((res: DataModel) => {
        this.branchList = [];
        if (res.data.length) {
          res.data.map((x) => {
            this.branchList.push({ value: x.id, name: x.name });
          });
        }
      })
  }

  patchValuesInPatientForm() {
    this.loaderService.show();
    this.patientService
      .getPatientConfigById(this.id)
      .subscribe((res: PatientData) => {
        this.loaderService.hide();
        // this.patientForm.get('branchId').patchValue(res.data.branchId);
        // this.getDepartment(res)
        res.data && res.data.departmentId == null ? this.patientForm.get('key').disable() : '';
        this.patientForm.controls['departmentId'].clearValidators();
        if (res.data && res.data.departmentId != null) {
          this.patientForm.controls['departmentId'].setValidators([Validators.required]);
          this.patientForm.controls['departmentId'].updateValueAndValidity();
        } 
        //&& this.patientForm.controls['departmentId'].updateValueAndValidity()
        this.patientForm.get('departmentId').patchValue(res.data.departmentId);
        this.patientForm.get('key').patchValue(res.data.key);
        this.patientForm.get('keyValue').patchValue(res.data.value);
      });
  }

  getRoleList() {
    const customerId = localStorage.getItem('customerId');
    this.picklistService.getRole(customerId).subscribe(res => {
      this.roleOption = res['data'];
      this.roleList = this.patientForm.controls['value2filtrCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => this.value2Filter(value))
        );
    }, err => {
      this.errorSnackBar(err);
    })
  }

  private value2Filter(value) {
    const filterValue = value.toLowerCase();
    return value != '' ?
      this.roleOption.filter(option => option.name.toLowerCase().includes(filterValue)) : this.roleOption;
  }


  getDepartment() {
    // let branchId = this.patientForm.value.branchId;
    this.departmentList = [];
    if (this.branchId) {
      this.picklistService.getDepartment(this.branchId, this.customerId).subscribe((res: DataModel) => {
        if (res.data.length) {
          res.data.map((x) => {
            this.departmentList.push({ value: x.id, name: x.name });
          });
        }
      });
    }
  }

  onSubmit3() {
    this.loaderService.show();
    // const admittedDate = new Date(this.patientForm.value.admittedDate)
    const body = {
      branchId: this.branchId,
      departmentId: this.patientForm.controls.departmentId.value,
      key: this.patientForm.controls.key.value,
      value: this.patientForm.value.keyValue,
      customerId: this.customerId
    }

    const patientId = this.id;
    if (this.updating) {
      this.patientService.updatePatientConfig(body, patientId)
        .subscribe((res: PatientDescModel) => {
          this.loaderService.hide();
          this.openSnackBar('success', res.description);
          this.router.navigate(['admin/patient-list/patient-configuration']);
        },
          (error) => {
            this.loaderService.hide();
            this.errorSnackBar(error);
          });
    } else {
      this.patientService.addPatientConfig({ ...body, customerId: this.customerId })
        .subscribe((res: PatientDescModel) => {
          this.loaderService.hide();
          this.openSnackBar('success', res.description);
          this.router.navigate(['admin/patient-list/patient-configuration']);
        },
          (error) => {
            this.loaderService.hide();
            this.errorSnackBar(error);
          });
    }
  }

  errorSnackBar(error) {
    // error.status === 409
    //   ? this.openSnackBar('Error-Code-409', error.error.message)
    //   :
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error }, type: 'error' },
      ErrorToasterComponent
    );
  }

  openSnackBar(panelClass: string, message) {
    if (panelClass === 'add-patient-success') {
      this.toastService.opentoast({
        toastName: 'addPatientSuccess',
        data: { name: '' },
      });
    } else if (panelClass === 'update-patient-success') {
      this.toastService.opentoast({
        toastName: 'updatePatientSuccess',
        data: { name: '' },
      });
    } else if (panelClass === 'Error-Code-400') {
      this.toastService.opentoast({
        toastName: 'error400',
        data: { name: '' },
      });
    } else if (panelClass === 'Error-Code-401') {
      this.toastService.opentoast({
        toastName: 'error401',
        data: { name: '' },
      });
    } else if (panelClass === 'Error-Code-404') {
      this.toastService.opentoast({
        toastName: 'error404',
        data: { name: '' },
      });
    } else if (panelClass === 'Error-Code-409') {
      this.toastService.opentoast({
        toastName: 'deperror409',
        data: { name: '' },
      });
    } else if (panelClass === 'Error-Code-500') {
      this.toastService.opentoast({
        toastName: 'error500',
        data: { name: '' },
      });
    } else if (panelClass === 'success') {
      this.toastService.opentoast(
        { toastName: 'success', data: { name: '', message: message }, type: 'success' },
        ToasterComponent
      );
    }
  }

  cancel() {
    this.router.navigate(['admin/patient-list/patient-configuration']);
  }
}
