import { Component, forwardRef, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';
import { SetNewPasswordModel } from './model/new-password';
import { ForgotPasswordService } from 'src/app/modules/Authentication/forget-password/service/forgot-password.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';


export interface PasswordFormValues {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SetNewPasswordComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SetNewPasswordComponent),
      multi: true
    }
  ]
})
export class SetNewPasswordComponent implements OnInit, OnDestroy {

  title = 'Caleedo System';
  resetForm: FormGroup;
  message;
  key: any;
  userId; any;
  email: any;
  check6Char: boolean = false;
  check1Num: boolean = false;
  check1Upper: boolean = false;
  check1Lower: boolean = false;
  check1SpecialChar: boolean = false;
  checkPasswordMatch: boolean = false;
  password1: any;
  password2: any;
  subscriptions: Subscription[] = [];
  token: string
  fieldTextType: boolean = false;
  fieldTextType2: boolean = false;
  constructor(
    public router: Router,
    private fb: FormBuilder,
    public commonService: CommonServiceService,
    public toastService: ToastService,
    public snackBar: MatSnackBar,
    public forgotPasswordService: ForgotPasswordService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute) {
  }
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

  get value(): PasswordFormValues {
    return this.resetForm.value;
  }

  set value(value: PasswordFormValues) {
    this.resetForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get passwordControl() {
    return this.resetForm.controls.password1;
  }

  get confirmPasswordControl() {
    return this.resetForm.controls.password2;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.resetForm.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.resetForm.valid ? null : { passwords: { valid: false } };
  }

  ngOnInit() {
    // this.userId=res.data.userId
    this.resetForm = this.fb.group({
      password1: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?#.^+=&])[A-Za-z\d$@$!%*?#.^+=&].{5,50}'), Validators.maxLength(50), Validators.minLength(5)]],
      password2: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?#.^+=&])[A-Za-z\d$@$!%*?#.^+=&].{5,50}'), Validators.maxLength(50), Validators.minLength(5)]]
    }, { validator: this.PasswordMatch });

    this.subscriptions.push(
      this.resetForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    // this.token = this.activatedRoute.snapshot.url[2].path;
    this.activatedRoute.queryParams.subscribe(res=>{
      console.log(res)
      this.token=res['token'];
      this.forgotPasswordService.verifyToken(this.token).subscribe((res: SetNewPasswordModel) => {
        if (res.description == 'Verified Successfully') {
          this.userId = res.data.userId;
        } else {
          this.router.navigate(['/auth/login']);
        }
      }, (error) => {
        this.errorSnackBar(error);
        this.router.navigate(['/auth/login']);
      })
    }
    )
      // params => console.log('queryParams', params['st']));

    console.log(this.token)
   
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  PasswordMatch(group: FormGroup): { [key: string]: boolean } | null {
    if (group.value.password1 != group.value.password2) {
      return { 'matchPassword': true };
    }
    return null;
  }

  Checker(e) {
    this.password1 = e.target.value;
    if (this.password1.length >= 6) {
      this.check6Char = true;
    } else {
      this.check6Char = false;
    }
    var a = 'sfadsdf';
    if (this.password1.match('(?=.*[0-9]){1,50}')) {
      this.check1Num = true;
    } else {
      this.check1Num = false;
    }
    if (this.password1.match('(?=.*[A-Z]){1,50}')) {
      this.check1Upper = true;
    }
    else {
      this.check1Upper = false;
    }
    if (this.password1.match('(?=.*[a-z]).{1,50}')) {
      this.check1Lower = true;
    } else {
      this.check1Lower = false;
    }
    if (this.password1.match('(?=.*[$@$!%*?#.^+=&]){1,50}')) {
      this.check1SpecialChar = true;
    } else {
      this.check1SpecialChar = false;
    }
  }

  MatchChecker1(e) {
    this.password1 = e.target.value;
    if (this.password1 == this.password2) {
      this.checkPasswordMatch = true;
    } else {
      this.checkPasswordMatch = false;
    }
  }

  MatchChecker2(e) {
    this.password2 = e.target.value;
    if (this.password1 == this.password2) {
      this.checkPasswordMatch = true;
    } else {
      this.checkPasswordMatch = false;
    }
  }

  onSubmit() {
    const password1 = this.resetForm.value.password1;
    const data = { token: this.token, password: password1, userId: this.userId };
    this.forgotPasswordService.changePassword(data)
      .subscribe((response: any) => {
        this.openSnackBar(response.description);
        this.router.navigate(['/auth/login']);
        // localStorage.clear();
      }, (error) => {
        this.errorSnackBar(error);
      });
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  ngOnDestroy() {
    this.commonService.resetAppTitle();
  }

}



