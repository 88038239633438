import { Component, Input, OnChanges, OnInit, HostListener, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { DialogService } from 'src/app/core/dialog.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DocumentsModel, DocumentContentModel } from 'src/app/modules/admin/modules/documents/models/documents.model';
import * as moment from 'moment';
import { DocumentsService } from 'src/app/modules/admin/modules/documents/services/documents.service';
@Component({
  selector: 'app-mat-move-document',
  templateUrl: './mat-move-document.component.html',
  styleUrls: ['./mat-move-document.component.scss']
})
export class MatMoveDocumentComponent implements OnInit {
  public pageInfo: PaginationModel = new PaginationModel();

  element: any = [];
  noResult: boolean;
  searchEventSubscription: Subscription;
  saveSearch: any = '';
  message;
  showPagination: boolean;
  state = ['two_Attendance Date'];
  show;
  status = [{ id: 2, status: 'Approved' }, { id: 4, status: 'Rejected' }];
  pages;
  private subscriptions: Subscription[] = [];
  pageSizeArray = [50, 100];
  cred = [];
  parentId: any;
  foldersLinkedList: any = [];
  activeListing: string;
  constructor(
    public dialogRef: MatDialogRef<MatMoveDocumentComponent>,
    public router: Router,
    public common: CommonServiceService,
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    public DocumentService: DocumentsService,
    public toast: ToastService,
    public dialog: DialogService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.pageInfo.sortBy = 'title';
    this.pageInfo.sortDir = 'DESC';
    this.pageInfo.size = 8;
    this.pageInfo.page = 0;
  }
  pageSizeChange(event) {
    if (event.value) {
      this.pageInfo.page = 0;
      this.pageInfo.size = event.value;
      this.filterlisting();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.subscriptions.push(this.common.getListingEvent().subscribe(data => {
      this.activeListing = this.common.listingType;
      this.filterlisting();
    }));
    this.subscriptions.push(this.common.getSearchEvent().subscribe(() => {
      this.search();
    }));
    this.showPagination = true;
    this.filterlisting();
  }

  mapData(item, headers) {
    return {
      heading: headers,
      content: item
    };
  }

  SetPage(event: any) {
    this.pageInfo.page = event.page;
    this.pageInfo.size = event.size;
    this.filterlisting();
  }

  filterlisting() {
    this.loaderService.show();
    this.DocumentService.FolderListing(this.data.value, this.pageInfo)
      .subscribe((response: DocumentsModel) => {
        this.pageInfo.TotalRecord = response.totalElements;
        this.pageInfo.totalPages = response.totalPages;
        this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));

        if (this.pageInfo.TotalRecord <= 10) {
          this.showPagination = false;
        }

        if (response.content.length) {
          this.noResult = false;
          this.element = response.content;
          this.element = this.element.map(x => {
            x.date = new Date(x.date * 1000);
            return x;
          });
        } else {
          this.noResult = true;
        }
        this.loaderService.hide();

      },
        (error) => {
          this.loaderService.hide();
          // this.errorSnackBar(error);
        });
  }

  search() {
    if (this.saveSearch !== '') {
      this.common.searchText = this.saveSearch;
      this.saveSearch = '';
    }
    this.showPagination = true;
    this.filterlisting();
  }

  sort(colName: string, sortDirection: any) {
    if (this.pageInfo.sortBy === colName) {
      if (sortDirection == 'ASC') {
        this.pageInfo.sortBy = 'createdOn';
        this.pageInfo.sortDir = 'DESC';
      } else {
        this.pageInfo.sortDir = sortDirection;
      }
    } else {
      this.pageInfo.sortBy = colName;
      this.pageInfo.sortDir = 'ASC';
    }
    this.pageInfo.size = 8;
    this.pageInfo.page = 0;
    this.filterlisting();
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  routebackToFolder(rowIndex, id, type) {
    this.parentId = id;
    this.foldersLinkedList.splice(rowIndex + 1, this.foldersLinkedList.length);
    if (id == 0) {
      this.filterlisting();
      return;
    }

    this.loaderService.show();
    this.DocumentService.OpenUpfolder(id, this.pageInfo, this.data.value.RowId).subscribe((response: DocumentsModel) => {
      this.pageInfo.TotalRecord = response.totalElements;
      this.pageInfo.totalPages = response.totalPages;
      this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));

      if (this.pageInfo.TotalRecord <= 8) {
        this.showPagination = false;
      }

      if (response.content.length) {
        this.noResult = false;
        this.element = response.content;
        this.element = this.element.map(x => {
          x.date = new Date(x.date * 1000);
          return x;
        });
      } else {
        this.noResult = true;
      }
      this.loaderService.hide();

    },
      (error) => {
        this.loaderService.hide();
        // this.errorSnackBar(error);
      });
  }

  reRouteToFolder(rowIndex, id, type) {
    this.parentId = id;
    this.foldersLinkedList.length == 0 ? this.foldersLinkedList.push({
      folderId: 0,
      folderName: 'Root'
    }) : '';
    this.foldersLinkedList.push({
      folderId: id,
      folderName: this.element[rowIndex].title
    });
    this.loaderService.show();
    this.DocumentService.OpenUpfolder(id, this.pageInfo, this.data.value.RowId).subscribe((response: DocumentsModel) => {
      this.pageInfo.TotalRecord = response.totalElements;
      this.pageInfo.totalPages = response.totalPages;
      this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));

      if (this.pageInfo.TotalRecord <= 8) {
        this.showPagination = false;
      }

      if (response.content.length) {
        this.noResult = false;
        this.element = response.content;
        this.element = this.element.map(x => {
          x.date = new Date(x.date * 1000);
          return x;
        });
      } else {
        this.noResult = true;
      }
      this.loaderService.hide();

    },
      (error) => {
        this.loaderService.hide();
        // this.errorSnackBar(error);
      });
  }

  // ----------edit---------------------------------------------------------------------------//
  Move() {
    const data = {
      //for private scope(self)
      accessTypeId: 2,
      parentId: this.parentId || 0,
    };
    this.loaderService.show();
    this.DocumentService.ItemToMove(this.data.value.RowId, data).subscribe(data => {
      this.loaderService.hide();
      this.dialogRef.close(true);
    })
  }

  errorSnackbar(error) {
    if (error.status === 400) {
      this.openSnackBar(this.message, 'Error-Code-400');
    }
    if (error.status === 401) {
      this.openSnackBar(this.message, 'Error-Code-401');
    }
    if (error.status === 404) {
      this.openSnackBar(this.message, 'Error-Code-404');
    }
    if (error.status === 409) {
      this.openSnackBar(this.message, 'Error-Code-409');
    }
    if (error.status === 500) {
      this.openSnackBar(this.message, 'Error-Code-500');
    }
  }

  openSnackBar(message: string, panelClass: string) {
    if (panelClass === 'delete-success') {
      this.toast.opentoast({ toastName: 'deleteAttendanceTypeSuccess', data: { name: '' } });
    } else if (panelClass === 'Error-Code-400') {
      this.toast.opentoast({ toastName: 'error400', data: { name: '' } });
    } else if (panelClass === 'Error-Code-401') {
      this.toast.opentoast({ toastName: 'error401', data: { name: '' } });
    } else if (panelClass === 'Error-Code-404') {
      this.toast.opentoast({ toastName: 'error404', data: { name: '' } });
    } else if (panelClass === 'Error-Code-409') {
      this.toast.opentoast({ toastName: 'deperror409', data: { name: '' } });
    } else if (panelClass === 'Error-Code-500') {
      this.toast.opentoast({ toastName: 'error500', data: { name: '' } });
    } else if (panelClass === 'update-Attendance-success') {
      this.toast.opentoast({ toastName: 'updateAttendanceDetailsSuccess', data: { name: '' } });
    } else if (panelClass === 'BulkUpdateSuccess') {
      this.toast.opentoast({ toastName: 'updateAttendanceDetailsSuccess', data: { name: '' } });
    }
  }
}


