import { Injectable } from '@angular/core';
import * as Msal from 'msal';  
import { Observable } from 'rxjs';
import { SSO } from 'src/app/config/constant';

@Injectable({
  providedIn: 'root'
})
export class MsalUserServiceService {

  private accessToken: any;  
  public clientApplication: Msal.UserAgentApplication;  

  constructor() { 
    this.clientApplication = new Msal.UserAgentApplication(  
      SSO.clientId,   
      'https://login.microsoftonline.com/' + SSO.tenantId,  
      this.authCallback,  
      {  
          // storeAuthStateInCookie: true,  
          cacheLocation: 'sessionStorage'
      });
  } 

public authCallback(errorDesc: string, token: any, error: string, tokenType: any) {  
    if (token) {  

    } else {  
        console.log(error + ':' + errorDesc);  
    }  
}  

public getCurrentUserInfo() { 
  console.log("loggedIn",JSON.parse(sessionStorage.getItem('msal-loggedIn')));
  if(JSON.parse(sessionStorage.getItem('msal-loggedIn'))){ 
    return this.clientApplication['_user'].displayableId;
  }
  else{
    const user = this.clientApplication.loginInProgress(); 
    if(user){
      sessionStorage.setItem('msal-loggedIn',JSON.stringify(user));
      return null;
    } 
    else{
      return null;
    }
  }
} 

public login(){
  this.clientApplication.loginRedirect();
}

public logout() {  
  if(sessionStorage.getItem('msal-loggedIn')){ 
    sessionStorage.removeItem('msal-loggedIn');
    // this.clientApplication.logout();  
  }
  }  

}


