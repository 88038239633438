import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from 'src/app/core/utilities/format-datepicker';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { NameValidator } from 'src/app/modules/shared/validators/name.validator';
import * as moment from 'moment';
// import { PatientService } from '../../services/patient.service';
// import { PatientContentList, PatientContentModel, PatientModel } from '../../models/patient.model';
import { DataModel, GenericPicklistModel } from 'src/app/modules/shared/model/picklist.model';
import { PatientService } from '../../../service/patient.service';
import { PatientDataModel, PatientDescModel, PatientListingModel, PatientResponseDto, PatientUpdateResponseDto, UpdatePatientRequestData, whitelistedVisitorData, whitelistedVisitorModel } from '../../../model/patient-list.model';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ConfigurationFormFieldService } from 'src/app/modules/shared/services/configuration-form-field.service';
import { FormFieldListModel } from 'src/app/modules/shared/model/configuration-form-field';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/app/config/environments/environment';
// import { PatientPicklistModel, DataModel, PatientPicklistModel, GenericPicklistModel } from 'src/app/modules/shared/model/picklist.model';

@Component({
  selector: 'app-update-patient-details-approval',
  templateUrl: './update-patient-details-approval.component.html',
  styleUrls: ['./update-patient-details-approval.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class UpdatePatientDetailsApprovalComponent implements OnInit {
  isActive;
  patientForm: FormGroup;
  patients: any = [];
  organizations: any = [];
  patientes: any = [];
  buildings: any = [];
  holidayCalendarPicklist: any = [];
  leavePolicyPicklist: any = [];
  id;
  updating = false;
  orgId: any;
  message;
  updateStatus;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  // branchList: any = [];
  // buildingList: any = [];
  patientList: any = [];
  countryList: any[];

  customerId = localStorage.getItem('customerId');
  branchId = localStorage.getItem('branchId');
  departmentList: any[];
  isP_IDUpdateRequest = false;
  patientUpdateResponseDto: PatientUpdateResponseDto;
  patientResponseDto: PatientResponseDto
  approvalStatus: number;
  isFNUpdateRequest: boolean = false;
  isLNUpdateRequest: boolean = false;
  isDNUpdateRequest: boolean = false;
  isMNoUpdateRequest: boolean = false;
  isEIDUpdateRequest: boolean = false;
  isCUpdateRequest: boolean = false;
  isRNoUpdateRequest: boolean = false;
  isBNoUpdateRequest: boolean = false;
  isDIdUpdateRequest: boolean = false;
  isADUpdateRequest: boolean = false;
  isMDUpdateRequest: boolean = false;
  bedList: any[];
  roomList: any[];
  doctorList: any;
  isGUpdateRequest: boolean;
  isAgeUpdateRequest: boolean;
  gender: any[];
  additionalFields = [];
  isAdditionalFieldUpdate = [];
  departmentByRoleList: any = [];
  whitelistVisitorDetailsForm: FormGroup;
  careTeamMemberForm: FormGroup;
  rolesList: any = [];
  selectedIndex: any;
  careTeamMemberDataList: any = [];
  filteredBedList: Observable<string[]>;
  filteredRoomList: Observable<string[]>;
  filteredDoctors: Observable<string[]>;
  filteredGenders: Observable<string[]>;
  filteredDepartments: Observable<string[]>;
  filteredCountries: Observable<string[]>;
  filteredDepartmentByRoleList: Observable<string[]> = new Observable<string[]>();
  filteredRoleList: Observable<string[]> = new Observable<string[]>();

  constructor(
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public commonService: CommonServiceService,
    private router: Router,
    private fb: FormBuilder,
    private picklistService: PicklistService,
    private patientService: PatientService,
    private loaderService: LoaderService,
    private configurationFormFieldService: ConfigurationFormFieldService
  ) { }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_APPROVAL');
    this.commonService.callToggleAddEmp.subscribe(() => {
      this.change();
    });
    this.initializeForm();
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    // this.getRoomNumber();
    this.getGender();
    // this.getDoctor();
    this.getCountry();
    this.getDepartment();
    this.getParams();
    this.getRoles();
    this.whitelistVisitorDetailsForm = this.fb.group({
      whitelistVisitors: this.fb.array([]),
    });
    this.careTeamMemberForm = this.fb.group({
      careTeamMembers: this.fb.array([]),
    });
    this.patientForm.controls['patientId'].disable();
  }

  getParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.updating = true;
        this.id = params.id;
        this.patchValuesInPatientForm();
      }
    });
  }

  getFormControlList(result) {
    this.loaderService.show();
    this.configurationFormFieldService.getListing(environment.patientApiUrl, 'patient').subscribe((res: FormFieldListModel) => {
      this.loaderService.hide();
      res.data.forEach(item => {
        let control = new FormControl();
        control.valueChanges.subscribe(x => {
        });
        if (item.hidden) {
          this.patientForm.addControl(item.fieldCode, control);
          this.additionalFields.push(item);
        }
      })
      this.additionalFields.forEach(item => {
        if (item.hidden) {
          if (!result.data.patientUpdateResponseDto[item.fieldCode] || (result.data.patientResponseDto[item.fieldCode] == result.data.patientUpdateResponseDto[item.fieldCode])) {
            this.isAdditionalFieldUpdate.push(false);
            this.patientForm.get(item.fieldCode).patchValue(result.data.patientResponseDto[item.fieldCode]);
          }
          else {
            this.isAdditionalFieldUpdate.push(true);
            this.patientForm.get(item.fieldCode).patchValue(result.data.patientUpdateResponseDto[item.fieldCode]);

          }
        }
      })
    })
  }
  initializeForm() {
    this.patientForm = this.fb.group({
      patientId: ['', [
        Validators.required
      ]],
      firstName: ['', [Validators.required, NameValidator]],
      lastName: ['', [Validators.required, NameValidator]],
      doctorName: ['', [Validators.required]],
      doctorFilterCtrl: [''],
      departmentId: ['', [Validators.required]],
      departmentFilterCtrl: [''],
      country: ['', [Validators.required]],
      countryFilterCtrl: [''],
      email: [''],
      mobileNo: ['', [Validators.required]],
      admittedDate: [''],
      roomNumber: ['', [Validators.required]],
      roomFilterCtrl: [''],
      bedNumber: ['', [Validators.required]],
      bedFilterCtrl: [''],
      approvalStatus: [''],
      userStatus: [''],
      age: ['', [Validators.required, Validators.min(1)]],
      gender: ['', [Validators.required]],
      genderFilterCtrl: [''],
    });
  }

  change() {
    this.isActive = !this.isActive;
  }

  getGender() {
    this.gender = [];
    this.loaderService.show();
    this.picklistService.getGender().subscribe((res: any) => {
      this.loaderService.hide();
      if (res.data.length) {
        res.data.map((x) => {
          this.gender.push({ id: x.id, name: x.name });
        });
      }
      this.filteredGenders = this.patientForm.controls['genderFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => this.commonService.dataFilter(value, this.gender))
        );
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }

  patchValuesInPatientForm() {
    this.loaderService.show();
    this.patientService
      .getPatientUpdateRequestList(this.id)
      .subscribe((res: UpdatePatientRequestData) => {
        this.loaderService.hide();
        this.patientResponseDto = res.data.patientResponseDto;
        this.patientUpdateResponseDto = res.data.patientUpdateResponseDto;
        this.patchWhitelistedVisitorValues(res.data.patientUpdateResponseDto.whiteListed);
        this.patchCareTeamMemberValues(res.data.patientUpdateResponseDto.moderators);
        if (res.data.patientResponseDto.patientId == res.data.patientUpdateResponseDto.patientId) {
          this.patientForm.get('patientId').patchValue(res.data.patientResponseDto.patientId);
        }
        else {
          this.isP_IDUpdateRequest = true;
          this.patientForm.get('patientId').patchValue(res.data.patientUpdateResponseDto.patientId);
        }
        // this.getPatient();
        if (!res.data.patientUpdateResponseDto.firstName || (res.data.patientResponseDto.firstName == res.data.patientUpdateResponseDto.firstName)) {
          this.patientForm.get('firstName').patchValue(res.data.patientResponseDto.firstName);
        }
        else {
          this.isFNUpdateRequest = true;
          this.patientForm.get('firstName').patchValue(res.data.patientUpdateResponseDto.firstName);

        }
        if (!res.data.patientUpdateResponseDto.lastName || (res.data.patientResponseDto.lastName == res.data.patientUpdateResponseDto.lastName)) {
          this.patientForm.get('lastName').patchValue(res.data.patientResponseDto.lastName);
        }
        else {
          this.isLNUpdateRequest = true;
          this.patientForm.get('lastName').patchValue(res.data.patientUpdateResponseDto.lastName);

        }
        if (!res.data.patientUpdateResponseDto.departmentId || (res.data.patientUpdateResponseDto.departmentId == res.data.patientResponseDto.departmentId)) {
          this.patientForm.get('departmentId').patchValue(res.data.patientResponseDto.departmentId);
        }
        else {
          this.isDIdUpdateRequest = true;
          this.patientForm.get('departmentId').patchValue(res.data.patientUpdateResponseDto.departmentId);
        }
        this.getDoctor();
        if (!res.data.patientUpdateResponseDto.doctorId || (res.data.patientResponseDto.doctorId == res.data.patientUpdateResponseDto.doctorId)) {
          this.patientForm.get('doctorName').patchValue({ id: res.data.patientResponseDto.doctorId, name: res.data.patientResponseDto.doctorName });
        }
        else {
          this.isDNUpdateRequest = true;
          this.patientForm.get('doctorName').patchValue({ id: res.data.patientUpdateResponseDto.doctorId, name: res.data.patientUpdateResponseDto.doctorName });
        }
        if (!res.data.patientUpdateResponseDto.email || (res.data.patientResponseDto.email == res.data.patientUpdateResponseDto.email)) {
          this.patientForm.get('email').patchValue(res.data.patientResponseDto.email);
        }
        else {
          this.isEIDUpdateRequest = true;
          this.patientForm.get('email').patchValue(res.data.patientUpdateResponseDto.email);

        }
        if (!res.data.patientUpdateResponseDto || (res.data.patientResponseDto.mobilePhone == res.data.patientUpdateResponseDto.mobilePhone)) {
          this.patientForm.get('mobileNo').patchValue(res.data.patientResponseDto.mobilePhone);
        }
        else {
          this.isMNoUpdateRequest = true;
          this.patientForm.get('mobileNo').patchValue(res.data.patientUpdateResponseDto.mobilePhone);

        }
        if (!res.data.patientUpdateResponseDto.countryCode || (res.data.patientResponseDto.countryCode == res.data.patientUpdateResponseDto.countryCode)) {
          this.patientForm.get('country').patchValue({ countryCode: res.data.patientResponseDto.countryCode, id: res.data.patientResponseDto.countryId });
        }
        else {
          this.isCUpdateRequest = true;
          this.patientForm.get('country').patchValue(res.data.patientUpdateResponseDto.countryCode);
        }

        if ((!res.data.patientUpdateResponseDto.gender) || (res.data.patientResponseDto.gender.id == res.data.patientUpdateResponseDto.gender.id)) {
          this.patientForm.get('gender').patchValue(res.data.patientResponseDto.gender);
        }
        else {
          this.isGUpdateRequest = true;
          this.patientForm.get('gender').patchValue(res.data.patientUpdateResponseDto.gender);
        }
        if (!(res.data.patientUpdateResponseDto.age) || (res.data.patientResponseDto.age == res.data.patientUpdateResponseDto.age)) {
          this.patientForm.get('age').patchValue(res.data.patientResponseDto.age);
        }
        else {
          this.isAgeUpdateRequest = true;
          this.patientForm.get('age').patchValue(res.data.patientUpdateResponseDto.age);
        }
        this.getRoomNumber();
        if (!res.data.patientUpdateResponseDto.roomId || (res.data.patientResponseDto.roomId == res.data.patientUpdateResponseDto.roomId)) {
          let room = this.roomList.find(item => item.id == res.data.patientResponseDto.roomId);
          room && room.id ? "" : this.roomList.push({ id: res.data.patientResponseDto.roomId, name: res.data.patientResponseDto.roomNumber });
          this.patientForm.get('roomNumber').patchValue({ id: res.data.patientResponseDto.roomId, name: res.data.patientResponseDto.roomNumber });
        }
        else {
          this.isRNoUpdateRequest = true;
          let room = this.roomList.find(item => item.id == res.data.patientUpdateResponseDto.roomId);
          room && room.id ? "" : this.roomList.push({ id: res.data.patientUpdateResponseDto.roomId, name: res.data.patientUpdateResponseDto.roomNumber });
          this.patientForm.get('roomNumber').patchValue({ id: res.data.patientUpdateResponseDto.roomId, name: res.data.patientUpdateResponseDto.roomNumber });
        }
        this.getBedNumber();

        if (!res.data.patientUpdateResponseDto.bedId || (res.data.patientUpdateResponseDto.bedId == res.data.patientResponseDto.bedId)) {
          let bed = this.bedList.find(item => item.id == res.data.patientUpdateResponseDto.bedId);
          bed && bed.id ? "" : this.bedList.push({ id: res.data.patientResponseDto.bedId, name: res.data.patientResponseDto.bedNumber });
          this.patientForm.get('bedNumber').patchValue({ id: res.data.patientResponseDto.bedId, name: res.data.patientResponseDto.bedNumber });
        }
        else {
          this.isBNoUpdateRequest = true;
          let bed = this.bedList.find(item => item.id == res.data.patientUpdateResponseDto.bedId);
          bed && bed.id ? "" : this.bedList.push({ id: res.data.patientUpdateResponseDto.bedId, name: res.data.patientUpdateResponseDto.bedNumber });
          this.patientForm.get('bedNumber').patchValue({ id: res.data.patientUpdateResponseDto.bedId, name: res.data.patientUpdateResponseDto.bedNumber });
        }
        if (!res.data.patientUpdateResponseDto.admittedDate || (res.data.patientUpdateResponseDto.admittedDate == res.data.patientResponseDto.admittedDate)) {
          res.data.patientResponseDto.admittedDate ? this.patientForm.get('admittedDate').patchValue(new Date(res.data.patientResponseDto.admittedDate).toISOString()) : '';
        }
        else {
          this.isADUpdateRequest = true;
          res.data.patientUpdateResponseDto.admittedDate ? this.patientForm.get('admittedDate').patchValue(new Date(res.data.patientUpdateResponseDto.admittedDate).toISOString()) : '';
        }
        this.careTeamMemberDataList = res.data.moderators;
        this.getFormControlList(res);
      });
  }

  Upper(data: string) {
    return data.toUpperCase();
  }

  addWhitelistVisitorsFormGroup(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      countryCode: ['', [Validators.required]],
      phoneNo: ['', [Validators.required, Validators.pattern('^([5-9]{1})([0-9]{5,12})$')]]
    });
  }

  whitelistVisitors(): FormArray {
    return this.whitelistVisitorDetailsForm.get("whitelistVisitors") as FormArray
  }

  addWhitelistVisitor() {
    (<FormArray>this.whitelistVisitorDetailsForm.get('whitelistVisitors')).push(this.addWhitelistVisitorsFormGroup());
    if (this.updating === true) {
      this.whitelistVisitorDetailsForm.disable();
    } else {
      this.whitelistVisitorDetailsForm.enable();
    }
  }

  removeWhitelistVisitor(i) {
    (<FormArray>this.whitelistVisitorDetailsForm.get('whitelistVisitors')).removeAt(i);
  }

  addCareTeamMembersFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      role: [''],
      departmentId: ['']
    });
  }

  careTeamMembers(): FormArray {
    return this.careTeamMemberForm.get("careTeamMembers") as FormArray
  }

  addCareTeamMember() {
    (<FormArray>this.careTeamMemberForm.get('careTeamMembers')).push(this.addCareTeamMembersFormGroup());
    if (this.updating === true) {
      this.careTeamMemberForm.disable();
    } else {
      this.careTeamMemberForm.enable();
    }
  }

  tabChange(value) {
    this.selectedIndex = value;
  }

  removeCareTeamMember(i) {
    (<FormArray>this.careTeamMemberForm.get('careTeamMembers')).removeAt(i);
  }

  patchWhitelistedVisitorValues(whitelistVisitorsData) {
    const whitelistedVisitorFormArray = <FormArray>this.whitelistVisitorDetailsForm.controls['whitelistVisitors'];
    whitelistVisitorsData.forEach(x => {
      whitelistedVisitorFormArray.push(this.patch(x));
    });
    if (this.updating === true) {
      this.whitelistVisitorDetailsForm.disable();
    } else {
      this.whitelistVisitorDetailsForm.enable();
    }
  }

  patch(whitelistedVisitor: whitelistedVisitorData) {
    return this.fb.group({
      id: [whitelistedVisitor.id],
      name: [whitelistedVisitor.name, [Validators.required]],
      countryCode: [{ id: whitelistedVisitor.countryId, countryCode: whitelistedVisitor.countryCode }, [Validators.required]],
      phoneNo: [whitelistedVisitor.phoneNo, [Validators.required]]
    });
  }

  patchCareTeamMemberValues(data) {
    const careTeamMemberFormArray = <FormArray>this.careTeamMemberForm.controls['careTeamMembers'];
    careTeamMemberFormArray.clear();
    data.forEach((x, index) => {
      careTeamMemberFormArray.push(this.patchCareTeamMemberFormValue(x));
      this.getDepartmentByRole(index);
    });
    this.careTeamMemberForm.disable();
  }

  patchCareTeamMemberFormValue(careTeamMember: any) {
    let departmentData = [];
    careTeamMember.departmentIds.forEach((item, index) => {
      departmentData.push({
        id: item,
        locationCode: careTeamMember.locationCodes[index]
      })
    })
    return this.fb.group({
      id: [careTeamMember.id],
      role: [{ id: careTeamMember.roleId, roleCode: careTeamMember.roleCode }],
      departmentId: [departmentData]
    });
  }

  getRoles(i?) {
    this.loaderService.show();
    this.rolesList = [];
    this.picklistService.getRole(this.customerId)
      .subscribe((res: any) => {
        if (res.data.length) {
          res.data.map((x) => {
            if (x.active == true && (x.roleCode != 'PATIENT' && x.roleCode != 'VISITOR')) {
              this.rolesList.push({ id: x.id, name: x.name, roleCode: x.roleCode })
            };
          });
        }
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
  }

  getDepartmentByRole(index) {
    let roleId = this.careTeamMembers().controls[index].value.role.id
    if (roleId) {
      this.loaderService.show();
      this.departmentByRoleList[index] = [];
      this.picklistService.getDepartmentByRole(this.branchId, roleId).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          res.data.map((x) => {
            this.departmentByRoleList[index].push({ id: x.id, name: x.name, locationCode: x.locationCode });
          });
        }
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error)
      });
    }
  }

  getCountry() {
    this.countryList = [];
    this.loaderService.show();
    this.picklistService.getCountry().subscribe((res: Array<GenericPicklistModel>) => {
      this.loaderService.hide();
      res.map((x: GenericPicklistModel) => {
        this.countryList.push({ id: x.id, name: x.name, code: x.code, countryCode: +x.countryCode });
      });
      this.filteredCountries = this.patientForm.controls['countryFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => this.commonService.dataFilter(value, this.countryList))
        );
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }

  getDepartment() {
    this.departmentList = [];
    this.loaderService.show();
    this.picklistService.getDepartment(this.branchId, this.customerId).subscribe((res: DataModel) => {
      this.loaderService.hide();
      if (res.data.length) {
        res.data.map((x) => {
          this.departmentList.push({ value: x.id, name: x.name });
        });
      }
      this.filteredDepartments = this.patientForm.controls['departmentFilterCtrl'].valueChanges.
        pipe(
          startWith(''),
          map(value => { return this.commonService.dataFilter(value, this.departmentList) })
        );
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
    // }
  }

  objectComparisonFunction(option, value) {
    return option.name === value.name && option.id === value.id;
  }

  checkApprovalStatus() {
    if (this.patientResponseDto.patientId != this.patientForm.controls['patientId'].value)
      if (this.patientUpdateResponseDto.patientId != this.patientForm.controls['patientId'].value) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.firstName != this.patientForm.value.firstName)
      if (this.patientUpdateResponseDto.firstName != this.patientForm.value.firstName) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.lastName != this.patientForm.value.lastName)
      if (this.patientUpdateResponseDto.lastName != this.patientForm.value.lastName) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.doctorName != this.patientForm.value.doctorName.id)
      if (this.patientUpdateResponseDto.doctorName != this.patientForm.value.doctorName) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.age != this.patientForm.value.age)
      if (this.patientUpdateResponseDto.doctorName != this.patientForm.value.doctorName) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.gender.id != this.patientForm.value.gender.id)
      if (this.patientUpdateResponseDto.doctorName != this.patientForm.value.doctorName) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.email != this.patientForm.value.email)
      if (this.patientUpdateResponseDto.email != this.patientForm.value.email) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.mobilePhone != this.patientForm.value.mobileNo)
      if (this.patientUpdateResponseDto.mobilePhone != this.patientForm.value.mobileNo) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.countryCode != this.patientForm.value.country)
      if (this.patientUpdateResponseDto.countryCode != this.patientForm.value.country) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.roomNumber != this.patientForm.value.roomNumber)
      if (this.patientUpdateResponseDto.roomNumber != this.patientForm.value.roomNumber) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.bedNumber != this.patientForm.value.bedNumber)
      if (this.patientUpdateResponseDto.bedNumber != this.patientForm.value.bedNumber) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.departmentId != this.patientForm.value.departmentId)
      if (this.patientUpdateResponseDto.departmentId != this.patientForm.value.departmentId) {
        return this.approvalStatus = 3;
      }
    if (this.patientResponseDto.admittedDate != this.patientForm.value.admittedDate)
      if (this.patientUpdateResponseDto.admittedDate != this.patientForm.value.admittedDate) {
        return this.approvalStatus = 3;
      }
  }

  patientPayloadData() {
    const admittedDate = new Date(this.patientForm.value.admittedDate)
    const body = {
      firstName: this.patientForm.value.firstName,
      lastName: this.patientForm.value.lastName,
      doctorName: this.patientForm.value.doctorName.name,
      branchId: this.branchId,
      patientId: this.patientForm.controls['patientId'].value,
      countryCode: this.patientForm.value.country,
      age: this.patientForm.value.age,
      genderId: this.patientForm.value.gender.id,
      mobilePhone: this.patientForm.value.mobileNo,
      departmentId: this.patientForm.value.departmentId,
      roomNumber: this.patientForm.value.roomNumber.id,
      bedNumber: this.patientForm.value.bedNumber.id,
      admittedDate: admittedDate.getTime(),
      email: this.patientForm.value.email,
      customerId: this.customerId
    }
    return body;
  }

  approveUpdateRequest(approvalStatus) {
    const patientId = this.patientResponseDto.id;
    this.loaderService.show();
    this.patientService.updateApproval(patientId, { approvalStatus: approvalStatus, bedId: this.patientForm.controls.bedNumber.value.id ,userId: parseInt(localStorage.getItem('LogoutId'))})
      .subscribe((res: any) => {
        this.loaderService.hide();
        this.openSnackBar(res.description);
        this.router.navigate(['admin/patient-list/patient-onboard']);
      },
        (error) => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        });
  }

  getDoctor() {
    this.doctorList = [];
    if (this.patientForm.controls.departmentId.value) {
      this.loaderService.show();
      this.picklistService.getDoctor(this.branchId, this.customerId, this.patientForm.controls.departmentId.value).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.length) {
          res.map((x) => {
            this.doctorList.push({ id: x.id, name: 'Dr. ' + x.name });
          });
        }
        this.filteredDoctors = this.patientForm.controls['doctorFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.doctorList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  getRoomNumber() {
    this.roomList = [];
    const departmentId = this.patientForm.value.departmentId;
    if (departmentId) {
      this.loaderService.show();
      this.picklistService.getRoomForPatientByDept(this.customerId, this.branchId, departmentId).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          // this.roomList = res.data;
          res.data.map((x) => {
            this.roomList.push({ id: x.id, name: x.name });
          });
        }
        this.filteredRoomList = this.patientForm.controls['roomFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.roomList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  getBedNumber() {
    this.bedList = [];
    // const departmentId = this.patientForm.value.departmentId;
    const roomId = this.patientForm.value.roomNumber.id;
    if (roomId) {
      this.loaderService.show();
      this.patientService.getBedByRoom(this.customerId, this.branchId, roomId, "", true).subscribe((res: any) => {
        this.loaderService.hide();
        if (res.data.length) {
          // this.bedList = res.data;
          res.data.map((x) => {
            this.bedList.push({ id: x.id, name: x.name });
          });
        }
        this.filteredBedList = this.patientForm.controls['bedFilterCtrl'].valueChanges.
          pipe(
            startWith(''),
            map(value => { return this.commonService.dataFilter(value, this.bedList) })
          );
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
    }
  }

  onSubmit3() {
    this.loaderService.show();
    const body = this.patientPayloadData();
    this.patientService.addPatient({ ...body, customerId: this.customerId }, {})
      .subscribe((res: any) => {
        this.loaderService.hide();
        this.openSnackBar(res.description);
        this.router.navigate(['admin/patient-list']);
      },
        (error) => {
          this.loaderService.hide();
          this.errorSnackBar(error);
        });
    // }
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error }, type: 'error' },
      ErrorToasterComponent
    );
  }

  openSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  departmentComparision(option, value) {
    return option.id == value.id;// && option.locationCode == value.locationCode;
  }

  roleComparision(option, value) {
    return option.roleCode === value.roleCode && option.id === value.id;
  }

  cancel() {
    this.router.navigate(['admin/patient-list/patient-onboard']);
  }

  countryComparision(option, value) {
    return value.id == option.id;
  }
}
