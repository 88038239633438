import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { PaginationModel } from '../../model/pagination-model';

@Component({
  selector: 'app-pagination-component',
  templateUrl: './pagination.html',
  styleUrls: ['./pagination.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnChanges {
  @Input() public pageInfo: PaginationModel;
  @Output() pageInfoChange = new EventEmitter<PaginationModel>();
  showPagination: boolean = false;
  LastPage: number;
  totalPage: any[];

  constructor() { }

  ngOnChanges() {
    this.setPagination();
  }

  setPagination(): any {
    if (this.pageInfo && this.pageInfo.TotalRecord > this.pageInfo.size) {
      var totalPages = this.pageInfo.totalPages;
      this.LastPage = totalPages - 1;
      this.totalPage = this.getVisiblePageIndex(totalPages, this.pageInfo.page);
      this.showPagination = true;
    } else {
      this.showPagination = false;
    }
  }
  getVisiblePageIndex(totalPages: number, CurrentPage: number) {
    // For shifting the Exceeding size of the total no of pages
    var array = [];
    if (totalPages > 5) {
      var j = 1;
      for (let i = 0; i < 5 || array.length < 5; i++) {
        if (CurrentPage - (1 - i) >= 1 || CurrentPage - (1 - i) <= totalPages) {
          if (CurrentPage - (1 - i) > 0)
            if (CurrentPage - (1 - i) <= totalPages) {
              array.push(CurrentPage - (1 - i));
            } else {
              array.unshift(CurrentPage - (1 + j));
              j++;
            }
        }
      }
      return array;
    } else {
      for (var i = 1; i <= totalPages; i++) {
        array.push(i);
      }
      return array;
    }
  }
  SetPage(index: number) {
    this.pageInfo.page = Number(index);
    this.pageInfoChange.emit(this.pageInfo); // Emit events on page change
  }
  changePageSize(val) {
    this.pageInfo.size = Number(val);
    this.pageInfo.page = 0;
    this.pageInfoChange.emit(this.pageInfo); // Emit events on page size change
  }
}
