import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from 'src/app/core/utilities/format-datepicker';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { ReasonService } from '../../../service/reason.service';
import { ReasonDataModel, PatientListingModel } from '../../../model/patient-list.model';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';

@Component({
  selector: 'app-add-reason',
  templateUrl: './add-reason.component.html',
  styleUrls: ['./add-reason.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class AddReasonComponent implements OnInit {
  isActive;
  reasonForm: FormGroup;
  buildings: any = [];
  id;
  updating = false;
  message;
  updateStatus;
  permissionsObject = {
    read: false,
    create: false,
    update: false,
    delete: false,
  };
  customerId = localStorage.getItem('customerId');
  branchId = localStorage.getItem('branchId');
  constructor(
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public commonService: CommonServiceService,
    private router: Router,
    private fb: FormBuilder,
    private patientService: ReasonService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT_BLACKLIST_REASON');
    this.commonService.callToggleAddEmp.subscribe(() => {
      this.change();
    });
    this.getParams();
    this.initializeForm();
    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
  change() {
    this.isActive = !this.isActive;
  }
  getParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.updating = true;
        this.id = params.id;
        this.patchValuesInReasonForm();
      }
    });
  }

  initializeForm() {
    this.reasonForm = this.fb.group({
      reason: ['', [Validators.required]],
      status : [true]
    });
  }

  patchValuesInReasonForm() {
    this.loaderService.show();
    this.patientService
      .getReasonById(this.id)
      .subscribe((res: ReasonDataModel) => {
        this.loaderService.hide();
        this.reasonForm.get('reason').patchValue(res.data.reason);
        this.reasonForm.get('status').patchValue(res.data.active);
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      });
  }

  onSubmit3() {
    this.loaderService.show();
    const body = {
      reason: this.reasonForm.value.reason,
      branchId: this.branchId,
      customerId: this.customerId,
      active : this.reasonForm.value.status
    }
    const reasonId = this.id;
    if (this.updating) {
      this.patientService.updateReason(body, reasonId)
        .subscribe((res: PatientListingModel) => {
          this.loaderService.hide();
          this.successSnackBar(res.description);
          this.router.navigate(['admin/patient-list/blacklist-reason']);
        },
          (error) => {
            this.loaderService.hide();
            this.errorSnackBar(error);
          });
    } else {
      this.patientService.addReason(body)
        .subscribe((res: PatientListingModel) => {
          this.loaderService.hide();
          this.successSnackBar(res.description);
          this.router.navigate(['admin/patient-list/blacklist-reason']);
        },
          (error) => {
            this.loaderService.hide();
            this.errorSnackBar(error);
          });
    }
  }

  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }
  errorSnackBar(error) {
      this.toastService.opentoast(
        { toastName: '', data: { errorCode: error.status, error: error.error } },
        ErrorToasterComponent
      );
  }

  cancel() {
    this.router.navigate(['admin/patient-list/blacklist-reason']);
  }
}
