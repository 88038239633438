import { Component, OnInit, ViewChild, OnDestroy, Inject, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { DialogService } from 'src/app/core/dialog.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { ToasterComponent } from '../toaster/toaster.component';
import { ErrorToasterComponent } from '../error-toaster/error-toaster.component';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit, OnDestroy {
  public QRTypeForm: FormGroup;
  public isVerified = false;
  public resend = false;
  private display: string;
  private time;
  public isOtpVerified = false;
  isValidOtp = false;
  private otp: string = '';
  public showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    public toastService: ToastService,
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<OtpComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public dialog: MatDialog
  ) { }

  public ngOnInit() {
    this.initialiseForm();
  }

  private initialiseForm() {

  }


  public openDialog(): void {
    // const dialogRef = this.dialog.open(ServiceDescComponent, {
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  public onOtpChange(otp) {
    this.otp = otp;
    if (this.otp.length === 4) {
      this.isValidOtp = true;
    } else {
      this.isValidOtp = false;
    }
  }

  public validateAlphaNumericInput(event) {
    // if (event) {
    //   return this.utilService.numberOnly(event);
    // }
    // return true;
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
  private startTimer(duration) {
    this.display = "02:00";
    this.resend = false;
    var timer = duration, minutes, seconds;
    this.time = setInterval(() => {
      minutes = Math.floor(timer / 60);
      seconds = timer % 60;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      this.display = minutes + ":" + seconds;
      document.getElementById("time").innerHTML = this.display;
      if (--timer < 0) {
        this.resend = true;
        window.clearInterval(this.time);
      }
    }, 1000);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onVerifyClick(): void {
    this.data.verifyOtp(this.otp).subscribe(res => {
      if (res.data) {
        this.successSnackBar(res['description']);
        this.dialogRef.close(true);
      } else {
        this.successSnackBar(res['description']);
      }
      this.loaderService.hide();
    }, (error) => {
      this.loaderService.hide();
      this.errorSnackBar(error);
    });
  }


  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }

  public ngOnDestroy() {
    window.clearInterval(this.time);
  }
}
